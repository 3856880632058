import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container, Accordion } from "react-bootstrap";
import faqData from "../../hooks/faqData";
import SectionTitleSecondary from "../../components/SectionTitleSecondary/SectionTitleSecondary";
import "./FrequentlyAskedQuestionsPage.css";
import CallToAction from "../../components/CallToAction/CallToAction";

const FrequentlyAskedPage = () => {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./FrequentlyAskedQuestionsPage-ar.css");
    } else {
      import("./FrequentlyAskedQuestionsPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <>
      <div className="faq-page section-spacing">
        <Container>
          <SectionTitleSecondary
            title={t("Frequently Asked Questions")}
            subtitle="Your questions relevant to SUZUKI, answered by Najeeb Auto"
          />
          <Accordion className="mt-5">
            {faqData.map((faq, index) => (
              <Accordion.Item className="brand-acc-faq-item" eventKey={index.toString()} key={index}>
                <Accordion.Header className={`${i18n.language === "ar" ? "brand-acc-faq-header rtl-acc-arrow" : "brand-acc-faq-header"}`}>{faq.title[currentLanguage]}</Accordion.Header>
                <Accordion.Body className="brand-acc-faq-body" dangerouslySetInnerHTML={{ __html: faq.content[currentLanguage] }} />
              </Accordion.Item>
            ))}
          </Accordion>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default FrequentlyAskedPage;
