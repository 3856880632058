import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import "./FleetSalesInquiryForm.css";

const FleetSalesInquiryForm = ({ cities, vehicles, regions }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./FleetSalesInquiryForm-ar.css");
    } else {
      import("./FleetSalesInquiryForm.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      contactNumber: "",
      email: "",
      gender: "",
      companyName: "",
      vehicle: "",
      numberOfVehicles: "",
      region: "",
      city: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t("Full Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z\s]+$/, t("Only characters are allowed"))
        .min(3, t("Full Name should be at least 3 characters long")),
      contactNumber: Yup.string()
        .required(t("Contact Number is required"))
        .matches(
          /^05\d{8}$/,
          t("Contact Number should start with 05 and be 10 digits long")
        ),
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid email format")),
      gender: Yup.string().required(t("Gender is required")),
      companyName: Yup.string()
        .required(t("Company Name is required"))
        .min(2, t("Company Name should be at least 2 characters long")),
      vehicle: Yup.string().required(t("Vehicle is required")),
      numberOfVehicles: Yup.number()
        .required(t("Number of Vehicles is required"))
        .positive(t("Number of Vehicles must be a positive number"))
        .integer(t("Number of Vehicles must be an integer")),
      region: Yup.string().required(t("Region is required")),
      city: Yup.string().required(t("City is required")),
      message: Yup.string().required(t("Message is required")),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("Name", values.name);
      formData.append("Gender", values.gender);
      formData.append("Phone", values.contactNumber);
      formData.append("Email", values.email);
      formData.append("Region", values.region);
      formData.append("City", values.city);
      formData.append("Company_name", values.companyName);
      formData.append("Number_vehicle", values.numberOfVehicles);
      formData.append("Vehicle", values.vehicle);
      formData.append("Message", values.message);

      axios
        .post("https://najeebapi.nhcops.com/public/api/fleet_sales", formData)
        .then((response) => {
          setIsSubmitting(false);
          if (response.data === "done") {
            setIsSubmitted(true);
            formik.resetForm();
          } else {
            alert("Error submitting form.");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setSubmissionError("Error submitting form.");
          console.error(error);
        });
    },
  });

  if (isSubmitted) {
    return (
      <div className="success-message text-center">
        <h3>{t("Submitted Successfully!")}</h3>
        <p>{t("Our team will reach you as soon as possible.")}</p>
      </div>
    );
  } else if (submissionError) {
    return (
      <div className="error-message text-center">
        <h3>{t("Submission Failed!")}</h3>
        <p>{t("Please try again after sometime.")}</p>
      </div>
    );
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <div className="form-instruction">
          <h3>{t("Fill in the form below")}</h3>
          <p>
            {t("Provide us your requirements and our Fleet Sales Manager will be in touch with you.")}
          </p>
        </div>
        <Row>
          <Col md={12}>
            <Form.Group controlId="formName">
              <Form.Label className="brand-label">
                {t("Full Name")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formContactNumber">
              <Form.Label className="brand-label">
                {t("Contact Number")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.contactNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contactNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="brand-label">
                {t("Email Address")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formGender">
              <Form.Label className="brand-label">
                {t("Select Gender")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.gender}
              >
                <option value="">{t("Select Gender")}</option>
                <option value="Male">{t("Male")}</option>
                <option value="Female">{t("Female")}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCompanyName">
              <Form.Label className="brand-label">
                {t("Company Name")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.companyName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.companyName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formVehicle">
              <Form.Label className="brand-label">
                {t("Select Vehicle")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="vehicle"
                value={formik.values.vehicle}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.vehicle}
              >
                <option value="">{t("Select Vehicle")}</option>
                {vehicles.map((vehicle, index) => (
                  <option key={index} value={vehicle}>
                    {vehicle}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.vehicle}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formNumberOfVehicles">
              <Form.Label className="brand-label">
                {t("Number of Vehicles")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="number"
                name="numberOfVehicles"
                value={formik.values.numberOfVehicles}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.numberOfVehicles}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.numberOfVehicles}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formRegion">
              <Form.Label className="brand-label">
                {t("Select Region")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.region}
              >
                <option value="">{t("Select Region")}</option>
                {regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.region}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label className="brand-label">
                {t("Select City")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.city}
              >
                <option value="">{t("Select City")}</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="formMessage">
              <Form.Label className="brand-label">
                {t("Message")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                as="textarea"
                rows={3}
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-grid gap-2 col-6 mx-auto">
          <Button
            className="form-brand-btn"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner animation="border" size="sm" /> : t("Submit")}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default FleetSalesInquiryForm;
