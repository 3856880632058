import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import OverviewCard from "../../components/OverviewCard/OverviewCard";
import "./OverviewPage.css";
import BookServiceAppointmentIcon from "../../images/book-service-appointment.png";
import ServiceMaintenanceIcon from "../../images/service-maintenance.png";
import MaintenanceCalculatorIcon from "../../images/suzuki-maintenance-calculator.png";
import FAQIcon from "../../images/faq-icon.png";
import WarrantyIcon from "../../images/suzuki-warranty-icon.png";
import RightsIcon from "../../images/know-your-rights-icon.png";
import RoadsideAssistanceIcon from "../../images/roadside-assistance-icon.png";
import BuySparePartsOnlineIcon from "../../images/buy-spare-parts-online-icon.png";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";

const overviewCardsData = [
  {
    image: BookServiceAppointmentIcon,
    title: {
      en: "Book Service Appointment",
      ar: "احجز موعد الخدمة",
    },

    description: {
      en: "Service Appointment made easy for your Suzuki car by Najeeb Auto",
      ar: "جعل حجز موعد الخدمة لسيارتك سوزوكي سيارة سهلاً بواسطة نجيب أوتو",
    },
    link: "/book-service-appointment",
  },
  {
    image: ServiceMaintenanceIcon,
    title: {
      en: "Service & Maintenance",
      ar: "الخدمة والصيانة",
    },
    description: {
      en: "Our regular servicing maintains your vehicles long-term reliability and safety",
      ar: "تضمن خدمتنا المنتظمة موثوقية وسلامة سيارتك سوزوكي سيارة على المدى الطويل",
    },
    link: "/service-maintenance",
  },
  {
    image: MaintenanceCalculatorIcon,
    title: {
      en: "Suzuki Maintenance Calculator",
      ar: "حاسبة صيانة سوزوكي",
    },
    description: {
      en: "Calculate the pricing of Suzuki cars based on the mileage",
      ar: "احسب تكلفة سيارات سوزوكي بناءً على الأميال",
    },
    link: "/maintenance-calculator",
  },
  {
    image: FAQIcon,
    title: {
      en: "Frequently Asked Questions",
      ar: "الأسئلة الشائعة",
    },
    description: {
      en: "Your questions relevant to Suzuki, answered by Najeeb Auto",
      ar: "أسئلتك المتعلقة بسوزوكي، يجيب عليها نجيب أوتو",
    },
    link: "/faqs",
  },
  {
    image: WarrantyIcon,
    title: {
      "en":"Suzuki Warranty",
      "ar":"ضمان سوزوكي"
    },
    description: {
      "en":"Get yourself covered with Suzuki/Najeeb Auto Warranty",
      "ar":"احصل على تغطية ضمان سوزوكي/نجيب أوتو"
    },
    link: "/warranty",
  },
  {
    image: RightsIcon,
    title: {
      "en":"Know Your Rights",
      "ar":"اعرف حقوقك"
    },
    description: {
      "en":"We support you for your rights!",
      "ar":"ندعمك لحقوقك"
    },
    link: "/know-your-rights",
  },
  {
    image: RoadsideAssistanceIcon,
    title: {
      "en":"Road-side Assistance",
      "ar":"المساعدة على الطريق"
    },
    description: {
      "en":"Roadside Assistance lets you travel with complete peace of mind",
      "ar":"تتيح لك المساعدة على الطريق السفر براحة بال كاملة"
    },
    link: "/road-side-assistance",
  },
  {
    image: BuySparePartsOnlineIcon,
    title: {
      "en":"Buy Spare Parts Online",
      "ar":"شراء قطع الغيار عبر الإنترنت"
    },
    description: {
      "en":"Get genuine spare parts through our partners MKENA’s online portal",
      "ar":"احصل على قطع غيار أصلية من خلال بوابة شركائنا MKENA عبر الإنترنت"
    },
    link: "https://mkena.com/en/suzuki/najeeb-auto",
  },
];

const OverviewPage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./OverviewPage-ar.css");
    } else {
      import("./OverviewPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <>
    <Container>
     
      <div className="overview-page section-spacing">
        <SectionTitlePrimary subtitle="Overview" title={t("Owners")} />
        <Row>
          {overviewCardsData.map((card, index) => (
            <Col md={6} key={index}>
              <OverviewCard
                image={card.image}
                title={card.title}
                description={card.description}
                link={card.link}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
    <CallToAction/>
    </>
  );
};

export default OverviewPage;
