const vehicleSparePartsVariants = {
  dzire: ["GL", "GLX"],
  baleno: ["2019-2022 GL", "2019-2022 GLX", "2023 GL", "2023 GLX"],
  ciaz: ["GL", "GLX"],
  swift: ["GL", "GLX"],
  fronx: ["GL", "GLX"],
  "grand-vitara": ["2WD GL", "2WD GLX", "2WD GLX + SR", "4WD GLX + SR"],
  ertiga: ["2019-2022 GL","2019-2022 GLX", "2023-2024 GL", "2023-2024 GLX"],
  jimny: ["GL", "GLX"],
  "jimny-5-doors": ["GL", "GLX"],
  carry: ["Pickup Single Cab"],
  eeco: ["VAN 1.2L MT"],
  vitara:[],
};

export default vehicleSparePartsVariants;
