import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import MaintenanceCalculatorModal from "../../components/MaintenanceCalculatorModal/MaintenanceCalculatorModal";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import vehicles from "../../hooks/vehicles";
import "./MaintenanceCalculatorPage.css";
import CallToAction from "../../components/CallToAction/CallToAction";

const MaintenanceCalculatorPage = () => {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./MaintenanceCalculatorPage-ar.css");
    } else {
      import("./MaintenanceCalculatorPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);



  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const handleShowModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVehicle(null);
  };

const filteredVehicles = vehicles.filter(vehicle => vehicle.maintenanceOnly);

  return (
    <Container className="section-spacing">
      <SectionTitlePrimary subtitle="Vehicles" title={t("Explore Your Ideal Suzuki")} />
      
      <Row className="justify-content-center">
        {filteredVehicles.map((vehicle) => (
          <Col key={vehicle.id} md={3}>
            <div className="vehicle-card">
              <Image
                src={vehicle.image}
                alt={vehicle.title[currentLanguage]}
                fluid
                className="vehicle-image"
              />
              <h3 className="vehicle-title">{vehicle.title[currentLanguage]}</h3>
              <div className="link-align">
                <Button variant="link" onClick={() => handleShowModal(vehicle)}>
                  {t("Calculate")}
                </Button>
                <i className={`${i18n.language === "ar" ? "jam jam-arrow-left" : "jam jam-arrow-right"}`}></i>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <CallToAction />

      {selectedVehicle && (
        <MaintenanceCalculatorModal
          show={showModal}
          handleClose={handleCloseModal}
          vehicle={selectedVehicle}
        />
      )}
    </Container>
  );
};

export default MaintenanceCalculatorPage;
