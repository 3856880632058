import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import "./VehiclesPage.css";
import CategorizedVehicles from "../../components/CategorizedVehicles/CategorizedVehicles";
import CallToAction from "../../components/CallToAction/CallToAction";

const VehiclesPage = () => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehiclesPage-ar.css");
    } else {
      import("./VehiclesPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <>
      <div className="section-spacing">
        <Container className="vehicles-page">
          <SectionTitlePrimary
            subtitle="Vehicles"
            title={t("Explore your ideal Suzuki")}
          />
          <p className="imp-msg">*{t("All PRICES ARE INCLUSIVE OF VAT")}</p>
          <CategorizedVehicles showExploreLink={false} />
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default VehiclesPage;
