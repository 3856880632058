import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./MegaMenu.css";

import OverviewIcon from "../../images/overview-icon.png";
import FAQsIcon from "../../images/faq-icon.png";
import BookServiceIcon from "../../images/book-service-appointment.png";
import WarrantyIcon from "../../images/suzuki-warranty-icon.png";
import MaintenanceIcon from "../../images/service-maintenance.png";
import RightsIcon from "../../images/know-your-rights-icon.png";
import CalculatorIcon from "../../images/suzuki-maintenance-calculator.png";
import AssistanceIcon from "../../images/roadside-assistance-icon.png";

const MegaMenu = ({ closeNavbar }) => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./MegaMenu-ar.css");
    } else {
      import("./MegaMenu.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  return (
    <div className="mega-menu">
        <NavLink to="/overview" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={OverviewIcon} alt="Overview" />
              <div className="mega-menu-content">
                <h6>{t("Overview")}</h6>
                <p>{t("All available services")}</p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/faqs" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={FAQsIcon} alt="FAQs" />
              <div className="mega-menu-content">
                <h6>{t("FAQs")}</h6>
                <p>{t("Questions regarding Suzuki")}</p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/book-service-appointment" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={BookServiceIcon} alt="Book a Service" />
              <div className="mega-menu-content">
                <h6>{t("Book a Service Appointment")}</h6>
                <p>{t("Servicing made easy")}</p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/warranty" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={WarrantyIcon} alt="Warranty" />
              <div className="mega-menu-content">
                <h6>{t("Suzuki Warranty")}</h6>
                <p>{t("Get your vehicle covered")}</p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/service-maintenance" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={MaintenanceIcon} alt="Maintenance" />
              <div className="mega-menu-content">
                <h6>{t("Service & Maintenance")}</h6>
                <p>{t("Regular Suzuki servicing")}</p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/know-your-rights" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={RightsIcon} alt="Rights" />
              <div className="mega-menu-content">
                <h6>{t("Know Your Rights")}</h6>
                <p>{t("We support you")}</p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/maintenance-calculator" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={CalculatorIcon} alt="Calculator" />
              <div className="mega-menu-content">
                <h6>{t("Maintenance Calculator")}</h6>
                <p>{t("Pricing based on mileage")}</p>
              </div>
            </div>
          </NavLink>

          <NavLink to="/road-side-assistance" onClick={closeNavbar}>
            <div className="mega-menu-item">
              <img src={AssistanceIcon} alt="Assistance" />
              <div className="mega-menu-content">
                <h6>{t("Road-side Assistance")}</h6>
                <p>{t("Travel with peace of mind")}</p>
              </div>
            </div>
          </NavLink>
    </div>
  );
};

export default MegaMenu;
