import React, { useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NewsCard.css";

const NewsCard = ({ news }) => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./NewsCard-ar.css");
    } else {
      import("./NewsCard.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Link to={`/news/${news.id}`}>
    <Card className="news-card">
      <Card.Img variant="top" src={news.image} alt={news.title[currentLanguage]} />
      <Card.Body className="card-body-align">
        <h5 className="news-date">{news.date}</h5>
        <Card.Title className="news-title">{news.title[currentLanguage]}</Card.Title>
        <div className="link-align">
          <Link to={`/news/${news.id}`} className="read-more-link">
            {t("Read More")}
            <i className={`${i18n.language === "ar" ? "jam jam-arrow-left" : "jam jam-arrow-right"}`}></i>
          </Link>
        </div>
      </Card.Body>
    </Card>
    </Link>
  );
};

export default NewsCard;
