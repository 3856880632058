import JimnyGLXAugustOfferImage from "../images/offers/jimny-glx-august-offer.jpg";
import SNBAugustOfferImage from "../images/offers/suzuki-snb-august-offer-2024.jpg";

const offers = [
  {
    id: "jimny-glx-august-offer",
    title: {
      en: "Get your Jimny 5-Doors GLX Now with 1,203 Only",
      ar: "الآن جيمني الجديدة ذات 5 أبواب فقط بـ 1,203  ريال",
    },
    description: {
      en: "Get your Jimny 5 Doors GLX 2024 back to school offer with only 1,203 monthly installments, during August 2024.",
      ar: "اشتر الآن سيارات سوزوكي سياز أو ارتيجا واحصل على أقل قسط شهري من خلال البنك الأهلي السعودي خلال شهر أغسطس",
    },

    image: JimnyGLXAugustOfferImage,
    expiryDate: "2024-08-31T00:00:00",
    cities: [
      {
        en: "Riyadh",
        ar: "الرياض",
      },
      {
        en:"Jeddah",
        ar:"جدة",
      },
      {
        en:"Dammam",
        ar:"الدمام",
      },
      {
        en:"Al Ahsa",
        ar:"الأحساء",
      },
      {
        en:"Najran",
        ar:"نجران",
      },
      {
        en:"Onaiza",
        ar:"عنيزة",
      },
      {
        en:"Mecca",
        ar:"مكة المكرمة",
      },
    ],
    vehicles: ["Jimny 5 Doors"],
    relatedVehicles: ["jimny-5-doors"],
  },

  {
    id: "suzuki-snb-august-offer",
    title: {
      en: "Suzuki SNB August Offer",
      ar: "عروض سوزوكي من البنك الأهلي السعودي خلال شهر أغسطس",
    },
    description: {
      en: "Get your Suzuki Ciaz or Ertiga now with the lowest monthly installment from SNB bank during August 2024",
      ar: "اشتر الآن سيارات سوزوكي سياز أو ارتيجا واحصل على أقل قسط شهري من خلال البنك الأهلي السعودي خلال شهر أغسطس",
    },

    image: SNBAugustOfferImage,
    expiryDate: "2024-08-31T00:00:00",
    cities: [
      {
        en: "Riyadh",
        ar: "الرياض",
      },
      {
        en:"Jeddah",
        ar:"جدة",
      },
      {
        en:"Dammam",
        ar:"الدمام",
      },
      {
        en:"Al Ahsa",
        ar:"الأحساء",
      },
      {
        en:"Najran",
        ar:"نجران",
      },
      {
        en:"Onaiza",
        ar:"عنيزة",
      },
      {
        en:"Mecca",
        ar:"مكة المكرمة",
      },
    ],
    vehicles: ["Ertiga", "Ciaz"],
    relatedVehicles: ["ertiga", "ciaz"],
  },
];

export default offers;
