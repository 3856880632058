import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import VehicleCard from "../VehicleCard/VehicleCard";
import vehicles from "../../hooks/vehicles";
import "./VehicleSection.css";
import CustomButton from "../CustomButton/CustomButton";
import SectionTitlePrimary from "../SectionTitlePrimary/SectionTitlePrimary";

const VehicleSection = () => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleSection-ar.css");
    } else {
      import("./VehicleSection.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  return (
    <div className="sec-bg section-spacing">
      <Container className="offer-section">
        <SectionTitlePrimary
          subtitle="Vehicles"
          title={t("Discover the Suzuki Range")} 
        />
        <Row className="justify-content-center">
          {vehicles.slice(0, 8).map((vehicle) => (
            <Col key={vehicle.id} md={6} lg={3}>
              <VehicleCard vehicle={vehicle} />
            </Col>
          ))}
        </Row>
        <div className="mt-5">
        <CustomButton text={t("All Vehicles")} to="/vehicles" />
        </div>
        
      </Container>
    </div>
  );
};

export default VehicleSection;
