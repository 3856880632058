import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import news from "../../hooks/news";
import "./NewsDetailPage.css";
import CallToAction from "../../components/CallToAction/CallToAction";

const NewsDetailPage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // You can import Arabic-specific CSS if needed
      // import("./NewsDetailPage-ar.css");
    } else {
      import("./NewsDetailPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const { id } = useParams();
  const newsItem = news.find((item) => item.id === id);

  if (!newsItem) {
    return <div>{t("News not found")}</div>;
  }

  return (
    <>
      <div className="sec-bg section-spacing">
        <Container className="news-detail">
          <div className="text-center">
            <h6>{t("News Article")}</h6>
            <h1 className="news-detail-title">{newsItem.title[i18n.language]}</h1>
            <p className="news-detail-date">{newsItem.date}</p>
          </div>

          {newsItem.content.length > 0 ? (
            newsItem.content.map((content, index) => (
              <div key={index} className="news-detail-content">
                {content.type === "image" ? (
                  <Image
                    src={content.src}
                    alt={content.alt || newsItem.title[i18n.language]}
                    fluid
                  />
                ) : (
                  <p>{content.text[i18n.language]}</p>
                )}
              </div>
            ))
          ) : (
            <p>{t("No detailed content available for this news item.")}</p>
          )}
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default NewsDetailPage;
