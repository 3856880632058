import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import "./KnowYourRightsPage.css";
import CallToAction from "../../components/CallToAction/CallToAction";

const KnowYourRightsPage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./KnowYourRightsPage-ar.css");
    } else {
      import("./KnowYourRightsPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="section-spacing">
      <Container className="mt-5">
        <Row>
          <Col md={4}>
            <div className="kyr-table">
              <h6 className="text-uppercase mb-4">{t("Table of Contents")}</h6>
              <h4 className="kyr-table-title">{t("When Buying a New Car")}</h4>
              <ul className="kyr-table-list">
                <li>{t("1. Necessary Information")}</li>
                <li>{t("2. Car Specs Documentation")}</li>
                <li>{t("3. Vehicle Warranty")}</li>
              </ul>

              <h4 className="kyr-table-title">
                {t("When Maintaining Your Vehicle")}
              </h4>
              <ul className="kyr-table-list">
                <li>{t("1. Inspection Duration")}</li>
                <li>{t("2. Warranty Extension")}</li>
                <li>{t("3. Completion Cost & Time")}</li>
                <li>{t("4. Loaner Car")}</li>
                <li>{t("5. Parts Replacement")}</li>
                <li>{t("6. Spare Parts Availability")}</li>
              </ul>
            </div>
          </Col>
          <Col md={8}>
            <div className="kyr-parent-content">
              <div className="kyr-content">
                <h2 className="kyr-main-title">{t("Know Your Rights")}</h2>
              </div>
              <h4 className="kyr-section-title">
                {t("When Buying a New Car")}
              </h4>
              <h5 className="kyr-list-title">
                {t("1. Necessary Information")}
              </h5>
              <p>
                {t(
                  "The presence of all the following on prominently displayed cars in the sales outlets and on the importer's website:"
                )}
              </p>
              <ul className="kyr-list-para">
                <li>
                  {t(
                    "- Price tag and specifications (with an explanation if the price includes the plate and the registration fees)."
                  )}
                </li>
                <li>{t("- Fuel economy card")}</li>
              </ul>

              <h5 className="kyr-list-title mt-5">
                {t("2. Car Specs Documentation")}
              </h5>
              <p>
                {t(
                  "Obtaining a written document of the cars specifications and modification made to the car before selling, in addition to mentioning where the added parts were made and where they were installed."
                )}
              </p>
              <p>
                {t(
                  "Obtaining a written document specifying the date of receiving the new car after purchasing it."
                )}
              </p>

              <h5 className="kyr-list-title mt-5">
                {t("3. Vehicle Warranty")}
              </h5>
              <p>
                {t(
                  "Obtain the vehicle warranty brochure and technical instructions in Arabic with clear and understandable wording."
                )}
              </p>

              <p>
                {t(
                  "The vehicle warranty period shall not be less than two years or the specified distance from the product."
                )}
              </p>

              <h4 className="kyr-section-title mt-4">
                {t("When Maintaining Your Vehicle")}
              </h4>
              <h5 className="kyr-list-title mt-5">
                {t("1. Inspection Duration")}
              </h5>
              <p>
                {t(
                  "Knowing the duration required for the completion of inspection and repair with a written document and signed by the consumer and the importer."
                )}
              </p>

              <h5 className="kyr-list-title mt-5">
                {t("2. Warranty Extension")}
              </h5>
              <p>
                {t(
                  "Obtaining an extension of the warranty in case of delay from the agreed data for completing maintenance work or securing spare parts."
                )}
              </p>

              <h5 className="kyr-list-title mt-5">
                {t("3. Completion Cost & Time")}
              </h5>
              <p>
                {t(
                  "Obtaining an extension of the warranty in case of delay from the agreed data for completing maintenance work or securing spare parts."
                )}
              </p>

              <h5 className="kyr-list-title mt-5">{t("4. Loaner Car")}</h5>
              <p>
                {t(
                  "In the event that you purchase a car from Najeeb Auto-Suzuki you are entitled to a loaner car in the same category as your car or compensate for it with a financial amount of (car purchase price ÷ 400) for each day of delay in the following cases:"
                )}
              </p>

              <ul className="kyr-list-para">
                <li>
                  {t(
                    "- Thee defect is converted by the warranty if the car cannot be used."
                  )}
                </li>
                <li>
                  {t(
                    " - Delay in the agreed date for completion of maintenance and provision of spare parts."
                  )}
                </li>
              </ul>

              <h5 className="kyr-list-title mt-5">
                {t("5. Parts Replacement")}
              </h5>
              <p>
                {t(
                  "Keep the parts that were replaced from your car out of warranty and see the parts replaced from your car on the warranty."
                )}
              </p>

              <h5 className="kyr-list-title mt-5">
                {t("6. Spare Parts Availability")}
              </h5>
              <p>
                {t(
                  "Directly obtaining spare parts with continuous demand and providing rare spare parts within 14 days"
                )}
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div>
        <CallToAction />
      </div>
    </div>
  );
};

export default KnowYourRightsPage;
