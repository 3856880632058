import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RtlProvider, useRtl } from "./RtlContext";
import "./i18n";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

// IMPORTING COMPONENTS
import Header from "./components/Header/Header";
import ScrollToTop from "./ScrollToTop";

// IMPORTING PAGES
import Home from "./pages/Home/Home";
import OffersPage from "./pages/OffersPage/OffersPage";
import OfferDetailPage from "./pages/OfferDetailPage/OfferDetailPage";
import VehiclesPage from "./pages/VehiclesPage/VehiclesPage";
import VehicleDetailPage from "./pages/VehicleDetailPage/VehicleDetailPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import SparePartsPage from "./pages/SparePartsPage/SparePartsPage";
import Footer from "./components/Footer/Footer";
import MaintenanceCalculatorPage from "./pages/MaintenanceCalculatorPage/MaintenanceCalculatorPage";
import ContactFormPage from "./pages/ContactPage/ContactPage";
import OverviewPage from "./pages/OverviewPage/OverviewPage";
import BookServiceAppointmentPage from "./pages/BookServiceAppointmentPage/BookServiceAppointmentPage";
import ServiceMaintenancePage from "./pages/ServiceMaintenancePage/ServiceMaintenancePage";
import FrequentlyAskedPage from "./pages/FrequentlyAskedQuestionsPage/FrequentlyAskedQuestionsPage";
import WarrantyPage from "./pages/WarrantyPage/WarrantyPage";
import RoadsideAssistancePage from "./pages/RoadsideAssistancePage/RoadsideAssistancePage";
import KnowYourRightsPage from "./pages/KnowYourRightsPage/KnowYourRightsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsConditionsPage from "./pages/TermsConditionsPage/TermsConditionsPage";
import GetQuotePage from "./pages/GetQuotePage/GetQuotePage";
import CareersPage from "./pages/CareersPage/CareersPage";
import FleetSalesInquiryPage from "./pages/FleetSalesInquiryPage/FleetSalesInquiryPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import NewsDetailPage from "./pages/NewsDetailPage/NewsDetailPage";
import ShowroomPage from "./pages/ShowroomsPage/ShowroomsPage";
import BookTestDrivePage from "./pages/BookTestDrivePage/BookTestDrivePage";

const GlobalStyle = createGlobalStyle`
  body {
    direction: ${(props) => (props.isRtl ? "rtl" : "ltr")};
  }
`;

const AppContent = () => {
  const { i18n } = useTranslation();
  const { isRtl, toggleRtl } = useRtl();

  const loadCss = (lng) => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      lng === "ar"
        ? "/path/to/arabic/css/file.css"
        : "/path/to/english/css/file.css";
    document.head.appendChild(link);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.classList.remove("ltr", "rtl");
    document.body.classList.add(lng === "ar" ? "rtl" : "ltr");
    document.documentElement.lang = lng;
    localStorage.setItem("language", lng);
    loadCss(lng);
  };

  const handleLanguageSwitch = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    changeLanguage(newLanguage);
    toggleRtl();
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    changeLanguage(savedLanguage);
    if (savedLanguage === "ar") {
      toggleRtl();
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={{ isRtl }}>
        <GlobalStyle isRtl={isRtl} />
        <Router>
          <ScrollToTop />
          <Header onLanguageSwitch={handleLanguageSwitch} />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/offers" element={<OffersPage />} />
              <Route path="/offers/:id" element={<OfferDetailPage />} />
              <Route path="/vehicles" element={<VehiclesPage />} />
              <Route path="/vehicles/:id" element={<VehicleDetailPage />} />
              <Route path="/news/:id" element={<NewsDetailPage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/spare-parts" element={<SparePartsPage />} />
              <Route
                path="/maintenance-calculator"
                element={<MaintenanceCalculatorPage />}
              />
              <Route path="/contact" element={<ContactFormPage />} />
              <Route path="/overview" element={<OverviewPage />} />
              <Route
                path="/book-service-appointment"
                element={<BookServiceAppointmentPage />}
              />
              <Route
                path="/service-maintenance"
                element={<ServiceMaintenancePage />}
              />
              <Route path="/faqs" element={<FrequentlyAskedPage />} />
              <Route path="/warranty" element={<WarrantyPage />} />
              <Route
                path="/road-side-assistance"
                element={<RoadsideAssistancePage />}
              />
              <Route
                path="/know-your-rights"
                element={<KnowYourRightsPage />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route
                path="/terms-conditions"
                element={<TermsConditionsPage />}
              />
              <Route path="/get-quote" element={<GetQuotePage />} />
              <Route path="/careers" element={<CareersPage />} />
              <Route
                path="/fleet-sales-inquiry"
                element={<FleetSalesInquiryPage />}
              />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/our-locations" element={<ShowroomPage />} />
              <Route path="/book-test-drive" element={<BookTestDrivePage />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      </ThemeProvider>
    </>
  );
};

const App = () => {
  return (
    <RtlProvider>
      <AppContent />
    </RtlProvider>
  );
};

export default App;
