import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Card } from "react-bootstrap";
import vehicles from "../../hooks/vehicles";
import "./VehicleFeatures.css";

const VehicleFeatures = ({ vehicleId }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleFeatures-ar.css");
    } else {
      import("./VehicleFeatures.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const vehicle = vehicles.find((v) => v.id === vehicleId);

  if (!vehicle) {
    return <div>Vehicle not found</div>;
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          textAlign: "center",
          transform: currentLanguage === "ar" ? "rotate(180deg)" : "none",
        }}
        onClick={onClick}
      >
        <i className="jam jam-arrow-right"></i>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          textAlign: "center",
          transform: currentLanguage === "ar" ? "rotate(180deg)" : "none",
        }}
        onClick={onClick}
      >
        <i className="jam jam-arrow-left"></i>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    rtl: currentLanguage === "ar", // Enable RTL mode for the slider if the language is Arabic
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <div className="vehicle-features section-spacing">
        <div className="vehicle-detail-section-title mb-2">
          <h1>{t("Safety & Special Features")}</h1>
        </div>
        <Slider {...settings}>
          {vehicle.features.map((feature, index) => (
            <Card key={index} className="feature-card">
              <Card.Img variant="top" src={feature.image} fluid="true" />
              <Card.Body className="feature-card-body">
                <Card.Title
                  className={`vehicle-feature-title ${
                    currentLanguage === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  {feature.title[currentLanguage]}
                </Card.Title>
                <Card.Text
                  className={`vehicle-feature-description ${
                    currentLanguage === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  {feature.description[currentLanguage]}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Slider>
      </div>
    </Container>
  );
};

export default VehicleFeatures;
