// NEWS 1 IMAGE SOURCE STARTS
import NewsImageOne from "../images/news/news-1.png";
import NewsOneImageOne from "../images/news/news-1-images/news-1-1.jpg";
import NewsOneImageTwo from "../images/news/news-1-images/news-1-2.jpg";
// NEWS 1 IMAGE SOURCE ENDS

// NEWS 2 IMAGE SOURCE STARTS
import NewsImageTwo from "../images/news/news-2.png";
import NewsTwoImageOne from "../images/news/news-2-images/news-2-1.jpg";
import NewsTwoImageTwo from "../images/news/news-2-images/news-2-2.jpg";
// NEWS 2 IMAGE SOURCE ENDS

// NEWS 3 IMAGE SOURCE STARTS
import NewsImageThree from "../images/news/news-3.png";
import NewsThreeImageOne from "../images/news/news-3-images/news-3-1.jpg";
import NewsThreeImageTwo from "../images/news/news-3-images/news-3-2.jpg";
// NEWS 3 IMAGE SOURCE ENDS

// NEWS 4 IMAGE SOURCE STARTS
import NewsImageFour from "../images/news/news-4.png";
import NewsFourImageOne from "../images/news/news-4-images/news-4-1.jpg";
import NewsFourImageTwo from "../images/news/news-4-images/news-4-2.jpg";
// NEWS 4 IMAGE SOURCE ENDS

// NEWS 5 IMAGE SOURCE STARTS
import NewsFiveImageOne from "../images/news/news-5-images/news-5-1.png";
import NewsFiveImageTwo from "../images/news/news-5-images/news-5-2.png";
import NewsFiveImageThree from "../images/news/news-5-images/news-5-3.png";
import NewsFiveImageFour from "../images/news/news-5-images/news-5-4.png";
// NEWS 5 IMAGE SOURCE ENDS

// NEWS 6 IMAGE SOURCE STARTS
import NewsSixImageOne from "../images/news/news-6-images/news-6-1.jpg";
// NEWS 6 IMAGE SOURCE ENDS

// NEWS 7 IMAGES SOURCE STARTS
import NewsSevenImageOne from "../images/news/news-7-images/news-7-1.jpg";
import NewsSevenImageTwo from "../images/news/news-7-images/news-7-2.jpg";
import NewsSevenImageThree from "../images/news/news-7-images/news-7-3.jpg";
import NewsSevenImageFour from "../images/news/news-7-images/news-7-4.jpg";
import NewsSevenImageFive from "../images/news/news-7-images/news-7-5.jpg";
import NewsSevenImageSix from "../images/news/news-7-images/news-7-6.jpg";
// NEWS 7 IMAGES SOURCE ENDS

// NEWS 8 IMAGES SOURCE STARTS
import NewsEightImageOne from "../images/news/news-8-images/news-8-1.jpg";
import NewsEightImageTwo from "../images/news/news-8-images/news-8-2.png";
// NEWS 8 IMAGES SOURCE ENDS

// NEWS 9 IMAGES SOURCE STARTS
import NewsNineImageOne from "../images/news/news-9-images/news-9-1.png";
import NewsNineImageTwo from "../images/news/news-9-images/news-9-2.png";
// NEWS 9 IMAGES SOURCE ENDS

// NEWS 10 IMAGES SOURCE STARTS
import NewsTenImageOne from "../images/news/news-10-images/news-10-1.jpg";
import NewsTenImageTwo from "../images/news/news-10-images/news-10-2.jpg";
import NewsTenImageThree from "../images/news/news-10-images/news-10-3.png";
import NewsTenImageFour from "../images/news/news-10-images/news-10-4.png";
import NewsTenImageFive from "../images/news/news-10-images/news-10-5.jpg";
import NewsTenImageSix from "../images/news/news-10-images/news-10-6.jpg";
// NEWS 10 IMAGES SOURCE ENDS

// NEWS 11 IMAGES SOURCE STARTS
import NewsElevenImageOne from "../images/news/news-11-images/news-11-1.jpg";
import NewsElevenImageTwo from "../images/news/news-11-images/news-11-2.jpg";
// NEWS 11 IMAGES SOURCE ENDS

// NEWS 12 IMAGES SOURCE STARTS
import NewsTwelveImageOne from "../images/news/news-12-images/news-12-1.jpg";
// NEWS 12 IMAGES SOURCE ENDS

const news = [
  {
    id: "news-12",
    image: NewsTwelveImageOne,
    title: {
      en: "A Journey of Success: Suzuki Awards Najeeb Auto Team with 6 Honors for Excellence in the Middle East",
      ar: "رحلة نجاح: سوزوكي تكرم فريق نجيب أوتو بست جوائز للتميز في الشرق الأوسط",
    },

    date: "March 28, 2024",
    content: [
      {
        type: "image",
        src: NewsTwelveImageOne,
      },

      {
        type: "text",
        text: {
          en:"In the vibrant heart of the Middle East, where commerce and innovation thrive, we stand tall as a symbol of excellence, driven by our relentless pursuit of our goals. This year, our efforts have been rewarded with six prestigious awards from Suzuki. These accolades are more than mere symbols of achievement; they represent the culmination of our commitment to brilliance, the trust of our esteemed customers, and the hard work of the Najeeb Auto Suzuki team.",
          ar:"في قلب الشرق الأوسط النابض بالحياة، حيث تزدهر التجارة والابتكار، نقف شامخين كرمز للتميز، مدفوعين بالسعي الدؤوب لتحقيق أهدافنا. هذا العام، تُوجت جهودنا بستة جوائز مرموقة من سوزوكي. هذه الجوائز ليست مجرد رموز للإنجاز؛ بل تمثل تتويجاً لالتزامنا بالتألق، وثقة عملائنا الكرام، والعمل الجاد لفريق نجيب أوتو سوزوكي.",
        } 
      },

      {
        type: "text",
        text: {
          en:"These awards highlight our ability not only to meet but to exceed the standards set by one of the most renowned companies for quality in the automotive industry, Suzuki.",
          ar:"تسلط هذه الجوائز الضوء على قدرتنا ليس فقط على تلبية المعايير التي وضعتها واحدة من أكثر الشركات شهرة في الجودة في صناعة السيارات، سوزوكي، بل تجاوزها.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Each of the six awards we received signifies our excellence in different domains:",
          ar:"كل من الجوائز الست التي حصلنا عليها تشير إلى تفوقنا في مجالات مختلفة:",
        } 
      },

      {
        type: "text",
        text: {
          en:"Market Share Growth in the Middle East 2023-24",
          ar:"نمو الحصة السوقية في الشرق الأوسط 2023-24",
        } 
      },

      {
        type: "text",
        text: {
          en:"Highest Volume in the Middle East 2023-24",
          ar:"أعلى حجم مبيعات في الشرق الأوسط 2023-24",
        } 
      },

      {
        type: "text",
        text: {
          en:"Best After-Sales Network Expansion in the Middle East 2023-24",
          ar:"أفضل توسيع لشبكة ما بعد البيع في الشرق الأوسط 2023-24",
        } 
      },

      {
        type: "text",
        text: {
          en:"Highest Network Sales Expansion in the Middle East 2023-24",
          ar:"أعلى توسع في مبيعات الشبكة في الشرق الأوسط 2023-24",
        } 
      },

      {
        type: "text",
        text: {
          en:"Best Addition to Bronze SSQS (Suzuki Service Quality Standard) in the Middle East 2023-24",
          ar:"أفضل إضافة إلى معيار جودة خدمة سوزوكي البرونزية (SSQS) في الشرق الأوسط 2023-24",
        } 
      },

      {
        type: "text",
        text: "Highest Growth Volume in the Middle East 2023-24",
      },

      {
        type: "text",
        text: {
          en:"Suzuki After-Sales Service Awards 2023-2024",
          ar:"جوائز خدمة ما بعد البيع من سوزوكي 2023-2024",
        } 
      },

      {
        type: "text",
        text: {
          en:"- Best After-Sales Network Expansion 2023-2024",
          ar:"- أفضل توسع لشبكة ما بعد البيع 2023-2024",
        } 
      },

      {
        type: "text",
        text: {
          en:"Najeeb Auto successfully expanded its after-sales service centers to new cities, complementing existing workshops in Riyadh, Jeddah, and Khobar. This expansion meets Suzuki’s ambitious goals and ensures greater customer access to service.",
          ar:"وسعت نجيب أوتو بنجاح مراكز خدمة ما بعد البيع إلى مدن جديدة، مما يكمل الورش الحالية في الرياض، جدة، والخبر. يتماشى هذا التوسع مع الأهداف الطموحة لسوزوكي ويضمن وصول العملاء بشكل أكبر إلى الخدمة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"- Best Addition to Bronze SSQS Holders 2023-2024",
          ar:"- أفضل إضافة إلى حاملي معيار جودة خدمة سوزوكي البرونزية 2023-2024",
        } 
      },

      {
        type: "text",
        text: {
          en:"Najeeb Auto achieved 100% training and certification of all its technicians under the Suzuki Service Qualification System (SSQS).",
          ar:"حققت نجيب أوتو تدريبًا بنسبة 100٪ وشهادة جميع فنييها بموجب نظام تأهيل خدمة سوزوكي (SSQS).",
        } 
      },

      {
        type: "text",
        text: {
          en:"Our Journey to Success",
          ar:"رحلتنا إلى النجاح",
        } 
      },

      {
        type: "text",
        text: {
          en:"The journey to these awards has been marked by strategic initiatives, continuous improvement, and an unwavering focus on quality. We have made significant investments in our employees and our team at Najeeb Auto. Moreover, our market leadership is the result of our strategic vision focused on growth, innovation, and excellence in all aspects of our business, starting with the Saudi Arabian market and expanding to the entire Middle East.",
          ar:"تميزت الرحلة إلى هذه الجوائز بمبادرات استراتيجية، وتحسين مستمر، وتركيز لا يتزعزع على الجودة. قمنا باستثمارات كبيرة في موظفينا وفريقنا في نجيب أوتو. علاوة على ذلك، فإن قيادتنا في السوق هي نتيجة رؤيتنا الاستراتيجية التي تركز على النمو، والابتكار، والتميز في جميع جوانب أعمالنا، بدءًا من السوق السعودية وتوسعًا إلى منطقة الشرق الأوسط بأكملها.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Winning these six awards is a milestone for us, but it is not the end of our journey. Rather, it is the beginning of greater objectives and a motivation to continue striving for advancement and excellence in every aspect, maintaining high standards, and exploring new opportunities to exceed our customers' expectations.",
          ar:"الفوز بهذه الجوائز الست هو معلم بالنسبة لنا، لكنه ليس نهاية رحلتنا. بل هو بداية لأهداف أكبر ودافع لمواصلة السعي نحو التقدم والتميز في كل جانب، والحفاظ على معايير عالية، واستكشاف فرص جديدة لتجاوز توقعات عملائنا.",
        } 
      },

      {
        type: "text",
        text: {
          en:"A Heartfelt Thank You",
          ar:"شكر خالص",
        } 
      },

      {
        type: "text",
        text: {
          en:"We express our deep gratitude to Suzuki for recognizing our efforts and achievements. We also thank our customers for their unwavering support and trust. Most importantly, we celebrate our team, whose efforts have made tangible impacts and real accomplishments. With great ambition, we look forward to many more years of success, growth, and recognition.",
          ar:"نعبر عن خالص امتناننا لسوزوكي على تقديرها لجهودنا وإنجازاتنا. كما نشكر عملائنا على دعمهم وثقتهم الدائمة. والأهم من ذلك، نحتفل بفريقنا، الذي جعلت جهوده تأثيرات ملموسة وإنجازات حقيقية. بطموح كبير، نتطلع إلى المزيد من السنوات من النجاح والنمو والتقدير.",
        } 
      },
    ],
  },

  {
    id: "news-11",
    image: NewsElevenImageOne,
    title: {
      en: "Budget Rent A Car, Saudi Arabia signs an agreement to purchase over 3000 cars from Najeeb Auto Suzuki Japan",
      ar: "بدجت لتأجير السيارات، السعودية توقع اتفاقية لشراء أكثر من 3000 سيارة من نجيب أوتو سوزوكي اليابان",
    },
    date: "March 22, 2024",
    content: [
      {
        type: "image",
        src: NewsElevenImageOne,
      },

      {
        type: "text",
        text: {
          en:"In a significant step towards strengthening a lasting partnership, an agreement was signed on the 7th of Dhu al-Qidah, 1445 AH, to purchase over 3000 cars from the Japanese company Najeeb Auto Suzuki for the United International Transportation Company - Budget Saudi Arabia -. This event highlights the long-term cooperation between the two companies, extending beyond the mere signing of a contractual agreement.",
          ar:"في خطوة هامة نحو تعزيز الشراكة الدائمة، تم توقيع اتفاقية في 7 ذو القعدة 1445 هـ، لشراء أكثر من 3000 سيارة من الشركة اليابانية نجيب أوتو سوزوكي لشركة النقل الدولي المتحدة - بدجت السعودية -. يبرز هذا الحدث التعاون الطويل الأمد بين الشركتين، ويمتد إلى ما بعد توقيع اتفاقية تعاقدية فقط.",
        } 
      },

      {
        type: "text",
        text: {
          en:"The alliance between Budget Saudi Arabia and Najeeb Auto Suzuki is rooted in mutual respect, trust, and a shared vision for excellence in the automotive market of Saudi Arabia. Over the years, both companies have consistently demonstrated their commitment to providing high-quality products and exceptional services to their customers.",
          ar:"تستند التحالف بين بدجت السعودية ونجيب أوتو سوزوكي إلى الاحترام المتبادل والثقة والرؤية المشتركة للتميز في سوق السيارات في المملكة العربية السعودية. على مر السنين، أظهرت كلتا الشركتين باستمرار التزامهما بتقديم منتجات عالية الجودة وخدمات استثنائية لعملائهما.",
        } 
      },

      {
        type: "image",
        src: NewsElevenImageTwo,
      },

      {
        type: "text",
        text: {
          en:"Furthermore, the purchase of over 3000 cars from Najib Auto Suzuki by Budget Saudi Arabia is a testament to the trust Budget places in the 100% Japanese quality and reliability of Suzuki vehicles. With a diverse range of models designed to meet the needs of various customers, Suzuki has established itself as a leading player in the automotive industry.",
          ar:"علاوة على ذلك، فإن شراء أكثر من 3000 سيارة من نجيب أوتو سوزوكي من قبل بدجت السعودية هو شهادة على الثقة التي تضعها بدجت في الجودة والموثوقية اليابانية بنسبة 100% للسيارات سوزوكي. مع مجموعة متنوعة من الطرز المصممة لتلبية احتياجات العملاء المختلفين، أثبتت سوزوكي نفسها كلاعب رئيسي في صناعة السيارات.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Looking ahead, this shared spirit strengthens the foundation for reaching new horizons. By continuing to work side by side, both companies reaffirm their commitment to nurturing their long-term partnership, ensuring mutual success and prosperity for years to come.",
          ar:"نظراً للمستقبل، يعزز هذا الروح المشتركة الأساس للوصول إلى آفاق جديدة. من خلال الاستمرار في العمل جنباً إلى جنب، تؤكد كلتا الشركتين على التزامهما بتنمية شراكتهما الطويلة الأمد، وضمان النجاح والازدهار المتبادل لسنوات قادمة.",
        } 
      },
    ],
  },

  {
    id: "news-1",
    image: NewsImageOne,
    date: "July 11, 2023",
    title: {
      en: "Suzuki Recognizes Najeeb Auto with Prestigious Performance Award for Exceptional Growth in 2022",
      ar: "سوزوكي تعترف بنجيب أوتو بجائزة الأداء المرموقة للنمو الاستثنائي في عام 2022",
    },
    content: [
      { type: "image", src: NewsOneImageOne },
      {
        type: "text",
        text: {
          en:"Jeddah, Saudi Arabia – JULY 11, 2023 - In a testament to business excellence and a deep commitment to customer satisfaction, Suzuki has bestowed Najeeb Auto, the authorized dealer of Suzuki in the kingdom of Saudi Arabia with its highly esteemed 'Best Performance Award.' This prestigious accolade comes in acknowledgment of Najeeb Auto’s staggering 119% growth achieved in 2022, a year marked by challenging market dynamics and evolving consumer needs.",
          ar:"جدة، المملكة العربية السعودية – 11 يوليو 2023 - في شهادة على التميز في الأعمال والالتزام العميق برضا العملاء، منحت سوزوكي شركة نجيب أوتو، الوكيل المعتمد لسوزوكي في المملكة العربية السعودية، جائزتها المرموقة 'أفضل أداء'. تأتي هذه الجائزة المرموقة تقديراً لنمو نجيب أوتو المذهل بنسبة 119% الذي تحقق في عام 2022، وهو عام تميز بديناميكيات السوق الصعبة واحتياجات المستهلك المتطورة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"By selling over 10,500 Suzuki vehicles last year(2022), Najeeb Auto has not only set a new benchmark in Suzuki sales but also demonstrated its capability to consistently deliver high performance under varying market conditions.",
          ar:"من خلال بيع أكثر من 10,500 سيارة سوزوكي العام الماضي (2022)، لم تحدد نجيب أوتو معياراً جديداً في مبيعات سوزوكي فحسب، بل أظهرت أيضاً قدرتها على تقديم أداء عالٍ بشكل مستمر تحت ظروف السوق المتنوعة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"This award is a true reflection of the hard work, dedication, and the relentless pursuit of excellence that the team at Najeeb Auto has shown in 2022, said Mr. Daisuke Iwase, General Manager Middle East & Africa at Suzuki. Their unwavering commitment to delivering the best of Suzuki to our customers has allowed them to achieve this remarkable feat of 119% growth. We are incredibly proud to have them as an integral part of the Suzuki family.",
          ar:"قال السيد دايسوكي إيواسي، المدير العام للشرق الأوسط وأفريقيا في سوزوكي: 'تعكس هذه الجائزة بصدق العمل الجاد، والتفاني، والسعي الدؤوب للتميز الذي أظهره فريق نجيب أوتو في عام 2022. لقد سمح لهم التزامهم الثابت بتقديم أفضل ما في سوزوكي لعملائنا بتحقيق هذا الإنجاز الرائع بنسبة نمو 119%. نحن فخورون للغاية بأن يكونوا جزءاً لا يتجزأ من عائلة سوزوكي.",
        } 
      },

      { type: "image", src: NewsOneImageTwo },
      {
        type: "text",
        text: {
          en:"Najeeb Auto, through its customer-centric approach and innovative marketing strategies, has established a robust presence in the automotive market. The dealership has built a reputation for providing superior customer service and high-quality vehicles, helping to bolster Suzuki's brand image and market share.",
          ar:"قامت نجيب أوتو، من خلال نهجها الذي يركز على العملاء واستراتيجياتها التسويقية المبتكرة، بتأسيس حضور قوي في سوق السيارات. وقد بنيت الوكالة سمعة لتقديم خدمة عملاء ممتازة وسيارات عالية الجودة، مما يساعد على تعزيز صورة علامة سوزوكي التجارية وحصتها في السوق.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Receiving the 'Best Performance Award' from Suzuki is a moment of great pride for us at Najeeb Auto, said Mr. AbdulAziz AlShehri, the CEO of Najeeb Auto. This award affirms our efforts towards enhancing Suzuki experience for our customers. We will continue to strive to exceed customer expectations, foster trust, and strengthen our relationship with Suzuki.",
          ar:"قال السيد عبدالعزيز الشهري، الرئيس التنفيذي لشركة نجيب أوتو: 'إن تلقي جائزة 'أفضل أداء' من سوزوكي هو لحظة فخر كبيرة بالنسبة لنا في نجيب أوتو. تؤكد هذه الجائزة جهودنا لتعزيز تجربة سوزوكي لعملائنا. سنواصل السعي لتجاوز توقعات العملاء، وتعزيز الثقة، وتقوية علاقتنا مع سوزوكي.",
        } 
      },

      {
        type: "text",
        text: {
          en:"This remarkable performance by Najeeb Auto is a clear indication of the potential and the resilience of the dealership in these challenging times.",
          ar:"إن هذا الأداء المذهل لشركة نجيب أوتو هو دليل واضح على الإمكانات والمرونة التي تتمتع بها الوكالة في هذه الأوقات الصعبة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Najeeb Auto's recognition serves to further solidify Suzuki position as a leader in the automotive industry, with its strong and growing network of Showrooms and Service center across the Kingdom of Saudi Arabia.",
          ar:"يعمل تقدير نجيب أوتو على تعزيز مكانة سوزوكي كقائد في صناعة السيارات، بشبكتها القوية والمتنامية من صالات العرض ومراكز الخدمة في جميع أنحاء المملكة العربية السعودية.",
        } 
      },
    ],
  },

  {
    id: "news-10",
    image: NewsTenImageTwo,
    title: {
      en: "Najeeb Auto, the authorized dealer of Suzuki in Saudi Arabia, launches the All New Grand Vitara 2023",
      ar: "نجيب أوتو، الوكيل المعتمد لسوزوكي في السعودية، يطلق الجيل الجديد من جراند فيتارا 2023",
    },
    date: "March 14, 2023",
    content: [
      {
        type: "image",
        src: NewsTenImageOne,
      },

      {
        type: "text",
        text: {
          en:"Suzuki, the Japanese automaker, is growing dramatically with a strong presence in the middle east. With its latest offering, the Grand Vitara, has now made its way to the shores of Saudi Arabia. The Grand Vitara is a mid-size SUV that combines style, comfort, and performance in a package that is both practical and versatile. The car has been launched in Saudi Arabia by authorized dealer of Suzuki in the kingdom Najeeb Auto, a leading Suzuki dealer in the country.",
          ar:"تنمو سوزوكي، صانع السيارات الياباني، بشكل كبير بحضور قوي في الشرق الأوسط. مع أحدث عروضها، الجراند فيتارا، التي وصلت الآن إلى شواطئ المملكة العربية السعودية. تعتبر الجراند فيتارا سيارة رياضية متعددة الاستخدامات متوسطة الحجم تجمع بين الأناقة والراحة والأداء في حزمة عملية ومتعددة الاستخدامات. تم إطلاق السيارة في المملكة العربية السعودية من قبل الوكيل المعتمد لسوزوكي في المملكة نجيب أوتو، وهو وكيل رائد لسوزوكي في البلاد.",
         } 
      },

      {
        type: "image",
        src: NewsTenImageTwo,
      },

      {
        type: "text",
        text: {
          en:"Najeeb Auto is a trusted name in the Saudi Auto market. It has a number of showrooms and service centers across the country, making it easier for customers to purchase and maintain their Suzuki vehicles. The dealership is known for its customer-centric approach and has a team of highly trained professionals who ensure that the customer's needs are met.",
          ar:"نجيب أوتو هو اسم موثوق في سوق السيارات السعودي. لديها عدد من صالات العرض ومراكز الخدمة في جميع أنحاء البلاد، مما يسهل على العملاء شراء وصيانة سيارات سوزوكي الخاصة بهم. تُعرف الوكالة بنهجها الذي يركز على العملاء ولديها فريق من المحترفين المدربين تدريباً عالياً الذين يضمنون تلبية احتياجات العملاء.",
        } 
      },

      {
        type: "image",
        src: NewsTenImageThree,
      },

      {
        type: "text",
        text: {
          en:"Speaking about the launch of the Grand Vitara, Mr. AbdulAziz Alshehri, the CEO of Najeeb Auto, said, We are thrilled to bring the Grand Vitara to the Saudi Arabian market. The car has been designed keeping in mind the needs of our customers, and we are confident that it will be well received. At Najeeb Auto, we are committed to providing our customers with the best products and services, and the Grand Vitara is a testament to that commitment.",
          ar:"وفي معرض حديثه عن إطلاق جراند فيتارا، قال السيد عبد العزيز الشهري، الرئيس التنفيذي لشركة نجيب للسيارات: نحن سعداء للغاية بجلب جراند فيتارا إلى السوق السعودية. لقد تم تصميم السيارة مع الأخذ في الاعتبار احتياجات عملائنا. ونحن على ثقة من أنها ستحظى باستقبال جيد، ونحن في نجيب أوتو ملتزمون بتزويد عملائنا بأفضل المنتجات والخدمات، وتعد جراند فيتارا بمثابة شهادة على هذا الالتزام."
        } 
      },

      {
        type: "image",
        src: NewsTenImageFour,
      },

      {
        type: "text",
        text: {
          en:"In conclusion, the launch of the Grand Vitara in Saudi Arabia is an exciting development for the country’s automobile market. The car combines style, comfort, and performance in a package that is both practical and versatile. With Najeeb Auto’s customer-centric approach and Suzuki’s commitment to quality, the Grand Vitara is sure to be a hit among customers in Saudi Arabia.",
          ar:"في الختام، فإن إطلاق الجراند فيتارا في المملكة العربية السعودية هو تطور مثير لسوق السيارات في البلاد. تجمع السيارة بين الأناقة والراحة والأداء في حزمة عملية ومتعددة الاستخدامات. مع نهج نجيب أوتو الذي يركز على العملاء والتزام سوزوكي بالجودة، من المؤكد أن الجراند فيتارا ستكون ناجحة بين العملاء في المملكة العربية السعودية.",
        } 
      },

      {
        type: "text",
        text: {
          en:"The Grand Vitara is a highly anticipated release in the Saudi Arabian market. The car has been designed with the latest technology, and its exterior and interior have been given a complete makeover. The front of the car has a bold and aggressive look, with a sleek chrome grille and sharp LED headlights. The car sits on 17-inch alloy wheels, giving it a strong road presence. When it comes to interior, the car has been designed to provide maximum comfort and convenience to the passengers. The car comes with a 9-inch touchscreen infotainment system, which is compatible with both Android Auto and Apple CarPlay. The car also has a 360-degree camera, which makes parking a breeze.",
          ar:"الجراند فيتارا هي إصدار مرتقب للغاية في السوق السعودية. تم تصميم السيارة بأحدث التقنيات، وقد حصلت واجهتها الخارجية والداخلية على إعادة تصميم كاملة. يتميز الجزء الأمامي من السيارة بمظهر جريء وعدواني، مع شبك كروم أنيق ومصابيح LED حادة. تجلس السيارة على عجلات معدنية بحجم 17 بوصة، مما يمنحها حضوراً قوياً على الطريق. عندما يتعلق الأمر بالداخلية، تم تصميم السيارة لتوفير أقصى درجات الراحة والراحة للركاب. تأتي السيارة بنظام ترفيهي بشاشة تعمل باللمس بحجم 9 بوصات، والذي يتوافق مع كل من أندرويد أوتو وآبل كار بلاي. تحتوي السيارة أيضًا على كاميرا بزاوية 360 درجة، مما يجعل ركن السيارة أمرًا سهلاً.",
        } 
      },

      {
        type: "image",
        src: NewsTenImageFive,
      },

      {
        type: "text",
        text: {
          en:"Under the hood, the Grand Vitara is powered by a 1.5-liter engine, which delivers a power output of 101 horsepower. The car has a six-speed automatic transmission, which provides smooth and seamless shifting. The car also comes with Suzuki’s ALLGRIP four-wheel-drive system, which makes it an excellent off-road vehicle.",
          ar:"تحت غطاء المحرك، يتم تشغيل الجراند فيتارا بمحرك سعة 1.5 لتر، والذي يوفر قوة إنتاجية قدرها 101 حصان. تحتوي السيارة على ناقل حركة أوتوماتيكي بست سرعات، مما يوفر نقلات سلسة وسلسة. تأتي السيارة أيضًا مع نظام الدفع الرباعي ALLGRIP من سوزوكي، مما يجعلها سيارة ممتازة للطرق الوعرة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"The Grand Vitara is equipped with a range of safety features, including airbags, anti-lock braking system, electronic stability program, hill hold control, and tire pressure monitoring system.",
          ar:"تأتي الجراند فيتارا مجهزة بمجموعة من ميزات الأمان، بما في ذلك الوسائد الهوائية، ونظام الفرامل المانعة للانغلاق، وبرنامج الاستقرار الإلكتروني، ونظام التحكم في الثبات على التلال، ونظام مراقبة ضغط الإطارات.",
        } 
      },

      {
        type: "image",
        src: NewsTenImageSix,
      },

      {
        type: "text",
        text: {
          en:"The all-new Suzuki Grand Vitara 2023 is available in three versions, GL standard , GLX 2WD and GLX 4WD, To checkout the all new Grand Vitara in action.",
          ar:"تتوفر سيارة سوزوكي الجراند فيتارا الجديدة كليًا 2023 في ثلاث نسخ، GL القياسية، GLX بنظام الدفع الثنائي وGLX بنظام الدفع الرباعي. للاطلاع على الجراند فيتارا الجديدة قيد العمل.",
        } 
      },
    ],
  },

  {
    id: "news-2",
    image: NewsTwoImageOne,
    date: "December 21, 2022",
    title: {
      en: "Suzuki Najeeb Auto & Balubaid Group Long-term Partnership",
      ar: "شراكة طويلة الأمد بين سوزوكي نجيب أوتو ومجموعة بلوبايد",
    },
    content: [
      {
        type: "image",
        src: NewsTwoImageOne,
      },

      {
        type: "text",
        text: {
          en:"Suzuki brand under Najeeb Auto dealership is growing rapidly because of its quality, reliability, fuel consumptions and great customer service. As a strategic direction to expand the network of Suzuki brand across the kingdom of Saudi Arabia, Najeeb Auto has signed agreement with Balubaid Automotive Group to be the first authorized Sub Dealer of Suzuki brand in 4 cities Jizan , Makkah, Buraida and AlAhsa. On this historical occasion a party has been held at Balubaid family palace in Jeddah where both companies’ management and employees were present.",
          ar:"تنمو علامة سوزوكي تحت وكالة نجيب أوتو بسرعة بفضل جودتها وموثوقيتها واستهلاكها للوقود وخدمة العملاء الرائعة. كاتجاه استراتيجي لتوسيع شبكة علامة سوزوكي في جميع أنحاء المملكة العربية السعودية، وقعت نجيب أوتو اتفاقية مع مجموعة بلوبييد للسيارات لتكون أول وكيل فرعي معتمد لعلامة سوزوكي في 4 مدن هي جيزان، مكة، بريدة والأحساء. في هذه المناسبة التاريخية، أقيمت حفلة في قصر عائلة بلوبييد في جدة حيث حضر كل من إدارة وموظفي الشركتين.",
        } 
      },

      {
        type: "text",
        text: {
          en:"The founder and the chairman of Najeeb Abdullatif Alissa Holding Gruop Mr. Najeeb Abdul Latif Alissa and Mr.Khaled Omar Balubaid , the chairman of the board of directors of Balubaid group were present at the party. The agreement was signed between both companies during the ceremony. Based on the agreement, Najeeb Auto will supply 1,800 Suzuki cars to Balubaid Group in 2023.",
          ar:"كان مؤسس ورئيس مجموعة نجيب عبداللطيف العيسى القابضة السيد نجيب عبداللطيف العيسى والسيد خالد عمر بلوبييد، رئيس مجلس إدارة مجموعة بلوبييد، حاضرين في الحفلة. تم توقيع الاتفاقية بين الشركتين خلال الحفل. بناءً على الاتفاقية، ستقوم نجيب أوتو بتوريد 1800 سيارة سوزوكي لمجموعة بلوبييد في عام 2023.",
        } 
      },

      {
        type: "image",
        src: NewsTwoImageTwo,
      },

      {
        type: "text",
        text: {
          en:"Lastly, both companies agreed to have customer first approach to provide tremendous customers service along with first class aftersales service to grant peace of mind to Suzuki customers across 4 mentioned cities and Saudi Arabia as a whole.",
          ar:"أخيرًا، اتفقت الشركتان على اتباع نهج يركز على العملاء لتقديم خدمة عملاء رائعة إلى جانب خدمة ما بعد البيع من الدرجة الأولى لمنح راحة البال لعملاء سوزوكي في المدن الأربعة المذكورة والمملكة العربية السعودية ككل.",
        } 
      },
    ],
  },

  {
    id: "news-3",
    image: NewsImageThree,
    date: "September 28, 2022",
    title: {
      en: "Najeeb Auto and Key Rent A Car Signed a deal to deliver 3500 Suzuki Cars",
      ar: "وقعت نجيب أوتو وكي رنت كار اتفاقية لتسليم 3500 سيارة سوزوكي",
    },
    content: [
      {
        type: "image",
        src: NewsThreeImageOne,
      },

      {
        type: "text",
        text: {
          en:"Najeeb Auto, the authorized dealer of Suzuki, is one of the leading companies in Saudi Auto market. Suzuki market share is growing rapidly because of its strong points quality, reliability & affordability and best in class aftersales customer services, which provides peace of mind to Suzuki’s customers and business partners.",
          ar:"نجيب أوتو، الوكيل المعتمد لسوزوكي، هي واحدة من الشركات الرائدة في سوق السيارات السعودي. تنمو حصة سوزوكي السوقية بسرعة بفضل نقاط قوتها في الجودة والموثوقية والقدرة على تحمل التكاليف وخدمة العملاء الأفضل في فئتها بعد البيع، مما يوفر راحة البال لعملاء وشركاء أعمال سوزوكي.",
        } 
      },

      {
        type: "text",
        text: {
          en:"On the other hand, Key Rent A Car one of the top rent a car companies in Saudi Arabia provides services both online and offline to its customers to get reliable and affordable car rides wherever they go.",
          ar:"من ناحية أخرى، تعد كي رنت كار واحدة من أفضل شركات تأجير السيارات في المملكة العربية السعودية وتقدم خدماتها عبر الإنترنت وغير المتصلة لعملائها للحصول على رحلات سيارة موثوقة وميسورة التكلفة أينما ذهبوا.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Based on both companies’ strategic directions and to strengthen the partnership, an agreement has been signed to deliver 3500 Suzuki cars to Key A Rent Car within next 6 months which will provide better & affordable rides to their customers in Saudi Arabia.",
          ar:"بناءً على التوجهات الاستراتيجية لكلا الشركتين ولتعزيز الشراكة، تم توقيع اتفاقية لتسليم 3500 سيارة سوزوكي لكي رنت كار خلال الأشهر الستة القادمة مما سيوفر رحلات أفضل وميسورة التكلفة لعملائها في المملكة العربية السعودية.",
        } 
      },

      {
        type: "image",
        src: NewsThreeImageTwo,
      },

      {
        type: "text",
        text: {
          en:"Furthermore, both companies owners & management team had a meeting to document this long-term partnership at all levels. Sheikh Najeeb Abdul Latif Al-Issa, Founder & Chairman of Najeeb Holding Group Companies presented a shield to Engineer. Tawfiq Bahmadeen , Chairman of Key Rent A Car , a subsidiary of Al-Murjan Holding Group.",
          ar:"علاوة على ذلك، عقد أصحاب ومدراء كلا الشركتين اجتماعًا لتوثيق هذه الشراكة الطويلة الأمد على جميع المستويات. قدم الشيخ نجيب عبداللطيف العيسى، مؤسس ورئيس شركات مجموعة نجيب القابضة، درعاً للمهندس. توفيق باحميدين، رئيس كي رنت كار، وهي شركة تابعة لمجموعة المرجان القابضة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Lastly, both companies agreed to continue the partnership to achieve long-term business goals along with world-class customer services.",
          ar:"أخيرًا، اتفقت الشركتان على مواصلة الشراكة لتحقيق الأهداف التجارية طويلة الأمد إلى جانب خدمات العملاء ذات المستوى العالمي.",
        } 
      },
    ],
  },

  {
    id: "news-4",
    image: NewsImageFour,
    date: "September 18, 2022",
    title: {
      "en":"Suzuki Najeeb Auto and Theeb Rent A Car long-term Partnership",
      "ar":"شراكة طويلة الأمد بين سوزوكي نجيب أوتو وثيب رنت كار"
    },
    content: [
      {
        type: "image",
        src: NewsFourImageOne,
      },
      {
        type: "text",
        text: {
          en:"Suzuki brand is growing rapidly in the kingdom of Saudi Arabia because of its quality, reliability and affordability along with Najeeb Auto best customer services. On the other hand, Theeb Rent a car one of the best and largest rent a car companies always thrive to provide better cars to their customers to commute from one location to another conveniently, that’s where two companies (Najeeb Auto and Theeb Rent a Car) come together to satisfy the needs of their customers.",
          ar:"تنمو علامة سوزوكي بسرعة في المملكة العربية السعودية بفضل جودتها وموثوقيتها وقدرتها على التحمل إلى جانب أفضل خدمات العملاء من نجيب أوتو. من ناحية أخرى، تعتبر ثيب رنت كار واحدة من أفضل وأكبر شركات تأجير السيارات التي تسعى دائمًا لتقديم سيارات أفضل لعملائها للتنقل من مكان إلى آخر بسهولة، وهنا تجتمع الشركتان (نجيب أوتو وثيب رنت كار) لتلبية احتياجات عملائهما.",
        } 
      },

      {
        type: "text",
        text: {
          en:"As a strategic direction of Najeeb Auto - the authorized dealer for Suzuki cars in the Kingdom of Saudi Arabia, to expand Suzuki brand and provide best in class customer service. The company has joined the fleet of Theeb Rent a car by providing 2,160 units of Suzuki Dzire 2022 & 2023 models, which is one the best car in the segment with best fuel economy. A part of the deal has already been delivered in order to enhance Theeb Rent a car’s customers service to meet high demand in the market at all levels especially new branches opened by Theeb Rent a car across the Kingdom.",
          ar:"كجزء من التوجه الاستراتيجي لنجيب أوتو - الوكيل المعتمد لسيارات سوزوكي في المملكة العربية السعودية، لتوسيع علامة سوزوكي وتقديم أفضل خدمة عملاء في فئتها. انضمت الشركة إلى أسطول ثيب رنت كار من خلال توفير 2,160 وحدة من طرازات سوزوكي ديزاير 2022 و2023، وهي واحدة من أفضل السيارات في الفئة مع أفضل اقتصاد في استهلاك الوقود. تم تسليم جزء من الصفقة بالفعل لتعزيز خدمة عملاء ثيب رنت كار لتلبية الطلب العالي في السوق على جميع المستويات، وخاصة الفروع الجديدة التي افتتحتها ثيب رنت كار في جميع أنحاء المملكة.",
        } 
      },

      {
        type: "image",
        src: NewsFourImageTwo,
      },

      {
        type: "text",
        text: {
          en:"To strengthen the partnership between two companies, Shiekh Najeeb Abdullatif Alissa the Chairman of the Board of the Directors of Najeeb Holding Group presented a shield to the CEO of Theeb Rent a Car Mr. Naif bin Mohammed Al Theeb to document this long-term relationship in the presence of a large number of employees of the two companies, at the headquarters of Theeb Rent A Car in Riyadh. Furthermore, both companies agreed to continue this partnership for long-term to achieve business objectives along with world class customer services.",
          ar:"لتعزيز الشراكة بين الشركتين، قدم الشيخ نجيب عبداللطيف العيسى، رئيس مجلس إدارة مجموعة نجيب القابضة، درعًا للرئيس التنفيذي لشركة ثيب رنت كار السيد نايف بن محمد الثيب لتوثيق هذه العلاقة الطويلة الأمد بحضور عدد كبير من موظفي الشركتين، في مقر شركة ثيب رنت كار في الرياض. علاوة على ذلك، اتفقت الشركتان على مواصلة هذه الشراكة على المدى الطويل لتحقيق الأهداف التجارية إلى جانب خدمات العملاء ذات المستوى العالمي.",
        } 
      },
    ],
  },

  {
    id: "news-9",
    image: NewsNineImageOne,
    title: {
      "en":"Visit to Japan- The Authorized dealer of Suzuki – Najeeb Auto",
      "ar":"زيارة إلى اليابان - الوكيل المعتمد لسوزوكي - نجيب أوتو"
    },
    date: "August 10, 2022",
    content: [
      {
        type: "image",
        src: NewsNineImageOne,
      },

      {
        type: "text",
        text: {
          en:"Najeeb Auto's Management team visited Suzuki - Japan to thank their support for tremendous growth during the fiscal year FY2021. The visiting team discussed the future plans for Najeeb Auto and Suzuki to reach the Top 10 automotive companies in KSA by 2025. Suzuki Japan also applauded the remarkable performance of the first half of 2022.",
          ar:"زار فريق إدارة نجيب أوتو شركة سوزوكي - اليابان لشكرهم على الدعم الكبير للنمو خلال السنة المالية 2021. ناقش الفريق الزائر الخطط المستقبلية لنجيب أوتو وسوزوكي للوصول إلى أفضل 10 شركات سيارات في السعودية بحلول عام 2025. أشادت سوزوكي اليابان أيضًا بالأداء الرائع للنصف الأول من عام 2022.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Mr. Najeeb Al Issa, The Chairman & Founder of Najeeb Holding Group, expressed his gratitude & appreciation to Suzuki Japan for their support in achieving great results during 2021. Najeeb Al Issa also added we as Suzuki Team steadily moving towards achieving long term strategic goals to provide world class products and experience to our valuable customers. Najeeb Auto is committed to satisfy its customers and partners by providing them ideal customer experience and high level of services.",
          ar:"أعرب السيد نجيب العيسى، رئيس مجلس الإدارة ومؤسس مجموعة نجيب القابضة، عن امتنانه وتقديره لشركة سوزوكي اليابان لدعمهم في تحقيق نتائج رائعة خلال عام 2021. وأضاف نجيب العيسى قائلاً نحن كفريق سوزوكي نتجه بثبات نحو تحقيق الأهداف الاستراتيجية طويلة الأجل لتقديم منتجات وتجربة عالمية لعملائنا الكرام. نجيب أوتو ملتزمة بإرضاء عملائها وشركائها من خلال تقديم تجربة عميل مثالية ومستوى عالٍ من الخدمات.",
        } 
      },

      {
        type: "image",
        src: NewsNineImageTwo,
      },

      {
        type: "text",
        text: {
          en:"Mr. Kinji Saito, Director & Senior Managing Office Global Automobile Marketing at Suzuki Motor Corporation, appreciated Najeeb Auto’s achievements and believes that Suzuki will be one of the top brands in Saudi Arabia in the coming years. “Suzuki products are reliable and trusted by our customers all over the world and we will make sure that they are satisfied with the quality, reliability and affordability along with the great aftersales services and word class products,” said Mr. Kinji Saito.",
          ar:"قدر السيد كينجي سايتو، مدير مكتب الإدارة العليا لتسويق السيارات العالمية في شركة سوزوكي موتور، إنجازات نجيب أوتو ويعتقد أن سوزوكي ستكون واحدة من أفضل العلامات التجارية في المملكة العربية السعودية في السنوات القادمة. 'منتجات سوزوكي موثوقة وموثوق بها من قبل عملائنا في جميع أنحاء العالم وسنتأكد من أنهم راضون عن الجودة والموثوقية والأسعار المعقولة إلى جانب خدمات ما بعد البيع الممتازة والمنتجات ذات المستوى العالمي،' قال السيد كينجي سايتو.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Furthermore, both parties agreed on more business opportunities to expand Suzuki’s brand and network in the kingdom of Saudi Arabia.",
          ar:"علاوة على ذلك، اتفق الطرفان على المزيد من الفرص التجارية لتوسيع علامة سوزوكي وشبكتها في المملكة العربية السعودية.",
        } 
      },
    ],
  },

  {
    id: "news-8",
    image: NewsEightImageOne,
    date: "March 30, 2022",
    title: {
      "en":"Najeeb Auto, authorized Dealer of Suzuki in Saudi Arabia, visits Maruti Suzuki - India",
      "ar":"نجيب أوتو، الوكيل المعتمد لسوزوكي في السعودية، يزور ماروتي سوزوكي - الهند"
    },
      
    content: [
      {
        type: "image",
        src: NewsEightImageOne,
      },

      {
        type: "text",
        text: {
          en:"Najeeb Auto's Management team visited Maruti Suzuki - India to thank their support for exceeding 5,800 units sold during the fiscal year FY2021. The visiting team discussed the future plans for Najeeb Auto and Suzuki to reach the Top 10 automotive companies in KSA by 2025.",
          ar:"زار فريق إدارة نجيب أوتو شركة ماروتي سوزوكي - الهند لشكرهم على دعمهم لتجاوز 5,800 وحدة تم بيعها خلال السنة المالية 2021. ناقش الفريق الزائر الخطط المستقبلية لنجيب أوتو وسوزوكي للوصول إلى أفضل 10 شركات سيارات في السعودية بحلول عام 2025.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Koichi Suzuki, GM of global marketing in the Middle East and Africa at Suzuki Motors, appreciated Najeeb Auto efforts for achieving great results and believes that Suzuki will be one of the top brands in Saudi Arabia very soon. “Suzuki products are reliable and trusted by our customers all over the world and we will make sure that they are satisfied with the quality, reliability and affordability along with the great aftersales services and word class products,” said Mr. Koichi Suzuki.",
          ar:"قدر السيد كويتشي سوزوكي، المدير العام للتسويق العالمي في الشرق الأوسط وأفريقيا في شركة سوزوكي موتورز، جهود نجيب أوتو لتحقيق نتائج رائعة ويعتقد أن سوزوكي ستكون واحدة من أفضل العلامات التجارية في المملكة العربية السعودية قريبًا جدًا. 'منتجات سوزوكي موثوقة وموثوق بها من قبل عملائنا في جميع أنحاء العالم وسنتأكد من أنهم راضون عن الجودة والموثوقية والأسعار المعقولة إلى جانب خدمات ما بعد البيع الممتازة والمنتجات ذات المستوى العالمي،' قال السيد كويتشي سوزوكي.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Furthermore, the meeting focused mainly on the future expansion of Najeeb Auto in the KSA.",
          ar:"علاوة على ذلك، ركز الاجتماع بشكل أساسي على التوسع المستقبلي لنجيب أوتو في المملكة العربية السعودية.",
        } 
      },

      {
        type: "image",
        src: NewsEightImageTwo,
      },
    ],
  },

  {
    id: "news-7",
    image: NewsSevenImageOne,
    date: "June 06, 2022",
    title: {
      "en":"Najeeb Auto, the authorized dealer of Suzuki in Saudi Arabia, launches the All New Baleno 2023",
      "ar":"نجيب أوتو، الوكيل المعتمد لسوزوكي في السعودية، يطلق سيارة بالينو الجديدة كلياً 2023"
    },
      
    content: [
      {
        type: "image",
        src: NewsSevenImageTwo,
      },

      {
        type: "text",
        text: {
          en:"The launch ceremony of the car was attended by the management of Najeeb Auto company and a large number of influencers working in the automotive sector.",
          ar:"حضرت إدارة شركة نجيب أوتو وعدد كبير من المؤثرين العاملين في قطاع السيارات حفل إطلاق السيارة.",
        } 
      },

      {
        type: "image",
        src: NewsSevenImageThree,
      },

      {
        type: "text",
        text: {
          en:"The ceremony began with the recitation of verses from the Qur’an, and during the ceremony, The GM HR of Najeeb Holding Group stressed the importance of the relationship with customers, which is based on attention and appreciation. Where it had a significant positive impact in developing and raising the level of performance with a commitment to development and providing them with all the necessary services and facilities, which in turn lead to building strong and solid relationships with them, and revealed through this occasion about the company. Seeking to expand and spread according to a well-studied geographical plan, through which it contributes more to providing an effective role in consolidating sales values in the car market in the Kingdom.",
          ar:"بدأ الحفل بتلاوة آيات من القرآن الكريم، وخلال الحفل، أكد مدير الموارد البشرية في مجموعة نجيب القابضة على أهمية العلاقة مع العملاء، والتي تعتمد على الاهتمام والتقدير. حيث كان لها تأثير إيجابي كبير في تطوير ورفع مستوى الأداء مع الالتزام بالتطوير وتوفير جميع الخدمات والتسهيلات اللازمة لهم، مما أدى بدوره إلى بناء علاقات قوية ومتينة معهم، وكشف من خلال هذه المناسبة عن الشركة. تسعى للتوسع والانتشار وفق خطة جغرافية مدروسة، من خلالها تساهم أكثر في توفير دور فعال في ترسيخ قيم المبيعات في سوق السيارات في المملكة.",
        } 
      },

      {
        type: "image",
        src: NewsSevenImageFive,
      },

      {
        type: "text",
        text: {
          en:"After that Director of Sales, announced the launch of the car in all Najeeb Auto showrooms in Riyadh, Jeddah and Al-Khobar, where he stressed that there are more new models from Suzuki that will be launched during this year and the coming years, God willing. There will be many new models that will be received in the Saudi market, the most important of which is the relaunch of the Suzuki Grand Vitara before the end of the year, God willing, as well as the introduction of the new concept of small transport vehicles, which will be the first Suzuki Eco next year.",
          ar:"بعد ذلك أعلن مدير المبيعات عن إطلاق السيارة في جميع صالات عرض نجيب أوتو في الرياض وجدة والخبر، حيث أكد أن هناك المزيد من الطرازات الجديدة من سوزوكي التي ستطلق خلال هذا العام والسنوات القادمة، بإذن الله. سيكون هناك العديد من الطرازات الجديدة التي سيتم استقبالها في السوق السعودية، وأهمها إعادة إطلاق سوزوكي جراند فيتارا قبل نهاية العام، بإذن الله، بالإضافة إلى تقديم المفهوم الجديد لسيارات النقل الصغيرة، التي ستكون أول سيارة سوزوكي إيكو العام المقبل.",
        } 
      },

      {
        type: "image",
        src: NewsSevenImageSix,
      },

      {
        type: "text",
        text: {
          en:"The all-new Baleno 2023 comes with radical changes from the previous Suzuki models, in line with the aspirations of the youth and the Saudi market, in particular, where the engine capacity has been slightly increased in a way that does not affect fuel consumption and enhances the driving experience and makes it smoother. The 1.5-liter, four-cylinder, 16-valve engine produces 103 horsepower.",
          ar:"تأتي بالينو 2023 الجديدة كليًا بتغييرات جذرية عن طرازات سوزوكي السابقة، بما يتماشى مع تطلعات الشباب والسوق السعودي بشكل خاص، حيث تم زيادة سعة المحرك قليلاً بطريقة لا تؤثر على استهلاك الوقود وتعزز تجربة القيادة وتجعلها أكثر سلاسة. ينتج المحرك سعة 1.5 لتر، رباعي الأسطوانات، 16 صمامًا قوة 103 حصان.",
        } 
      },

      {
        type: "text",
        text: {
          en:"The all-new Suzuki Baleno 2023 is available in two versions, GL standard and GLX Higher Spec, and the party ended with a dinner in honor of the guests. To checkout of all new Baleno in action.",
          ar:"تتوفر سيارة سوزوكي بالينو الجديدة كلياً 2023 بنسختين، GL القياسية و GLX عالية المواصفات، وانتهى الحفل بعشاء على شرف الضيوف. للاطلاع على جميع الميزات الجديدة لسيارة بالينو أثناء التشغيل.",
        } 
      },
    ],
  },

  {
    id: "news-6",
    image: NewsSixImageOne,
    date: "February 25, 2020",
    title: {
      "en":"Najeeb Auto, the authorized dealer of Suzuki Motor, launches its main and second branch in the Kingdom of Saudi Arabia",
      "ar":"نجيب أوتو، الوكيل المعتمد لسوزوكي موتور، يطلق فرعه الرئيسي والثاني في المملكة العربية السعودية"
    },
    content: [
      {
        type: "text",
        text: {
          en:"Najeeb Auto Company and Suzuki Motor recently celebrated the opening of their second and main branch in Riyadh.",
          ar:"احتفلت شركة نجيب أوتو وسوزوكي موتور مؤخرًا بافتتاح فرعهما الثاني والرئيسي في الرياض.",
        } 
      },

      {
        type: "text",
        text: {
          en:"The opening was held in presence of the senior executives of Suzuki Motor, the Chairman of the Board of Directors at Najeeb Auto, Mr. Najeeb Abdullatif Alissa, many interested people in the automotive sector, and representatives of the media and press in the Kingdom of Saudi Arabia. The two parties exchanged words confirming the importance of the automotive industry in the Saudi market and the new strategic partnership between them to serve the customers of the Kingdom.",
          ar:"أقيم حفل الافتتاح بحضور كبار التنفيذيين في شركة سوزوكي موتور، رئيس مجلس إدارة شركة نجيب أوتو السيد نجيب عبد اللطيف العيسى، العديد من المهتمين في قطاع السيارات، وممثلي الإعلام والصحافة في المملكة العربية السعودية. وتبادل الطرفان الكلمات التي تؤكد على أهمية صناعة السيارات في السوق السعودي والشراكة الاستراتيجية الجديدة بينهما لخدمة عملاء المملكة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Mr. Alissa stressed the importance of this occasion, which lies in the well-studied geographical expansion of the company, in affirmation to the dealership of the Japanese Suzuki Motor in the Kingdom of Saudi Arabia, the company of high and well-established reputation in the automotive sector all over the world. The relationship between the two companies will develop by Allah’s will into a long-term strategic partnership, with a priority to provide distinguished customer service at all levels in sales, maintenance, and spare parts services.",
          ar:"أكد السيد العيسى على أهمية هذه المناسبة، التي تكمن في التوسع الجغرافي المدروس للشركة، تأكيدًا لوكالة شركة سوزوكي موتور اليابانية في المملكة العربية السعودية، الشركة ذات السمعة العالية والراسخة في قطاع السيارات في جميع أنحاء العالم. ستتطور العلاقة بين الشركتين بإذن الله إلى شراكة استراتيجية طويلة الأمد، مع أولوية لتقديم خدمة عملاء متميزة على جميع المستويات في خدمات المبيعات والصيانة وقطع الغيار.",
        } 
      },

      {
        type: "text",
        text: {
          en:"'We are optimistic about the strength of the KSA economy and the increasing demand for the automotive sector in particular,” said Alissa, “We are relying on Allah, the Almighty, above all, to be among the best and most requested brands in this market,” he added.",
          ar:"قال العيسى: 'نحن متفائلون بشأن قوة اقتصاد المملكة العربية السعودية والطلب المتزايد على قطاع السيارات بشكل خاص،' وأضاف: 'نعتمد على الله، القدير، قبل كل شيء، لنكون من بين أفضل وأكبر العلامات التجارية المطلوبة في هذا السوق'.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Among our main priorities set under implementation, we included the recruitment and training of national human cadres, in a way that supports our national economy in line with the Kingdom Vision 2030 in supporting the recruitment and investment of effective national human cadres and energies. We will apply it through a well-studied expansion plan with advanced business models, commensurate with the global development in communications and outreach between customers and service providers.",
          ar:"من بين أولوياتنا الرئيسية التي تم وضعها قيد التنفيذ، قمنا بتضمين توظيف وتدريب الكوادر البشرية الوطنية، بطريقة تدعم اقتصادنا الوطني بما يتماشى مع رؤية المملكة 2030 في دعم توظيف واستثمار الكوادر والطاقة البشرية الوطنية الفعالة. سنطبق ذلك من خلال خطة توسع مدروسة جيداً مع نماذج أعمال متقدمة، تتناسب مع التطور العالمي في الاتصالات والتواصل بين العملاء ومقدمي الخدمة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"We have already finished preparing the first phase of sales halls and service centers in Jeddah, currently finalizing in Riyadh, and in Dammam soon by Allah’s will.",
          ar:"لقد انتهينا بالفعل من إعداد المرحلة الأولى من صالات العرض ومراكز الخدمة في جدة، ويتم حالياً إنهاء العمل في الرياض، وفي الدمام قريباً بإذن الله.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Mr. Koichi Suzuki, Deputy Director General of Global Marketing at Suzuki Motor, said in his speech at the ceremony, 'The great and wide experience of Najeeb Auto management in the automotive world highly encouraged us to consider a strategic partnership with them with no doubt. We are confident that Suzuki will be served in an advanced and professional manner that suits its long history under the umbrella of Najeeb Auto, and we are certain that the presence of this brand will be effective and widespread in the Kingdom of Saudi Arabia.”",
          ar:"قال السيد كويتشي سوزوكي، نائب المدير العام للتسويق العالمي في شركة سوزوكي موتور، في كلمته في الحفل، 'إن التجربة الكبيرة والواسعة لإدارة نجيب أوتو في عالم السيارات شجعتنا بشدة على النظر في شراكة استراتيجية معهم دون شك. نحن واثقون من أن سوزوكي سيتم خدمتها بطريقة متقدمة ومهنية تناسب تاريخها الطويل تحت مظلة نجيب أوتو، ونحن متأكدون من أن وجود هذه العلامة التجارية سيكون فعالاً ومنتشراً في المملكة العربية السعودية'.",
        } 
      },

      {
        type: "text",
        text: {
          en:"'We will provide all technical and logistical support, in addition to our endeavor to provide the latest models required for the Saudi market,” Suzuki added. 'We are optimistic about the strength of the Kingdom of Saudi Arabia's economy and the high demand for the automotive sector in particular, and we will strive to be among the best and most requested brands in this market,' he concluded.",
          ar:"وأضاف سوزوكي: 'سنوفر كل الدعم الفني واللوجستي، بالإضافة إلى سعينا لتقديم أحدث الطرازات المطلوبة للسوق السعودي. نحن متفائلون بقوة اقتصاد المملكة العربية السعودية والطلب العالي على قطاع السيارات بشكل خاص، وسنسعى لأن نكون من بين أفضل وأكبر العلامات التجارية المطلوبة في هذا السوق'.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Suzuki Motor Corporation is a Japanese company specializing in the production of automobiles, complete sets of motorcycles, and all-geographical factors vehicles. It ranked No. 1 in the automobile industry and the 10th largest car manufacturer in the world, employing 45,000 workers, and owning 35 production sites in 20 countries, in addition to having 133 distributors in 207 countries.",
          ar:"شركة سوزوكي موتور هي شركة يابانية متخصصة في إنتاج السيارات ومجموعات كاملة من الدراجات النارية والمركبات ذات العوامل الجغرافية. احتلت المرتبة الأولى في صناعة السيارات والعاشر كأكبر مصنع للسيارات في العالم، حيث توظف 45,000 عامل وتمتلك 35 موقعًا للإنتاج في 20 دولة، بالإضافة إلى 133 موزعًا في 207 دول.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Following the war, Japan was in great need of affordable and reliable private means of transportation. Facing this major challenge, Suzuki’s thinking was once again redirected to vehicles when in 1909, Michio Suzuki formed the Suzuki Company on the sea coast of the Japanese village of Hamamatsu. In 1929, the business grew strongly when Suzuki invented and exported a new type of multi-armed machinery.",
          ar:"بعد الحرب، كانت اليابان في حاجة ماسة إلى وسائل نقل خاصة ميسورة التكلفة وموثوقة. مواجهةً لهذا التحدي الكبير، تم توجيه تفكير سوزوكي مرة أخرى نحو السيارات عندما قام ميشيو سوزوكي بتأسيس شركة سوزوكي على ساحل البحر في قرية هاماماتسو اليابانية في عام 190 9. في عام 1929، نمت الأعمال بقوة عندما اخترع سوزوكي وصدر نوعًا جديدًا من الآلات متعددة الأذرع.",
        }
      },

      {
        type: "text",
        text: {
          en:"Suzuki has 120 patents registered.",
          ar:"لدى سوزوكي 120 براءة اختراع مسجلة.",
        } 
      },
    ],
  },

  {
    id: "news-5",
    image: NewsFiveImageOne,
    date: "March 08, 2022",
    title: {
      "en":"Suzuki dealer Najeeb Auto honors outstanding employees & customers",
      "ar":"تكرم شركة نجيب أوتو، وكيل سوزوكي، الموظفين والعملاء المميزين"
    } ,
    content: [
      {
        type: "text",
        text: {
          en:"Suzuki dealer Najeeb Auto honors outstanding employees & customers",
          ar:"تكرم شركة نجيب أوتو، وكيل سوزوكي، الموظفين والعملاء المميزين",
        } 
      },

      {
        type: "image",
        src: NewsFiveImageOne,
      },

      {
        type: "text",
        text: {
          en:"RIYADH: Najeeb Auto, the authorized dealer of Suzuki cars in Saudi Arabia, honored the most distinguished employees and customers who exemplify the companys vision and goals, during a recent ceremony held in Riyadh.",
          ar:"الرياض: كرمت شركة نجيب أوتو، الوكيل المعتمد لسيارات سوزوكي في السعودية، الموظفين والعملاء الأكثر تميزاً الذين يجسدون رؤية وأهداف الشركة، خلال حفل أقيم مؤخراً في الرياض.",
        } 
      },

      {
        type: "image",
        src: NewsFiveImageTwo,
      },

      {
        type: "text",
        text: {
          en:"Najeeb Abdullatif Al-Issa highlighted in his speech the challenges and successes of Najeeb Auto in 2021. He said the company sold around 5,000 cars last year, and increased its Saudi market share.",
          ar:"أكد نجيب عبد اللطيف العيسى في كلمته على التحديات والنجاحات التي حققتها نجيب أوتو في عام 2021. وقال إن الشركة باعت حوالي 5000 سيارة العام الماضي، وزادت حصتها في السوق السعودي.",
        } 
      },

      {
        type: "text",
        text: {
          en:"He said the company plans to further increase its market share this year and its sales volume to 25,000 vehicles in the next five years. Al-Issa expressed his confidence in the Kingdom, the Saudi leadership and the economic growth of the Kingdom.",
          ar:"وقال إن الشركة تخطط لزيادة حصتها في السوق هذا العام وحجم مبيعاتها إلى 25000 مركبة في السنوات الخمس المقبلة. أعرب العيسى عن ثقته في المملكة والقيادة السعودية والنمو الاقتصادي للمملكة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"He said the company aims to be among the most valuable brands in the car market, by focusing on Saudi Vision 2030 goals and training national talents to support the economy of the Kingdom.",
          ar:"وقال إن الشركة تهدف إلى أن تكون من بين العلامات التجارية الأكثر قيمة في سوق السيارات، من خلال التركيز على أهداف رؤية المملكة 2030 وتدريب المواهب الوطنية لدعم اقتصاد المملكة.",
        } 
      },

      {
        type: "text",
        text: {
          en:"Koichi Suzuki, general manager of global marketing in the Middle East and Africa at Suzuki Motors, attended the ceremony via videoconferencing. In his speech, he expressed deep gratitude to Al-Issa and his team members. He praised their efforts to maintain a great customer experience and lauded the company's expansion plans in Saudi Arabia.",
          ar:"حضر كويتشي سوزوكي، المدير العام للتسويق العالمي في الشرق الأوسط وأفريقيا في شركة سوزوكي موتورز، الحفل عبر الفيديو. وفي كلمته، أعرب عن عميق امتنانه للعيسى وأعضاء فريقه. وأشاد بجهودهم للحفاظ على تجربة رائعة للعملاء وأثنى على خطط الشركة للتوسع في السعودية.",
        } 
      },

      {
        type: "image",
        src: NewsFiveImageThree,
      },

      {
        type: "text",
        text: {
          en:"Meanwhile, Abdulaziz Al-Shehri, vice president of Najeeb Auto, stressed the importance of strong customer relations, and the positive impact of showing attention and gratitude, on the performance level. Al-Shehri underlined the company’s commitment to strengthening customers relations, and to continue achieving business growth.",
          ar:"وفي الوقت نفسه، أكد عبدالعزيز الشهري، نائب رئيس نجيب أوتو، على أهمية العلاقات القوية مع العملاء، والتأثير الإيجابي لإظهار الاهتمام والامتنان، على مستوى الأداء. وأكد الشهري على التزام الشركة بتعزيز علاقات العملاء، ومواصلة تحقيق النمو التجاري.",
        } 
      },

      {
        type: "image",
        src: NewsFiveImageFour,
      },
    ],
  },
];

export default news;
