import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Container,
  Row,
  Col,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import maintenanceCalculatorData from "../../hooks/maintenanceCalculatorData";
import SectionTitleSecondary from "../SectionTitleSecondary/SectionTitleSecondary";
import "./MaintenanceCalculatorModal.css";

const MaintenanceCalculatorModal = ({ show, handleClose, vehicle }) => {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./MaintenanceCalculatorModal-ar.css");
    } else {
      import("./MaintenanceCalculatorModal.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);



  const [selectedMileage, setSelectedMileage] = useState("");

  const handleMileageChange = (e) => {
    setSelectedMileage(e.target.value);
  };

  const mileages = [
    "10,000km",
    "20,000km",
    "30,000km",
    "40,000km",
    "50,000km",
    "60,000km",
    "70,000km",
    "80,000km",
    "90,000km",
    "100,000km",
    "100,000+km",
  ];

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <SectionTitleSecondary
            title={t("Maintenance Calculator")}
            subtitle="Calculate the pricing of Suzuki cars based on the mileage."
          />
          <p className="imp-msg">*{t("All PRICES ARE INCLUSIVE OF VAT")}</p>
          <Row>
            <Col md={6}>
              <Form.Group controlId="selectVehicle">
                <Form.Label className="brand-label">{t("Select Vehicle")}</Form.Label>
                <Form.Control
                  className="brand-input text-capitalize"
                  as="input"
                  value={vehicle.title[currentLanguage]}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="selectMileage">
                <Form.Label className="brand-label">{t("Select Mileage")}</Form.Label>
                <Form.Select
                  className="brand-input"
                  value={selectedMileage}
                  onChange={handleMileageChange}
                >
                  <option value="">{t("Please Select Mileage")}</option>
                  {mileages.map((mileage, index) => (
                    <option key={index} value={mileage}>
                      {mileage}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {selectedMileage === "100,000+km" ? (
            <div className="mt-4">
              <p>
                {t("Please book a service appointment for maintenance over 100,000km.")}
              </p>
              <div className="text-center">
                <Button
                  className="custom-button"
                  href="/book-service-appointment"
                >
                  {t("Book Service Appointment")}
                  <i className="jam jam-arrow-up-right text-white"></i>
                </Button>
              </div>
            </div>
          ) : (
            selectedMileage && (
              <>
                <div className="highlighted-message mt-3">
                  {t("Maintenance cost of your")} {vehicle.title[currentLanguage]} {t("for")} {selectedMileage}
                  :
                  <span>
                    {" "}
                    {maintenanceCalculatorData[vehicle.id]?.[selectedMileage] ||
                      "N/A"}
                  </span>
                </div>
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="brand-table mt-2"
                >
                  <thead>
                    <tr>
                      <th>Vehicle</th>
                      {mileages.slice(0, -1).map((mileage, index) => (
                        <th key={index}>{mileage}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr>
                      <td>{vehicle.title[currentLanguage]}</td>
                      {mileages.slice(0, -1).map((mileage, index) => (
                        <td
                          key={index}
                          className={
                            mileage === selectedMileage
                              ? "highlighted-price"
                              : ""
                          }
                        >
                          {maintenanceCalculatorData[vehicle.id]?.[mileage] ||
                            "N/A"}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              </>
            )
          )}
        </Container>

        <div className="mt-4">
          <SectionTitleSecondary title={t("Terms & Conditions")} />

          <ul className="calculator-tc">
            <li>{t("Price menu based on normal driving condition.")} <span class="required-mark">*</span></li>
            <li>
              {t("Service menu based at every 10,000 KM and or 6 months (which ever comes first)")} <span class="required-mark">*</span>
            </li>
            <li>{t("Brakes will be replaced depends on driving condition.")} <span class="required-mark">*</span></li>
            <li>
              {t("All wear and tear parts replacement depends on driving condition.")} <span class="required-mark">*</span>
            </li>
            <li>{t("Prices are subject to change without prior notice.")} <span class="required-mark">*</span></li>
            <li>{t("Above prices are net (Include Parts & Labor).")} <span class="required-mark">*</span></li>
            <li>
              {t("Grand Vitara & Fronx A/Transmisson oil replacment is not required, Only leakage inspection required every 30,000 km or 24 months.")} <span class="required-mark">*</span>
            </li>
            <li>
              {t("Grand Vitara Transfer Oil (4WD) Inspect replace every 150,000 km")} <span class="required-mark">*</span>
            </li>
            <li>
              {t("Grand Vitara, EECO & Fronx Engine Oil OW20, the other all Suzuki models Engine Oil = 5W30")} <span class="required-mark">*</span>
            </li>
            <li>{t("All above prices are with VAT.")} <span class="required-mark">*</span></li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MaintenanceCalculatorModal;
