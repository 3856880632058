import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useLocation } from "react-router-dom";
import vehicles from "../../hooks/vehicles";
import "./GetQuoteForm.css";

const GetQuoteForm = () => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./GetQuoteForm-ar.css");
    } else {
      import("./GetQuoteForm.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const location = useLocation();
  const state = location.state || {};
  const [selectedVehicle, setSelectedVehicle] = useState(
    state.vehicleId ? vehicles.find((v) => v.id === state.vehicleId) : null
  );
  const [selectedVariant, setSelectedVariant] = useState(
    state.selectedVariant || ""
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);
  const cities = [t("Riyadh"), t("Jeddah"), t("Al Khobar"), t("Madinah"), t("Al Ahsa"), t("Al Kharj"), t("Khamis Mushait"), t("Najran"), t("Onaiza"), t("Mecca") ];
  const interiorColors = ["Black"];

  useEffect(() => {
    if (selectedVehicle) {
      setVariantOptions(selectedVehicle.variants);
    }
  }, [selectedVehicle]);

  const formik = useFormik({
    initialValues: {
      vehicle: selectedVehicle ? selectedVehicle.title[currentLanguage] : "",
      variant: selectedVariant,
      exteriorColor: "",
      interiorColor: "",
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      gender: "",
      city: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      vehicle: Yup.string().required(t("Vehicle is required")),
      variant: Yup.string().required(t("Variant is required")),
      exteriorColor: Yup.string().required(t("Exterior Color is required")),
      interiorColor: Yup.string().required(t("Interior Color is required")),
      firstName: Yup.string()
        .required(t("First Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z ]+$/, t("Only characters are allowed"))
        .min(3, t("First Name should be at least 3 characters long")),
      lastName: Yup.string()
        .required(t("Last Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z ]+$/, t("Only characters are allowed"))
        .min(3, t("Last Name should be at least 3 characters long")),
      contactNumber: Yup.string()
        .required(t("Contact Number is required"))
        .matches(
          /^05\d{8}$/,
          t("Contact Number should start with 05 and be 10 digits long")
        ),
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid email format")),
      gender: Yup.string().required(t("Gender is required")),
      city: Yup.string().required(t("City is required")),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("Car", values.vehicle);
      formData.append("Variant", values.variant);
      formData.append("ExColor", values.exteriorColor);
      formData.append("InColor", values.interiorColor);
      formData.append("First_name", values.firstName);
      formData.append("Last_name", values.lastName);
      formData.append("Phone", values.contactNumber);
      formData.append("Email", values.email);
      formData.append("Gender", values.gender);
      formData.append("City", values.city);

      setIsSubmitting(true);
      axios
        .post("https://najeebapi.nhcops.com/public/api/GetQoute", formData)
        .then((response) => {
          setIsSubmitting(false);
          if (response.data === "done") {
            setIsSubmitted(true);
            formik.resetForm();
          } else {
            alert("Error submitting form.");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setSubmissionError("Error submitting form.");
          console.error(error);
        });
    },
  });

  const handleVehicleChange = (e) => {
    const vehicleName = e.target.value;
    const vehicle = vehicles.find((v) => v.title[currentLanguage] === vehicleName);
    setSelectedVehicle(vehicle);
    formik.setFieldValue("vehicle", vehicleName);
    formik.setFieldValue("variant", "");
    setVariantOptions(vehicle ? vehicle.variants : []);
  };

  const handleVariantChange = (e) => {
    const variantName = e.target.value;
    setSelectedVariant(variantName);
    formik.setFieldValue("variant", variantName);
  };

  if (isSubmitted) {
    return (
      <div className="success-message text-center">
        <h3>{t("Submitted Successfully!")}</h3>
        <p>{t("Our team will reach you as soon as possible.")}</p>
      </div>
    );
  }

  if (submissionError) {
    return (
      <div className="error-message text-center">
        <h3>{t("Submission Failed!")}</h3>
        <p>{t("Please try again later.")}</p>
      </div>
    );
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
      <div className="form-instruction">
          <h3>{t("Fill in the form below")}</h3>
          <p>
          {t("Provide us your requirements and our Sales Team will be in touch with you.")}
          </p>
        </div>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formVehicle">
              <Form.Label className="brand-label">
                {t("Select Vehicle")}<span className="required-mark">*</span>
              </Form.Label>
              {state.vehicleId ? (
                <Form.Control
                  className="brand-input"
                  type="text"
                  name="vehicle"
                  value={formik.values.vehicle}
                  readOnly
                  disabled
                />
              ) : (
                <Form.Select
                  className="brand-input"
                  name="vehicle"
                  value={formik.values.vehicle}
                  onChange={handleVehicleChange}
                  isInvalid={!!formik.errors.vehicle}
                >
                  <option value="">{t("Select Vehicle")}</option>
                  {vehicles.filter((v) => !v.discontinued).map((vehicle, index) => (
                    <option key={index} value={vehicle.title[currentLanguage]}>
                      {vehicle.title[currentLanguage]}
                    </option>
                  ))}
                </Form.Select>
              )}
              <Form.Control.Feedback type="invalid">
                {formik.errors.vehicle}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formVariant">
              <Form.Label className="brand-label">
                {t("Select Variant")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="variant"
                value={formik.values.variant}
                onChange={handleVariantChange}
                isInvalid={!!formik.errors.variant}
                disabled={state.vehicleId && !selectedVehicle}
              >
                <option value="">{t("Select Variant")}</option>
                {variantOptions.map((variant, index) => (
                  <option key={index} value={variant.name}>
                    {variant.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.variant}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formExteriorColor">
              <Form.Label className="brand-label">
                {t("Select Exterior Color")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="exteriorColor"
                value={formik.values.exteriorColor}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.exteriorColor}
              >
                <option value="">{t("Select Exterior Color")}</option>
                {selectedVehicle?.colors.map((color, index) => (
                  <option key={index} value={color.name}>
                    {color.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.exteriorColor}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formInteriorColor">
              <Form.Label className="brand-label">
                {t("Select Interior Color")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="interiorColor"
                value={formik.values.interiorColor}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.interiorColor}
              >
                <option value="">{t("Select Interior Color")}</option>
                {interiorColors.map((color, index) => (
                  <option key={index} value={color}>
                    {color}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.interiorColor}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formFirstName">
              <Form.Label className="brand-label">
                {t("First Name")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formLastName">
              <Form.Label className="brand-label">
                {t("Last Name")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.lastName}/>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
    
              <Col md={6}>
                <Form.Group controlId="formContactNumber">
                  <Form.Label className="brand-label">
                    {t("Contact Number")}<span className="required-mark">*</span>
                  </Form.Label>
                  <Form.Control
                    className="brand-input"
                    type="text"
                    name="contactNumber"
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.contactNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.contactNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
    
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label className="brand-label">
                    {t("Email Address")}<span className="required-mark">*</span>
                  </Form.Label>
                  <Form.Control
                    className="brand-input"
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
    
              <Col md={6}>
                <Form.Group controlId="formGender">
                  <Form.Label className="brand-label">
                    {t("Select Gender")}<span className="required-mark">*</span>
                  </Form.Label>
                  <Form.Select
                    className="brand-input"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.gender}
                  >
                    <option value="">{t("Select Gender")}</option>
                    <option value="Male">{t("Male")}</option>
                    <option value="Female">{t("Female")}</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
    
              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label className="brand-label">
                    {t("Select City")}<span className="required-mark">*</span>
                  </Form.Label>
                  <Form.Select
                    className="brand-input"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.city}
                  >
                    <option value="">{t("Select City")}</option>
                    {cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
    
            <div className="d-grid gap-2 col-6 mx-auto">
              <Button
                className="form-brand-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner animation="border" size="sm" /> : t("Submit")}
              </Button>
            </div>
          </Form>
        </Container>
      );
    };
    
    export default GetQuoteForm;
    