import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ targetDate }) => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className={`${i18n.language === "ar" ? "dir-ltr text-right" : "dir-ltr"}`}>
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span>{t("Offer has expired")}</span>
      )}
    </div>
  );
};

export default CountdownTimer;
