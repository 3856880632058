// src/pages/WarrantyPage/WarrantyPage.js
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import WarrantyForm from "../../components/WarrantyForm/WarrantyForm";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";
import "./WarrantyPage.css"

const WarrantyPage = () => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./WarrantyPage-ar.css");
    } else {
      import("./WarrantyPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  return (
    <>
      <div className="sec-bg section-spacing">
        <Container>
          <SectionTitlePrimary subtitle="Owners" title={t("Suzuki Warranty")} />
          <div className="warranty-content section-spacing mt-5">
            <h3>{t("Suzuki Warranty - 3 Years / 100,000 km warranty")}</h3>
            <p>
              {t("Every new SUZUKI comes with a 3 years / 100,000 KM warranty as standard. We therefore assure you that in the unlikely event of any part of the vehicle becoming defective due to faulty materials or manufacturing within the Warranty period, the part will be repaired or replaced by SUZUKI’s authorized dealer Najeeb Auto. Full terms and conditions of your SUZUKI warranty can be found in the Service booklet which has been provided with your vehicle.")}
            </p>
          </div>
          <Row>
            <Col md={12}>
              <div className="">
                <div className="mt-4 form-instruction mb-4">
                  <h3>{t("ACTIVATE YOUR SUZUKI WARRANTY")}</h3>
                </div>
                <WarrantyForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default WarrantyPage;
