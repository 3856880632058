import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CallToActionCard from "../CallToActionCard/CallToActionCard";
import ctas from "../../hooks/cta";
import "./CallToAction.css";
import SectionTitlePrimary from "../SectionTitlePrimary/SectionTitlePrimary";
import { t } from "i18next";

const CallToAction = () => {
  return (
    <div className="call-to-action-section section-spacing">
      <Container>
        <SectionTitlePrimary subtitle="" title={t("Take the next steps")}/>
        <Row className="mt-4">
          {ctas.map((cta) => (
            <Col key={cta.id} md={6} lg={3}>
              <CallToActionCard
                icon={cta.icon}
                title={cta.title}
                description={cta.description}
                link={cta.link}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CallToAction;
