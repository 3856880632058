import React from "react";
import { Container } from "react-bootstrap";
import CareersForm from "../../components/CareersForm/CareersForm";
import SectionTitleSecondary from "../../components/SectionTitleSecondary/SectionTitleSecondary";
import CallToAction from "../../components/CallToAction/CallToAction";

const CareersPage = () => {
  return (
    <>
      <div className="sec-bg">
        <Container className="section-spacing">
          <SectionTitleSecondary
            title="Careers"
            subtitle="apply for a job at SUZUKI Najeeb Auto"
          />
          
          <CareersForm/>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default CareersPage;
