import React from "react";
import "./SectionTitleSecondary.css";

const SectionTitlePrimary = ({ subtitle, title }) => {
  return (
    <div className="section-title-secondary">
      <h2 className="section-title">{title}</h2>
      {/* <h6 className="section-subtitle">{subtitle}</h6> */}
    </div>
  );
};

export default SectionTitlePrimary;
