import React, { useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./VehicleCard.css";

const VehicleCard = ({
  vehicle,
  showPrice = false,
  showExploreLink = true,
}) => {
  const lowestPriceVariant = vehicle.variants.reduce(
    (prevVariant, currVariant) =>
      parseInt(prevVariant.price.replace(/,/g, "")) <
      parseInt(currVariant.price.replace(/,/g, ""))
        ? prevVariant
        : currVariant
  );

  const { t, i18n } = useTranslation();

  const currentLang = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleCard-ar.css");
    } else {
      import("./VehicleCard.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="vehicle-card">
      <Link to={`/vehicles/${vehicle.id}`}>
        <Image variant="top" src={vehicle.image} alt={vehicle.title} fluid />
      </Link>
      <Link to={`/vehicles/${vehicle.id}`}>
      <h3 className="vehicle-title">{vehicle.title[currentLang]}</h3>
      </Link>
      {showPrice && <h6 className="vehicle-price-title">{t("Starting Price")}</h6>}

      {showPrice && (
        <div className="link-align vehicle-list-price">
          <Link to={`/vehicles/${vehicle.id}`}>
            SAR {lowestPriceVariant.price}<span className='imp-msg'>*</span>
          </Link>
          <i className={`${i18n.language === "ar" ? "jam jam-arrow-left" : "jam jam-arrow-right"}`}></i>
        </div>
      )}

      {showExploreLink && (
        <div className="link-align">
          <Link to={`/vehicles/${vehicle.id}`}>{t("Explore")}</Link>
          <i className={`${i18n.language === "ar" ? "jam jam-arrow-left" : "jam jam-arrow-right"}`}></i>
        </div>
      )}
    </div>
  );
};

export default VehicleCard;
