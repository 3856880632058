import React from "react";
import "../Home/Home.css";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import OfferSection from "../../components/OfferSection/OfferSection";
import VehicleSection from "../../components/VehicleSection/VehicleSection";
import CallToAction from "../../components/CallToAction/CallToAction";
import NewsSection from "../../components/NewsSection/NewsSection";

const Home = () => {
  return (
    <div>
      <HomeBanner />
      <OfferSection />
      <VehicleSection />
      <CallToAction />
      <NewsSection/>
    </div>
  );
};

export default Home;
