import React , {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import "./VehicleTaglineComponent.css";

const VehicleTaglineComponent = ({ vehicle }) => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleTaglineComponent-ar.css");
    } else {
      import("./VehicleTaglineComponent.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  return (
    <Container>
      <div className="vehicle-tagline section-spacing">
      <h1 className="vehicle-name">{vehicle.title[currentLanguage]}</h1>
      <p className="vehicle-short-description">{vehicle.shortDescription[currentLanguage]}</p>
      <h6 className="vehicle-tagline-text">{vehicle.tagline[currentLanguage]}</h6>
    </div>
    </Container>
    
  );
};

export default VehicleTaglineComponent;
