import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CallToActionCard.css";

const CallToActionCard = ({ icon, title, description, link }) => {

  const { t, i18n } = useTranslation();

  const currentLang = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./CallToActionCard-ar.css");
    } else {
      import("./CallToActionCard.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Link to={link} className="call-to-action-card-link">
      <Card className="call-to-action-card">
        <Card.Body className="cta-body">
          <i className={`jam ${icon} icon-style`}></i>
          <Card.Title className="cta-title">{title[currentLang]}</Card.Title>
          <Card.Text className="cta-desc">{description[currentLang]}</Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default CallToActionCard;
