import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import "./AboutUsPage.css";

import AboutImage2 from "../../images/about-image-2.png";
import AboutSec2Icon from "../../images/about-sec-2-icon.png";
import SheikhOne from "../../images/sheikh-abdullatif-alissa.png";
import SheikhTwo from "../../images/sheikh-najeeb-alissa.png";
import DoubleQuote from "../../images/double-quote.png";
import CustomButton from "../../components/CustomButton/CustomButton";

const AboutUsPage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./AboutUsPage-ar.css");
    } else {
      import("./AboutUsPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="about-us-page section-spacing">
      <div className="about-us-main">
        <h1 className="about-page-title">{t("Najeeb Auto in a Glimpse")}</h1>
        <h5 className="text-center mb-4">
          {t("Crafting automotive dreams into reality, one gear at a time.")}
        </h5>
        <h5 className="text-center">{t("Welcome to our journey.")}</h5>
      </div>

      <Container>
        <div className="section-spacing">
          <div className="about-sec-two">
            <Row>
              <Col md={6}>
                <div className="about-sec-two-image">
                  <img className="img-fluid" src={AboutImage2} />
                </div>
              </Col>

              <Col md={6}>
                <h3 className="about-sec-two-title">
                  {t("Delivering Exceptional & Beyond")}
                </h3>
                <p className="about-sec-two-para">
                  {t(
                    "With the launch of Najeeb Auto, our goal is to position ourselves at the forefront throughout the Middle East, setting new standards of excellence and customer satisfaction in the region."
                  )}
                </p>
              </Col>
            </Row>
            <div className="achivement-bar">
              <Row>
                <Col md={4}>
                  <h2>60K+</h2>
                  <p>{t("Cars Sold")}</p>
                </Col>
                <Col md={4}>
                  <h2>600+</h2>
                  <p>{t("Employees")}</p>
                </Col>
                <Col md={4}>
                  <h2>5+</h2>
                  <p>{t("Years")}</p>
                </Col>
              </Row>
            </div>
            <div className="about-sec-2-icon">
              <img src={AboutSec2Icon} />
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="section-spacing">
          <Row>
            <Col lg={4}>
              <div className="founders">
                <img className="img-fluid" src={SheikhOne} />
              </div>
            </Col>

            <Col lg={8}>
              <div className="founders-content text-center">
                <img className="mb-4" src={DoubleQuote} />
                <h3>
                  {t("Sincerity and true intentions in dealing with people are the foundations of success")}
                </h3>
                <h5>{t("Sheikh Abdullatif Alissa, 1910 - 2005")}</h5>
                <p>
                  {t("Regarded today as one of the most respected names in the market and a true pioneer who set standards and shape the Automotive market. Over 70 years later and the name Alissa is still synonymous with trust, professionalism, and customer care.")}
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section-spacing">
          <Row>
            <Col lg={8}>
              <div className="founders-content text-center">
                <img className="mb-4" src={DoubleQuote} />
                <h3>
                  {t("Our assets are our customers, our people and our experience.")}
                </h3>
                <h5>{t("Najeeb Abdullatif Alissa")}</h5>
                <p>
                  {t("Continuing the legacy and in the footsteps of his father, Najeeb Alissa has been the driving force and visionary for the group. Developing an automotive ecology that is second to none. Providing a total automotive solution catering to all segments.")}
                </p>
              </div>
            </Col>

            <Col lg={4}>
              <div className="founders">
                <img className="img-fluid" src={SheikhTwo} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section-spacing">
          <div className="about-sec-3-content text-center">
            <h3>
              {t("With a rich legacy spanning 70 years in the Automotive Industry")}
            </h3>
            <p>
              {t("we at Najeeb Auto boast a deep understanding of the sector. Our foundation is rooted in strong Saudi cultural and family values, instilled by our late founder, Abdullatif Alissa, whose vision continues to guide us. Being recognized as the leading Suzuki dealer in the Middle East is a source of immense pride for us. This achievement is not merely a testament to our expertise but also a reflection of our unwavering commitment to excellence and customer satisfaction.")}
            </p>
            <p>
              <span>
                {t("As we move forward, we remain steadfast in our commitment to building a legacy based on trust, satisfaction, and enduring partnerships.")}
              </span>
              {t("These values serve as the cornerstone of our future success and growth, driving us to continually raise the bar in the automotive industry.")}
            </p>
          </div>
        </div>

        <div className="section-spacing">
          <div className="about-sec-4-content text-center">
            <h3>{t("Our Values")}</h3>
          </div>
          <Row>
            <Col md={6} lg={3}>
              <div className="values-content">
                <div className="value-1">
                  <h5>{t("Professionalism")}</h5>
                  <p>{t("Exemplary automotive industry standards")}</p>
                </div>
              </div>
            </Col>

            <Col md={6} lg={3}>
              <div className="values-content">
                <div className="value-2">
                  <h5>{t("Honesty")}</h5>
                  <p>{t("Transparent & trustworthy service")}</p>
                </div>
              </div>
            </Col>

            <Col md={6} lg={3}>
              <div className="values-content">
                <div className="value-3">
                  <h5>{t("Reliability")}</h5>
                  <p>{t("Dependable vehicles & customer care")}</p>
                </div>
              </div>
            </Col>

            <Col md={6} lg={3}>
              <div className="values-content">
                <div className="value-4">
                  <h5>{t("Respect")}</h5>
                  <p>{t("Valuing every customer's needs")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section-spacing">
          <Row>
            <Col md={6}>
              <div className="mv-content">
                <p>
                  <span>{t("Mission")}</span>
                  {t("to deliver top-notch experiences, prioritize excellence, reliability, affordability, and ease of maintenance, aiming to establish ourselves as industry leaders.")}
                </p>
              </div>
            </Col>

            <Col md={6}>
              <div className="mv-content">
                <p>
                  <span>{t("Vision")}</span>
                  {t("to be recognized as the leading Suzuki distributor in the Middle East. We strive to remain synonymous with exceptional care and support in everything we offer.")}
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section-spacing">
          <div className="about-us-last-sec text-center">
            <h3>{t("Step into our Najeeb Auto showroom")}</h3>
            <p>
              {t("Where you'll find an extensive range of Suzuki vehicles meticulously curated to cater to diverse needs and preferences. Each product and service we offer upholds the highest standards of quality and professionalism, reinforcing our pledge to exceed expectations at every turn.")}
            </p>
          </div>
          <div className="about-cta">
            <CustomButton to="/our-locations" text={t("Find the Nearest Branch")} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUsPage;
