import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Container, Table } from "react-bootstrap";
import vehicles from "../../hooks/vehicles";
import "./VehicleSpecification.css";

const VehicleSpecification = ({ vehicleId }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleSpecification-ar.css");
    } else {
      import("./VehicleSpecification.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  // Define only the specific values that need translation
  const valueTranslations = {
    Manual: {
      en: "Manual",
      ar: "يدوي",
    },
    Auto: {
      en: "Auto",
      ar: "أوتوماتيكي",
    },
    Optional: {
      en: "Optional",
      ar: "اختياري",
    },
    "7inch": {
      en: "7inch",
      ar: "7 بوصة",
    },
    "9inch": {
      en: "9inch",
      ar: "9 بوصة",
    },
    "15-inch (Steel)": {
      en: "15-inch (Steel)",
      ar: "15 بوصة (فولاذ)",
    },
    "16-inch (Alloy)": {
      en: "16-inch (Alloy)",
      ar: "16 بوصة (سبائك)",
    },
    Rear: {
      en: "Rear",
      ar: "خلفي",
    },
    "Front & Rear": {
      en: "Front & Rear",
      ar: "أمامي وخلفي",
    },
    Halogen: {
      en: "Halogen",
      ar: "هالوجين",
    },
    LED: {
      en: "LED",
      ar: "LED",
    },
    "Yes (Optional)": {
      en: "Yes (Optional)",
      ar: "نعم (اختياري)",
    },
    Polished: {
      en: "Polished",
      ar: "مصقول",
    },
    Black: {
      en: "Black",
      ar: "أسود",
    },
    Silver: {
      en: "Silver",
      ar: "فضي",
    },
    Painted: {
      en: "Painted",
      ar: "مطلي",
    },
    "Yes (Painted)": {
      en: "Yes (Painted)",
      ar: "نعم (مطلي)",
    },
    Urethane: {
      en: "Urethane",
      ar: "يوريثان",
    },
    "Leather covered": {
      en: "Leather covered",
      ar: "مغطى بالجلد",
    },
    Steel: {
      en: "Steel",
      ar: "فولاذ",
    },
    Alloy: {
      en: "Alloy",
      ar: "سبائك",
    },
    "Halogen multi-reflector": {
      en: "Halogen multi-reflector",
      ar: "عاكس هالوجين متعدد",
    },
  };

  const translateValue = (value, language) => {
    if (valueTranslations[value]) {
      return valueTranslations[value][language];
    }
    return value;
  };

  const getFeatureValue = (value) => {
    // Keep the original "Yes" logic as is
    if (value === "Yes") {
      return <i className="jam jam-check"></i>;
    } else if (value === "--") {
      return "--";
    } else if (value) {
      // Translate only the specified values
      return translateValue(value, currentLanguage);
    }
    return "";
  };

  const vehicle = vehicles.find((v) => v.id === vehicleId);

  if (!vehicle) {
    return <div>Vehicle not found</div>;
  }

  const sections = [
    { key: "features", title: t("Features") },
    { key: "dimensions", title: t("Dimensions") },
    { key: "capacity", title: t("Capacity") },
    { key: "engine", title: t("Engine") },
    { key: "transmission", title: t("Transmission") },
    { key: "weight", title: t("Weight") },
  ];

  return (
    <Container>
      <div className="vehicle-specification section-spacing">
        <div className="vehicle-detail-section-title mb-2">
          <h1>{t("Specifications")}</h1>
          <p>{vehicle.specSubtitle[currentLanguage]}</p>
        </div>
        <div className="mb-4">
          <p className="spec-remark">
            {t(
              "*All the information provided is manufacturer’s data and may vary for each market."
            )}
          </p>
          <p className="spec-remark">
            {t(
              "*The specifications are subject to change without prior notice."
            )}
          </p>
        </div>

        <Accordion defaultActiveKey="0">
          {sections.map((section, index) => (
            <Accordion.Item
              className="brand-acc-item"
              eventKey={index.toString()}
              key={section.key}
            >
              <Accordion.Header
                className={`${
                  i18n.language === "ar"
                    ? "brand-acc-header rtl-acc-arrow"
                    : "brand-acc-header"
                }`}
              >
                {section.title}
              </Accordion.Header>
              <Accordion.Body>
                {vehicle.specifications[section.key] ? (
                  <Table responsive striped>
                    <thead className="brand-specs-thead">
                      <tr>
                        <th>{t("Model Type")}</th>
                        {vehicle.models.map((model, idx) => (
                          <th key={idx}>{model.type}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {vehicle.specifications[section.key].map((spec, idx) => (
                        <tr key={idx}>
                          <td>{spec.name[currentLanguage]}</td>
                          {vehicle.models.map((model, modelIdx) => (
                            <td key={modelIdx}>
                              {getFeatureValue(spec[model.type])}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>{t("No data available for this section.")}</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </Container>
  );
};

export default VehicleSpecification;
