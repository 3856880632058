import React from "react";
import "./SectionTitlePrimary.css";

const SectionTitlePrimary = ({ subtitle, title }) => {
  return (
    <div className="section-title-primary">
      {/* <h6 className="section-subtitle">{subtitle}</h6> */}
      <h2 className="section-title">{title}</h2>
    </div>
  );
};

export default SectionTitlePrimary;
