import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import OfferCard from "../OfferCard/OfferCard";
import "./VehicleOffer.css";
import { Container } from "react-bootstrap";

const VehicleOffer = ({ offers, vehicleTitle }) => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleOffer-ar.css");
    } else {
      import("./VehicleOffer.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  // Get the number of offers to display (at most 3)
  const offerCount = offers.length < 3 ? offers.length : 3;

  // Determine column width based on the number of offers
  const getColSize = (count) => {
    if (count === 1) return 12;
    if (count === 2) return 6;
    return 4; // for 3 or more offers
  };


  return (
    <Container>
      <h2 className="vehicle-offer-title">{t("Available Offers on")} {vehicleTitle}</h2>
      <Row className="justify-content-center">
        <Col md={getColSize(offerCount)}>
          <div className="vehicle-offer">
            {offers.map((offer) => (
              <OfferCard key={offer.id} offer={offer} />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VehicleOffer;
