const ctas = [
    {
      id: "1",
      icon: "jam-car",
      title: {
        "en":"Book a Test Drive",
        "ar":"احجز تجربة قيادة"
      },
      description: {
        "en":"Schedule Your Drive Today",
        "ar":"حدد موعد قيادتك اليوم"
      } ,
      link: "/book-test-drive",
    },
    {
      id: "2",
      icon: "jam-map-marker-f",
      title: {
        "en":"Find a Showroom",
        "ar":"ابحث عن صالة عرض"
      },
      description: {
        "en": "Locate Nearby Showrooms",
        "ar":"تحديد صالات العرض القريبة"
      },
      link: "/our-locations",
    },
    {
      id: 3,
      icon: "jam-calculator",
      title: {
        "en":"Book a Service",
        "ar":"احجز خدمة"
      } ,
      description: {
        "en":"Schedule a Maintenance Appointment",
        "ar":"جدولة موعد صيانة"
      },
      link: "/book-service-appointment",
    },
    {
      id: 4,
      icon: "jam-info",
      title: {
        "en":"FAQ's",
        "ar":"الأسئلة الشائعة"
      },
      description: {
        "en":"Find Your Answers Here",
        "ar":"اعثر على إجاباتك هنا"
      },
      link: "/faqs",
    },
  ];
  
  export default ctas;
  