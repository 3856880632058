import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import "./RoadsideAssistancePage.css";
import CallToAction from "../../components/CallToAction/CallToAction";

const RoadsideAssistancePage = () => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./RoadsideAssistancePage-ar.css");
    } else {
      import("./RoadsideAssistancePage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  return (
    <>
    <Container>
      <div className="roadside-assistance-page section-spacing">
        <SectionTitlePrimary
          className="mb-5"
          subtitle="Owners"
          title={t("Road-side Assistance")}
        />
        <div class="assistance-bar mt-5 mb-5">
          <h4>
            {t("Inside KSA")}
            <span>
              <a href="tel:8001166611">8001166611</a> |
              <a href="tel:8003044111">8003044111</a>
            </span>
          </h4>

          <h4>
           {t("Outside KSA")}
            <span>
              <a className="dir-ltr" href="tel:+966920017517">+966920017517</a>
            </span>
          </h4>
        </div>

        <p>
          {t("Breakdowns are never welcome, but our Roadside Assistance will make requesting and receiving Roadside Assistance a breeze. No matter where you’re driving, Roadside Assistance lets you travel with complete peace of mind. As part of our commitment of care to our customers, the Roadside Assistance Program offers 24/7 emergency assistance. We are proud to offer the response, security, and convenience of our 24-Hour Roadside Assistance Program.")}
        </p>

        <h2 className="assistance-title">{t("3 Years of Confidence")}</h2>

        <p>
          {t("Suzuki Najeeb Auto has partnered with third party Roadside Assistance service provider to provide and administer the Suzuki Roadside Assistance program giving customers access to their vast national resources and expertise, 24 hours a day, 365 days a year.")}
        </p>

        <p>
          {t("From December 1, 2020 onwards all new Suzuki vehicles will include up to 3 years of Roadside Assistance for all retail customers. The Roadside Assistance program will re-new annually for a period up to 3 years conditional to customers returning to Suzuki Najeeb Auto for scheduled servicing and following the terms and conditions of Suzuki’s.")}
        </p>

        <h4 className="assistance-subtitle">
          {t("To make sure that you are back on the road as soon as possible, please have the following information available when calling:")}
        </h4>

        <ul className="assistance-list">
          <li>
            {t("- Roadside Assistance Booklet and Istimarah (Vehicle registration)")}
          </li>
          <li>{t("- Name and location")}</li>
          <li>{t("- A description of the problem")}</li>
          <li>
            {t("- Customers should stay with the vehicle until assistance arrives.")}
          </li>
        </ul>

        <p>
          {t("Roadside Assistance personnel will be dispatched promptly to assess and attempt to rectify the problem. All care will be taken to get the customer back on the road, however, if this is not possible or if the personnel consider there is a chance that any mechanical action may void part of the vehicle’s New Vehicle Warranty, Suzuki Roadside Assist, will provide towing.")}
        </p>

        <h4 className="assistance-subtitle">
          {t("Emergency Road Services are available 24 hours a day throughout the GCC, Jordan, Lebanon, Egypt and Turkey.")}
        </h4>

        <p>
          {t("The services are not applicable in case of breakdown because of force majeure, i.e., thunderstorm, fire, earth- quake, hail, flood, and war.")}
        </p>
        <p>
          {t("All services are excluded in the case of accident, and the towing services is provided only to the dealers service centers.")}
        </p>

        <h2 className="assistance-title">{t("Road-side Assistance Services")}</h2>

        <div className="assistance-highlights">
          <h5>{t("Battery Boost")}</h5>
          <p>{t("In the event of battery failure.")}</p>
        </div>

        <div className="assistance-highlights">
          <h5>{t("Fuel Backup")}</h5>
          <p>{t("To enable the car to reach the nearest fuel station.")}</p>
        </div>

        <div className="assistance-highlights">
          <h5>{t("Flat Tire Replacement")}</h5>
          <p>{t("Replacement of flat tire with spare tire, in the event of puncture.")}</p>
        </div>

        <div className="assistance-highlights">
          <h5>{t("Towing Services")}</h5>
          <p>{t("Towing to one destination in the event of a disabling mechanical/electrical failure, to the nearest authorized service center.")}</p>
        </div>

        <div className="assistance-highlights">
          <h5>{t("Free Member Ride")}</h5>
          <p>{t("In the towing truck to the destination where the car will be towed to.")}</p>
        </div>
      </div>
    </Container>
    <div>
        <CallToAction/>
    </div>
    </>
  );
};

export default RoadsideAssistancePage;
