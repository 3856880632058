import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from "react-bootstrap";
import vehicles from "../../hooks/vehicles";
import "./VehicleInterior.css";

const VehicleInterior = ({ vehicleId }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleInterior-ar.css");
    } else {
      import("./VehicleInterior.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const vehicle = vehicles.find((v) => v.id === vehicleId);

  if (!vehicle) {
    return <div>Vehicle not found</div>;
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          textAlign: "center",
          marginTop: "10px",
          transform: currentLanguage === "ar" ? "rotate(180deg)" : "none",
        }}
        onClick={onClick}
      >
        <i className="jam jam-arrow-right"></i>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          textAlign: "center",
          marginTop: "10px",
          transform: currentLanguage === "ar" ? "rotate(180deg)" : "none",
        }}
        onClick={onClick}
      >
        <i className="jam jam-arrow-left"></i>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    rtl: currentLanguage === "ar", // Enable RTL mode for the slider if the language is Arabic
  };

  return (
    <div className="vehicle-interior section-spacing">
      <Container>
        <div className="vehicle-detail-section-title mb-2">
          <h1>{t("Interior")}</h1>
          <p>{t("Engulf yourself in elegance.")}</p>
        </div>

        <Row>
          <Col>
            <Slider {...settings}>
              {vehicle.interiorImages.map((image, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <img
                      src={image}
                      alt={`Interior ${index + 1}`}
                      className="img-fluid"
                    />
                  ) : (
                    <Row className="align-items-center">
                      <Col md={6} className={currentLanguage === "ar" ? "order-md-2" : ""}>
                        <h3 className="vehicle-interior-title">
                          {vehicle.interiorContents[index - 1].title[currentLanguage]}
                        </h3>
                        <p className="vehicle-interior-description">
                          {vehicle.interiorContents[index - 1].description[currentLanguage]}
                        </p>
                      </Col>
                      <Col md={6} className={currentLanguage === "ar" ? "order-md-1" : ""}>
                        <img
                          src={image}
                          alt={`Interior ${index + 1}`}
                          className="img-fluid"
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VehicleInterior;
