const faqData = [
  {
    title: {
      en: "How much will a SUZUKI service cost?",
      ar: "كم ستكلف خدمة سوزوكي؟",
    },
    content: {
      en: "If you have bought new SUZUKI, you can have peace of mind knowing your Scheduled Maintenance Service costs. To find out the required service intervals for your SUZUKI please <a href='/book-service-appointment'>click here</a>.",
      ar: "إذا كنت قد اشتريت سوزوكي جديدة، يمكنك أن تكون مطمئنًا لمعرفة تكاليف خدمة الصيانة المجدولة. لمعرفة فترات الخدمة المطلوبة لسيارتك سوزوكي يرجى <a href='/book-service-appointment'>النقر هنا</a>.",
    },
  },
  {
    title: {
      en: "How often should I get my SUZUKI serviced?",
      ar: "كم مرة يجب أن أخدم سوزوكي؟",
    },
    content: {
      en: "If you have bought new SUZUKI, you can have peace of mind knowing your Scheduled Maintenance Service costs. To find out the required service intervals for your Suzuki please <a href='/maintenance-calculator'>click here</a>.",
      ar: "إذا كنت قد اشتريت سوزوكي جديدة، يمكنك أن تكون مطمئنًا لمعرفة تكاليف خدمة الصيانة المجدولة. لمعرفة فترات الخدمة المطلوبة لسيارتك سوزوكي يرجى <a href='/maintenance-calculator'>النقر هنا</a>.",
    },
  },
  {
    title: {
      en: "How often do I need my air-conditioning serviced?",
      ar: "كم مرة أحتاج إلى خدمة تكييف الهواء؟",
    },
    content: {
      en: "This will depend on the model and age of your SUZUKI. Please consult your Owner's Manual or visit our service center for more detailed information. <a href='/our-locations'>Click here</a> to know our locations.",
      ar: "سيعتمد هذا على الطراز وعمر سيارتك سوزوكي. يرجى الرجوع إلى دليل المالك أو زيارة مركز الخدمة لدينا للحصول على معلومات أكثر تفصيلاً. <a href='/our-locations'>انقر هنا</a> لمعرفة مواقعنا.",
    },
  },
  {
    title: {
      en: "What is the right type of oil for my car?",
      ar: "ما هو النوع المناسب من الزيت لسيارتي؟",
    },
    content: {
      en: "This will depend on the model and age of your SUZUKI. Please consult your Owner's Manual or visit our service center for more detailed information. <a href='/our-locations'>Click here</a> to know our locations.",
      ar: "سيعتمد هذا على الطراز وعمر سيارتك سوزوكي. يرجى الرجوع إلى دليل المالك أو زيارة مركز الخدمة لدينا للحصول على معلومات أكثر تفصيلاً. <a href='/our-locations'>انقر هنا</a> لمعرفة مواقعنا.",
    },
  },
  {
    title: {
      en: "I have missed one of my services. What happens now?",
      ar: "لقد فاتني أحد خدماتي. ماذا يحدث الآن؟",
    },
    content: {
      en: "Missed services need to be discussed with our professional staff as this may affect your vehicle's warranty. It is important that the servicing schedule is returned to normal as soon as possible.",
      ar: "يجب مناقشة الخدمات الفائتة مع موظفينا المحترفين حيث قد يؤثر ذلك على ضمان سيارتك. من المهم أن يتم إعادة جدول الصيانة إلى طبيعته في أقرب وقت ممكن.",
    },
  },
  {
    title: {
      en: "Why should I get my car serviced at a SUZUKI dealership?",
      ar: "لماذا يجب أن أخدم سيارتي في وكالة سوزوكي؟",
    },
    content: {
      en: "We are committed to keeping your SUZUKI safe and reliable, giving you complete piece of mind. It is a franchise requirement that all technicians employed by Authorized dealers are trained on SUZUKI products. This training, combined with the necessary specialist tooling and regular product updates, ensures that your SUZUKI is serviced or repaired to the highest standards.",
      ar: "نحن ملتزمون بالحفاظ على سوزوكي آمنة وموثوقة، مما يمنحك راحة البال الكاملة. من متطلبات الامتياز أن يكون جميع الفنيين العاملين في الوكلاء المعتمدين مدربين على منتجات سوزوكي. يضمن هذا التدريب، جنبًا إلى جنب مع الأدوات التخصصية اللازمة وتحديثات المنتجات الدورية، أن يتم خدمة أو إصلاح سوزوكي بأعلى المعايير.",
    },
  },
  {
    title: {
      en: "How can I contact SUZUKI Najeeb Auto?",
      ar: "كيف يمكنني الاتصال بـ سوزوكي نجيب أوتو؟",
    },
    content: {
      en: "If you're an owner and have changed your contact details, or if you have a question about your car you can Contact Najeeb Auto 8002444441 or submit your request to book an appointment.",
      ar: "إذا كنت مالكًا وقمت بتغيير تفاصيل الاتصال الخاصة بك، أو إذا كان لديك سؤال حول سيارتك يمكنك الاتصال بـ نجيب أوتو على الرقم 8002444441 أو إرسال طلبك لحجز موعد.",
    },
  },
];

export default faqData;
