import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container, Image, Row, Col } from "react-bootstrap";
import offers from "../../hooks/offers";
import "./OfferDetailPage.css";
import OfferFormOne from "../../components/OfferFormOne/OfferFormOne";
import CountdownTimer from "../../services/CountdownTimer/CountdownTimer";

const OfferDetailPage = () => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./OfferDetailPage-ar.css");
    } else {
      import("./OfferDetailPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const { id } = useParams();
  const offer = offers.find((offer) => offer.id === id);

  const targetDate = new Date(offer.expiryDate);
  const offerURL = window.location.href;

  // Format the target date
  const formattedDate = targetDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });

  if (!offer) {
    return <div>Offer not found</div>;
  }

  return (
    <div className="offer-detail">
      <Image src={offer.image} alt={offer.title[currentLanguage]} fluid />
      <div className="section-spacing sec-bg">
        <Container>
          <Row className="align-items-center">
            <Col lg={3}>
              <h2>{offer.title[currentLanguage]}</h2>
              <p>{offer.description[currentLanguage]}</p>
              <h3>{t("Offer valid till")} {formattedDate}</h3>
              <p>
                <CountdownTimer targetDate={targetDate} />
              </p>
              <p>{t("*Terms & conditions apply")}</p>
            </Col>
            <Col lg={9}>
              <div className="offer-form-wrap">
                <div className="offer-form-title">
                  <h2>{t("Get the Offer")}</h2>
                </div>
                <OfferFormOne
                  cities={offer.cities}
                  vehicles={offer.vehicles}
                  offerName={offer.title[currentLanguage]}
                  offerURL={offerURL}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OfferDetailPage;
