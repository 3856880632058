import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import "./PrivacyPolicyPage.css";
import CallToAction from "../../components/CallToAction/CallToAction";

const KnowYourRightsPage = () => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./PrivacyPolicyPage-ar.css");
    } else {
      import("./PrivacyPolicyPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  return (
    <div className="section-spacing">
      <Container>
        <Row>
          <Col md={4}>
            <div className="kyr-table">
              <h6 className="text-uppercase mb-4">{t("Table of Contents")}</h6>
              <h4 className="kyr-table-title">{t("1. We Value Your Privacy")}</h4>
              <h4 className="kyr-table-title">{t("2. Data Collection & Usage")}</h4>
              <h4 className="kyr-table-title">{t("3. Cookies & Similar Technologies")}</h4>
              <h4 className="kyr-table-title">{t("4. Sharing, Transferring & Disclosing Personal Data")}</h4>
              <ul className="kyr-table-list">
                <li>{t("4.1 Affiliates")}</li>
                <li>{t("4.2 Third Party Service Providers")}</li>
                <li>{t("4.3 Data Sharing for Legal Reasons")}</li>
              </ul>

              <h4 className="kyr-table-title">{t("5. Data Processing Using Third-Party Services")}</h4>
              <h4 className="kyr-table-title">{t("6. Storing & Protecting Personal Data")}</h4>
              <h4 className="kyr-table-title">{t("7. Accessing & Controlling Personal Data")}</h4>
              <ul className="kyr-table-list">
                <li>{t("7.1 Access & Modify Your Personal Data")}</li>
                <li>{t("7.2 Refuse Advertisements")}</li>
                <li>{t("7.3 Delete Your Personal Data")}</li>
                <li>{t("7.4 Respond to Your Request")}</li>
              </ul>

              <h4 className="kyr-table-title">{t("8. How does your personal data circulate worldwide?")}</h4>
              <h4 className="kyr-table-title">{t("9. Contact Us")}</h4>
              <h4 className="kyr-table-title">{t("10. Privacy Policy Updates")}</h4>
            </div>
          </Col>
          <Col md={8}>
            <div className="kyr-parent-content">
              <div className="kyr-content">
                <h2 className="kyr-main-title">{t("Privacy Policy")}</h2>
              </div>
              <p className="mb-5">{t("Publication date April 24, 2024")}</p>
              <h4 className="kyr-section-title">{t("1. We Value Your Privacy")}</h4>
              <p>
               {t("Thank you for visiting Suzuki Najeeb Auto website. Najeeb Auto Company. (Hereinafter referred to as “Suzuki” or “we”), as the owner of this website (www.suzukinajeebauto.com), hereby publishes this Privacy Policy (hereinafter referred to as “this Policy”).")}
              </p>

              <p>{t("Please read this Policy carefully.")}</p>

              <p>
                {t("Najeeb Auto Suzuki collects information from its users at several different points on this Web site. Najeeb Auto Company is the sole owner of the information collected on this site. We will not sell or rent this information to others. We will only share this information with our affiliates and dealers, and marketing partners contracted to do business on our behalf.")}
              </p>

              <p>
                {t("We use two types of user information: anonymous information gathered from cookies and log files, and personal information you provide us for certain services. See below for definitions of each of these methods.")}
              </p>

              <p>
                {t("This policy will help you understand how we handle your personal data, and give you information of your choices regarding access and control your personal data. However, this policy does notapply to:")}
              </p>

              <ul className="kyr-list-para">
                <li>
                  {t("- Data processing by other companies and organizations providing advertisement or marketing services for products and services.")}
                </li>
                <li>
                  {t("- Products and services with separate privacy policies which are not incorporated into this policy.")}
                </li>
              </ul>

              <p>
                {t("Please read the whole policy and fully understand it. By using our products and services, you agree that we use and protect your personal data in accordance with this policy. If you have any question regarding this policy, or have any complaint or suggestion, please contact us via the contact detail hereof in this policy.")}
              </p>

              <p>
                {t("This policy is aiming to explain to you the following issues:")}
              </p>

              <ul className="kyr-list-para">
                <li>{t("- How do we collect and use your personal data.")}</li>
                <li>{t("- How do we use Cookies and similar technologies.")}</li>
                <li>
                  {t("- How do we share, transfer, and disclose your personal data.")}
                </li>
                <li>{t("- How do we protect your personal data.")}</li>
                <li>{t("- The right to access and control your personal data.")}</li>
                <li>{t("- How do your personal data circulate worldwide.")}</li>
                <li>{t("- Contact Us")}</li>
                <li>{t("- Update of privacy policy.")}</li>
              </ul>

              <h4 className="kyr-section-title mt-5">
                {t("2. Data Collection & Usage")}
              </h4>
              <p>
                {t("We will collect and use your personal data in the following scenarios. If we collect your personal data beyond the following purposes or use your personal data beyond the direct or reasonable association with the stated purpose at the time of collection, we will inform you again and get your consent before collecting or using your personal data.")}
              </p>

              <ul className="kyr-list-para">
                <li>
                  {t("- The categories of personal data we collect and the purposes.")}
                </li>
                <li>
                  {t("- The data we collect during your visit to this website.")}
                </li>
                <li>
                  {t("- When you visit our website, our website server will automatically record the browser, the Internet Service Provider, operating system, pages visited and the date and time visited.")}
                </li>
                <li>
                  {t("- The data we collect when you register this website or use services via this website.")}
                </li>
              </ul>
              <p>
                {t("At the time you register to become a user of our website, or wish to use offline services (such as booking test driving, Request of Offer, ordering cars, Download Brochures etc.) through our website, you may be asked to voluntarily provide relevant personal data by completing registration forms online, which require you to provide information including but not limited to your name, gender, date of birth, ID number, passport number, driver’s license information, phone number, email address and other contact information, shipping address, age, online payment account, bank card information and relevant additional information (such as the provinces and cities in your address, postal code, etc.), etc.")}
              </p>

              <p>
                {t("You may choose not to provide certain information, but this may cause you to be unable to use some of the services on this site. Please understand that we collect and use the above-mentioned personal information to provide you with products or services online and to ensure your convenience of purchasing Najeeb Auto Suzuki products or services online. We will also improve our website and communicate with you based on your information.")}
              </p>

              <p>{t("We collect and use your vehicle to record data to:")}</p>

              <ul className="kyr-list-para">
                <li>
                  {t("(i) help technicians diagnose and repair vehicle failures during repairs and maintenance, and")}
                </li>
                <li>
                  {t("(ii) develop products for Suzuki, such as improving vehicle quality and safety functions, and")}
                </li>
                <li>{t("(iii) manage Suzuki’s warranty commitments.")}</li>
              </ul>

              <p>
                {t("We collect your personal data to provide products or services for you and to provide you with better products and services, optimize and enrich your user experience.")}
              </p>

              <p>
                {t("Your personal data we obtained from third parties. When we have a proper reason to believe that a third party has the legal right to disclose your data, we will obtain your data from that third party.")}
              </p>

              <p>
                {t("We may obtain following personal data of yours from thir parties and use them for the following purposes:")}
              </p>

              <ul className="kyr-list-para">
                <li>
                  {t("- Collect your data from authorized dealers and maintainers.")}
                </li>
              </ul>

              <p>
                {t("We may collect the following personal data from our authorized dealers and maintainers:")}
              </p>
            </div>

            <ul className="kyr-list-para">
              <li>
               {t("- Contact information: name, address, phone number, email, etc.")}
              </li>
              <li>
                {t("- Demographic information: age, marital status and family structure, etc.")}
              </li>
              <li>
                {t("- Vehicle information: VIN, vehicle type, purchase date, maintenance history, etc.")}
              </li>
            </ul>

            <p>
              {t("Data about your purchase and use of our products and services: customer preferences and settings, purchase history, Suzuki account, etc.")}
            </p>

            <h4 className="kyr-section-title mt-5">
              {t("3. Cookies & Similar Technologies")}
            </h4>

            <p>
              {t("We will use Cookie when you visit our website. Cookies are small text files stored in the computer’s hard drive by websites’ servers.")}
            </p>

            <p>
              {t("We might use Cookies and similar technologies, such as storing network beacons of user preferences and settings, to prevent fraud, to authenticate users, and to collect operational information related to the service.")}
            </p>

            <p>
              {t("You may deny or manage Cookies by changing the settings of your browser add-ons. Please be noted, by disabling cookies, you may not be able to enjoy the best service experience, and some services may not function well.")}
            </p>

            <h4 className="kyr-section-title mt-5">
              {t("4. Sharing, Transferring & Disclosing Personal Data")}
            </h4>
            <p>
              {t("We will share, transfer or disclose your personal data to third parties with your consent or clear instructions. In addition, in order to improve the quality of our products and services, we may also share your personal data with third parties, or transfer and disclose it to third parties.")}
            </p>

            <h5 className="kyr-list-title">{t("4.1 AFFILIATES")}</h5>

            <p>
              {t("We may share your personal data with our affiliates and guarantee that they will process your personal data in accordance with the purposes and requirements of this policy. If affiliates wish to use your personal data for purposes other than those described in this policy, we will ask your permission again.")}
            </p>

            <h5 className="kyr-list-title">
              {t("4.2 THIRD PARTY SERVICE PROVIDERS")}
            </h5>

            <p>
              {t("We may work with third-party service providers in certain areas to provide services to you. We may share your personal data with following third party service providers and ensure that they will handle your personal data in accordance with our instructions, the purposes and requirements described in this policy, and take appropriate confidentiality and security measures to ensure data security:")}
            </p>

            <ul className="kyr-list-para">
              <li>
                {t("Dealers and maintainers authorized by Suzuki, so as to release information on the products and services we provide and other information related to you.")}
              </li>
              <li>
                {t("Suppliers who have bond with same data processing agreement")}
              </li>
              <li>
                {t("Other business partners, so as to release information on the products and services we provide and other information related to you, or for research and development purposes.")}
              </li>
            </ul>

            <h5 className="kyr-list-title">
              {t("4.3 DATA SHARING FOR LEGAL REASONS")}
            </h5>

            <p>
              {t("Within the requirements of this policy, we may need to share or disclose your personal data under certain circumstances, such as the mandatory requirements on complying with laws and regulations and government directives; to guarantee the legitimate rights and interests of you, us and the third party; and to prevent or address fraud, safety, or technical issues.")}
            </p>

            <p className="text-italic">
              {t("Change of the company’s organizational structure and existing status.")}
            </p>

            <p>
              {t("Your personal data may be transferred to a new entity or our affiliates (“data recipient”) if there is a change on our organizational structure or on the existing status of the company (such as reorganization, merger or bankruptcy). Data recipient will continue to be bound by the requirements of this policy.")}
            </p>

            <h4 className="kyr-section-title mt-5">
              {t("5. Data Processing Using Third-Party Services")}
            </h4>

            <p>
              {t("We may provide you with links to third party products or services while you visit this website or while we providing services to you. These links are provided solely for your convenience, and we cannot control or guarantee them in any form, nor shall we consider them as recommendations or authorizations.")}
            </p>

            <p>
              {t("Please remember that when you visit or use a third-party product or service through such access, which give such third-party product or service the access to obtain your personal data, such obtained personal data will be subject to the privacy policy of that third-party product or service.")}
            </p>

            <h4 className="kyr-section-title mt-5">
              {t("6. Storing & Protecting Personal Data")}
            </h4>

            <h5 className="kyr-list-title">{t("6.1 PERSONAL DATA SECURITY")}</h5>

            <p>
              {t("We are committed to securing your personal data. We use a variety of possible security technologies and procedures to protect your personal data from unauthorized access, use, modification, damage, or leakage.")}
            </p>

            <p>{t("We have adopted following security measures:")}</p>

            <ul className="kyr-list-para">
              <li>
                {t("- We will store your personal data in a computer system located in a controlled location and take common security measures.")}
              </li>
              <li>
                {t("- We will only allow certain employees who must process the relevant data for the purpose of operation of the product and service to access personal data and require them to perform strict confidentiality obligations.")}
              </li>
              <li>
                {t("- We will protect your personal data security and adopt whole-process encryption in the transfer of data to prevent your information from unauthorized access, use, modification, damage or leakage.")}
              </li>
              <li>
                {t("- We will be more cautious in transferring and storing your special personal data, and will take technical measures such as encryption to ensure data security.")}
              </li>
              <li>
                {t("- If there is a personal data security incident, we will abide by the requirements of the laws and regulations in order to inform you, by notification or notice, the basic situation and possible influence of the incident, treatment measures we have taken or will take, suggestions for measures you could take to prevent or lower the risk and remedial measures, etc. We will promptly handle security risks such as system vulnerability, network attack, virus attack and network invasion.")}
              </li>
              <li>{t("Storage period of personal data.")}</li>
              <li>
                {t("- We will store your personal data for the necessary period of time for the purposes of data collection and processing. Personal data beyond the above-mentioned period will be deleted or anonymized.")}
              </li>
            </ul>

            <h4 className="kyr-section-title mt-5">
              {t("7. Accessing & Controlling Personal Data")}
            </h4>

            <h5 className="kyr-list-title">
              {t("7.1 ACCESS AND MODIFY YOUR PERSONAL DATA")}
            </h5>

            <ul className="kyr-list-para">
              <li>
                {t("- You have the right to access, edit and modify your personal data.")}
              </li>
              <li>{t("- Change the scope of your consent")}</li>
              <li>
                {t("- If you wish, you can change the scope of your authorization to us on processing your personal data. Please remember that when you withdraw your consent for the processing of certain personal data, we may be unable to provide you with certain services. Upon receipt of your consent on withdrawing, we will no longer process your personal data accordingly.")}
              </li>
            </ul>

            <h5 className="kyr-list-title">{t("7.2 REFUSE ADVERTISEMENTS")}</h5>

            <p>
              {t("You always have the option of receiving promotional emails, text messages, calls and letters from us or third parties. You can also stop receiving interest-based advertisements by cancelling the subscription, etc.")}
            </p>

            <p>
              {t("Upon receipt of your request on refusing advertisement, we will no longer use your personal data for the above-mentioned commercial promotion purposes.")}
            </p>

            <h5 className="kyr-list-title">{t("7.3 DELETE YOUR PERSONAL DATA")}</h5>

            <p>{t("You may request us to delete your personal data if:")}</p>

            <ul className="kyr-list-para">
              <li>
                {t("Your personal data is no longer necessary for the purposes of collection or processing.")}
              </li>
              <li>
                {t("You have withdrawn your consent for us to process your personal data.")}
              </li>
              <li>{t("Illegal processing of personal data or")}</li>
              <li>
                {("We are obliged to delete your personal data as required by applicable law.")}
              </li>
            </ul>

            <p>
              {t("For technical reasons, we may not be able to delete your personal data immediately. We will delete this data when the system is updated. After receiving your request to delete your personal data, we will no longer process any of your personal data collected.")}
            </p>

            <h5 className="kyr-list-title">{t("7.4 RESPOND TO YOUR REQUEST")}</h5>
            <p>
              {t("If you wish to exercise these rights, you can contact us by e-mailing inquiry@najeeb-auto.com. After receiving your request, we may first verify your identity and may ask you to provide the necessary information for authentication.")}
            </p>

            <p>
              {t("We will get back to you as soon as we receive your request. If we have any reason to think that we do not need to do anything about your request, we will inform you of our decision, and will explain accordingly.")}
            </p>

            <p>
              {t("We will try our best to help you solve relevant problems. If you are not satisfied with our response, you may file a complaint with the relevant regulatory authority or file a lawsuit with the competent judicial authority.")}
            </p>

            <h4 className="kyr-section-title mt-5">
              {t("8. How does your personal data circulate worldwide?")}
            </h4>

            <p>
              {t("We may transfer your personal data to other jurisdictions outside the country/region in which you are using the product or service, or may be accessed from those jurisdictions. These jurisdictions include, but are not limited to: Japan, India, Hungry, Malaysia, etc.")}
            </p>

            <p>
              {t("These jurisdictions may have different legislation on personal data protection, or even have no relevant legal system. In such cases, we will endeavor to take all measures to ensure the security of your personal data. For example, before transmitting your personal data, we will obtain your specific consent, and adopt encryption, de-identification and other security measures in the transmission process.")}
            </p>
            <p>
              {t("After your personal data are transferred to these jurisdictions, we will still protect your personal data as required by this policy.")}
            </p>

            <h4 className="kyr-section-title mt-5">{t("9. Contact Us")}</h4>

            <p>{t("If you have any question or suggestion on this policy, or if you find any violation of this policy, please feel free to contact us.")} </p>
            <p>{t("Our email is: inquiry@najeeb-auto.com or call us directly on our Toll-Free number 8002444441.")}</p>

            <h4 className="kyr-section-title mt-5">{t("10. Privacy Policy Updates")}</h4>
            <p>{t("We will change and revise this policy from time to time. If there is any update of this policy, we will remind you to check it in time through the interface of this product, and the updated part will be marked for your convenience.")}</p>

            <p>{t("Please make sure that you read and fully understand the updated privacy policy. If you continue to use our products and services, you will be deemed to have agreed to an updated version of this privacy policy.")} </p>

            <p>{t("We will not undermine your rights under this privacy policy without your explicit consent.")}</p>
          </Col>
        </Row>
      </Container>

      <div>
        <CallToAction />
      </div>
    </div>
  );
};

export default KnowYourRightsPage;
