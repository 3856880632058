import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import vehicles from "../../hooks/vehicles";
import offers from "../../hooks/offers";
import VehicleBanner from "../../components/VehicleBanner/VehicleBanner";
import VehicleActionData from "../../components/VehicleActionData/VehicleActionData";
import "./VehicleDetailPage.css";
import VehicleTaglineComponent from "../../components/VehicleTaglineComponent/VehicleTaglineComponent";
// import View360 from '../../components/View360/View360';
import VehicleExterior from "../../components/VehicleExterior/VehicleExterior";
import VehicleInterior from "../../components/VehicleInterior/VehicleInterior";
import VehicleFeatures from "../../components/VehicleFeatures/VehicleFeatures";
import CallToAction from "../../components/CallToAction/CallToAction";
import VehicleSpecification from "../../components/VehicleSpecification/VehicleSpecification";
import VehicleVideo from "../../components/VehicleVideo/VehicleVideo";
import VehicleOverview from "../../components/VehicleOverview/VehicleOverview";
import VehicleVariant from "../../components/VehicleVariant/VehicleVariant";
import VehicleOffer from "../../components/VehicleOffer/VehicleOffer";

const VehicleDetailPage = () => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleDetailPage-ar.css");
    } else {
      import("./VehicleDetailPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const { id } = useParams();
  const vehicle = vehicles.find((vehicle) => vehicle.id === id);

  if (!vehicle) {
    return <div>Vehicle not found</div>;
  }

  const relatedOffers = offers.filter((offer) => offer.relatedVehicles.includes(id));

  return (
    <div className="vehicle-tagline">
      {vehicle.video && <VehicleVideo videoSrc={vehicle.video} />}
      
      <VehicleActionData vehicle={vehicle} />
      {relatedOffers.length > 0 && <VehicleOffer offers={relatedOffers} vehicleTitle={vehicle.title[currentLanguage]} />}
      <VehicleTaglineComponent vehicle={vehicle}/>
      <VehicleBanner vehicle={vehicle} />
      <VehicleOverview vehicleId={id}/>
      <VehicleVariant vehicleId={id}/>
      {/* <View360 vehicleName={vehicle.title} imageUrl={vehicle.View360Image} /> */}
      <VehicleExterior vehicleId={id} />
      <VehicleInterior vehicleId={id} />
      <VehicleFeatures vehicleId={id}/>
      <VehicleSpecification vehicleId={id}/>
      <CallToAction/>
    </div>
  );
};

export default VehicleDetailPage;
