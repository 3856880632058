import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from "react-bootstrap";
import vehicles from "../../hooks/vehicles";
import "./VehicleExterior.css";

const NextArrow = (props) => {
  const { className, style, onClick, currentLanguage } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "30px",
        color: "black",
        textAlign: "center",
        marginTop: "1rem",
        transform: currentLanguage === "ar" ? "rotate(180deg)" : "none", // Adjust rotation for RTL
      }}
      onClick={onClick}
    >
      <i className="jam jam-arrow-right"></i>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick, currentLanguage } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "30px",
        color: "black",
        textAlign: "center",
        marginTop: "1rem",
        transform: currentLanguage === "ar" ? "rotate(180deg)" : "none", 
      }}
      onClick={onClick}
    >
      <i className="jam jam-arrow-left"></i>
    </div>
  );
};

const VehicleExterior = ({ vehicleId }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleExterior-ar.css");
    } else {
      import("./VehicleExterior.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const vehicle = vehicles.find((v) => v.id === vehicleId);

  if (!vehicle) {
    return <div>Vehicle not found</div>;
  }

  const settings = {
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow currentLanguage={currentLanguage} />,
    prevArrow: <PrevArrow currentLanguage={currentLanguage} />,
    rtl: currentLanguage === "ar",
  };

  return (
    <div className="vehicle-exterior sec-bg section-spacing">
      <Container>
        <div className="vehicle-detail-section-title mb-2">
          <h1>{t("Exterior")}</h1>
          <p>{t("Impose your presence everywhere you go.")}</p>
        </div>
        <Row className="align-items-center">
          <Col md={6}>
            <h1 className="text-uppercase">
              {vehicle.exteriorTitle[currentLanguage]}
            </h1>
            <p className="vehicle-exterior-description">
              {vehicle.exteriorDescription1[currentLanguage]}
            </p>
            <p className="vehicle-exterior-description">
              {vehicle.exteriorDescription2[currentLanguage]}
            </p>
          </Col>
          <Col md={6}>
            <Slider {...settings}>
              {vehicle.exteriorImages.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Exterior ${index + 1}`}
                    className="img-fluid"
                  />
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VehicleExterior;
