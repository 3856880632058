import React from "react";
import PropTypes from "prop-types";
import "./VehicleVideo.css";
import { Container } from "react-bootstrap";

const VehicleVideo = ({ videoSrc }) => {
  return (
    <div className="vehicle-video">
      <video src={videoSrc} loop autoPlay muted controls={false} />
    </div>
  );
};

VehicleVideo.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default VehicleVideo;
