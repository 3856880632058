import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import SparePartsModal from "../../components/SparePartsModal/SparePartsModal";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import vehicles from "../../hooks/vehicles";
import "./SparePartsPage.css";
import CallToAction from "../../components/CallToAction/CallToAction";

const SparePartsPage = () => {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./SparePartsPage-ar.css");
    } else {
      import("./SparePartsPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const handleShowModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVehicle(null);
  };

  // Filter vehicles that should be shown on the Spare Parts Page
  const filteredVehicles = vehicles.filter(
    (vehicle) => vehicle.showOnSparePartsPage
  );

  return (
    <>
    <Container>
      <div className="section-spacing">
        <SectionTitlePrimary subtitle="Owners" title={t("Spare Parts")} />
        
        <Row>
          {filteredVehicles.map((vehicle) => (
            <Col key={vehicle.id} md={3}>
              <div className="vehicle-card">
                <Image
                  src={vehicle.image}
                  alt={vehicle.title[currentLanguage]}
                  fluid
                  className="vehicle-image"
                />
                <h3 className="vehicle-title">{vehicle.title[currentLanguage]}</h3>
                <div className="link-align">
                  <Button
                    variant="link"
                    onClick={() => handleShowModal(vehicle)}
                  >
                    {t("Show Prices")}
                  </Button>
                  <i className={`${i18n.language === "ar" ? "jam jam-arrow-left" : "jam jam-arrow-right"}`}></i>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {selectedVehicle && (
          <SparePartsModal
            show={showModal}
            handleClose={handleCloseModal}
            selectedVehicle={selectedVehicle.id}
          />
        )}
      </div>
    </Container>
    <CallToAction />
    </>
  );
};

export default SparePartsPage;
