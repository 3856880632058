import React, { useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../CustomButton/CustomButton.css";

const CustomButton = ({ text, to }) => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./CustomButton-ar.css");
    } else {
      import("./CustomButton.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);
  
  return (
    <Link to={to}>
      <Button className="custom-button">
        {text} <i className={`${i18n.language === "ar" ? "jam jam-arrow-up-left text-white" : "jam jam-arrow-up-right text-white"}`}></i>
      </Button>
    </Link>
  );
};

export default CustomButton;
