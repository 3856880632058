import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Container, Row, Col, Table, Form } from "react-bootstrap";
import sparePartsData from "../../hooks/sparePartsData";
import vehicleSparePartsVariants from "../../hooks/vehicleSparePartsVariants";
import SectionTitleSecondary from "../SectionTitleSecondary/SectionTitleSecondary";
import "./SparePartsModal.css";

const SparePartsModal = ({ show, handleClose, selectedVehicle }) => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./SparePartsModal-ar.css");
    } else {
      import("./SparePartsModal.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const [selectedVariant, setSelectedVariant] = useState("");

  const handleVariantChange = (e) => {
    setSelectedVariant(e.target.value);
  };

  const variants = vehicleSparePartsVariants[selectedVehicle] || [];
  const isDiscontinuedWithoutVariants = selectedVehicle === "vitara";
  const parts = isDiscontinuedWithoutVariants
    ? sparePartsData[selectedVehicle] || []
    : sparePartsData[selectedVehicle]?.[selectedVariant] || [];

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <SectionTitleSecondary
            title={t("Spare Parts")}
            subtitle="Get the spare parts pricing of all Suzuki"
          />
          <p className="imp-msg">*{t("All PRICES ARE INCLUSIVE OF VAT")}</p>
          <Row>
            <Col md={6}>
              <Form.Group controlId="selectVehicle">
                <Form.Label className="brand-label">{t("Selected Vehicle")}</Form.Label>
                <Form.Control
                  className="brand-input text-capitalize"
                  as="input"
                  value={selectedVehicle}
                  disabled
                />
              </Form.Group>
            </Col>
            {!isDiscontinuedWithoutVariants && (
              <Col md={6}>
                <Form.Group controlId="selectVariant">
                  <Form.Label className="brand-label">
                    {t("Select Variant")}
                  </Form.Label>
                  <Form.Select
                    className="brand-input"
                    as="select"
                    value={selectedVariant}
                    onChange={handleVariantChange}
                  >
                    <option value="">{t("Please Select Variant")}</option>
                    {variants.map((variant) => (
                      <option key={variant} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            )}
          </Row>
          {isDiscontinuedWithoutVariants || selectedVariant ? (
            <Table striped bordered hover className="brand-table mt-4">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Retail Price</th>
                  <th>Arabic Description</th>
                </tr>
              </thead>
              <tbody className="text-center text-uppercase">
                {parts.map((part, index) => (
                  <tr key={index}>
                    <td>{part.description}</td>
                    <td>{part.price}</td>
                    <td>{part.arabicDescription}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center">{t("Please select a variant to see spare parts.")}</div>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SparePartsModal;
