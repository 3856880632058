import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import "./BookServiceAppointment.css";
import vehicles from "../../hooks/vehicles";

const BookServiceAppointment = ({ cities, serviceLocations, mileages }) => {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./BookServiceAppointment-ar.css");
    } else {
      import("./BookServiceAppointment.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const formik = useFormik({
    initialValues: {
      vehicle: "",
      mileage: "",
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      gender: "",
      city: "",
      serviceLocation: "",
      date: "",
      message: "",
    },
    validationSchema: Yup.object({
      vehicle: Yup.string().required(t("Vehicle is required")),
      mileage: Yup.string().required(t("Mileage is required")),
      firstName: Yup.string()
        .required(t("First Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z ]+$/, t("Only characters are allowed"))
        .min(3, t("First Name should be at least 3 characters long")),
      lastName: Yup.string()
        .required(t("Last Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z ]+$/, t("Only characters are allowed"))
        .min(3, t("Last Name should be at least 3 characters long")),
      contactNumber: Yup.string()
        .required(t("Contact Number is required"))
        .matches(
          /^05\d{8}$/,
          t("Contact Number should start with 05 and be 10 digits long")
        ),
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid email format")),
      gender: Yup.string().required(t("Gender is required")),
      city: Yup.string().required(t("City is required")),
      serviceLocation: Yup.string().required(t("Service Location is required")),
      date: Yup.string().required(t("Date is required")),
      message: Yup.string(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("First_name", values.firstName);
      formData.append("Last_name", values.lastName);
      formData.append("Phone", values.contactNumber);
      formData.append("Email", values.email);
      formData.append("Gender", values.gender);
      formData.append("City", values.city);
      formData.append("Vehicle", values.vehicle);
      formData.append("Date", values.date);
      formData.append("Mileage", values.mileage);
      formData.append("Service_Center_Location", values.serviceLocation);
      formData.append("Message", values.message);

      axios
        .post(
          "https://najeebapi.nhcops.com/public/api/Book_Appointment",
          formData
        )
        .then((response) => {
          setIsSubmitting(false);
          if (response.data === "done") {
            setIsSubmitted(true);
            formik.resetForm();
          } else {
            alert("Error submitting form.");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setSubmissionError("Error submitting form.");
          console.error(error);
        });
    },
  });

  if (isSubmitted) {
    return (
      <div className="success-message text-center">
        <h3>{t("Submitted Successfully!")}</h3>
        <p>{t("Our team will reach you as soon as possible.")}</p>
      </div>
    );
  } else if (submissionError) {
    return (
      <div className="error-message text-center">
        <h3>{t("Submission Failed!")}</h3>
        <p>{t("Please try again after some time.")}</p>
      </div>
    );
  }

  return (
   
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formVehicle">
              <Form.Label className="brand-label">
                {t("Select Vehicle")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="vehicle"
                value={formik.values.vehicle}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.vehicle}
              >
                <option value="">{t("Select Vehicle")}</option>
                {vehicles
                  .filter((vehicle) => !vehicle.discontinued)
                  .map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.title[currentLanguage]}>
                      {vehicle.title[currentLanguage]}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.vehicle}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formMileage">
              <Form.Label className="brand-label">
                {t("Select Mileage")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="mileage"
                value={formik.values.mileage}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.mileage}
              >
                <option value="">{t("Select Mileage")}</option>
                {mileages.map((mileage, index) => (
                  <option key={index} value={mileage}>
                    {mileage}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.mileage}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formFirstName">
              <Form.Label className="brand-label">
                {t("First Name")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formLastName">
              <Form.Label className="brand-label">
                {t("Last Name")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formContactNumber">
              <Form.Label className="brand-label">
                {t("Contact Number")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.contactNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contactNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="brand-label">
                {t("Email Address")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formGender">
              <Form.Label className="brand-label">
                {t("Select Gender")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.gender}
              >
                <option value="">{t("Select Gender")}</option>
                <option value="Male">{t("Male")}</option>
                <option value="Female">{t("Female")}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label className="brand-label">
              {t("Select City")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.city}
              >
                <option value="">{t("Select City")}</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formServiceLocation">
              <Form.Label className="brand-label">
                {t("Select Service Location")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="serviceLocation"
                value={formik.values.serviceLocation}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.serviceLocation}
              >
                <option value="">{t("Select Service Location")}</option>
                {serviceLocations.map((location, index) => (
                  <option key={index} value={location}>
                    {location}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.serviceLocation}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formDate">
              <Form.Label className="brand-label">
                {t("Date")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.date}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.date}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="formMessage">
              <Form.Label className="brand-label">{t("Message")}</Form.Label>
              <Form.Control
                className="brand-input"
                as="textarea"
                rows={3}
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="d-grid gap-2 col-6 mx-auto">
          <Button
            className="form-brand-btn"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner animation="border" size="sm" /> : t("Submit")}
          </Button>
        </div>
      </Form>
    
  );
};

export default BookServiceAppointment;
