import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShowroomCategoryTabs from "../../components/ShowroomCategoryTabs/ShowroomCategoryTabs";
import ShowroomDetails from "../../components/ShowroomDetails/ShowroomDetails";
import showroomsData from "../../hooks/showroomsData";
import { Container, Form } from "react-bootstrap";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import "./ShowroomsPage.css";

const ShowroomPage = () => {
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCity, setSelectedCity] = useState("All Cities");

  useEffect(() => {
    setSelectedCategory(
      showroomsData[0]?.category[i18n.language] || ""
    );
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const categories = showroomsData.map((showroom) => showroom.category[i18n.language]);
  const selectedCategoryData = showroomsData.find(
    (showroom) => showroom.category[i18n.language] === selectedCategory
  );

  const cities = selectedCategoryData
    ? [
        t("All Cities"),
        ...selectedCategoryData.cities.map((city) => city.name),
      ]
    : [];

  const filteredShowrooms =
    selectedCity === "All Cities"
      ? selectedCategoryData?.cities.flatMap((city) => city.showrooms) || []
      : selectedCategoryData?.cities.find((city) => city.name === selectedCity)
          ?.showrooms || [];

  return (
    <div className="section-spacing">
      <Container>
        <SectionTitlePrimary subtitle="Showrooms" title={t("Locate Our Branches")} />
        <div className="showroom-page">
          <ShowroomCategoryTabs
            categories={categories}
            selectedCategory={selectedCategory}
            onSelectCategory={(category) => {
              setSelectedCategory(category);
              setSelectedCity("All Cities");
            }}
          />
          <div className="section-spacing">
            <Form.Select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className="mb-3 brand-input w-50 mx-auto"
            >
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="showroom-list">
            {filteredShowrooms.map((showroom, index) => (
              <ShowroomDetails key={index} showroom={showroom} category={selectedCategory} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ShowroomPage;
