import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Container } from "react-bootstrap";
import './ShowroomCategoryTabs.css';

const ShowroomCategoryTabs = ({
  categories,
  selectedCategory,
  onSelectCategory,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Container>
      <Tabs
        id="showroom-category-tabs"
        activeKey={selectedCategory}
        onSelect={(k) => onSelectCategory(k)}
        className="mb-3"
      >
        {categories.map((category, index) => (
          <Tab eventKey={category} title={category} key={index}></Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default ShowroomCategoryTabs;
