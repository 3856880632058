// src/components/OverviewCard/OverviewCard.js
import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './OverviewCard.css';

const OverviewCard = ({ image, title, description, link }) => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./OverviewCard-ar.css");
    } else {
      import("./OverviewCard.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Link to={link} className="overview-card-link">
      <Card className="overview-card">
        <Card.Img variant="top" src={image} alt={title} />
        <Card.Body className='overview-card-body'>
          <Card.Title className="overview-card-title">
            {title[currentLanguage]} <i className="jam jam-arrow-right"></i>
          </Card.Title>
          <Card.Text>{description[currentLanguage]}</Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default OverviewCard;
