// src/components/VehicleOverview/VehicleOverview.js
import React , { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import "./VehicleOverview.css";
import vehicles from "../../hooks/vehicles";

const VehicleOverview = ({ vehicleId }) => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleOverview-ar.css");
    } else {
      import("./VehicleOverview.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const vehicle = vehicles.find((v) => v.id === vehicleId);

  if (!vehicle) return null;

  return (
    <div className="section-spacing">
      <Container className="vehicle-overview">
        <Row className="vehicle-overview-data">
          {vehicle.overview.map((item, index) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={2}
              key={index}
              className="overview-item"
            >
              <h6 className="overview-title">{item.title[currentLanguage]}</h6>
              <h4 className="overview-data">{item.data}</h4>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default VehicleOverview;
