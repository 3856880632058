// src/pages/GetQuotePage/GetQuotePage.js
import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import GetQuoteForm from "../../components/GetQuoteForm/GetQuoteForm";
import CallToAction from "../../components/CallToAction/CallToAction";
import SectionTitleSecondary from "../../components/SectionTitleSecondary/SectionTitleSecondary";
import { useLocation } from "react-router-dom";
import vehicles from "../../hooks/vehicles";

const GetQuotePage = () => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./GetQuotePage-ar.css");
    } else {
      import("./GetQuotePage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const location = useLocation();
  const state = location.state || {};
  const selectedVehicle = state.vehicleId
    ? vehicles.find((v) => v.id === state.vehicleId)
    : null;
  const selectedVariant = state.selectedVariant || "";
  const cities = [t("Riyadh"), t("Jeddah"), t("Dammam"), t("Al Khobar"), t("Al Ahsa"), t("Madinah"), t("Al Kharj"), t("Khamis Mushait"), t("Najran"), t("Onaiza"), t("Mecca")];

  return (
    <>
    <div className="sec-bg">
      <Container className="section-spacing">
        <SectionTitleSecondary title={t("Get a Quote")} />
        <Row className="align-items-center">
          <Col md={12}>
            <GetQuoteForm
              cities={cities}
              vehicles={vehicles}
              selectedVehicle={selectedVehicle}
              selectedVariant={selectedVariant}
            />
          </Col>
        </Row>
      </Container>
    </div>
    <CallToAction />
    </>
  );
};

export default GetQuotePage;
