import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import FooterPrimaryLogo from "../../images/najeeb-footer-logo.png";
import FooterSecondaryLogo from "../../images/suzuki-footer-logo.png";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./Footer-ar.css");
    } else {
      import("./Footer.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="section-spacing footer-bg">
      <Container>
        <Row className="logo-row">
          <Col md={6}>
            <div className="footer-logo-secondary">
              <img src={FooterSecondaryLogo} />
            </div>
          </Col>

          <Col md={6}>
            <div
              className={`${
                i18n.language === "ar"
                  ? "footer-logo-primary float-md-start"
                  : "footer-logo-primary float-md-end"
              }`}
            >
              <img src={FooterPrimaryLogo} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>
            <h4 className="list-title">{t("Owners")}</h4>
            <ul className="footer-list">
              <li>
                <Link to="/book-service-appointment">
                  {t("Book a Service")}
                </Link>
              </li>
              <li>
                <Link
                  to="https://mkena.com/en/suzuki/najeeb-auto"
                  target="_blank"
                >
                  {t("Buy Spare Parts")}
                </Link>
              </li>
              <li>
                <Link to="/warranty">{t("Warranty")}</Link>
              </li>
              <li>
                <Link to="/maintenance-calculator">
                  {t("Maintenance Calculator")}
                </Link>
              </li>
              <li>
                <Link to="/road-side-assistance">
                  {t("Roadside Assistance")}
                </Link>
              </li>
            </ul>
          </Col>

          <Col md={6} lg={3}>
            <h4 className="list-title">{t("Shopping Tools")}</h4>
            <ul className="footer-list">
              <li>
                <Link to="/book-test-drive">{t("Book a Test Drive")}</Link>
              </li>
              <li>
                <Link to="/get-quote">{t("Get a Quote")}</Link>
              </li>
              <li>
                <Link to="/our-locations">{t("Branch Locator")}</Link>
              </li>
              <li>
                <Link to="/offers">{t("Offers")}</Link>
              </li>
              <li>
                <Link to="/vehicles">{t("All Vehicles")}</Link>
              </li>
            </ul>
          </Col>

          <Col md={6} lg={3}>
            <h4 className="list-title">{t("Customer Services")}</h4>
            <ul className="footer-list">
              <li>
                <Link to="/careers">{t("Careers")}</Link>
              </li>
              <li>
                <Link to="/faqs">{t("FAQs")}</Link>
              </li>
              <li>
                <Link to="/fleet-sales-inquiry">{t("Fleet Inquiry")}</Link>
              </li>
              <li>
                <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
              </li>
              <li>
                <Link to="/terms-conditions">{t("Terms & Conditions")}</Link>
              </li>
            </ul>
          </Col>

          <Col md={6} lg={3}>
            <h4 className="list-title">{t("Social Media")}</h4>
            <ul className="social-links">
              <li>
                <Link to="https://www.instagram.com/najeebauto/?igsh=andtcDVlamdhaGE0&amp;utm_source=qr" target="_blank">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.017 2.47681H7.947C6.37015 2.47866 4.85844 3.10595 3.74353 4.22104C2.62862 5.33614 2.00159 6.84796 2 8.42481L2 16.4948C2.00185 18.0717 2.62914 19.5834 3.74424 20.6983C4.85933 21.8132 6.37115 22.4402 7.948 22.4418H16.018C17.5948 22.44 19.1066 21.8127 20.2215 20.6976C21.3364 19.5825 21.9634 18.0707 21.965 16.4938V8.42381C21.9631 6.84696 21.3359 5.33524 20.2208 4.22034C19.1057 3.10543 17.5938 2.47839 16.017 2.47681V2.47681ZM19.957 16.4938C19.957 17.0112 19.8551 17.5236 19.6571 18.0016C19.4591 18.4796 19.1689 18.9139 18.803 19.2798C18.4371 19.6457 18.0028 19.9359 17.5248 20.1339C17.0467 20.3319 16.5344 20.4338 16.017 20.4338H7.947C6.90222 20.4335 5.90032 20.0183 5.16165 19.2795C4.42297 18.5406 4.008 17.5386 4.008 16.4938V8.42381C4.00827 7.37903 4.42349 6.37713 5.16235 5.63845C5.90122 4.89978 6.90322 4.48481 7.948 4.48481H16.018C17.0628 4.48507 18.0647 4.90029 18.8034 5.63916C19.542 6.37802 19.957 7.38003 19.957 8.42481V16.4948V16.4938Z"
                      fill="white"
                    />
                    <path
                      d="M11.9818 7.2959C10.6133 7.29801 9.30136 7.84268 8.33372 8.8105C7.36609 9.77833 6.82168 11.0903 6.81982 12.4589C6.82141 13.8278 7.36585 15.1402 8.33372 16.1083C9.3016 17.0764 10.6139 17.621 11.9828 17.6229C13.3519 17.6213 14.6645 17.0767 15.6326 16.1086C16.6007 15.1406 17.1452 13.828 17.1468 12.4589C17.1447 11.09 16.5998 9.77778 15.6315 8.81009C14.6633 7.8424 13.3507 7.29822 11.9818 7.2969V7.2959ZM11.9818 15.6149C11.1451 15.6149 10.3426 15.2825 9.7509 14.6908C9.15922 14.0991 8.82682 13.2967 8.82682 12.4599C8.82682 11.6231 9.15922 10.8207 9.7509 10.229C10.3426 9.6373 11.1451 9.3049 11.9818 9.3049C12.8186 9.3049 13.6211 9.6373 14.2127 10.229C14.8044 10.8207 15.1368 11.6231 15.1368 12.4599C15.1368 13.2967 14.8044 14.0991 14.2127 14.6908C13.6211 15.2825 12.8186 15.6149 11.9818 15.6149V15.6149Z"
                      fill="white"
                    />
                    <path
                      d="M17.1559 8.5719C17.8391 8.5719 18.3929 8.01808 18.3929 7.3349C18.3929 6.65172 17.8391 6.0979 17.1559 6.0979C16.4728 6.0979 15.9189 6.65172 15.9189 7.3349C15.9189 8.01808 16.4728 8.5719 17.1559 8.5719Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>

              <li>
                <Link to="https://x.com/najeebauto?s=21&amp;t=3Fvai_OFATn0DysHTpZmpg&amp;mx=2" target="_blank">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_1140_18495"
                      maskUnits="userSpaceOnUse"
                      x="3"
                      y="2"
                      width="19"
                      height="20"
                    >
                      <path d="M3 2.52319H22V21.5232H3V2.52319Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_1140_18495)">
                      <path
                        d="M17.9625 3.41333H20.8763L14.5113 10.7066L22 20.6328H16.1371L11.5419 14.6138L6.28971 20.6328H3.37321L10.1806 12.8292L3 3.41469H9.01214L13.1596 8.91519L17.9625 3.41333ZM16.9379 18.8848H18.5529L8.13 5.0704H6.39829L16.9379 18.8848Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </Link>
              </li>

              <li>
                <Link to="https://www.tiktok.com/@najeebauto" target="_blank">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6002 6.29681C15.9166 5.51643 15.5399 4.51424 15.5402 3.47681H12.4502V15.8768C12.4263 16.5478 12.143 17.1834 11.6599 17.6497C11.1768 18.1161 10.5316 18.3767 9.86016 18.3768C8.44016 18.3768 7.26016 17.2168 7.26016 15.7768C7.26016 14.0568 8.92016 12.7668 10.6302 13.2968V10.1368C7.18016 9.67681 4.16016 12.3568 4.16016 15.7768C4.16016 19.1068 6.92016 21.4768 9.85016 21.4768C12.9902 21.4768 15.5402 18.9268 15.5402 15.7768V9.48681C16.7932 10.3867 18.2975 10.8695 19.8402 10.8668V7.77681C19.8402 7.77681 17.9602 7.86681 16.6002 6.29681Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>

              <li>
                <Link to="https://www.snapchat.com/add/najeebauto?sender_web_id=d24d0196-621d-40f2-bcd2-835d94c8abec&amp;device_type=desktop&amp;is_copy_url=true" target="_blank">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.7143 9.95177C17.7143 1.38034 6.28571 1.38034 6.28571 9.95177V11.3803H4.73524C4.18 11.3803 3.95143 12.0937 4.40381 12.4165L6.28571 13.7613C5.96857 14.8727 4.66667 17.2851 2 18.047C2.31714 18.5232 3.2381 19.4756 4.38095 19.4756L5.33333 20.9041L7.71429 20.428C8.50762 21.0632 10.4762 22.3327 12 22.3327C13.5238 22.3327 15.4924 21.0632 16.2857 20.428L18.6667 20.9041L19.619 19.4756C20.7619 19.4756 21.6829 18.5232 22 18.047C19.3333 17.2851 18.0314 14.8727 17.7143 13.7613L19.5962 12.4165C20.0486 12.0937 19.82 11.3803 19.2648 11.3803H17.7143V9.95177Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
              </li>

              <li>
                <Link to="https://www.youtube.com/@najeebauto-suzuki2682" target="_blank">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.812 5.49392H6.145C3.855 5.49392 2 7.32892 2 9.59292V15.3609C2 17.6249 3.856 19.4609 6.145 19.4609H17.812C20.102 19.4609 21.957 17.6249 21.957 15.3609V9.59292C21.957 7.32892 20.101 5.49292 17.812 5.49292V5.49392ZM15.009 12.7569L9.552 15.3319C9.51872 15.3479 9.48192 15.3553 9.44503 15.3533C9.40815 15.3513 9.37237 15.3399 9.34103 15.3204C9.3097 15.3008 9.28382 15.2736 9.2658 15.2414C9.24779 15.2091 9.23822 15.1729 9.238 15.1359V9.82692C9.23867 9.78979 9.24872 9.75344 9.26722 9.72124C9.28573 9.68905 9.31208 9.66206 9.34382 9.6428C9.37556 9.62353 9.41167 9.61261 9.44877 9.61106C9.48587 9.60951 9.52276 9.61737 9.556 9.63392L15.014 12.3689C15.0504 12.3871 15.0809 12.4151 15.102 12.4497C15.1232 12.4844 15.1341 12.5244 15.1336 12.565C15.1331 12.6056 15.1211 12.6453 15.0991 12.6794C15.077 12.7135 15.0458 12.7407 15.009 12.7579V12.7569Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>

              <li>
                <Link to="https://www.linkedin.com/company/najeeb-auto/" target="_blank">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.959 14.1957V21.5747H17.681V14.6897C17.681 12.9597 17.062 11.7797 15.514 11.7797C14.332 11.7797 13.628 12.5757 13.319 13.3447C13.206 13.6197 13.177 14.0027 13.177 14.3877V21.5747H8.897C8.897 21.5747 8.955 9.91469 8.897 8.70569H13.177V10.5297L13.149 10.5717H13.177V10.5297C13.745 9.65469 14.76 8.40369 17.033 8.40369C19.848 8.40369 21.959 10.2437 21.959 14.1957V14.1957ZM4.421 2.50269C2.958 2.50269 2 3.46269 2 4.72569C2 5.96069 2.93 6.94969 4.365 6.94969H4.393C5.886 6.94969 6.813 5.96069 6.813 4.72569C6.787 3.46269 5.887 2.50269 4.422 2.50269H4.421ZM2.254 21.5747H6.532V8.70569H2.254V21.5747Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>
            </ul>

            <h4 className="footer-call-link-title">{t("Toll-Free Number")}</h4>
            <Link className="footer-call-link" to="tel:8002444441">
              8002444441
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
