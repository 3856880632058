import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ServiceMaintenancePage.css";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";
import CustomButton from "../../components/CustomButton/CustomButton";

const ServiceMaintenancePage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./ServiceMaintenancePage-ar.css");
    } else {
      import("./ServiceMaintenancePage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <>
      <div className="section-spacing serviceMaintenancePage">
        <Container>
          <SectionTitlePrimary
            subtitle="Owners"
            title={t("Service & Maintenance")}
          />
          <div className="serviceMaintenancePage-content mt-4">
            <p>
              {t(
                "Our Regular servicing maintains your Suzuki long-term reliability and safety, which is why we specify a Distance Travelled and/or time interval, whichever comes first. Our expertly trained technicians not only use the latest Suzuki servicing equipment, but also have access to the latest information and training on Suzuki products, to keep your Suzuki operating in peak condition. So don't jeopardize the reliability and safety of your investment by allowing it to be serviced by someone who lacks the equipment and training that only your authorized Suzuki Dealer's service department can offer."
              )}
            </p>

            <p>
              {t(
                "To maintain and in some cases improve vehicle performance and fuel consumption, Suzuki recommend that you regularly carry out a 'mini' check on your vehicle. Sometimes, these checks can avoid the need for expensive repairs and in some cases a fine!"
              )}
            </p>

            <div className="tips-bar">
              <h4>
                <i className="jam jam-lightbulb"></i>
                <span>{t("Pro tip:")}</span>
              </h4>
              <p>
                {t(
                  "Make sure you change the engine oil and oil filter on every service"
                )}
              </p>
            </div>

            <h4 className="servicMaintenance-title">
              {t("Clear & Competitve Pricing")}
            </h4>
            <p>
              {t(
                "Our aim is to provide our customers with the very best cost of ownership for their vehicles by offering cost efficiencies and the most competitive pricing. In addition, our Customers will always be made aware of the cost of any scheduled maintenance or menu priced item thanks to transparent cost estimates, based on clearly communicated prices."
              )}
            </p>

            <div className="serviceMaintencance-cta">
              <p>{t("Calculate your Suzuki’s maintenance cost")}</p>
              <CustomButton
                text={t("Calculate")}
                to="/maintenance-calculator"
              />
            </div>

            <h4 className="servicMaintenance-title">
              {t("Convenience, Transparency & Value")}
            </h4>

            <p>
              {t(
                "We are committed to delivering a superior service experience to all our Customers. An integral part of that commitment is the high standards we set ourselves in maintaining our customers’ vehicles. By focusing on convenience, transparency, and value, we place the Customer at the very center of everything we do and seek to exceed their expectations, day in and day out."
              )}
            </p>

            <h4 className="servicMaintenance-title">
              {t("Prompt & Reliable Vehicle Servce")}
            </h4>

            <p>
              {t(
                "We value and respect our customer’s time. That is why we put Customer convenience above all else. We ensure that appointments are available within 5 working days, or whenever suits the Customer. Through our effective appointment booking system, we follow up with service reminders and provide ‘quick delivery’ for all Scheduled Maintenance jobs under appointment."
              )}
            </p>

            <h4 className="servicMaintenance-title">
              {t("superior Customer Service experience")}
            </h4>

            <p>
              {t(
                "We deliver superior service from beginning to end. Prior to work commencing, a dedicated Service Advisor explains the work to be done and ensures all Customer requirements will be met. During the service, the Advisor is always on-hand to provide progress updates and clear completion times. The Service Advisor will follow up with the Customer to check their satisfaction and find ways to continually improve our service."
              )}
            </p>
          </div>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default ServiceMaintenancePage;
