// DZIRE IMAGE RESOURCES STARTS

import DzireImage from "../images/dzire-home.png";
import DzireBlueBannerImage from "../images/vehicles/dzire/dzire-blue-banner.png";
import DzireRedBannerImage from "../images/vehicles/dzire/dzire-red-banner.png";
import DzireSilverBannerImage from "../images/vehicles/dzire/dzire-silver-banner.png";
import DzireGreyBannerImage from "../images/vehicles/dzire/dzire-grey-banner.png";
import DzireWhiteBannerImage from "../images/vehicles/dzire/dzire-white-banner.png";
import DzireBrownBannerImage from "../images/vehicles/dzire/dzire-brown-banner.png";
import DzireBlackBannerImage from "../images/vehicles/dzire/dzire-black-banner.png";
import DzireBrochure from "../images/vehicles/dzire/brochure/Dzire.pdf";
import Dzire360ViewImage from "../images/vehicles/dzire/360-dzire.png";
import DzireExterior1 from "../images/vehicles/dzire/exterior/dzire-exterior-1.png";
import DzireExterior2 from "../images/vehicles/dzire/exterior/dzire-exterior-2.png";
import DzireExterior3 from "../images/vehicles/dzire/exterior/dzire-exterior-3.png";
import DzireExterior4 from "../images/vehicles/dzire/exterior/dzire-exterior-4.png";
import DzireInteriorBanner from "../images/vehicles/dzire/interior/dzire-interior-banner.png";
import DzireInterior1 from "../images/vehicles/dzire/interior/dzire-interior-1.png";
import DzireInterior2 from "../images/vehicles/dzire/interior/dzire-interior-2.png";
import DzireInterior3 from "../images/vehicles/dzire/interior/dzire-interior-3.png";
import DzireFeature1 from "../images/vehicles/dzire/features/dzire-feature-1.png";
import DzireFeature2 from "../images/vehicles/dzire/features/dzire-feature-2.png";
import DzireFeature3 from "../images/vehicles/dzire/features/dzire-feature-3.png";
import DzireFeature4 from "../images/vehicles/dzire/features/dzire-feature-4.png";
import DzireFeature5 from "../images/vehicles/dzire/features/dzire-feature-5.png";
import DzireFeature6 from "../images/vehicles/dzire/features/dzire-feature-6.png";
import DzireVideo from "../videos/vehicle-videos/Dzire.mp4";

// DZIRE IMAGE RESOURCES ENDS

// BALENO IMAGE RESOURCES STARTS

import BalenoImage from "../images/baleno-home.png";
import BalenoBlueBannerImage from "../images/vehicles/baleno/baleno-blue-banner.png";
import BalenoRedBannerImage from "../images/vehicles/baleno/baleno-red-banner.png";
import BalenoSilverBannerImage from "../images/vehicles/baleno/baleno-silver-banner.png";
import BalenoGreyBannerImage from "../images/vehicles/baleno/baleno-grey-banner.png";
import BalenoWhiteBannerImage from "../images/vehicles/baleno/baleno-white-banner.png";
import BalenoBrownBannerImage from "../images/vehicles/baleno/baleno-brown-banner.png";
import BalenoBlackBannerImage from "../images/vehicles/baleno/baleno-black-banner.png";
import BalenoBrochure from "../images/vehicles/baleno/brochure/Baleno.pdf";
import Baleno360ViewImage from "../images/vehicles/baleno/360-baleno.png";
import BalenoExterior1 from "../images/vehicles/baleno/exterior/baleno-exterior-1.png";
import BalenoExterior2 from "../images/vehicles/baleno/exterior/baleno-exterior-2.png";
import BalenoExterior3 from "../images/vehicles/baleno/exterior/baleno-exterior-3.png";
import BalenoExterior4 from "../images/vehicles/baleno/exterior/baleno-exterior-4.png";
import BalenoInteriorBanner from "../images/vehicles/baleno/interior/baleno-interior-banner.png";
import BalenoInterior1 from "../images/vehicles/baleno/interior/baleno-interior-1.png";
import BalenoInterior2 from "../images/vehicles/baleno/interior/baleno-interior-2.png";
import BalenoInterior3 from "../images/vehicles/baleno/interior/baleno-interior-3.png";
import BalenoInterior4 from "../images/vehicles/baleno/interior/baleno-interior-4.png";
import BalenoFeature1 from "../images/vehicles/baleno/features/baleno-feature-1.png";
import BalenoFeature2 from "../images/vehicles/baleno/features/baleno-feature-2.png";
import BalenoFeature3 from "../images/vehicles/baleno/features/baleno-feature-3.png";
import BalenoFeature4 from "../images/vehicles/baleno/features/baleno-feature-4.png";
import BalenoFeature5 from "../images/vehicles/baleno/features/baleno-feature-5.png";
import BalenoFeature6 from "../images/vehicles/baleno/features/baleno-feature-6.png";
import BalenoVideo from "../videos/vehicle-videos/Baleno.mp4";

// BALENO IMAGE RESOURCES ENDS

// CIAZ IMAGE RESOURCES STARTS

import CiazImage from "../images/ciaz-home.png";
import CiazBlueBannerImage from "../images/vehicles/ciaz/ciaz-blue-banner.png";
import CiazRedBannerImage from "../images/vehicles/ciaz/ciaz-red-banner.png";
import CiazSilverBannerImage from "../images/vehicles/ciaz/ciaz-silver-banner.png";
import CiazGreyBannerImage from "../images/vehicles/ciaz/ciaz-grey-banner.png";
import CiazWhiteBannerImage from "../images/vehicles/ciaz/ciaz-white-banner.png";
import CiazBrownBannerImage from "../images/vehicles/ciaz/ciaz-brown-banner.png";
import CiazBlackBannerImage from "../images/vehicles/ciaz/ciaz-black-banner.png";
import CiazBrownDualBannerImage from "../images/vehicles/ciaz/ciaz-brown-dual-banner.png";
import CiazSilverDualBannerImage from "../images/vehicles/ciaz/ciaz-silver-dual-banner.png";
import CiazRedDualBannerImage from "../images/vehicles/ciaz/ciaz-red-dual-banner.png";
import CiazBrochure from "../images/vehicles/ciaz/brochure/Ciaz.pdf";
import Ciaz360ViewImage from "../images/vehicles/ciaz/360-ciaz.png";
import CiazExterior1 from "../images/vehicles/ciaz/exterior/ciaz-exterior-1.png";
import CiazExterior2 from "../images/vehicles/ciaz/exterior/ciaz-exterior-2.png";
import CiazExterior3 from "../images/vehicles/ciaz/exterior/ciaz-exterior-3.png";
import CiazExterior4 from "../images/vehicles/ciaz/exterior/ciaz-exterior-4.png";
import CiazInteriorBanner from "../images/vehicles/ciaz/interior/ciaz-interior-banner.png";
import CiazInterior1 from "../images/vehicles/ciaz/interior/ciaz-interior-1.png";
import CiazInterior2 from "../images/vehicles/ciaz/interior/ciaz-interior-2.png";
import CiazInterior3 from "../images/vehicles/ciaz/interior/ciaz-interior-3.png";
import CiazInterior4 from "../images/vehicles/ciaz/interior/ciaz-interior-4.png";
import CiazFeature1 from "../images/vehicles/ciaz/features/ciaz-feature-1.png";
import CiazFeature2 from "../images/vehicles/ciaz/features/ciaz-feature-2.png";
import CiazFeature3 from "../images/vehicles/ciaz/features/ciaz-feature-3.png";
import CiazVideo from "../videos/vehicle-videos/Ciaz.mp4";

// CIAZ IMAGE RESOURCES ENDS

// SWIFT IMAGE RESOURCE STARTS

import SwiftImage from "../images/swift-home.png";
import SwiftBlueBannerImage from "../images/vehicles/swift/swift-blue-banner.png";
import SwiftRedBannerImage from "../images/vehicles/swift/swift-red-banner.png";
import SwiftSilverBannerImage from "../images/vehicles/swift/swift-silver-banner.png";
import SwiftGreyBannerImage from "../images/vehicles/swift/swift-grey-banner.png";
import SwiftWhiteBannerImage from "../images/vehicles/swift/swift-white-banner.png";
import SwiftRedDualToneBannerImage from "../images/vehicles/swift/swift-red-dual-banner.png";
import SwiftOrangeDualToneBannerImage from "../images/vehicles/swift/swift-orange-dual-banner.png";
import SwiftGoldDualToneBannerImage from "../images/vehicles/swift/swift-yellow-dual-banner.png";
import SwiftBlueDualToneBannerImage from "../images/vehicles/swift/swift-blue-dual-banner.png";
import SwiftBrochure from "../images/vehicles/swift/brochure/Swift.pdf";
import Swift360ViewImage from "../images/vehicles/swift/360-swift.png";
import SwiftExterior1 from "../images/vehicles/swift/exterior/swift-exterior-1.png";
import SwiftExterior2 from "../images/vehicles/swift/exterior/swift-exterior-2.png";
import SwiftExterior3 from "../images/vehicles/swift/exterior/swift-exterior-3.png";
import SwiftInteriorBanner from "../images/vehicles/swift/interior/swift-interior-banner.png";
import SwiftInterior1 from "../images/vehicles/swift/interior/swift-interior-1.png";
import SwiftInterior2 from "../images/vehicles/swift/interior/swift-interior-2.png";
import SwiftInterior3 from "../images/vehicles/swift/interior/swift-interior-3.png";
import SwiftFeature1 from "../images/vehicles/swift/features/swift-feature-1.png";
import SwiftFeature2 from "../images/vehicles/swift/features/swift-feature-2.png";
import SwiftFeature3 from "../images/vehicles/swift/features/swift-feature-3.png";
import SwiftVideo from "../videos/vehicle-videos/Swift.mp4";

// SWIFT IMAGE RESOURCE ENDS

// FRONX IMAGE RESOURCE STARTS

import FronxImage from "../images/fronx-home.png";
import FronxBlueBannerImage from "../images/vehicles/fronx/fronx-blue-banner.png";
import FronxBlackBannerImage from "../images/vehicles/fronx/fronx-black-banner.png";
import FronxSilverBannerImage from "../images/vehicles/fronx/fronx-silver-banner.png";
import FronxGreyBannerImage from "../images/vehicles/fronx/fronx-grey-banner.png";
import FronxWhiteBannerImage from "../images/vehicles/fronx/fronx-white-banner.png";
import FronxOrangeBannerImage from "../images/vehicles/fronx/fronx-orange-banner.png";
import FronxRedDualBannerImage from "../images/vehicles/fronx/fronx-red-dual-banner.png";
import FronxOrangeDualBannerImage from "../images/vehicles/fronx/fronx-orange-dual-banner.png";
import FronxSilverDualBannerImage from "../images/vehicles/fronx/fronx-silver-dual-banner.png";
import FronxBrochure from "../images/vehicles/fronx/brochure/Fronx.pdf";
import Fronx360ViewImage from "../images/vehicles/fronx/360-fronx.png";
import FronxExterior1 from "../images/vehicles/fronx/exterior/fronx-exterior-1.png";
import FronxExterior2 from "../images/vehicles/fronx/exterior/fronx-exterior-2.png";
import FronxExterior3 from "../images/vehicles/fronx/exterior/fronx-exterior-3.png";
import FronxExterior4 from "../images/vehicles/fronx/exterior/fronx-exterior-4.png";
import FronxInteriorBanner from "../images/vehicles/fronx/interior/fronx-interior-banner.png";
import FronxInterior1 from "../images/vehicles/fronx/interior/fronx-interior-1.png";
import FronxInterior2 from "../images/vehicles/fronx/interior/fronx-interior-2.png";
import FronxInterior3 from "../images/vehicles/fronx/interior/fronx-interior-3.png";
import FronxInterior4 from "../images/vehicles/fronx/interior/fronx-interior-4.png";
import FronxInterior5 from "../images/vehicles/fronx/interior/fronx-interior-5.png";
import FronxFeature1 from "../images/vehicles/fronx/features/fronx-feature-1.png";
import FronxFeature2 from "../images/vehicles/fronx/features/fronx-feature-2.png";
import FronxFeature3 from "../images/vehicles/fronx/features/fronx-feature-3.png";
import FronxVideo from "../videos/vehicle-videos/Fronx.mp4";

// FRONX IMAGE RESOURCE ENDS

// GRAND VITRA IMAGE RESORUCE STARTS

import GrandVitaraImage from "../images/grand-vitara-home.png";
import GrandVitaraBlueBannerImage from "../images/vehicles/grand-vitara/grand-vitara-blue-banner.png";
import GrandVitaraBlackBannerImage from "../images/vehicles/grand-vitara/grand-vitara-black-banner.png";
import GrandVitaraSilverBannerImage from "../images/vehicles/grand-vitara/grand-vitara-silver-banner.png";
import GrandVitaraBrownBannerImage from "../images/vehicles/grand-vitara/grand-vitara-brown-banner.png";
import GrandVitaraWhiteBannerImage from "../images/vehicles/grand-vitara/grand-vitara-white-banner.png";
import GrandVitaraRedBannerImage from "../images/vehicles/grand-vitara/grand-vitara-red-banner.png";
import GrandVitaraWhiteDualBannerImage from "../images/vehicles/grand-vitara/grand-vitara-white-dual-banner.png";
import GrandVitaraRedDualBannerImage from "../images/vehicles/grand-vitara/grand-vitara-red-dual-banner.png";
import GrandVitaraSilverDualBannerImage from "../images/vehicles/grand-vitara/grand-vitara-silver-dual-banner.png";
import GrandVitaraBrochure from "../images/vehicles/grand-vitara/brochure/Grand_Vitara.pdf";
import GrandVitara360ViewImage from "../images/vehicles/grand-vitara/360-grand-vitara.png";
import GrandVitaraExterior1 from "../images/vehicles/grand-vitara/exterior/grand-vitara-exterior-1.png";
import GrandVitaraExterior2 from "../images/vehicles/grand-vitara/exterior/grand-vitara-exterior-2.png";
import GrandVitaraExterior3 from "../images/vehicles/grand-vitara/exterior/grand-vitara-exterior-3.png";
import GrandVitaraExterior4 from "../images/vehicles/grand-vitara/exterior/grand-vitara-exterior-4.png";
import GrandVitaraInteriorBanner from "../images/vehicles/grand-vitara/interior/grand-vitara-interior-banner.png";
import GrandVitaraInterior1 from "../images/vehicles/grand-vitara/interior/grand-vitara-interior-1.png";
import GrandVitaraInterior2 from "../images/vehicles/grand-vitara/interior/grand-vitara-interior-2.png";
import GrandVitaraInterior3 from "../images/vehicles/grand-vitara/interior/grand-vitara-interior-3.png";
import GrandVitaraInterior4 from "../images/vehicles/grand-vitara/interior/grand-vitara-interior-4.png";
import GrandVitaraFeature1 from "../images/vehicles/grand-vitara/features/grand-vitara-feature-1.png";
import GrandVitaraFeature2 from "../images/vehicles/grand-vitara/features/grand-vitara-feature-2.png";
import GrandVitaraFeature3 from "../images/vehicles/grand-vitara/features/grand-vitara-feature-3.png";
import GrandVitaraFeature4 from "../images/vehicles/grand-vitara/features/grand-vitara-feature-4.png";
import GrandVitaraFeature5 from "../images/vehicles/grand-vitara/features/grand-vitara-feature-5.png";
import GrandVitaraFeature6 from "../images/vehicles/grand-vitara/features/grand-vitara-feature-6.png";
import GrandVitaraVideo from "../videos/vehicle-videos/Grand-Vitara.mp4";

// GRAND VITRA IMAGE RESORUCE ENDS

// ERTIGA IMAGE RESOURCE STARTS

import ErtigaImage from "../images/ertiga-home.png";
import ErtigaBlueBannerImage from "../images/vehicles/ertiga/ertiga-blue-banner.png";
import ErtigaRedBannerImage from "../images/vehicles/ertiga/ertiga-red-banner.png";
import ErtigaSilverBannerImage from "../images/vehicles/ertiga/ertiga-silver-banner.png";
import ErtigaGreyBannerImage from "../images/vehicles/ertiga/ertiga-grey-banner.png";
import ErtigaWhiteBannerImage from "../images/vehicles/ertiga/ertiga-white-banner.png";
import ErtigaBrownBannerImage from "../images/vehicles/ertiga/ertiga-brown-banner.png";
import ErtigaBlackBannerImage from "../images/vehicles/ertiga/ertiga-black-banner.png";
import ErtigaBrochure from "../images/vehicles/ertiga/brochure/Ertiga.pdf";
import Ertiga60ViewImage from "../images/vehicles/ertiga/360-ertiga.png";
import ErtigaExterior1 from "../images/vehicles/ertiga/exterior/ertiga-exterior-1.png";
import ErtigaExterior2 from "../images/vehicles/ertiga/exterior/ertiga-exterior-2.png";
import ErtigaExterior3 from "../images/vehicles/ertiga/exterior/ertiga-exterior-3.png";
import ErtigaExterior4 from "../images/vehicles/ertiga/exterior/ertiga-exterior-4.png";
import ErtigaInteriorBanner from "../images/vehicles/ertiga/interior/ertiga-interior-banner.png";
import ErtigaInterior1 from "../images/vehicles/ertiga/interior/ertiga-interior-1.png";
import ErtigaInterior2 from "../images/vehicles/ertiga/interior/ertiga-interior-2.png";
import ErtigaInterior3 from "../images/vehicles/ertiga/interior/ertiga-interior-3.png";
import ErtigaInterior4 from "../images/vehicles/ertiga/interior/ertiga-interior-4.png";
import ErtigaFeature1 from "../images/vehicles/ertiga/features/ertiga-feature-1.png";
import ErtigaFeature2 from "../images/vehicles/ertiga/features/ertiga-feature-2.png";
import ErtigaFeature3 from "../images/vehicles/ertiga/features/ertiga-feature-3.png";
import ErtigaVideo from "../videos/vehicle-videos/Ertiga.mp4";

// ERTIGA IMAGE RESOURCE ENDS

// JIMNY IMAGE SOURCE STARTS

import JimnyImage from "../images/jimny-home.png";
import JimnyGreyImage from "../images/jimny-home-grey.png";
import JimnyGreenDualBannerImage from "../images/vehicles/jimny/jimny-green-banner.png";
import JimnyBlueDualBannerImage from "../images/vehicles/jimny/jimny-blue-banner.png";
import JimnyIvoryDualBannerImage from "../images/vehicles/jimny/jimny-ivory-banner.png";
import JimnyBlackBannerImage from "../images/vehicles/jimny/jimny-black-banner.png";
import JimnyJungleGreenBannerImage from "../images/vehicles/jimny/jimny-jungle-green-banner.png";
import JimnyDarkGreyBannerImage from "../images/vehicles/jimny/jimny-dark-grey-banner.png";
import JimnySilverBannerImage from "../images/vehicles/jimny/jimny-silver-banner.png";
import JimnyWhiteBannerImage from "../images/vehicles/jimny/jimny-white-banner.png";
import JimnyBrochure from "../images/vehicles/jimny/brochure/Jimny.pdf";
import Jimny360ViewImage from "../images/vehicles/jimny/360-jimny.png";
import JimnyExterior1 from "../images/vehicles/jimny/exterior/jimny-exterior-1.png";
import JimnyExterior2 from "../images/vehicles/jimny/exterior/jimny-exterior-2.png";
import JimnyExterior3 from "../images/vehicles/jimny/exterior/jimny-exterior-3.png";
import JimnyExterior4 from "../images/vehicles/jimny/exterior/jimny-exterior-4.png";
import JimnyInteriorBanner from "../images/vehicles/jimny/interior/jimny-interior-banner.png";
import JimnyInterior1 from "../images/vehicles/jimny/interior/jimny-interior-1.png";
import JimnyInterior2 from "../images/vehicles/jimny/interior/jimny-interior-2.png";
import JimnyInterior3 from "../images/vehicles/jimny/interior/jimny-interior-3.png";
import JimnyInterior4 from "../images/vehicles/jimny/interior/jimny-interior-4.png";
import JimnyFeature1 from "../images/vehicles/jimny/features/jimny-feature-1.png";
import JimnyFeature2 from "../images/vehicles/jimny/features/jimny-feature-2.png";
import JimnyFeature3 from "../images/vehicles/jimny/features/jimny-feature-3.png";
import JimnyVideo from "../videos/vehicle-videos/Jimny.mp4";

// JIMNY IMAGE SOURCE ENDS

// JIMNY 5 DOORS IMAGE SOURCE STARTS
import Jimny5DoorsImage from "../images/jimny-5-doors-home.png";
import Jimny5DoorsRedImage from "../images/jimny-home-red.png";
import Jimny5DoorsGreenDualBannerImage from "../images/vehicles/jimny-5-doors/jimny-5-doors-green-dual-banner.png";
import Jimny5DoorsIvoryDualBannerImage from "../images/vehicles/jimny-5-doors/jimny-5-doors-ivory-dual-banner.png";
import Jimny5DoorsRedDualBannerImage from "../images/vehicles/jimny-5-doors/jimny-5-doors-red-dual-banner.png";
import Jimny5DoorsBlackBannerImage from "../images/vehicles/jimny-5-doors/jimny-5-doors-black.png";
import Jimny5DoorsJungleGreenBannerImage from "../images/vehicles/jimny-5-doors/jimny-5-doors-jungle-green-banner.png";
import Jimny5DoorsDarkGreyBannerImage from "../images/vehicles/jimny-5-doors/jimny-5-doors-dark-grey-banner.png";
import Jimny5DoorsWhiteBannerImage from "../images/vehicles/jimny-5-doors/jimny-5-doors-white-banner.png";
import Jimny5DoorsBrochure from "../images/vehicles/jimny-5-doors/brochure/Jimny-5-Doors.pdf";
import Jimny5Doors360ViewImage from "../images/vehicles/jimny-5-doors/360-jimny-5-doors.png";
import Jimny5DoorsExterior1 from "../images/vehicles/jimny-5-doors/exterior/jimny-5-doors-exterior-1.png";
import Jimny5DoorsExterior2 from "../images/vehicles/jimny-5-doors/exterior/jimny-5-doors-exterior-2.png";
import Jimny5DoorsExterior3 from "../images/vehicles/jimny-5-doors/exterior/jimny-5-doors-exterior-3.png";
import Jimny5DoorsExterior4 from "../images/vehicles/jimny-5-doors/exterior/jimny-5-doors-exterior-4.png";
import Jimny5DoorsInteriorBanner from "../images/vehicles/jimny-5-doors/interior/jimny-5-doors-interior-banner.png";
import Jimny5DoorsInterior1 from "../images/vehicles/jimny-5-doors/interior/jimny-5-doors-interior-1.png";
import Jimny5DoorsInterior2 from "../images/vehicles/jimny-5-doors/interior/jimny-5-doors-interior-2.png";
import Jimny5DoorsInterior3 from "../images/vehicles/jimny-5-doors/interior/jimny-5-doors-interior-3.png";
import Jimny5DoorsInterior4 from "../images/vehicles/jimny-5-doors/interior/jimny-5-doors-interior-4.png";
import Jimny5DoorsInterior5 from "../images/vehicles/jimny-5-doors/interior/jimny-5-doors-interior-5.png";
import Jimny5DoorsFeature1 from "../images/vehicles/jimny-5-doors/features/jimny-5-doors-feature-1.png";
import Jimny5DoorsFeature2 from "../images/vehicles/jimny-5-doors/features/jimny-5-doors-feature-2.png";
import Jimny5DoorsFeature3 from "../images/vehicles/jimny-5-doors/features/jimny-5-doors-feature-3.png";
import Jimny5DoorsVideo from "../videos/vehicle-videos/Jimny5Door.mp4";

// JIMNY 5 DOORS IMAGE SOURCE ENDS

// CARRY IMAGE SOURCE STARTS

import CarryImage from "../images/carry-home.png";
import CarryWhiteBanner from "../images/vehicles/carry/carry-white-banner.png";
import CarryBrochure from "../images/vehicles/carry/brochure/Carry.pdf";
import Carry360ViewImage from "../images/vehicles/carry/360-carry.png";
import CarryExterior1 from "../images/vehicles/carry/exterior/carry-exterior-1.png";
import CarryExterior2 from "../images/vehicles/carry/exterior/carry-exterior-2.png";
import CarryExterior3 from "../images/vehicles/carry/exterior/carry-exterior-3.png";
import CarryExterior4 from "../images/vehicles/carry/exterior/carry-exterior-4.png";
import CarryInteriorBanner from "../images/vehicles/carry/interior/carry-interior-banner.png";
import CarryInterior1 from "../images/vehicles/carry/interior/carry-interior-1.jpg";
import CarryFeature1 from "../images/vehicles/carry/features/carry-feature-1.png";
import CarryFeature2 from "../images/vehicles/carry/features/carry-feature-2.png";
import CarryFeature3 from "../images/vehicles/carry/features/carry-feature-3.png";
import CarryFeature4 from "../images/vehicles/carry/features/carry-feature-4.png";
import CarryFeature5 from "../images/vehicles/carry/features/carry-feature-5.png";
import CarryFeature6 from "../images/vehicles/carry/features/carry-feature-6.png";
import CarryVideo from "../videos/vehicle-videos/Carry.mp4";

// CARRY IMAGE SOURCE ENDS

// EECO IMAGE SOURCE STARTS

import EecoImage from "../images/eeco-new.png";
import EecoWhiteBanner from "../images/vehicles/eeco/eeco-white-banner.png";
import EecoBrochure from "../images/vehicles/eeco/brochure/EECO.pdf";
import Eeco360ViewImage from "../images/vehicles/eeco/360-eeco.png";
import EecoExterior1 from "../images/vehicles/eeco/exterior/eeco-exterior-1.png";
import EecoExterior2 from "../images/vehicles/eeco/exterior/eeco-exterior-2.png";
import EecoInteriorBanner from "../images/vehicles/eeco/interior/eeco-interior-banner.png";
import EecoInterior1 from "../images/vehicles/eeco/interior/eeco-interior-1.png";
import EecoFeature1 from "../images/vehicles/eeco/features/eeco-feature-1.png";
import EecoFeature2 from "../images/vehicles/eeco/features/eeco-feature-2.png";
import EecoFeature3 from "../images/vehicles/eeco/features/eeco-feature-3.png";
import EecoVideo from "../videos/vehicle-videos/Eeco.mp4";

// EECO IMAGE SOURCE ENDS

// OTHERS IMAGE SOURCE STARTS

import VitaraImage from "../images/vitara-home.png";
import APVImage from "../images/apv-home.png";
import SX4Image from "../images/sx4-home.png";

// OTHERS IMAGE SOURCE ENDS

const vehicles = [
  // DZIRE DATA SOURCE STARTS
  {
    id: "dzire",
    title: {
      en: "Dzire",
      ar: "ديزاير",
    },
    image: DzireImage,
    video: DzireVideo,
    category: "Sedan",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      { name: "Brown", hexCode:"#9C988D", customName: {en:"Sherwood Brown Pearl Metallic", ar:"بني لؤلؤي لامع"}, image: DzireBrownBannerImage },
      { name: "Grey", hexCode:"#6A6B70", customName: {en:"Magma Grey Metallic", ar:"رمادي فاخر لؤلؤي لامع"}, image: DzireGreyBannerImage },
      { name: "Blue", hexCode:"#38569C", customName: {en:"Oxford Blue Pearl Metallic", ar:"أزرق لؤلؤي لامع"}, image: DzireBlueBannerImage },
      { name: "Red", hexCode:"#BB1926", customName: {en:"Phoenix Red Pearl", ar:"أحمر فاخر لؤلؤي لامع"}, image: DzireRedBannerImage },
      { name: "Silver", hexCode:"#BBC4CB", customName: {en:"Premium Silver Pearl Metallic", ar:"فضي فاخر لؤلؤي لامع"}, image: DzireSilverBannerImage },
      { name: "White", hexCode:"#E3E3E3", customName: {en:"Arctic White Pearl", ar:"لؤلؤة بيضاء قطبية"}, image: DzireWhiteBannerImage },
      { name: "Black", hexCode:"#343436", customName: {en:"Midnight Black Pearl", ar:"أسود لؤلؤي داكن"}, image: DzireBlackBannerImage },
    ],
    variants: [
      {
        name: "DZIRE AMT GL ALLOY",
        price: "51,750",
        items: [
          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },
          {
            en: "Urethane Steering Wheel",
            ar:"عجلة قيادة من اليوريثين"
          },
          {
            en:"Manual Front AC with Rear Vents",
            ar:"مكيف أمامي يدوي مع فتحات خلفية"
          },
          {
            en:"Front and Rear Speakers",
            ar:"مكبرات الصوت الأمامية والخلفية"
          }
        ],
      },

      {
        name: "DZIRE AMT GLX ALLOY",
        price: "53,475",
        items: [ {
          en:"Halogen Headlamps",
          ar:"مصابيح أمامية هالوجينية",
        }, 
        {
          en:"Leather Steering Wheel",
          ar:"عجلة قيادة من الجلد"
        },
        {
          en:"Automatic Front AC with Rear Vents",
          ar:"مكيف أمامي تلقائي مع فتحات خلفية",
        },
        {
          en:"Keyless Remote Entry",
          ar:"دخول بدون مفتاح عن بُعد"
        }
        ],
      },
    ],
    overview: [
      {
        title: {
          en: "Drive Line",
          ar: "خط القيادة",
        },
        data: "FWD",
      },
      {
        title: {
          en: "Engine",
          ar: "المحرك",
        },
        data: "1.2L",
      },
      {
        title: {
          en: "Horse Power",
          ar: "القدرة الحصانية",
        },
        data: "82",
      },
      {
        title: {
          en: "Fuel Efficiency",
          ar: "كفاءة الوقود",
        },
        data: "21.0 KMPL",
      },
      {
        title: {
          en: "Fuel Tank Capacity",
          ar: "سعة خزان الوقود",
        },
        data: "37L",
      },
    ],
    brochure: DzireBrochure,
    shortDescription: {
      en: "Where elegant design meets confident performance for a journey beyond ordinary.",
      ar: "حيث يلتقي التصميم الأنيق بالأداء الواثق لرحلة تتجاوز العادية.",
    },

    tagline: {
      en: "Streamlined. Compact. Stylish.",
      ar: "انسيابي. مضغوط. أنيق.",
    },
    View360Image: Dzire360ViewImage,
    exteriorImages: [
      DzireExterior1,
      DzireExterior2,
      DzireExterior3,
      DzireExterior4,
    ],
    exteriorTitle: {
      en: "All that you desire.",
      ar: "كل ما ترغب فيه.",
    },
    exteriorDescription1: {
      en: "The Suzuki Dzire’s stylish sedan design features a distinctive front grille with chrome detailing and sharp projector headlamps, giving it a refined yet dynamic presence.",
      ar: "تصميم سيدان أنيق لسوزوكي ديزاير يتميز بشبك أمامي مميز بتفاصيل من الكروم ومصابيح بروجكتور حادة، مما يمنحها مظهراً مصقولاً وديناميكياً.",
    },

    exteriorDescription2: {
      en: "Its smooth character lines run seamlessly from front to rear, adding a touch of sophistication and elegance to its profile.",
      ar: "خطوطها الناعمة تمتد بسلاسة من الأمام إلى الخلف، مما يضيف لمسة من الرقي والأناقة إلى مظهرها.",
    },

    interiorImages: [
      DzireInteriorBanner,
      DzireInterior1,
      DzireInterior2,
      DzireInterior3,
    ],
    interiorContents: [
      {
        title: {
          en: "Space that indulges your desire",
          ar: "مساحة تلبية رغباتك",
        },
        description: {
          en: "The moment you sit inside the Dzire is when an exceptional experience begins. The spacious interior in the front and rear ensures a relaxing drive for everyone as the car drives through the city. Also, amenities for the rear seats make the drive pleasant.",
          ar: "عندما تجلس داخل ديزاير تبدأ تجربة استثنائية. يضمن الداخل الفسيح في الأمام والخلف قيادة مريحة للجميع أثناء القيادة في المدينة. كما أن المرافق الخلفية تجعل القيادة ممتعة.",
        },
      },
      {
        title: {
          en: "Smartphone Linkage Display Audio",
          ar: "نظام عرض الصوت المتصل بالهاتف الذكي",
        },
        description: {
          en: "For your entertainment, the Dzire comes with a highly advanced Smartphone Linkage Display Audio, which lets you stay connected to excitement at every moment. It works with Apple CarPlay for your iPhone, or Android Auto™ or MirrorLink™ for your compatible smartphone.",
          ar: "من أجل ترفيهك، تأتي ديزاير مع نظام عرض الصوت المتصل بالهاتف الذكي المتطور، الذي يتيح لك البقاء متصلاً بالإثارة في كل لحظة. يعمل مع Apple CarPlay لهاتف iPhone الخاص بك، أو Android Auto™ أو MirrorLink™ لهاتفك الذكي المتوافق.",
        },
      },
      {
        title: {
          en: "Boost Space",
          ar: "مساحة التخزين",
        },
        description: {
          en: "Convenient 378 litres* of boot space and multiple storages are a part of your everyday life.",
          ar: "مساحة تخزين 378 لتر* ووجود مساحات تخزين متعددة جزء من حياتك اليومية.",
        },
      },
    ],
    features: [
      {
        image: DzireFeature1,
        title: {
          en: "Hill hold control",
          ar: "نظام التحكم في الثبات على المنحدرات",
        },
        description: {
          en: "The hill hold control prevents vehicle from rolling backwards during standing starts on slopes, by engaging the brakes for up to two seconds after the driver removes pressure on the brake pedal to depress the accelerator.",
          ar: "نظام التحكم في الثبات على المنحدرات يمنع المركبة من الرجوع للخلف عند بدء الحركة على المنحدرات، من خلال تفعيل الفرامل لمدة تصل إلى ثانيتين بعد أن يرفع السائق قدمه عن دواسة الفرامل ويضغط على دواسة الوقود.",
        },
      },
      {
        image: DzireFeature2,
        title: {
          en: "Light and impact absorbing body (TECT)",
          ar: "جسم خفيف ويمتص الصدمات (TECT)",
        },
        description: {
          en: "The Dzire uses Suzuki's TECT, or Total Effective Control Technology, to effectively absorb impact on the body and disperse the collision energy across the frame thereby mitigating damage to the car and its occupants.",
          ar: "تستخدم ديزاير تقنية TECT من سوزوكي، أو تقنية التحكم الفعال الكامل، لامتصاص الصدمات بشكل فعال وتوزيع طاقة الاصطدام عبر الهيكل مما يقلل من الأضرار على السيارة وركابها.",
        },
      },
      {
        image: DzireFeature3,
        title: {
          en: "Airbags",
          ar: "وسائد هوائية",
        },
        description: {
          en: "The Dzire comes with dual front airbags for the safety of the driver and the front passenger in case of a frontal collision.",
          ar: "تأتي ديزاير مع وسائد هوائية أمامية مزدوجة لسلامة السائق والراكب الأمامي في حالة الاصطدام الأمامي",
        },
      },
      {
        image: DzireFeature4,
        title: {
          en: "Pedestrian injury mitigating body",
          ar: "هيكل يخفف من إصابات المشاة",
        },
        description: {
          en: "The structure of the bonnet, front windscreen wiper area, front bumper and other parts absorb impacts, with the aim of mitigating head and leg injuries in the event of a collision with a pedestrian.",
          ar: "هيكل غطاء المحرك ومنطقة مساحات الزجاج الأمامي والمصد الأمامي والأجزاء الأخرى يمتص الصدمات بهدف تخفيف إصابات الرأس والساق في حالة التصادم مع المشاة.",
        },
      },
      {
        image: DzireFeature5,
        title: "ESP®*",
        description: {
          en:"If Electronic Stability Programme (ESP®) detects wheel slippage when the driver turns the steering wheel sharply and/or the road is slippery, for example, it automatically adjusts the engine torque and brakes to suppress the wheel slippage and help the driver stay in directional control.",
          ar:"إذا اكتشف نظام الثبات الإلكتروني (ESP®) انزلاق العجلات عندما يدير السائق عجلة القيادة بشدة و/أو كان الطريق زلقًا، على سبيل المثال، يقوم تلقائيًا بضبط عزم المحرك والفرامل لقمع انزلاق العجلات ومساعدة السائق في الحفاظ على السيطرة الاتجاهية.",
        }
          
      },
      {
        image: DzireFeature6,
        title: "Reverse parking sensors",
        description: {
          en:"Ultrasonic sensors in the rear bumper detect obstacles while the driver is reversing the car. Warning sounds help keep the driver informed of the distance to the obstacle.",
          ar:"تكتشف أجهزة الاستشعار فوق الصوتية في المصد الخلفي العوائق أثناء رجوع السائق للخلف. تساعد الأصوات التحذيرية على إبقاء السائق على علم بالمسافة إلى العائق.",
        }
          
      },
    ],
    specSubtitle: {
      en: "What makes Dzire desirable?",
      ar: "ما الذي يجعل ديزاير مرغوبة؟",
    },
    models: [{ type: "GL" }, { type: "GLX" }],
    specifications: {
      features: [
        {
          name: {
            en: "Keyless push start system",
            ar: "نظام بدء التشغيل بدون مفتاح",
          },
          GL: "--",
          GLX: "Yes",
        },

        {
          name: {
            en: "SRS front, side",
            ar: "وسائد هوائية أمامية وجانبية SRS",
          },
          GL: "Yes",
          GLX: "Yes",
        },

        {
          name: {
            en: "Immobiliser",
            ar: "نظام منع السرقة",
          },
          GL: "Yes",
          GLX: "Yes",
        },

        {
          name: {
            en: "Parking sensors (Rear) & Security alarm",
            ar: "حساسات ركن (خلفية) وإنذار أمني",
          },
          GL: "--",
          GLX: "Yes",
        },

        {
          name: {
            en: "TPMS (Tyre pressure monitoring system)",
            ar: "نظام مراقبة ضغط الإطارات (TPMS)",
          },
          GL: "Yes",
          GLX: "Yes",
        },

        {
          name: {
            en: "ABS with EBD function, ESP(Electronic Stability Program)",
            ar: "نظام المكابح المانع للانغلاق مع وظيفة التوزيع الإلكتروني لقوة الكبح، ونظام الثبات الإلكتروني (ESP)",
          },
          GL: "Yes",
          GLX: "Yes",
        },

        {
          name: {
            en: "Brake assist function",
            ar: "نظام مساعدة الفرامل",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Air conditioner",
            ar: "مكيف هواء",
          },
          GL: "Manual",
          GLX: "Auto",
        },
        {
          name: {
            en: "High-mount stop lamp | LED",
            ar: "مصباح توقف عالي التركيب | LED",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Fog lamps | Front",
            ar: "مصابيح ضباب | أمامية",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Fog lamps | Rear",
            ar: "مصابيح ضباب | خلفية",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Headlamps - Halogen Multi Reflector",
            ar: "المصابيح الأمامية - عاكس متعدد الهالوجين",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Front seats | Seat height adjuster (driver's side)",
            ar: "المقاعد الأمامية | ضبط ارتفاع المقعد (جانب السائق)",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Power windows | Front & Rear",
            ar: "نوافذ كهربائية | أمامية وخلفية",
          },
          GL: "Yes",
          GLX: "Yes",
        },

        {
          name: {
            en: "Outside door mirrors - Body coloured with turn lamps",
            ar: "المرايا الخارجية كهربائية مع إشارة انعطاف",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside door mirrors | Power Adjust",
            ar: "مرايا الأبواب الخارجية | ضبط الطاقة",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside door mirrors | Power fold with built-in turn signal lamp",
            ar: "مرايا الأبواب الخارجية | طي كهربائي مع مصباح إشارة الانعطاف المدمج",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "3-spoke steering wheel | With audio controls",
            ar: "عجلة قيادة ثلاثية الأضلاع | مع الضوابط الصوتية",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "3-spoke steering wheel | Leather covered",
            ar: "عجلة قيادة بثلاثة أذرع | مغطاة بالجلد",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Remote-control door locks | With hazard lamp answerback function",
            ar: "أقفال الأبواب بالتحكم عن بعد | مع وظيفة الرد بمصباح الخطر",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Alloy Wheels	",
            ar: "عجلات معدنية",
          },

          GL: "Optional",
          GLX: "Yes",
        },
        {
          name: {
            en: "Audio",
            ar: "نظام صوتي",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "Antenna (mounted at rear of roof)",
            ar: "هوائي (مثبت في الجزء الخلفي من السقف)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Front & Rear Speakers",
            ar: "مكبرات صوت أمامية وخلفية",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Front Tweeters",
            ar: "مكبرات الصوت الأمامية",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            "en":"MP3/USB/AUX/SW and DAB Radio, Bluetooth - Locally Installed- Made in China",
            "ar":"مدخل MP3/USB/ AUX/SW /بلوتوث - مجهز محليًا صنع في الصين",
          },
          GL:"Yes",
          GLX:"Yes"
        },

      ],
      dimensions: [
        {
          name: {
            en: "Overall length (mm)",
            ar: "الطول الكلي (مم)",
          },
          GL: "3995",
          GLX: "3995",
        },
        {
          name: {
            en: "Overall width (mm)",
            ar: "العرض الكلي (مم)",
          },
          GL: "1,735",
          GLX: "1,735",
        },
        {
          name: {
            en: "Overall height (mm)",
            ar: "الارتفاع الكلي (مم)",
          },
          GL: "1,515",
          GLX: "1,515",
        },
        {
          name: {
            en: "Wheelbase (mm)",
            ar: "قاعدة العجلات (مم)",
          },
          GL: "2,450",
          GLX: "2,450",
        },
        {
          name: {
            en: "Tread(mm)",
            ar: "عرض المسار (مم)",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "Front",
            ar: "الأمام",
          },
          GL: "1,530",
          GLX: "1,520",
        },
        {
          name: {
            en: "Rear",
            ar: "الخلف",
          },
          GL: "1,530",
          GLX: "1,520",
        },
        {
          name: {
            en: "Minimum turning radius (m)",
            ar: "أدنى نصف قطر دوران (م)",
          },
          GL: "4.8",
          GLX: "4.8",
        },
        {
          name: {
            en: "Minimum ground clearance (mm)	",
            ar: "أدنى خلوص أرضي (مم)",
          },
          GL: "145",
          GLX: "145",
        },
      ],
      capacity: [
        {
          name: {
            en: "Seating capacity (Including Driver)	",
            ar: "سعة الجلوس (بما في ذلك السائق)",
          },
          GL: "5",
          GLX: "5",
        },
        {
          name: {
            en: "Luggage capacity*1 / Max.volume (litres)",
            ar: "سعة الأمتعة*1 / الحجم الأقصى (لترات)",
          },
          GL: "378",
          GLX: "378",
        },
        {
          name: {
            en: "Fuel tank capacity (litres)",
            ar: "سعة خزان الوقود (لترات)",
          },
          GL: "37",
          GLX: "37",
        },
      ],
      engine: [
        {
          name: {
            en: "Engine (4 CYL)",
            ar: "المحرك (4 أسطوانات)",
          },
          GL: "1.2L",
          GLX: "1.2L",
        },
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "K12M",
          GLX: "K12M",
        },
        {
          name: {
            en: "Number of cylinders",
            ar: "عدد الأسطوانات",
          },
          GL: "4",
          GLX: "4",
        },
        {
          name: {
            en: "Number of valves",
            ar: "عدد الصمامات",
          },
          GL: "16",
          GLX: "16",
        },
        {
          name: {
            en: "Piston displacement (cm3)	",
            ar: "إزاحة المكبس",
          },
          GL: "1,197	",
          GLX: "1,197",
        },
        {
          name: {
            en: "Bore x stroke (mm)	",
            ar: "قطر الأسطوانة × الشوط (مم)",
          },
          GL: "73.0 x 71.5",
          GLX: "73.0 x 71.5",
        },
        {
          name: {
            en: "Compression ratio",
            ar: "نسبة الانضغاط",
          },
          GL: "11.0",
          GLX: "11.0",
        },
        {
          name: {
            en: "Maximum output (kW/rpm)",
            ar: "القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          },
          GL: "61/6,000",
          GLX: "61/6,000",
        },
        {
          name: {
            en: "Maximum torque (N･m/rpm)",
            ar: "أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          },
          GL: "113/4,200",
          GLX: "113/4,200",
        },
        {
          name: {
            en: "Fuel distribution",
            ar: "توزيع الوقود",
          },
          GL: "Multipoint injection	",
          GLX: "Multipoint injection	",
        },
      ],
      transmission: [
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "5AGS",
          GLX: "5AGS",
        },
        {
          name: {
            en: "Gear Ratio",
            ar: "نسبة التروس",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "1st",
            ar: "1st",
          },
          GL: "3.545",
          GLX: "3.545",
        },
        {
          name: {
            en: "2nd",
            ar: "2nd",
          },
          GL: "1.905",
          GLX: "1.905",
        },
        {
          name: {
            en: "3rd",
            ar: "3rd",
          },
          GL: "1.240",
          GLX: "1.240",
        },
        {
          name: {
            en: "4th",
            ar: "4th",
          },
          GL: "0.906	",
          GLX: "0.906",
        },
        {
          name: {
            en: "5th",
            ar: "5th",
          },
          GL: "0.697",
          GLX: "0.697",
        },
        {
          name: {
            en: "Reverse",
            ar: "الرجوع",
          },
          GL: "3.273",
          GLX: "3.273",
        },
        {
          name: {
            en: "Final Gear Ratio	",
            ar: "نسبة الترس النهائي",
          },
          GL: "4.706",
          GLX: "4.706",
        },
      ],
      weight: [
        {
          name: {
            en: "Kerb weight (min./with full option)",
            ar: "الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          },
          GL: "885-895",
          GLX: "885-895",
        },
        {
          name: {
            en: "Gross vehicle weight (kg)",
            ar: "الوزن الإجمالي للمركبة (كجم)",
          },
          GL: "1,315",
          GLX: "1,315",
        },
      ],
    },
  },
  // DZIRE DATA SOURCE ENDS

  // BALENO DATA SOURCE STARTS
  {
    id: "baleno",
    title: {
      en: "Baleno",
      ar: "بالينو",
    },
    image: BalenoImage,
    video: BalenoVideo,
    category: "Hatchback",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      { name: "Blue", hexCode:"#064D8B", customName: { en:"Celestial Blue Pearl Metallic", ar:"أزرق لؤلؤي لامع"}, image: BalenoBlueBannerImage },
      { name: "Red", hexCode:"#BB1926", customName: { en:"Opulent Red Pearl Metallic", ar:"أحمر فاخر لؤلؤي لامع"}, image: BalenoRedBannerImage },
      { name: "Silver", hexCode:"#BBC4CB", customName: { en:"Splendid Silver Pearl Metallic", ar:"فضي فاخر لؤلؤي لامع"}, image: BalenoSilverBannerImage },
      { name: "Grey", hexCode:"#6A6B70", customName: { en:"Grandeur Grey Pearl Metallic", ar:"رمادي فاخر لؤلؤي لامع"}, image: BalenoGreyBannerImage },
      { name: "White", hexCode:"#E3E3E3", customName: { en:"Arctic White Pearl", ar:"لؤلؤة بيضاء قطبية"}, image: BalenoWhiteBannerImage },
      { name: "Brown", hexCode:"#AC917C", customName: { en:"Luxe Beige Pearl Metallic", ar:"بيج فاخر لؤلؤي لامع"}, image: BalenoBrownBannerImage },
      { name: "Black", hexCode:"#343436", customName: { en:"Midnight Black Pearl", ar:"أسود لؤلؤي داكن"}, image: BalenoBlackBannerImage },
    ],
    variants: [
      {
        name: "BALENO AT GL WHEEL COVER",
        price: "60,950",
        items: [
          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },

          {
            en:"Rear Fog Lamps",
            ar:" مصابيح خلفية"
          },

          {
            en:"7-inch Display Audio",
            ar:"شاشة صوت مقاس 7 بوصات"
          },

          {
            en:"Rear View Camera",
            ar:"كاميرا للرؤية الخلفية"
          },

          {
            en:"15-inch Steel Wheels with Full Cover",
            ar:"عجلات فولاذية مقاس 15 بوصة مع غطاء كامل"
          },
        ],
      },

      {
        name: "BALENO GL PLUS ALLOY",
        price: "61,525",
        items: [
          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },

          {
            en:"Rear Fog Lamps",
            ar:" مصابيح خلفية"
          },

          {
            en:"7-inch Display Audio",
            ar:"شاشة صوت مقاس 7 بوصات"
          },

          {
            en:"Rear View Camera",
            ar:"كاميرا للرؤية الخلفية"
          },

          {
            en:"15-inch Steel Wheels with Full Cover",
            ar:"عجلات فولاذية مقاس 15 بوصة مع غطاء كامل"
          },
        ],
      },

      {
        name: "BALENO AT GLX ALLOY",
        price: "67,275",
        items: [
          {
            en:"LED Headlamps + Home System",
            ar:"مصابيح أمامية LED+نظام تابعني للمنزل"
          },

          {
            en:"9-inch Display Audio",
            ar:"شاشة صوت مقاس 9 بوصات "
          },

          {
            en:"360 View Camera",
            ar:"كاميرا بزاوية 360 درجة"
          },

          {
            en:"16-inch Alloy Wheels (Polished)",
            ar:"عجلات ألمنيوم مقاس 16 بوصة (مصقولة)"
          },

          {
            en:"Keyless Push Engine Start",
            ar:"نظام بدء التشغيل بدون مفتاح"
          },

          {
            en:"Head-up Display",
            ar:"شاشة عرض علوية"
          },
        ],
      },
    ],
    overview: [
      {
        title: {
          en: "Drive Line",
          ar: "خط القيادة",
        },
        data: "FWD",
      },
      {
        title: {
          en: "Engine",
          ar: "المحرك",
        },
        data: "1.5L",
      },
      {
        title: {
          en: "Horse Power",
          ar: "القدرة الحصانية",
        },
        data: "103",
      },
      {
        title: {
          en: "Fuel Efficiency",
          ar: "كفاءة الوقود",
        },
        data: "19.0 KMPL",
      },
      {
        title: {
          en: "Fuel Tank Capacity",
          ar: "سعة خزان الوقود",
        },
        data: "37L",
      },
    ],
    brochure: BalenoBrochure,
    shortDescription: {
      en: "Makes an impression with its versatility. Hop in and live it all.",
      ar: "يترك انطباعًا بفضل تعدديته. اقفز واستمتع بكل شيء.",
    },

    tagline: {
      en: "Efficient. Spacious. Practical.",
      ar: "فعال. فسيح. عملي.",
    },
    View360Image: Baleno360ViewImage,
    exteriorImages: [
      BalenoExterior1,
      BalenoExterior2,
      BalenoExterior3,
      BalenoExterior4,
    ],
    exteriorTitle: {
      en: "Tech Goes Bold.",
      ar: "التقنية تصبح جريئة.",
    },
    exteriorDescription1: {
      en: "The New Age Baleno radiates confidence from every perspective. Its impeccably crafted 16-inch alloy wheels enhance its allure, while the chrome grille bestows the Baleno with a broader presence.",
      ar: "بالينو الجديدة تشع بالثقة من كل منظور. عجلاتها السبائكية 16 بوصة المصممة بدقة تعزز جاذبيتها، بينما يمنحها الشبك الكرومي مظهراً أوسع.",
    },

    exteriorDescription2: {
      en: "With automatic lighting-detection LED technology, driving becomes effortless and convenient.",
      ar: "مع تقنية الكشف التلقائي عن الإضاءة LED، تصبح القيادة سهلة ومريحة.",
    },

    interiorImages: [
      BalenoInteriorBanner,
      BalenoInterior1,
      BalenoInterior2,
      BalenoInterior3,
      BalenoInterior4,
    ],
    interiorContents: [
      {
        title: {
          en: "Display Audio",
          ar: "عرض الصوت",
        },
        description: {
          en: "The Baleno’s new generation display audio comes with a large 9-inch (7-inch in GL) HD touchscreen. It’s highly functional with features such as smartphone linkage, voice recognition and Bluetooth®* music playback. It also shows various vehicle status, keeping you always updated on your drive.",
          ar: "نظام عرض الصوت من الجيل الجديد لبالينو يأتي بشاشة لمس عالية الدقة كبيرة بحجم 9 بوصة (7 بوصة في GL). يتميز بوظائف عالية مثل الاتصال بالهاتف الذكي، والتعرف على الصوت، وتشغيل الموسيقى عبر Bluetooth®*. كما يعرض حالة السيارة المختلفة، مما يبقيك محدثًا دائمًا حول قيادتك.",
        },
      },
      {
        title: {
          en: "Next Level of Comfort",
          ar: "مستوى جديد من الراحة",
        },
        description: {
          en: "You’re assured of a pleasant experience when on the go in the Baleno. Effective use of space ensures ample room for all. The seats have also been designed for your maximum comfort. Multiple storage spaces for everyday items add convenience to every drive.",
          ar: "تأكد من تجربة ممتعة أثناء التنقل في بالينو. الاستخدام الفعال للمساحة يضمن مساحة واسعة للجميع. تم تصميم المقاعد أيضًا لراحتك القصوى. توفر مساحات التخزين المتعددة للأغراض اليومية راحة في كل رحلة ",
        },
      },
      {
        title: {
          en: "Head-up display (GLX only)",
          ar: "شاشة عرض علوية (فقط في GLX)",
        },
        description: {
          en: "Enjoy driving in the city without taking your eyes off the road. Check the Turn by turn navigation, Speed, RPM, Fuel Economy, AC functions, and Warning notifications in color with multi-display options in your line of sight.",
          ar: "استمتع بالقيادة في المدينة دون الحاجة إلى إبعاد عينيك عن الطريق. تحقق من التنقل خطوة بخطوة، السرعة، عدد دورات المحرك، استهلاك الوقود، وظائف التكييف، وإشعارات التحذير بالألوان مع خيارات العرض المتعددة في خط رؤيتك.",
        },
      },
      {
        title: {
          en: "Luggage space",
          ar: "مساحة الأمتعة",
        },
        description: {
          en: "Smart package provides enough luggage space for all your cargoes. Within the coupe style body, the Baleno provides a large luggage capacity of 318 litres (without parcel shelf)*.",
          ar: "الحزمة الذكية توفر مساحة كافية للأمتعة لكل أغراضك. داخل هيكل الكوبيه، توفر بالينو سعة أمتعة كبيرة تبلغ 318 لتر (بدون الرف العلوي)*.",
        },
      },
    ],
    features: [
      {
        image: BalenoFeature1,
        title: {
          en: "Aerodynamics",
          ar: "الديناميكا الهوائية",
        },
        description: {
          en: "Superior aerodynamic performance in the Baleno achieved by making full use of CAE and wind tunnel tests and optimising the shape of each part results in high fuel efficiency and a quiet ride.",
          ar: "أداء ديناميكي هوائي متفوق في بالينو تحقق بفضل الاستخدام الكامل لتقنية التحليل المساعد بالحاسوب (CAE) واختبارات النفق الهوائي وتحديد الشكل الأمثل لكل جزء مما يؤدي إلى كفاءة عالية في استهلاك الوقود وركوب هادئ.",
        },
      },
      {
        image: BalenoFeature2,
        title: {
          en: "360 view camera",
          ar: "كاميرا عرض 360 درجة",
        },
        description: {
          en: "The 360-degree view provides added safety and convenience. It uses four cameras (front, rear and both sides) to offer a variety of views, including 3D view for safe starts and top view for safer parking.",
          ar: "منظر بزاوية 360 درجة يوفر أمان وراحة إضافية. يستخدم أربع كاميرات (أمامية، خلفية وكلا الجانبين) لتقديم مجموعة متنوعة من المناظر، بما في ذلك العرض الثلاثي الأبعاد للبدايات الآمنة والعرض العلوي لركن السيارة بأمان.",
        },
      },
      {
        image: BalenoFeature3,
        title: {
          en: "Airbags",
          ar: "وسائد هوائية",
        },
        description: {
          en: "The six airbags – dual front airbags, side airbags and curtain airbags help protect the driver and passenger in the event of a collision.",
          ar: "الوسائد الهوائية الست - الوسائد الهوائية الأمامية المزدوجة، والوسائد الهوائية الجانبية، والوسائد الهوائية الستارية تساعد في حماية السائق والركاب في حالة التصادم.",
        },
      },
      {
        image: BalenoFeature4,
        title: {
          en: "Pedestrian injury mitigating body",
          ar: "هيكل يخفف من إصابات المشاة",
        },
        description: {
          en: "The structure of the Baleno’s bonnet, front windscreen wiper area, front bumper and other parts absorb impacts, with the aim of mitigating head and leg injuries in the event of a collision with a pedestrian.",
          ar: "هيكل غطاء المحرك لبالينو، منطقة مساحات الزجاج الأمامي، المصد الأمامي والأجزاء الأخرى يمتصون الصدمات بهدف تخفيف إصابات الرأس والساق في حالة التصادم مع المشاة.",
        },
      },
      {
        image: BalenoFeature5,
        title: {
          en: "ESP®*",
          ar: "نظام الثبات الإلكتروني (ESP®)",
        },
        description: {
          en: "If Electronic Stability Programme (ESP®) detects wheel slippage when the driver turns the steering wheel sharply or the road is slippery, it automatically adjusts the engine torque and brakes to suppress the wheel slippage and help the driver stay in directional control.",
          ar: "إذا اكتشف نظام الثبات الإلكتروني (ESP®) انزلاق العجلات عندما يدير السائق عجلة القيادة بشدة أو كان الطريق زلقًا، يقوم تلقائيًا بضبط عزم المحرك والفرامل لقمع انزلاق العجلات ومساعدة السائق في الحفاظ على السيطرة الاتجاهية.",
        },
      },
      {
        image: BalenoFeature6,
        title: {
          en: "Rear parking sensors",
          ar: "حساسات ركن خلفية",
        },
        description: {
          en: "Ultrasonic sensors in the rear bumper detect obstacles when in reverse gear, and audible alerts sound to indicate your approximate distance from the obstacles as you approach.",
          ar: "تكتشف أجهزة الاستشعار فوق الصوتية في المصد الخلفي العوائق عند وضع الجير في الرجوع، وتصدر تنبيهات صوتية للإشارة إلى المسافة التقريبية بينك وبين العوائق أثناء الاقتراب.",
        },
      },
    ],
    specSubtitle: {
      en: "What makes Baleno bold?",
      ar: "ما الذي يجعل بالينو جريئة؟",
    },
    models: [{ type: "GL" }, { type: "GLX" }],
    specifications: {
      features: [
        {
          name: {
            en: "Keyless push start system",
            ar: "نظام بدء التشغيل بدون مفتاح",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Cruise Control",
            ar: "مثبت السرعة",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "SRS front, side & curtain airbags",
            ar: "وسائد هوائية أمامية وجانبية وستارية SRS",
          },
          GL: "2",
          GLX: "6",
        },
        {
          name: {
            en: "Air conditioner Automatic",
            ar: "مكيف هواء تلقائي",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "4.2-inch color LCD information display",
            ar: "شاشة معلومات ملونة LCD بحجم 4.2 بوصة",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Remote-control door locks",
            ar: "أقفال الأبواب بالتحكم عن بعد",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Audio Display Screen (Apple CarPlay, Android Auto)",
            ar: "شاشة عرض الصوت (Apple CarPlay، Android Auto)",
          },
          GL: "7inch",
          GLX: "9inch",
        },
        {
          name: {
            en: "7inch Display Audio + Bluetooth®* + Rearview camera",
            ar: "شاشة صوت مقاس 7 بوصات + تقنية Bluetooth®* + كاميرا للرؤية الخلفية",
          },
          GL: "Optional",
          GLX: "--",
        },
        {
          name: {
            en: "9inch Display Audio + Bluetooth®* + 360 view camera",
            ar: "شاشة صوت مقاس 9 بوصات + تقنية Bluetooth®* + كاميرا بزاوية 360 درجة",
          },
          GL: "--",
          GLX: "Optional",
        },
        {
          name: {
            en: "Head up Display",
            ar: "شاشة عرض علوية",
          },
          GL: "--",
          GLX: "Auto",
        },
        {
          name: {
            en: "Wheels",
            ar: "العجلات",
          },
          GL: "15-inch (Steel)",
          GLX: "16-inch (Alloy)",
        },
        {
          name: {
            en: "Parking sensors (Rear)",
            ar: "أجهزة استشعار الركن (خلفي)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Immobiliser & Security alarm",
            ar: "جهاز منع الحركة وإنذار أمني",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "ABS with EBD Function ESP(Electronic Stability Program)",
            ar: "نظام المكابح المانع للانغلاق مع وظيفة التوزيع الإلكتروني لقوة الكبح ونظام الثبات الإلكتروني",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Fog lamps",
            ar: "مصابيح الضباب",
          },
          GL: "Rear",
          GLX: "Front & Rear",
        },
        {
          name: {
            en: "LED-DRL (Daytime Running Lights)",
            ar: "مصابيح LED للقيادة النهارية",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Power Windows and Center Door Locks",
            ar: "نوافذ كهربائية وأقفال أبواب مركزية",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Power Windows (Front & Rear)",
            ar: "نوافذ كهربائية (أمامية وخلفية)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Front seats Height Adjuster (Driver Side)",
            ar: "ضبط ارتفاع المقاعد الأمامية (جانب السائق)",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Centre Console box armrest",
            ar: "مسند ذراع صندوق الكونسول المركزي",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Head Lamps - Projector",
            ar: "مصابيح أمامية - بروجكتور",
          },
          GL: "Halogen",
          GLX: "LED",
        },
        {
          name: {
            en: "Automatic lamps / Follow me home system",
            ar: "مصابيح تلقائية / نظام تابعني للمنزل",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside door handles - chrome",
            ar: "مقابض الأبواب الخارجية - كروم",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside Door Mirrors Power Foldable and Adjustable",
            ar: "مرايا الأبواب الخارجية قابلة للطي والتعديل كهربائياً",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside door mirrors - With built-in turn signal lamps",
            ar: "مرايا الأبواب الخارجية مع مصابيح إشارة مدمجة",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Day / night rearview mirror - Dimming",
            ar: "مرآة الرؤية الخلفية نهارًا/ليلًا - تعتيم",
          },
          GL: "Manual",
          GLX: "Auto",
        },
        {
          name: {
            en: "Power Steering",
            ar: "نظام التوجيه المعزز",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "4 Speakers",
            ar: "4 مكبرات صوت",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "2 Tweeters",
            ar: "2 مكبرات صوت عالية التردد",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "3-Spoke Steering wheel with Audio Control & Tilt Adjust",
            ar: "عجلة قيادة بثلاثة أذرع مع تحكم في الصوت وضبط الميل",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "3-Spoke Steering wheel with Telescopic-adjust",
            ar: "عجلة قيادة بثلاثة أذرع مع ضبط تلسكوبي",
          },
          GL: "--",
          GLX: "Yes",
        },
      ],
      dimensions: [
        {
          name: {
            en: "Overall length (mm)",
            ar: "الطول الكلي (مم)",
          },
          GL: "3,990",
          GLX: "3,990",
        },
        {
          name: {
            en: "Overall width (mm)",
            ar: "العرض الكلي (مم)",
          },
          GL: "1,745",
          GLX: "1,745",
        },
        {
          name: {
            en: "Overall height (mm)",
            ar: "الارتفاع الكلي (مم)",
          },
          GL: "1,500",
          GLX: "1,500",
        },
        {
          name: {
            en: "Wheelbase (mm)",
            ar: "قاعدة العجلات (مم)",
          },
          GL: "2,520	",
          GLX: "2,520	",
        },
        {
          name: {
            en: "Tread(mm)",
            ar: "عرض المسار (مم)",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "Front",
            ar: "الأمام",
          },
          GL: "1,515",
          GLX: "1,505",
        },
        {
          name: {
            en: "Rear",
            ar: "الخلف",
          },
          GL: "1,520",
          GLX: "1,510",
        },
        {
          name: {
            en: "Minimum Ground Clearance (mm)",
            ar: "أدنى خلوص أرضي (مم)",
          },
          GL: "150",
          GLX: "150",
        },
        {
          name: {
            en: "Minimum turning radius (m)",
            ar: "أدنى نصف قطر دوران (م)",
          },
          GL: "5.1",
          GLX: "5.1",
        },
      ],
      capacity: [
        {
          name: {
            en: "Luggage capacity*1 / Max.volume (litres)",
            ar: "سعة الأمتعة*1 / الحجم الأقصى (لترات)",
          },
          GL: "1,057",
          GLX: "1,057",
        },
        {
          name: {
            en: "Seating capacity (Including Driver)",
            ar: "سعة الجلوس (بما في ذلك السائق)",
          },
          GL: "5",
          GLX: "5",
        },
        {
          name: {
            en: "Fuel tank capacity (litres)",
            ar: "سعة خزان الوقود (لترات)",
          },
          GL: "37",
          GLX: "37",
        },
      ],
      engine: [
        {
          name: {
            en: "Engine (4 CYL)",
            ar: "المحرك (4 أسطوانات)",
          },
          GL: "1.5 L	",
          GLX: "1.5 L	",
        },
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "K15B",
          GLX: "K15B",
        },
        {
          name: {
            en: "Number of cylinders",
            ar: "عدد الأسطوانات",
          },
          GL: "4	",
          GLX: "4	",
        },
        {
          name: {
            en: "Number of valves",
            ar: "عدد الصمامات",
          },
          GL: "16",
          GLX: "16",
        },
        {
          name: {
            en: "Piston displacement (cm3)",
            ar: "إزاحة المكبس (سم مكعب)",
          },
          GL: "1,462	",
          GLX: "1,462	",
        },
        {
          name: {
            en: "Bore x stroke (mm)",
            ar: "قطر الأسطوانة × الشوط (مم)",
          },
          GL: "74.0 x 85.0	",
          GLX: "74.0 x 85.0	",
        },
        {
          name: {
            en: "Compression ratio",
            ar: "نسبة الانضغاط",
          },
          GL: "10.5",
          GLX: "10.5",
        },
        {
          name: {
            en: "Maximum output (kW/rpm)",
            ar: "القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          },
          GL: "77/6,000	",
          GLX: "77/6,000",
        },
        {
          name: {
            en: "Maximum torque (N･m/rpm)",
            ar: "أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          },
          GL: "138/4,400	",
          GLX: "138/4,400	",
        },
        {
          name: {
            en: "Fuel distribution",
            ar: "توزيع الوقود",
          },
          GL: "Multipoint injection	",
          GLX: "Multipoint injection",
        },
      ],
      transmission: [
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "4AT	",
          GLX: "4AT	",
        },
        {
          name: {
            en: "Gear Ratio",
            ar: "نسبة التروس",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "1st",
            ar: "1st",
          },
          GL: "2.875	",
          GLX: "2.875	",
        },
        {
          name: {
            en: "2nd	",
            ar: "2nd	",
          },
          GL: "1.568",
          GLX: "1.568",
        },
        {
          name: {
            en: "3rd	",
            ar: "3rd	",
          },
          GL: "1.000	",
          GLX: "1.000",
        },
        {
          name: {
            en: "4th",
            ar: "4th",
          },
          GL: "0.697	",
          GLX: "0.697	",
        },

        {
          name: {
            en: "Reverse",
            ar: "الرجوع",
          },
          GL: "2.300",
          GLX: "2.300",
        },
        {
          name: {
            en: "Final Gear Ratio",
            ar: "نسبة الترس النهائي",
          },
          GL: "4.145",
          GLX: "4.145",
        },
      ],
      weight: [
        {
          name: {
            en: "Kerb weight (min./with full option)",
            ar: "الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          },
          GL: "965/985",
          GLX: "965/985",
        },
        {
          name: {
            en: "Gross vehicle weight (kg)",
            ar: "الوزن الإجمالي للمركبة (كجم)",
          },
          GL: "1,430",
          GLX: "1,430",
        },
      ],
    },
  },
  // BALENO DATA SOURCE ENDS

  // CIAZ DATA SOURCE STARTS
  {
    id: "ciaz",
    title: {
      en: "Ciaz",
      ar: "سياز",
    },
    image: CiazImage,
    video: CiazVideo,
    category: "Sedan",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      { name:"Black/Red", hexCode:"#343436/#BB1926", customName:{ en:"Opulent Red Pearl Metallic + Midnight Black", ar:"أحمر فاخر لؤلؤي لامع / أسود" }, image: CiazRedDualBannerImage },
      { name:"Black/Brown", hexCode:"#343436/#957951", customName:{ en:"Dignity Brown Pearl Metaalic + Midnight Black", ar:"بني لؤلؤي لامع / أسود" }, image: CiazBrownDualBannerImage },
      { name:"Black/Grey", hexCode:"#343436/#6A6B70", customName:{ en:"Grandeur Grey Pearl Metallic + Midnight Black", ar:"رمادي فاخر لؤلؤي لامع / أسود" }, image: CiazSilverDualBannerImage },
      { name: "Blue", hexCode:"#064D8B", customName: { en:"Celestial Blue Pearl Metallic", ar:"أزرق لؤلؤي لامع"}, image: CiazBlueBannerImage },
      { name: "Red", hexCode:"#BB1926", customName: { en:"Opulent Red Pearl Metallic", ar:"أحمر فاخر لؤلؤي لامع"}, image: CiazRedBannerImage },
      { name: "Silver", hexCode:"#BBC4CB", customName: { en:"Splendid Silver Pearl Metallic", ar:"فضي فاخر لؤلؤي لامع"}, image: CiazSilverBannerImage },
      { name: "Grey", hexCode:"#6A6B70", customName: { en:"Grandeur Grey Pearl Metallic", ar:"رمادي فاخر لؤلؤي لامع"}, image: CiazGreyBannerImage },
      { name: "White", hexCode:"#E3E3E3", customName: { en:"Arctic White Pearl", ar:"لؤلؤة بيضاء قطبية"}, image: CiazWhiteBannerImage },
      { name: "Brown", hexCode:"#957951", customName: { en:"Dignity Brown Pearl Metallic", ar:"بني لؤلؤي لامع"}, image: CiazBrownBannerImage },
      { name: "Black", hexCode:"#343436", customName: { en:"Midnight Black Pearl", ar:"أسود لؤلؤي داكن"}, image: CiazBlackBannerImage }
    ],
    variants: [
      {
        name: "CIAZ AT GL ALLOY",
        price: "59,800",
        items: [
          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },

          {
            en:"Rear Fog Lamps",
            ar:" مصابيح خلفية"
          },

          {
            en:"9-inch Screen",
            ar:"شاشة صوت مقاس 9 بوصات "
          },

          {
            en:"Rear View Camera",
            ar:"كاميرا للرؤية الخلفية"
          },

          {
            en:"Tachometer",
            ar:"مقياس سرعة الدوران"
          },

          {
            en:"Fabric Interior",
            ar:"خامة المقاعد قماش"
          },
        ],
      },

      {
        name: "CIAZ AT GLX",
        price: "70,150",
        items: [
          {
            en:"LED Headlamps",
            ar:"مصابيح أمامية LED"
          },
          {
            en:"Cruise Control",
            ar:"مثبت السرعة"
          },
          {
            en:"Rear View Camera with Sensors",
            ar:"كاميرا خلفية مع حساسات"
          },
          {
            en:"Keyless Push Engine Start",
            ar:"تشغيل المحرك بضغطة زر"
          },
          {
            en:"Smart Speedometer Display",
            ar:"عرض مقياس السرعة الذكي"
          },
          {
            en:"Leather Interior",
            ar:"خامة المقاعد جلد"
          },
        ],
      },
    ],
    overview: [
      {
        title: {
          en: "Drive Line",
          ar: "خط القيادة",
        },
        data: "FWD",
      },
      {
        title: {
          en: "Engine",
          ar: "المحرك",
        },
        data: "1.5L",
      },
      {
        title: {
          en: "Horse Power",
          ar: "القدرة الحصانية",
        },
        data: "103",
      },
      {
        title: {
          en: "Fuel Efficiency",
          ar: "كفاءة الوقود",
        },
        data: "19.4 KMPL",
      },
      {
        title: {
          en: "Fuel Tank Capacity",
          ar: "سعة خزان الوقود",
        },
        data: "43L",
      },
    ],
    brochure: CiazBrochure,
    shortDescription: {
      en: "Sophisticated Style, Seamless Performance, Elevating Every Drive.",
      ar: "أسلوب راقي، أداء سلس، يرتقي بكل رحلة.",
    },

    tagline: {
      en: "Sleek. Comfortable. Refined.",
      ar: "أنيق. مريح. راقي.",
    },
    View360Image: Ciaz360ViewImage,
    exteriorImages: [
      CiazExterior1,
      CiazExterior2,
      CiazExterior3,
      CiazExterior4,
    ],
    exteriorTitle: "Up Your Game.",
    exteriorDescription1: {
      en: "Suzuki Ciaz’s elegant sedan design captivates with its sleek, aerodynamic silhouette and sophisticated front grille featuring subtle chrome accents and distinct projector headlamps.",
      ar: "تصميم سيدان أنيق لسوزوكي سياز يجذب الأنظار بخطوطه الديناميكية الهوائية وشبكه الأمامي الأنيق الذي يتميز بتفاصيل كرومية دقيقة ومصابيح بروجكتور مميزة.",
    },

    exteriorDescription2: {
      en: "Refined shoulder lines flow gracefully from front to rear, enhancing its modern, premium appeal.",
      ar: "خطوط الكتف المكررة تنساب بنعومة من الأمام إلى الخلف، مما يعزز جاذبيتها الحديثة والراقية.",
    },

    interiorImages: [
      CiazInteriorBanner,
      CiazInterior1,
      CiazInterior2,
      CiazInterior3,
      CiazInterior4,
    ],
    interiorContents: [
      {
        title: {
          en: "Display Audio",
          ar: "عرض الصوت",
        },
        description: {
          en: "The audio system features a touch-panel display as intuitive as a smartphone. Connect your smartphone via Bluetooth®* or USB cable to use your apps through the display.",
          ar: "يتميز نظام الصوت بشاشة تعمل باللمس بديهية مثل الهاتف الذكي. قم بتوصيل هاتفك الذكي عبر Bluetooth®* أو كابل USB لاستخدام تطبيقاتك من خلال الشاشة.",
        },
      },
      {
        title: {
          en: "Luxurious Interiors",
          ar: "مقصورة داخلية فاخرة",
        },
        description: {
          en: "The interior of the Ciaz introduces a new dimension in sportiness and elegance that reflects the exterior design. The high-quality instrument panel and upholstery, a wide range of advanced feature and amenity enhance travelling comfort and convenience.",
          ar: "المقصورة الداخلية لسياز تقدم بُعدًا جديدًا من الرياضة والأناقة التي تعكس التصميم الخارجي. لوحة العدادات عالية الجودة والتنجيد، ومجموعة واسعة من الميزات المتقدمة ووسائل الراحة تعزز راحة السفر والراحة.",
        },
      },
      {
        title: {
          en: "Boot Space",
          ar: "مساحة الأمتعة",
        },
        description: {
          en: "A huge storage capacity of 480 liters in Ciaz means space for all and space for all occasions. The electromagnetic latch and boot light enable the passengers, added comfort and loading, unloading and sorting through the luggage too.",
          ar: "سعة تخزين ضخمة تبلغ 480 لتر في سياز تعني مساحة للجميع ولكل المناسبات. يوفر القفل الكهربائي وضوء الصندوق الراحة الإضافية للركاب، وتسهيل عملية التحميل والتفريغ وترتيب الأمتعة.",
        },
      },
      {
        title: {
          en: "Rear AC Vents & 12V Accessory Socket",
          ar: "فتحات تهوية خلفية لمكيف الهواء ومقبس ملحقات 12 فولت",
        },
        description: {
          en: "Provides comfortable airflow to passengers in the rear seats, ensuring a pleasant and refreshing journey. Convenient power source for charging devices or connecting accessories, adding versatility to your vehicle's interior.",
          ar: "يوفر تدفق هواء مريح للركاب في المقاعد الخلفية، مما يضمن رحلة ممتعة ومنعشة. مصدر طاقة مريح لشحن الأجهزة أو توصيل الملحقات، مما يضيف مرونة إلى داخلية مركبتك.",
        },
      },
    ],
    features: [
      {
        image: CiazFeature1,
        title: {
          en: "ABS (Anti-Lock-Braking System)",
          ar: "نظام المكابح المانع للانغلاق (ABS)",
        },
        description: {
          en: "Helps prevent wheel lock-up during emergency braking, ensuring better vehicle control and shorter stopping distances on slippery or uneven road surfaces.",
          ar: "يساعد في منع انغلاق العجلات أثناء الكبح الطارئ، مما يضمن تحكمًا أفضل في المركبة ومسافات توقف أقصر على الطرق الزلقة أو غير المستوية.",
        },
      },
      {
        image: CiazFeature2,
        title: {
          en: "TECT (Total Effective Control Technology) BODY",
          ar: "هيكل TECT (تقنية التحكم الفعال الكلي)",
        },
        description: {
          en: "The benefits of structural analysis with CAE technologies are complemented by effective use of high-tensile steel for major parts to make the body light and outstanding crashworthy.",
          ar: "فوائد التحليل الهيكلي بتقنيات CAE تتكامل مع الاستخدام الفعال للفولاذ العالي الشد للأجزاء الرئيسية لجعل الجسم خفيفًا وذو مقاومة تصادمية ممتازة.",
        },
      },
      {
        image: CiazFeature3,
        title: {
          en: "Airbags",
          ar: "وسائد هوائية",
        },
        description: {
          en: "As a supplemental restraint system to the seatbelts, the airbags contribute to mitigating the collision force inflicted on the head and chest.",
          ar: "كنظام تقييد تكميلي لأحزمة الأمان، تساهم الوسائد الهوائية في تخفيف قوة التصادم المؤثرة على الرأس والصدر.",
        },
      },
    ],
    specSubtitle: {
      en: "What makes Ciaz convenient bold?",
      ar: "ما الذي يجعل سياز جريئة ومريحة؟",
    },
    models: [{ type: "GL" }, { type: "GLX" }],
    specifications: {
      features: [
        {
          name: {
            en: "SRS front, side",
            ar: "وسائد هوائية أمامية وجانبية SRS",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "ABS with EBD function, ESP(Electronic Stability Program)",
            ar: "نظام المكابح المانع للانغلاق مع وظيفة التوزيع الإلكتروني لقوة الكبح، ونظام الثبات الإلكتروني (ESP)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "TPMS (Tyre pressure monitoring system)",
            ar: "نظام مراقبة ضغط الإطارات (TPMS)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Parking sensors | Rear",
            ar: "أجهزة استشعار الركن | خلفي",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Immobiliser & Security Alarm",
            ar: "جهاز منع الحركة وإنذار أمني",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Keyless push start system",
            ar: "نظام بدء التشغيل بدون مفتاح",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Seat upholstery material | Genuine and synthetic leather",
            ar: "مادة تنجيد المقاعد | جلد طبيعي وصناعي",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Cruise Control",
            ar: "مثبت السرعة",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Air conditioner",
            ar: "مكيف هواء",
          },
          GL: "Manual",
          GLX: "Auto",
        },
        {
          name: {
            en: "Remote-Control Door Locks",
            ar: "أقفال الأبواب بالتحكم عن بعد",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "With hazard lamp",
            ar: "مع مصباح الخطر",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Sound answer back function",
            ar: "وظيفة الرد الصوتي",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "High-mount stop lamp | LED",
            ar: "مصباح توقف عالي التركيب | LED",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Front seats | Seat height adjuster (driver's side)",
            ar: "المقاعد الأمامية | ضبط ارتفاع المقعد (جانب السائق)",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Power windows | Front & Rears",
            ar: "نوافذ كهربائية | أمامية وخلفية",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Day/Night Rearview Mirror",
            ar: "مرآة رؤية خلفية ليلية/نهارية",
          },
          GL: "Manual",
          GLX: "Auto",
        },
        {
          name: {
            en: "Automatic dimming",
            ar: "تعتيم تلقائي",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "With rearview camera",
            ar: "مع كاميرا الرؤية الخلفية",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "3-spoke steering wheel | Leather covered",
            ar: "عجلة قيادة بثلاثة أذرع | مغطاة بالجلد",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Fog lamps | Front",
            ar: "مصابيح ضباب | أمامية",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        { 
          name: {
          en:"Fog lamps | Rear",
          ar:"مصابيح الضباب | مؤخرة",
        },  
        GL: "Yes", 
        GLX: "Yes" 
      },
        {
          name: {
            en: "Headlamps | LED projector (low/high beam)",
            ar: "المصابيح الأمامية | LED بروجكتور (شعاع منخفض/عالي)",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside door handles | Chrome",
            ar: "مقابض الأبواب الخارجية | كروم",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside door mirrors - With built-in turn signal lamps",
            ar: "مرايا الأبواب الخارجية - مع مصابيح إشارة مدمجة",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside door mirrors",
            ar: "مرايا الأبواب الخارجية",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "Power adjust",
            ar: "تعديل كهربائي",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Power fold",
            ar: "طي كهربائي",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "With built-in turn signal lamp",
            ar: "مع مصباح إشارة مدمج",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Alloy Wheels 16-inches",
            ar: "عجلات سبائك 16 بوصة",
          },
          GL: "Yes (Option)",
          GLX: "Yes",
        },
        // {
        //   name: {
        //     en: "Bluetooth",
        //     ar: "بلوتوث",
        //   },
        //   GL: "Yes",
        //   GLX: "Yes",
        // },
        // {
        //   name: {
        //     en: "USB, AUX & 12V Accessory socket",
        //     ar: "منفذ USB، AUX و12 فولت",
        //   },
        //   GL: "Yes",
        //   GLX: "Yes",
        // },
        // {
        //   name: {
        //     en: "MP3/WMA Compatible CD Player",
        //     ar: "مشغل CD متوافق مع MP3/WMA",
        //   },
        //   GL: "Yes",
        //   GLX: "Yes",
        // },

        {
          name: {
            "en":"MP3/USB/AUX/SW and DAB Radio, Bluetooth - Locally Installed- Made in China",
            "ar":"مدخل MP3/USB/ AUX/SW /بلوتوث - مجهز محليًا صنع في الصين"
          },
          GL:"Yes",
          GLX:"Yes"
        },
      ],
      dimensions: [
        {
          name: {
            en: "Overall length (mm)",
            ar: "الطول الكلي (مم)",
          },
          GL: "4,490",
          GLX: "4,490",
        },
        {
          name: {
            en: "Overall width (mm)",
            ar: "العرض الكلي (مم)",
          },
          GL: "1,730	",
          GLX: "1,730	",
        },
        {
          name: {
            en: "Overall height (mm)",
            ar: "الارتفاع الكلي (مم)",
          },
          GL: "1,475	",
          GLX: "1,475	",
        },
        {
          name: {
            en: "Wheelbase (mm)",
            ar: "قاعدة العجلات (مم)",
          },
          GL: "2,650",
          GLX: "2,650",
        },
        {
          name: {
            en: "Tread(mm)",
            ar: "عرض المسار (مم)",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "Front",
            ar: "الأمام",
          },
          GL: "1,495",
          GLX: "1,495",
        },
        {
          name: {
            en: "Rear",
            ar: "الخلف",
          },
          GL: "1,505",
          GLX: "1,505",
        },
        {
          name: {
            en: "Minimum turning radius (m)",
            ar: "أدنى نصف قطر دوران (م)",
          },
          GL: "5.4",
          GLX: "5.4",
        },
        {
          name: {
            en: "Minimum Ground Clearance (mm)",
            ar: "أدنى خلوص أرضي (مم)",
          },
          GL: "145",
          GLX: "145",
        },
      ],
      capacity: [
        {
          name: {
            en: "Seating capacity (Including Driver)",
            ar: "سعة الجلوس (بما في ذلك السائق)",
          },
          GL: "5",
          GLX: "5",
        },
        {
          name: {
            en: "Luggage capacity*2* 1 Max volume (litres)",
            ar: "سعة الأمتعة*1 / الحجم الأقصى (لترات)",
          },
          GL: "495	",
          GLX: "495	",
        },
        {
          name: {
            en: "Fuel tank capacity (litres)",
            ar: "سعة خزان الوقود (لترات)",
          },
          GL: "43",
          GLX: "43",
        },
      ],
      engine: [
        {
          name: {
            en: "Engine (4 CYL)",
            ar: "المحرك (4 أسطوانات)",
          },
          GL: "1.5 L VVT	",
          GLX: "1.5 L VVT	",
        },
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "K15B	",
          GLX: "K15B	",
        },
        {
          name: {
            en: "Number of cylinders",
            ar: "عدد الأسطوانات",
          },
          GL: "4	",
          GLX: "4	",
        },
        {
          name: {
            en: "Number of valves",
            ar: "عدد الصمامات",
          },
          GL: "16",
          GLX: "16",
        },
        {
          name: {
            en: "Piston displacement (cm3)",
            ar: "إزاحة المكبس",
          },
          GL: "1,462	",
          GLX: "1,462	",
        },
        {
          name: {
            en: "Bore x stroke (mm)",
            ar: "قطر الأسطوانة × الشوط (مم)",
          },
          GL: "74.0 x 85.0	",
          GLX: "74.0 x 85.0	",
        },
        {
          name: {
            en: "Compression ratio",
            ar: "نسبة الانضغاط",
          },
          GL: "10.5",
          GLX: "10.5",
        },
        {
          name: {
            en: "Maximum output (kW/rpm)",
            ar: "القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          },
          GL: "77/6,000	",
          GLX: "77/6,000	",
        },
        {
          name: {
            en: "Maximum torque (N･m/rpm)",
            ar: "أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          },
          GL: "138/4,400	",
          GLX: "138/4,400	",
        },
        {
          name: {
            en: "Fuel distribution	",
            ar: "توزيع الوقود",
          },
          GL: "Multipoint injection	",
          GLX: "Multipoint injection	",
        },
      ],
      transmission: [
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "4AT",
          GLX: "4AT",
        },
        {
          name: {
            en: "Gear Ratio",
            ar: "نسبة التروس",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "1st",
            ar: "1st",
          },
          GL: "2.875	",
          GLX: "2.875	",
        },
        {
          name: {
            en: "2nd	",
            ar: "2nd	",
          },
          GL: "1.568",
          GLX: "1.568",
        },
        {
          name: {
            en: "3rd",
            ar: "3rd",
          },
          GL: "1.000	",
          GLX: "1.000	",
        },
        {
          name: {
            en: "4th",
            ar: "4th",
          },
          GL: "0.697	",
          GLX: "0.697	",
        },

        {
          name: {
            en: "Reverse",
            ar: "الرجوع",
          },
          GL: "2.300",
          GLX: "2.300",
        },
        {
          name: {
            en: "Final Gear Ratio",
            ar: "نسبة الترس النهائي",
          },
          GL: "4.145",
          GLX: "4.145",
        },
      ],
      weight: [
        {
          name: {
            en: "Kerb weight (min./with full option)",
            ar: "الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          },
          GL: "1,030 /1,050	",
          GLX: "1,030 /1,050	",
        },
        {
          name: {
            en: "Gross vehicle weight (kg)	",
            ar: "الوزن الإجمالي للمركبة (كجم)",
          },
          GL: "1,490	",
          GLX: "1,490	",
        },
      ],
    },
  },

  // CIAZ DATA SOURCE STARTS

  // SWIFT DATA SOURCE STARTS
  {
    id: "swift",
    title: {
      en: "Swift",
      ar: "سويفت",
    },
    image: SwiftImage,
    video: SwiftVideo,
    category: "Hatchback",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      // { name: "Blue", image: SwiftBlueBannerImage },
      // { name: "Red", image: SwiftRedBannerImage },
      // { name: "Silver", image: SwiftSilverBannerImage },
      // { name: "Grey", image: SwiftGreyBannerImage },
      // { name: "White", image: SwiftWhiteBannerImage },
      { name: "Black/Blue", hexCode:"#343436/#018DC2", customName: { en:"Speedy Blue Metallic x Super Black Pearl", ar:"أزرق لامع / أسود"}, image: SwiftBlueDualToneBannerImage },
      { name: "Black/Red", hexCode:"#343436/#BB1926", customName: { en:"Burning Red Pearl Metallic x Super Black Pearl", ar:"أحمر فاخر لؤلؤي لامع / أسود"}, image: SwiftRedDualToneBannerImage },
      { name: "Black/Orange", hexCode:"#343436/#C24517", customName: { en:"Frame Orange Pearl Metallic x Super Black Pearl", ar:"برتقالي لؤلؤي لامع / أسود"}, image: SwiftOrangeDualToneBannerImage }
      // { name: "Black/Gold", image: SwiftGoldDualToneBannerImage },
      
    ],
    variants: [
      {
        name: "SWIFT CVT GLX ALLOY",
        price: "72,450",
        items: [],
      },
    ],
    overview: [
      {
        title: {
          en: "Drive Line",
          ar: "خط القيادة",
        },
        data: "FWD",
      },
      {
        title: {
          en: "Engine",
          ar: "المحرك",
        },
        data: "1.2L",
      },
      {
        title: {
          en: "Horse Power",
          ar: "القدرة الحصانية",
        },
        data: "82",
      },
      {
        title: {
          en: "Fuel Efficiency",
          ar: "كفاءة الوقود",
        },
        data: "22.0 KMPL",
      },
      {
        title: {
          en: "Fuel Tank Capacity",
          ar: "سعة خزان الوقود",
        },
        data: "37L",
      },
    ],
    brochure: SwiftBrochure,
    shortDescription: {
      en: "Experience the thrill of the road with Swift, the dynamic hatchback engineered for urban sophistication.",
      ar: "اكتشف إثارة الطريق مع سويفت، الهاتشباك الديناميكي المصمم للأناقة الحضرية.",
    },

    tagline: {
      en: "Sporty. Agile. Stylish.",
      ar: "رياضي. رشيق. أنيق.",
    },

    View360Image: Swift360ViewImage,
    exteriorImages: [SwiftExterior1, SwiftExterior2, SwiftExterior3],
    exteriorTitle: {
      en: "A whole lot of fun.",
      ar: "الكثير من المرح.",
    },

    exteriorDescription1: {
      en: "The Suzuki Swift’s sporty hatchback design is marked by its aggressive front grille, sleek headlamps, and flowing lines.",
      ar: "تصميم الهاتشباك الرياضي لسوزوكي سويفت يتميز بشبك أمامي عدواني، مصابيح أمامية أنيقة، وخطوط انسيابية.",
    },

    exteriorDescription2: {
      en: "Its compact, dynamic profile and athletic stance convey energy and agility, making it perfect for urban driving.",
      ar: "ملفها المضغوط والديناميكي وموقفها الرياضي ينقلان الطاقة والرشاقة، مما يجعلها مثالية للقيادة الحضرية.",
    },

    interiorImages: [
      SwiftInteriorBanner,
      SwiftInterior1,
      SwiftInterior2,
      SwiftInterior3,
    ],
    interiorContents: [
      {
        title: {
          en: "Display Audio",
          ar: "عرض الصوت",
        },
        description: {
          en: "The audio system features a touch-panel display as intuitive as a smartphone. Connect your smartphone via Bluetooth®* or USB cable to use your apps through the display.",
          ar: "يتميز نظام الصوت بشاشة تعمل باللمس بديهية مثل الهاتف الذكي. قم بتوصيل هاتفك الذكي عبر Bluetooth®* أو كابل USB لاستخدام تطبيقاتك من خلال الشاشة.",
        },
      },
      {
        title: {
          en: "Easy-to-see and stylish meter cluster",
          ar: "مجموعة العدادات السهلة الرؤية والأنيقة",
        },
        description: {
          en: "Satin chrome accents the black-toned interior, embellishing two main meters with red and white highlights, resembling chronograph watches. Water temperature and fuel gauges add to the sporty design's visibility and style.",
          ar: "اللمسات الكرومية الساتان تزين الداخلية ذات اللون الأسود، تزين عدادين رئيسيين بلمسات حمراء وبيضاء، تشبه ساعات الكرونوغراف. مقاييس درجة حرارة الماء والوقود تضيف إلى رؤية التصميم الرياضي وأناقته.",
        },
      },
      {
        title: {
          en: "Expansive, versatile cabin",
          ar: "مقصورة واسعة ومتعددة الاستخدامات",
        },
        description: {
          en: "The new Swift’s plentiful luggage space allows you to take more, and the split, fold-down rear seatbacks (standard on GLX and GL) help you fit what you need. Ample head clearance is provided by low seating positions, and numerous easy-to-use storage spaces are a short reach away.",
          ar: "توفر سويفت الجديدة مساحة واسعة للأمتعة تتيح لك حمل المزيد، والمقاعد الخلفية القابلة للطي (قياسي في GLX و GL) تساعدك على تلبية احتياجاتك. توفر وضعيات الجلوس المنخفضة مساحة كافية للرأس، والعديد من مساحات التخزين السهلة الاستخدام في متناول اليد.",
        },
      },
    ],
    features: [
      {
        image: SwiftFeature1,
        title: {
          en: "Rear Parking Sensors",
          ar: "أجهزة استشعار الركن الخلفية",
        },
        description: {
          en: "Ultrasonic sensors in the rear bumper detect obstacles when in reverse gear, and audible alerts sound to indicate your approximate distance from the obstacles as you approach.",
          ar: "تكتشف أجهزة الاستشعار فوق الصوتية في المصد الخلفي العوائق عند وضع الجير في الرجوع، وتصدر تنبيهات صوتية للإشارة إلى المسافة التقريبية بينك وبين العوائق أثناء الاقتراب.",
        },
      },
      {
        image: SwiftFeature2,
        title: {
          en: "Hill hold control",
          ar: "نظام التحكم في الثبات على المنحدرات",
        },
        description: {
          en: "A hill hold control function makes hill start easier by preventing the car from rolling backward for up to two seconds when the driver moves his or her foot from the brake pedal to the accelerator pedal.",
          ar: "وظيفة التحكم في الثبات على المنحدرات تجعل بدء الحركة على المنحدرات أسهل من خلال منع السيارة من الرجوع للخلف لمدة تصل إلى ثانيتين عندما ينقل السائق قدمه من دواسة الفرامل إلى دواسة الوقود.",
        },
      },
      {
        image: SwiftFeature3,
        title: {
          en: "Airbags",
          ar: "وسائد هوائية",
        },
        description: {
          en: "The six airbags – dual front airbags, side airbags and curtain airbags help protect the driver and passenger in the event of a collision.",
          ar: "الوسائد الهوائية الست - الوسائد الهوائية الأمامية المزدوجة، والوسائد الهوائية الجانبية، والوسائد الهوائية الستارية تساعد في حماية السائق والركاب في حالة التصادم.",
        },
      },
    ],
    specSubtitle: {
      en: "What makes Swift sporty?",
      ar: "ما الذي يجعل سويفت رياضية؟",
    },
    models: [{ type: "GL" }, { type: "GLX" }],
    specifications: {
      features: [
        {
          name: {
            en: "ABS with EBD Function",
            ar: "نظام المكابح المانع للانغلاق مع وظيفة التوزيع الإلكتروني لقوة الفرملة",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "ESP®*1 (Electronic Stability Program)",
            ar: "نظام الثبات الإلكتروني (ESP®*1)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Air Condition",
            ar: "مكيف الهواء",
          },
          GL: "Manual	",
          GLX: "Auto",
        },
        {
          name: {
            en: "SRS front, side & curtain airbags",
            ar: "وسائد هوائية أمامية وجانبية وستارية SRS",
          },
          GL: "2",
          GLX: "6",
        },
        {
          name: {
            en: "DRL (Daytime running light)-LED Mounted on Front bumper",
            ar: "DRL (ضوء النهار) - LED مثبت على المصد الأمامي",
          },
          GL: "Yes",
          GLX: "--",
        },
        {
          name: {
            en: "DRL (Daytime running light)-LED Integrated in Head lamps",
            ar: "مصابيح القيادة النهارية (DRL)-LED مدمجة في المصابيح الأمامية",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Parking Rear sensors(4)",
            ar: "أجهزة استشعار الركن الخلفية (4)",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Brake Assist Function",
            ar: "وظيفة مساعدة الفرامل",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Central Door Locking",
            ar: "قفل الأبواب المركزي",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Cruise Control",
            ar: "مثبت السرعة",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Front and Rear Fog Lamps",
            ar: "مصابيح الضباب الأمامية والخلفية",
          },
          GL: "Optional",
          GLX: "Yes",
        },
        {
          name: {
            en: "Headlamps LED projector",
            ar: "المصابيح الأمامية بروجكتور LED",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Keyless Push Start System",
            ar: "نظام بدء التشغيل بدون مفتاح",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "High-mount stop lamp | LED",
            ar: "مصباح توقف عالي التركيب | LED",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Driver Seat Height Adjuster",
            ar: "ضبط ارتفاع مقعد السائق",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Power Steering with Tilt",
            ar: "توجيه كهربائي مع إمالة",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Power Windows (Front&Rear)",
            ar: "نوافذ كهربائية (أمامية وخلفية)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Remote - control Door Locks",
            ar: "أقفال الأبواب بالتحكم عن بعد",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Outside Door Mirror-Power fold with built in turn signal lamp",
            ar: "مرآة الباب الخارجية - قابلة للطي كهربائيًا مع مصباح إشارة مدمج",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Telescope - adjustable steering wheel",
            ar: "عجلة قيادة قابلة للتعديل تلسكوبيًا",
          },
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            en: "Tire Pressure Monitoring System (TPMS)",
            ar: "نظام مراقبة ضغط الإطارات (TPMS)",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Alloy wheels",
            ar: "عجلات سبائك",
          },
          GL: "Yes",
          GLX: "Polished",
        },
        {
          name: {
            en: "Audio MP3/WMA compatible CD",
            ar: "نظام صوتي متوافق مع MP3/WMA،",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Bluetooth, Speakers",
            ar: "بلوتوث، مكبرات صوت",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            en: "Immobilizer and security alarm",
            ar: "جهاز منع الحركة وإنذار أمني",
          },
          GL: "Yes",
          GLX: "Yes",
        },
        
        {
          name: {
            "en":"USB, AUX and Bluetooth Connectivity- Locally Installed",
            "ar":"مدخل USB, AUXوبلوتوث - مجهز محليًا"
          },
          GL:"Yes",
          GLX:"Yes"
        },
      ],
      dimensions: [
        {
          name: {
            en: "Overall length (mm)",
            ar: "الطول الكلي (مم)",
          },
          GL: "3,845",
          GLX: "3,845",
        },
        {
          name: {
            en: "Overall width (mm)",
            ar: "العرض الكلي (مم)",
          },
          GL: "1,735	",
          GLX: "1,735	",
        },
        {
          name: {
            en: "Overall height (mm)",
            ar: "الارتفاع الكلي (مم)",
          },
          GL: "1,495",
          GLX: "1,495",
        },
        {
          name: {
            en: "Wheelbase (mm)",
            ar: "قاعدة العجلات (مم)",
          },
          GL: "2,450	",
          GLX: "2,450	",
        },
        {
          name: {
            en: "Tread(mm)",
            ar: "عرض المسار (مم)",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "Front",
            ar: "الأمام",
          },
          GL: "1,520",
          GLX: "1,520",
        },
        {
          name: {
            en: "Rear",
            ar: "الخلف",
          },
          GL: "1,520",
          GLX: "1,520",
        },
        {
          name: {
            en: "Minimum turning radius (m)",
            ar: "أدنى نصف قطر دوران (م)",
          },
          GL: "4.8",
          GLX: "4.8",
        },
        {
          name:{
            en:"Minimum Ground Clearance (mm)",
            ar:"أدنى خلوص أرضي (مم)"
          },
          GL:"120",
          GLX:"120",
        }
      ],
      capacity: [
        {
          name: {
            "en":"Luggage capacity*1 / Max.volume (litres)",
            "ar":"سعة الأمتعة*1 / الحجم الأقصى (لترات)",
          },
          GL:"947",
          GLX:"947"
        },
        {
          name: {
            en: "Seating capacity (Including Driver)",
            ar: "سعة الجلوس (بما في ذلك السائق)",
          },
          GL: "5",
          GLX: "5",
        },
        {
          name: {
            en: "Fuel tank capacity (litres)",
            ar: "سعة خزان الوقود (لترات)",
          },
          GL: "37	",
          GLX: "37	",
        },
      ],
      engine: [
        {
          name: {
            en: "Engine (4 CYL)",
            ar: "المحرك (4 أسطوانات)",
          },
          GL: "1.2L	",
          GLX: "1.2L	",
        },
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "K12M	",
          GLX: "K12M	",
        },
        {
          name: {
            en: "Number of cylinders",
            ar: "عدد الأسطوانات",
          },
          GL: "4	",
          GLX: "4	",
        },
        {
          name: {
            en: "Number of valves",
            ar: "عدد الصمامات",
          },
          GL: "16",
          GLX: "16",
        },
        {
          name: {
            en: "Piston displacement (cm3)",
            ar: "إزاحة المكبس (سم مكعب)",
          },
          GL: "1,197	",
          GLX: "1,197	",
        },
        {
          name: {
            en: "Bore x stroke (mm)",
            ar: "قطر الأسطوانة × الشوط (مم)",
          },
          GL: "73 x 71.5	",
          GLX: "73 x 71.5	",
        },
        {
          name: {
            en: "Compression Ratio",
            ar: "نسبة الانضغاط",
          },
          GL: "11.0",
          GLX: "11.0",
        },
        {
          name: {
            en: "Maximum output (kW/rpm)",
            ar: "القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          },
          GL: "61/6000	",
          GLX: "61/6000	",
        },
        {
          name: {
            en: "Maximum torque (N･m/rpm)",
            ar: "أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          },
          GL: "113/4200	",
          GLX: "113/4200",
        },
        {
          name: {
            en: "Fuel distribution",
            ar: "توزيع الوقود",
          },
          GL: "Multipoint injection	",
          GLX: "Multipoint injection	",
        },
      ],
      transmission: [
        {
          name: {
            en: "Type",
            ar: "نوع",
          },
          GL: "CVT",
          GLX: "CVT",
        },
        {
          name: {
            en: "Gear Ratio",
            ar: "نسبة التروس",
          },
          GL: "",
          GLX: "",
        },
        {
          name: {
            en: "1st	",
            ar: "1st	",
          },
          GL: "4.006",
          GLX: "4.006",
        },
        {
          name: {
            en: "2nd	",
            ar: "2nd	",
          },
          GL: "Low",
          GLX: "Low",
        },
        {
          name: {
            en: "3rd	",
            ar: "3rd	",
          },
          GL: "Low",
          GLX: "Low",
        },
        {
          name: {
            en: "4th",
            ar: "4th",
          },
          GL: "4.006",
          GLX: "4.006",
        },
        {
          name: {
            en: "5th",
            ar: "5th",
          },
          GL: "2.200",
          GLX: "0.550",
        },
        {
          name: {
            en: "Reverse",
            ar: "الرجوع",
          },
          GL: "3.771",
          GLX: "3.771",
        },
        {
          name: {
            en: "Final Gear Ratio",
            ar: "نسبة الترس النهائي",
          },
          GL: "3.757	",
          GLX: "3.757	",
        },
      ],
      weight: [
        {
          name: {
            en: "Kerb weight (min./with full option)",
            ar: "الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          },
          GL: "900-925	",
          GLX: "900-925",
        },
        {
          name: {
            en: "Gross vehicle weight (kg)",
            ar: "الوزن الإجمالي للمركبة (كجم)",
          },
          GL: "1,365	",
          GLX: "1,365",
        },
      ],
    },
  },

  // FRONX DATA SOURCE STARTS
  {
    id: "fronx",
    title: {
      en: "Fronx",
      ar: "فرونكس",
    },
    image: FronxImage,
    video: FronxVideo,
    category: "suv-crossover",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      { name: "Black/Orange", hexCode:"#343436/#EC6233", customName: { en:"Lucent Orange Pearl Metallic x Bluish Black Pearl", ar:"برتقالي لؤلؤي لامع / أسود"}, image: FronxOrangeDualBannerImage },
      { name: "Black/Red", hexCode:"#343436/#BB1926", customName: { en:"Opulent Red Pearl Metallic x Bluish Black Pearl", ar:"أحمر فاخر لؤلؤي لامع / أسود"}, image: FronxRedDualBannerImage },
      { name: "Black/Silver", hexCode:"#343436/#BBC4CB", customName: { en:"Splendid Silver Pearl Metallic x Bluish Black Pearl", ar:"رمادي فاخر  لؤلؤي لامع / أسود"}, image: FronxSilverDualBannerImage },
      { name: "Blue", hexCode:"#064D8B", customName: { en:"Celestial Blue Pearl Metallic", ar:"أزرق لؤلؤي لامع"}, image: FronxBlueBannerImage },
      { name: "Black", hexCode:"#343436", customName: { en:"Bluish Black Pearl", ar:"أسود لؤلؤي داكن"}, image: FronxBlackBannerImage },
      { name: "Silver", hexCode:"#BBC4CB", customName: { en:"Splendid Silver Pearl Metallic", ar:"فضي فاخر لؤلؤي لامع"}, image: FronxSilverBannerImage },
      { name: "Grey", hexCode:"#6A6B70", customName: { en:"Grandeur Grey Pearl Metallic", ar:"رمادي فاخر لؤلؤي لامع"}, image: FronxGreyBannerImage },
      { name: "White", hexCode:"#E3E3E3", customName: { en:"Arctic White Pearl", ar:"لؤلؤة بيضاء قطبية"}, image: FronxWhiteBannerImage },
      { name: "Orange", hexCode:"#EC6233", customName: { en:"Lucent Orange Pearl Metallic", ar:"برتقالي لؤلؤي لامع"}, image: FronxOrangeBannerImage }
    ],
    variants: [
      {
        name: "FRONX GL ALLOY WHEELS",
        price: "69,000",
        items: [
          {
            en:"7-inch Display Audio",
            ar:"شاشة صوت مقاس 7 بوصات"
          },

          {
            en:"Rear View Camera",
            ar:"كاميرا للرؤية الخلفية"
          },

          {
            en:"Roof Rail Garnish - Black",
            ar:"ماسك حقائب - أسود"
          },

          {
            en:"Outside Rear View Mirrors - Power Adjustable & Foldable",
            ar:"وقابلة للطي مرآة الرؤية الخلفية الخارجية - قابلة للتعديل كهربائيًا"
          },
          
        ],
      },
      {
        name: "FRONX GLX ALLOY WHEELS",
        price: "74,750",
        items: [ 
        {
          en:"9-inch Display Audio",
          ar:"شاشة صوت مقاس 9 بوصات "
        },
        {
          en:"360 View Camera",
          ar:"كاميرا بزاوية 360 درجة"
        },
        {
          en:"Roof Rail Garnish - Silver",
          ar:"ماسك حقائب - فضي"
        },
        {
          en:"Keyless Push Engine Start",
          ar:"تشغيل المحرك بضغطة زر"
        },
        {
          en:"Head-up Display",
          ar:"شاشة عرض علوية"
        },
        {
          en: "Wireless Charger",
          ar:"شاحن لاسلكي"
        },
        ],
      },
    ],
    overview: [
      {
        title: {
          en: "Drive Line",
          ar: "خط القيادة",
        },
        data: "FWD",
      },
      {
        title: {
          en: "Engine",
          ar: "المحرك",
        },
        data: "1.5L",
      },
      {
        title: {
          en: "Horse Power",
          ar: "القدرة الحصانية",
        },
        data: "101",
      },
      {
        title: {
          en: "Fuel Efficiency",
          ar: "كفاءة الوقود",
        },
        data: "21.1 KMPL",
      },
      {
        title: {
          en: "Fuel Tank Capacity",
          ar: "سعة خزان الوقود",
        },
        data: "37L",
      },
    ],
    brochure: FronxBrochure,
    shortDescription: {
      "en":"Explore the urban jungle with Fronx, designed to navigate city streets and beyond with ease.",
      "ar":"استكشف الأدغال الحضرية مع فرونكس، المصمم للتنقل بسهولة في شوارع المدينة وما بعدها.",
    },
      
    tagline: {
      "en":"Compact. Versatile. Trustworthy.",
      "ar":"مضغوط. متعدد الاستخدامات. موثوق.",
    } ,
    View360Image: Fronx360ViewImage,
    exteriorImages: [
      FronxExterior1,
      FronxExterior2,
      FronxExterior3,
      FronxExterior4,
    ],
    exteriorTitle: {
      "en":"Vibe Different",
      "ar":"اجعل أسلوبك مختلفًا",
    }, 
    exteriorDescription1: {
      "en":"The Suzuki Fronx’s sporty crossover design stands out with its prominent front grille, muscular wheel arches, and stylish LED headlamps.",
      "ar":"تصميم الكروس أوفر الرياضي لسوزوكي فرونكس يبرز بشبكه الأمامي البارز، أقواس العجلات العضلية، والمصابيح الأمامية LED الأنيقة.",
    },
      
    exteriorDescription2: {
      "en":"Dynamic lines and a raised stance give it a robust, adventurous persona, perfect for urban and off-road escapades.",
      "ar":"الخطوط الديناميكية والموقف المرتفع تمنحها شخصية قوية ومغامرة، مما يجعلها مثالية للمغامرات الحضرية وخارج الطريق.",
    },
      
    interiorImages: [
      FronxInteriorBanner,
      FronxInterior1,
      FronxInterior2,
      FronxInterior3,
      FronxInterior4,
      FronxInterior5,
    ],
    interiorContents: [
      {
        title: {
          "en":"Display Audio",
          "ar":"عرض الصوت",
        }, 
        description: {
          "en":"The 9-inch HD display audio with capacitive touchscreen is highly functional and responsive. It supports both Apple CarPlay and Android Auto smartphone connectivity via USB or Wi-Fi and well as voice recognition, Bluetooth music playback, and the display of various vehicle status.",
          "ar":"نظام عرض الصوت بحجم 9 بوصات عالي الدقة مع شاشة تعمل باللمس بالسعة يتميز بوظائف عالية واستجابة سريعة. يدعم الاتصال بالهاتف الذكي عبر Apple CarPlay و Android Auto عبر USB أو Wi-Fi وكذلك التعرف على الصوت، تشغيل الموسيقى عبر البلوتوث، وعرض حالة المركبة المختلفة.",
        },
          
      },

      {
        title: {
          "en":"Wireless Charger (GLX only)",
          "ar":"شاحن لاسلكي (فقط في GLX)",
        }, 
        description: {
          "en":"Stay connected throughout your drive by keeping your smartphone charged. A wireless charger for compatible smartphones is integrated into the front lower tray.",
          "ar":"ابق متصلاً طوال القيادة من خلال شحن هاتفك الذكي. يتم دمج شاحن لاسلكي للهواتف الذكية المتوافقة في الدرج السفلي الأمامي.",
        },
          
      },

      {
        title: {
          "en":"Head-up display (GLX only)",
          "ar":"شاشة عرض علوية (فقط في GLX)",
        }, 
        description: {
          "en":"The combiner type head-up display keeps you focused on the road by projecting information of the instrument cluster and navigation onto the combiner.",
          "ar":"شاشة العرض العلوية من النوع المدمج تبقيك مركّزًا على الطريق عن طريق عرض معلومات مجموعة العدادات والملاحة على المدمج.",
        },
          
      },

      {
        title: {
          "en":"Comfort & Convenience",
          "ar":"الراحة والملاءمة"
        }, 
        description: {
          "en":"The Fronx stands out with its smartly designed interior. A spacious cabin ensures comfort for all while multiple storage amenities give you space for everyday items.",
          "ar":"يتميز فرونكس بمقصورته المصممة بذكاء. تضمن المقصورة الواسعة الراحة للجميع بينما توفر العديد من مرافق التخزين مساحة للأغراض اليومية.",
        },
          
      },

      {
        title: {
          "en":"360º View Camera",
          "ar":"كاميرا عرض 360 درجة",
        }, 
        description: {
          "en":"Get the best view whether you’re driving or parking with the 360 View Camera that guides you and shows your car from all angles. Giving you convenience and assurance, so that there’s never a second thought.",
          "ar":"احصل على أفضل رؤية سواء كنت تقود أو تركن السيارة باستخدام كاميرا عرض 360 درجة التي ترشدك وتعرض سيارتك من جميع الزوايا. تمنحك الراحة والاطمئنان، حتى لا تضطر للتفكير مرتين.",
        },
          
      },
    ],
    features: [
      {
        image: FronxFeature1,
        title: {
          "en":"Hill hold control",
          "ar":"نظام التحكم في الثبات على المنحدرات"
        }, 
        description: {
          "en":"Hill hold control is designed to prevent the vehicle from rolling backwards during standing starts on slopes. The system engage the brakes up to two seconds after the driver removes pressure from the brake pedal to depress the accelerator.",
          "ar":"تم تصميم نظام التحكم في الثبات على المنحدرات لمنع المركبة من الرجوع للخلف أثناء بدء الحركة على المنحدرات. يعمل النظام على تفعيل الفرامل لمدة تصل إلى ثانيتين بعد أن يرفع السائق قدمه عن دواسة الفرامل ويضغط على دواسة الوقود.",
        },
          
      },

      {
        image: FronxFeature2,
        title: {
          "en":"360º View Camera",
          "ar":"كاميرا عرض 360 درجة",
        }, 
        description: {
          "en":"Parking is now easier with the Fronx's 360 view camera mounted at the front, side and rear to provide a real-time view of the area surrounding the vehicle.",
          "ar":"أصبح الركن الآن أسهل مع كاميرا عرض 360 درجة المثبتة في فرونكس في الأمام والجوانب والخلف لتوفير رؤية في الوقت الحقيقي للمنطقة المحيطة بالمركبة.",
        },
          
      },

      {
        image: FronxFeature3,
        title: {
          "en":"Airbags",
          "ar":"وسائد هوائية",
        }, 
        description: {
          "en":"The Fronx is equipped with dual front and side airbags, that help keep you and your co-passenger safe. In addition, curtain airbags are present for the driver and passengers in the front and rear, on both sides of the seat.",
          "ar":"تم تجهيز فرونكس بوسائد هوائية أمامية وجانبية مزدوجة تساعد في الحفاظ على سلامتك وسلامة الراكب المرافق لك. بالإضافة إلى ذلك، تتوفر وسائد هوائية ستائرية للسائق والركاب في الأمام والخلف، على جانبي المقعد.",
        },
          
      },
    ],
    specSubtitle: {
      "en":"What makes Fronx functional?",
      "ar":"ما الذي يجعل فرونكس عمليًا؟",
    }, 
    models: [{ type: "GL" }, { type: "GLX" }],
    specifications: {
      features: [
        { name: {
          "en":"Keyless push start system",
          "ar":"نظام بدء التشغيل بدون مفتاح",
        },  GL: "--", GLX: "Yes" },
        { name: {
          "en":"Keyless entry system",
          "ar":"نظام الدخول بدون مفتاح",
        },  GL: "--", GLX: "Yes" },
        { name: {
          "en": "Cruise Control",
          "ar":"مثبت السرعة",
        }, GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"SRS front, side & curtain airbags",
          "ar":"وسائد هوائية أمامية وجانبية وستارية SRS",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Roof rails",
          "ar":"قضبان السقف",
        },  GL: "Black", GLX: "Silver" },
        { name: {
          "en":"Air conditioner (Automatic)",
          "ar":"مكيف الهواء (تلقائي)",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Rear A/C Ventilation",
          "ar":"تهوية خلفية لمكيف الهواء",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Remote-control door locks",
          "ar":"أقفال الأبواب بالتحكم عن بعد",
        },  GL: "Yes", GLX: "Yes" },
        {
          name: {
            "en":"Audio Display Screen (Apple CarPlay, Android Auto)",
            "ar":"شاشة عرض الصوت (Apple CarPlay، Android Auto)",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"7inch Display Audio + Bluetooth®* + Rearview camera",
            "ar":"شاشة صوت مقاس 7 بوصات + تقنية Bluetooth®* + كاميرا للرؤية الخلفية",
          }, 
          GL: "Yes",
          GLX: "--",
        },
        {
          name: {
            "en":"9inch Display Audio + Bluetooth®* + 360 view camera",
            "ar":"شاشة صوت مقاس 9 بوصات + تقنية Bluetooth®* + كاميرا بزاوية 360 درجة",
          }, 
          GL: "--",
          GLX: "Yes",
        },
        { name: {
          "en":"Head up Display	",
          "ar":"شاشة عرض علوية",
        },  GL: "--", GLX: "Yes" },
        { name: {
          "en":"Hill hold control",
          "ar":"نظام التحكم في الثبات على المنحدرات",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Alloy Wheels 16 inch",
          "ar":"عجلات سبائك 16 بوصة",
        },  GL: "Painted", GLX: "Polished" },
        { name: {
          "en":"Parking sensors (Rear)",
          "ar":"أجهزة استشعار الركن (خلفي)",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Immobiliser & Security alarm",
          "ar":"جهاز منع الحركة وإنذار أمني",
        },  GL: "Yes", GLX: "Yes" },
        {
          name: {
            "en":"ABS with EBD Function ESP(Electronic Stability Program)",
            "ar":"نظام المكابح المانع للانغلاق مع وظيفة التوزيع الإلكتروني لقوة الكبح ونظام الثبات الإلكتروني",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        { name: {
          "en":"Headlamps LED Multi-reflector",
          "ar":"مصابيح أمامية LED متعددة العاكسات",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"LED-DRL (Daytime Running Lights)",
          "ar":"مصابيح LED للقيادة النهارية",
        }, GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Power Windows and Center Door Locks",
          "ar":"نوافذ كهربائية وأقفال أبواب مركزية",
        },  GL: "Yes", GLX: "Yes" },
        {
          name: {
            "en":"Outside door mirrors - Power Adjustable - Foldable",
            "ar":"مرايا خارجية قابلة للتعديل كهربائيًا - قابلة للطي",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Outside door mirrors - With built-in turn signal lamps",
            "ar":"مرايا الأبواب الخارجية - مع مصابيح إشارة مدمجة",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Day / night rearview mirror - Dimming",
            "ar":"مرآة الرؤية الخلفية النهارية / الليلية - تعتيم",
          }, 
          GL: "Manual",
          GLX: "Auto",
        },
        { name: {
          "en":"Seat material (Fabric)",
          "ar":"مادة المقاعد (قماش)",
        },  GL: "Yes", GLX: "Yes" },
        {
          name: {
            "en":"Front seats- Seat height adjuster (driver's side)",
            "ar":"مقاعد أمامية - ضبط ارتفاع المقعد (جانب السائق)",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        { name: {
          "en":"Rear seats- 60:40-split single-fold",
          "ar":"المقاعد الخلفية - تقسيم 60:40 وقابلة للطي",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Console armrest",
          "ar":"مسند ذراع الكونسول",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"Wireless Charger",
          "ar":"شاحن لاسلكي",
        },  GL: "--", GLX: "Yes" },
        { name: {
          "en":"USB Charger (Rear x 2)",
          "ar":"شاحن USB (خلفي x 2)",
        },  GL: "--", GLX: "Yes" },
        { name: {
          "en":"4 Speakers",
          "ar":"4 مكبرات صوت",
        },  GL: "Yes", GLX: "Yes" },
        { name: {
          "en":"2 Tweeters",
          "ar":"2 مكبرات صوت عالية التردد",
        },  GL: "--", GLX: "Yes" },
      ],
      dimensions: [
        { name: {
          "en":"Overall length (mm)",
          "ar":"الطول الكلي (مم)",
        },  GL: "3,995", GLX: "3,995" },
        { name: {
          "en":"Overall width (mm)",
          "ar":"العرض الكلي (مم)",
        },  GL: "1,765", GLX: "1,765" },
        { name: {
          "en":"Overall height (mm)",
          "ar":"الارتفاع الكلي (مم)",
        },  GL: "1,550", GLX: "1,550" },
        { name: {
          "en":"Wheelbase (mm)",
          "ar":"قاعدة العجلات (مم)",
        },  GL: "2,520", GLX: "2,520" },
        { name: {
          "en":"Tread(mm)",
          "ar":"عرض المسار (مم)",
        },  GL: "", GLX: "" },
        { name: {
          "en":"Front",
          "ar":"الأمام",
        },  GL: "1,520", GLX: "1,520" },
        { name: {
          "en":"Rear",
          "ar":"الخلف",
        },  GL: "1,530", GLX: "1,530" },
        { name: {
          "en":"Minimum turning radius (m)",
          "ar":"أدنى نصف قطر دوران (م)",
        },  GL: "4.8	", GLX: "4.8	" },
        { name: {
          "en":"Minimum ground clearance (mm)	",
          "ar":"أدنى خلوص أرضي (مم)",
        },  GL: "170	", GLX: "170	" },
      ],
      capacity: [
        { name: {
          "en":"Seating capacity (Including Driver)",
          "ar":"سعة الجلوس (بما في ذلك السائق)",
        },  GL: "5", GLX: "5" },
        {
          name: {
            "en":"Luggage capacity*1 / Max.volume (litres)",
            "ar":"سعة الأمتعة*1 / الحجم الأقصى (لترات)",
          }, 
          GL: "1,009	",
          GLX: "1,009	",
        },
        { name: {
          "en":"Fuel tank capacity (litres)",
          "ar":"سعة خزان الوقود (لترات)",
        },  GL: "37", GLX: "37" },
      ],
      engine: [
        { name: {
          "en":"Engine (4 CYL)",
          "ar":"المحرك (4 أسطوانات)",
        },  GL: "1.5L	", GLX: "1.5L	" },
        {
          name: {
            "en":"Type",
            "ar":"نوع",
          }, 
          GL: "K15C + SHVS Mild Hybrid	",
          GLX: "K15C + SHVS Mild Hybrid	",
        },
        { name: {
          "en":"Number of cylinders",
          "ar":"عدد الأسطوانات",
        },  GL: "4	", GLX: "4	" },
        { name: {
          "en":"Number of valves", 
          "ar":"عدد الصمامات",
        }, GL: "16", GLX: "16" },
        { name: {
          "en":"Piston displacement (cm3)	",
          "ar":"إزاحة المكبس (سم مكعب)",
        },  GL: "1,462	", GLX: "1,462	" },
        { name: {
          "en":"Bore x stroke (mm)",
          "ar":"قطر الأسطوانة × الشوط (مم)",
        },  GL: "74 X 85	", GLX: "74 X 85	" },
        { name: {
          "en":"Compression ratio",
          "ar":"نسبة الانضغاط",
        },  GL: "12", GLX: "12" },
        {
          name: {
            "en":"Maximum output (kW/rpm)",
            "ar":"القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          }, 
          GL: "75.8/6,000	",
          GLX: "75.8/6,000	",
        },
        {
          name: {
            "en":"Maximum torque (N･m/rpm)",
            "ar":"أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          }, 
          GL: "136.8/4,400	",
          GLX: "136.8/4,400	",
        },
        {
          name: {
            "en":"Fuel distribution	",
            "ar":"توزيع الوقود",
          }, 
          GL: "Multipoint injection	",
          GLX: "Multipoint injection	",
        },
      ],
      transmission: [
        { name: {
          "en":"Type",
          "ar":"نوع",
        },  GL: "CVT", GLX: "CVT" },
        { name: {
          "en":"Gear Ratio",
          "ar":"نسبة التروس",
        },  GL: "", GLX: "" },
        { name: {
          "en":"1st",
          "ar":"1st",
        },  GL: "4.667	", GLX: "4.667	" },
        { name: {
          "en":"2nd",
          "ar":"2nd",
        },  GL: "2.533	", GLX: "2.533	" },
        { name: {
          "en":"3rd", 
          "ar":"3rd", 
        }, GL: "1.556", GLX: "1.556" },
        { name: {
          "en":"4th",
          "ar":"4th",
        },  GL: "1.135	", GLX: "1.135	" },
        { name: {
          "en":"5th",
          "ar":"5th",
        },  GL: "0.859	", GLX: "0.859	" },
        { name: {
          "en":"6th",
          "ar":"6th",
        },  GL: "0.686", GLX: "0.686" },
        { name: {
          "en": "Reverse",
          "ar":"الرجوع",
        }, GL: "3.394	", GLX: "3.394" },
        { name: {
          "en":"Final Gear Ratio",
          "ar":"نسبة الترس النهائي",
        },  GL: "3.683	", GLX: "3.683	" },
      ],
      weight: [
        {
          name: {
            "en":"Kerb weight (min./with full option)",
            "ar":"الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          }, 
          GL: "1,055/1,060	",
          GLX: "1,055/1,060	",
        },
        { name: {
          "en":"Gross vehicle weight (kg)",
          "ar":"الوزن الإجمالي للمركبة (كجم)",
        },  GL: "1480	", GLX: "1480	" },
      ],
    },
  },
  // FRONX DATA SOURCE ENDS

  // GRAND VITARA DATA SOURCE STARTS
  {
    id: "grand-vitara",
    title: {
      en: "Grand Vitara",
      ar: "جراند فيتارا",
    },
    image: GrandVitaraImage,
    video: GrandVitaraVideo,
    category: "suv-crossover",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      { name: "Black/Red", hexCode:"#343436/#BB1926", customName: { en:"Opulent Red Pearl Metallic", ar:"أحمر فاخر لؤلؤي لامع"}, image: GrandVitaraRedDualBannerImage },
      { name: "Black/Silver", hexCode:"#343436/#BBC4CB", customName: { en:"Splendid Silver Pearl Metallic", ar:"فضي فاخر لؤلؤي لامع"}, image: GrandVitaraSilverDualBannerImage },
      { name: "Black/White", hexCode:"#343436/#E3E3E3", customName: { en:"Arctic White Pearl + Midnight Black", ar:"لؤلؤة بيضاء قطبية / أسود"}, image: GrandVitaraWhiteDualBannerImage },
      { name: "Blue", hexCode:"#064D8B", customName: { en:"Celestial Blue Pearl Metallic", ar:"أزرق لؤلؤي لامع"}, image: GrandVitaraBlueBannerImage },
      { name: "Black", hexCode:"#343436", customName: { en:"Midnight Black Pearl", ar:"أسود لؤلؤي داكن"}, image: GrandVitaraBlackBannerImage },
      { name: "Silver", hexCode:"#BBC4CB", customName: { en:"Splendid Silver Pearl Metallic", ar:"فضي فاخر لؤلؤي لامع"}, image: GrandVitaraSilverBannerImage },
      { name: "White", hexCode:"#E3E3E3", customName: { en:"Arctic White Pearl", ar:"لؤلؤة بيضاء قطبية"}, image: GrandVitaraWhiteBannerImage },
      { name: "Red", hexCode:"#BB1926", customName: { en:"Opulent Red Pearl Metallic", ar:"أحمر فاخر لؤلؤي لامع"}, image: GrandVitaraRedBannerImage },
      { name: "Brown", hexCode:"#8E7D6D", customName: { en:"Cave Black Pearl Metallic", ar:"بني غامق لؤلؤي لامع"}, image: GrandVitaraBrownBannerImage }
    ],
    variants: [
      {
        name: "GRAND VITARA 2WD GL",
        price: "79,235",
        items: [
          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },

          {
            en:"Rear Fog Lamps",
            ar:" مصابيح خلفية"
          },

          {
            en:"7-inch Display Audio",
            ar:"شاشة صوت مقاس 7 بوصات"
          },

          {
            en:"Rear View Camera",
            ar:"كاميرا للرؤية الخلفية"
          },

          {
            en:"15-inch Steel Wheels with Full Cover",
            ar:"عجلات فولاذية مقاس 15 بوصة مع غطاء كامل"
          },
        ],
      },
      {
        name: "GRAND VITARA 2WD GLX",
        price: "86,135",
        items: [
          {
            en:"9-inch Display Audio",
            ar:"شاشة صوت مقاس 9 بوصات "
          },
          {
            en:"360 View Camera",
            ar:"كاميرا بزاوية 360 درجة"
          },
          {
            en:"Keyless Push Engine Start",
            ar:"تشغيل المحرك بضغطة زر"
          },
          {
            en:"Head-up Display",
            ar:"شاشة عرض علوية"
          },
          {
            en:"Wireless Charger",
            ar:"شاحن لاسلكي"
          },
        ],
      },
      {
        name: "GRAND VITARA 2WD GLX + SR",
        price: "89,585",
        items: ["Includes all GLX specs.", "Panoramic Sunroof"],
      },
      {
        name: "GRAND VITARA 4WD GLX + SR",
        price: "99,935",
        items: [
          {
            en:"Includes all GLX specs.",
            ar:"تشمل جميع مواصفات GLX"
          },

          {
            en:"Four Wheel Drive",
            ar:"دفع رباعي"
          },

          {
            en:"Panaromic Sunroof",
            ar:"سقف بانورامي"
          },
        ],
      },
    ],
    overview: [
      { title: {
        en: "Drive Line",
        ar: "خط القيادة",
      }, data: "FWD" },
      { title: {
        en: "Engine",
        ar: "المحرك",
      }, data: "1.5L" },
      {title: {
        en: "Horse Power",
        ar: "القدرة الحصانية",
      }, data: "102" },
      { title: {
        en: "Fuel Efficiency",
        ar: "كفاءة الوقود",
      }, data: "19.5 - 19.8 KMPL" },
      { title: {
        en: "Fuel Tank Capacity",
        ar: "سعة خزان الوقود",
      }, data: "45L" },
    ],
    brochure: GrandVitaraBrochure,
    shortDescription: {
      "en":"With the Grand Vitara, everything you accomplish will be nothing short of grand.",
      "ar":"مع جراند فيتارا، كل ما تحققه سيكون عظيمًا.",
    },
      
    tagline: {
      "en":"Compact. Versatile. Reliable.",
      "ar":"مضغوط. متعدد الاستخدامات. موثوق.",
    }, 
    View360Image: GrandVitara360ViewImage,
    exteriorImages: [
      GrandVitaraExterior1,
      GrandVitaraExterior2,
      GrandVitaraExterior3,
      GrandVitaraExterior4,
    ],
    exteriorTitle: {
      "en":"Robust. Sophisticated.",
      "ar":"قوي. متطور.",
    },
    exteriorDescription1: {
      "en":"Grand Vitara’s expressive SUV design makes it stand out with the large-sized polygonal front grille in a finely crafted pattern, a bold chrome grille bar and unique 3-point signature lamps.",
      "ar":"يتميز تصميم SUV الجريء لجراند فيتارا بشبك أمامي كبير متعدد الأضلاع بنمط متقن، وشريط كرومي جريء ومصابيح توقيع ثلاثية النقاط فريدة.",
    },
      
    exteriorDescription2: {
      "en":"The strong shoulder lines that run from the front to the rear adds to its rugged persona.",
      "ar":"الخطوط القوية الممتدة من الأمام إلى الخلف تضيف إلى شخصيتها القوية.",
    },
      
    interiorImages: [
      GrandVitaraInteriorBanner,
      GrandVitaraInterior1,
      GrandVitaraInterior2,
      GrandVitaraInterior3,
      GrandVitaraInterior4,
    ],
    interiorContents: [
      {
        title: {
          "en":"Display Audio",
          "ar":"عرض الصوت",
        }, 
        description: {
          "en":"The display audio with a 9-inch HD capacitive touchscreen* delivers a quick and accurate response. It features smartphone linkage for both Apple CarPlay™ and Android Auto™ through USB or Wi-Fi, voice recognition, Bluetooth®** music playback and shows various vehicle status.",
          "ar":"توفر شاشة العرض الصوتية المزودة بشاشة لمس سعوية عالية الدقة مقاس 9 بوصات* استجابة سريعة ودقيقة. وتتميز بوصلة الهاتف الذكي لكل من Apple CarPlay™ وAndroid Auto™ من خلال USB أو Wi-Fi، والتعرف على الصوت، وتشغيل الموسيقى عبر Bluetooth®** و يظهر حالة السيارة المختلفة."
        },
          
      },
      {
        title: {
          "en":"Panoramic Sunroof (GLX only)",
          "ar":"سقف بانورامي (فقط في GLX)",
        }, 
        description: {
          "en":"Double sliding glass panels create an extra-large opening for the panoramic sunroof that can be enjoyed from any seat in the car.",
          "ar":"الألواح الزجاجية المنزلقة المزدوجة تخلق فتحة كبيرة جدًا للسقف البانورامي التي يمكن الاستمتاع بها من أي مقعد في السيارة.",
        },
          
      },
      {
        title: {
          "en":"Head-up Display (GLX only)",
          "ar":"شاشة عرض علوية (فقط في GLX)",
        }, 
        description: {
          "en":"The Grand Vitara is equipped with the combiner type head-up display. It projects an array of information such as speed, intersection navigation and instant fuel economy enabling you to stay focused on the road by reducing eye movements.",
          "ar":"تم تجهيز جراند فيتارا بشاشة عرض علوية من النوع المدمج. تعرض مجموعة من المعلومات مثل السرعة، الملاحة عند التقاطعات واستهلاك الوقود الفوري مما يتيح لك البقاء مركّزًا على الطريق عن طريق تقليل حركات العين.",
        },
          
      },
      {
        title: {
          "en":"Wireless Charger (GLX only)",
          "ar":"شاحن لاسلكي (فقط في GLX)",
        }, 
        description: {
          "en":"You can stay connected by keeping your smartphone charged in the Grand Vitara. It comes with a wireless charger for Qi* compatible smartphone, integrated in the front lower console tray in the center.",
          "ar":"يمكنك البقاء متصلاً من خلال شحن هاتفك الذكي في جراند فيتارا. يأتي مع شاحن لاسلكي للهواتف الذكية المتوافقة مع Qi*، مدمج في الدرج السفلي الأمامي في المنتصف.",
        },
          
      },
    ],
    features: [
      {
        image: GrandVitaraFeature1,
        title: {
          "en": "Hill hold control",
          "ar":"نظام التحكم في الثبات على المنحدرات",
        },
        description: {
          "en":"A hill hold control function makes hill start easier by preventing the car from rolling backward for up to two seconds when the driver moves his or her foot from the brake pedal to the accelerator pedal.",
          "ar":"وظيفة التحكم في الثبات على المنحدرات تجعل بدء الحركة على المنحدرات أسهل من خلال منع السيارة من الرجوع للخلف لمدة تصل إلى ثانيتين عندما ينقل السائق قدمه من دواسة الفرامل إلى دواسة الوقود.",
        },
          
      },
      {
        image: GrandVitaraFeature2,
        title: {
          "en":"Hill descent control*",
          "ar":"نظام التحكم في نزول المنحدرات*",
        }, 
        description: {
          "en":"On tricky descents, the system is designed to automatically apply the brakes and maintain a fixed speed so you can concentrate on steering without using the brake pedal.",
          "ar":"في النزول الصعب، تم تصميم النظام لتفعيل الفرامل تلقائيًا والحفاظ على سرعة ثابتة حتى تتمكن من التركيز على التوجيه دون استخدام دواسة الفرامل.",
        },
          
      },
      {
        image: GrandVitaraFeature3,
        title: {
          "en":"Airbags",
          "ar":"وسائد هوائية",
        }, 
        description: {
          "en":"The six airbags – dual front airbags, side airbags and curtain airbags help protect the driver and passenger in the event of a collision.",
          "ar":"الوسائد الهوائية الست - الوسائد الهوائية الأمامية المزدوجة، والوسائد الهوائية الجانبية، والوسائد الهوائية الستارية تساعد في حماية السائق والركاب في حالة التصادم.",
        },
          
      },
      {
        image: GrandVitaraFeature4,
        title: {
          "en":"Pedestrian injury mitigating body",
          "ar":"هيكل يخفف من إصابات المشاة",
        }, 
        description: {
          "en":"The structure of the Grand Vitara’s bonnet, front windscreen wiper area, front bumper and other parts absorb impacts, with the aim of mitigating head and leg injuries in the event of a collision with a pedestrian.",
          "ar":"هيكل غطاء محرك جراند فيتارا، منطقة مساحات الزجاج الأمامي، المصد الأمامي والأجزاء الأخرى تمتص الصدمات بهدف تخفيف إصابات الرأس والساق في حالة التصادم مع المشاة.",
        },
          
      },
      {
        image: GrandVitaraFeature5,
        title: {
          "en":"ESP®*",
          "ar":"نظام الثبات الإلكتروني (ESP®)",
        }, 
        description: {
          "en":"If Electronic Stability Programme (ESP®) detects wheel slippage when the driver turns the steering wheel sharply or the road is slippery, it automatically adjusts the engine torque and brakes to suppress the wheel slippage and help the driver stay in directional control.",
          "ar":"إذا اكتشف نظام الثبات الإلكتروني (ESP®) انزلاق العجلات عندما يدير السائق عجلة القيادة بشدة أو كان الطريق زلقًا، يقوم تلقائيًا بضبط عزم المحرك والفرامل لقمع انزلاق العجلات ومساعدة السائق في الحفاظ على السيطرة الاتجاهية.",
        },
          
      },
      {
        image: GrandVitaraFeature6,
        title: {
          "en":"Luggage Space",
          "ar":"مساحة الأمتعة",
        }, 
        description: {
          "en":"The large luggage space of 373 litres* adds utility to your needs. It is also equipped with a host of advanced features that gives you a hi-tech experience, enabling you to explore the unknown with more confidence.",
          "ar":"مساحة الأمتعة الكبيرة البالغة 373 لترًا* تضيف فائدة لاحتياجاتك. كما أنها مجهزة بمجموعة من الميزات المتقدمة التي تمنحك تجربة تقنية عالية، مما يتيح لك استكشاف المجهول بثقة أكبر.",
        },
          
      },
    ],
    specSubtitle: {
      "en":"What makes Grand Vitara grand?",
      "ar":"ما الذي يجعل جراند فيتارا عظيمًا؟",
    }, 
    models: [{ type: "GL 2WD" }, { type: "GLX 2WD" }, { type: "GLX 4WD" }],
    specifications: {
      features: [
        {
          name: {
            "en":"Keyless push start system",
            "ar":"نظام بدء التشغيل بدون مفتاح",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Keyless entry system",
            "ar":"نظام الدخول بدون مفتاح",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Cruise Control",
            "ar":"مثبت السرعة",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"SRS front, side & curtain airbags",
            "ar":"وسائد هوائية أمامية وجانبية وستارية SRS",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Roof rails",
            "ar":"قضبان السقف",
          }, 
          "GL 2WD": "Black",
          "GLX 2WD": "Silver",
          "GLX 4WD": "Silver",
        },
        {
          name: {
            "en":"Air conditioner (Automatic)",
            "ar":"مكيف الهواء (تلقائي)",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Sunroof",
            "ar":"فتحة سقف",
          }, 
          "GL 2WD": "--",
          "GLX 2WD": "Optional",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Rear A/C Ventilation",
            "ar":"تهوية خلفية لمكيف الهواء",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Remote-control door locks",
            "ar":"أقفال الأبواب بالتحكم عن بعد",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Audio Display Screen (Apple CarPlay, Android Auto)",
            "ar":"شاشة عرض الصوت (Apple CarPlay، Android Auto)",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },

        {
          name: {
            "en":"7inch Display Audio + Rearview Camera",
            "ar":"شاشة عرض 7 بوصة للصوت + كاميرا للرؤية الخلفية"
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "--",
          "GLX 4WD": "--",
        },
        {
          name: {
            "en":"9inch Display Audio + Surround View Camera",
            "ar":"شاشة عرض صوتية مقاس 9 بوصات + كاميرا رؤية محيطية",
          }, 
          "GL 2WD": "--",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Head up Display",
            "ar":"شاشة عرض علوية",
          }, 
          "GL 2WD": "--",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Hill hold control",
            "ar":"نظام التحكم في الثبات على المنحدرات",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Alloy Wheels",
            "ar":"عجلات معدنية",
          }, 
          "GL 2WD": "Yes (Painted)",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Parking sensors (Rear)",
            "ar":"أجهزة استشعار الركن (خلفي)",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Immobiliser & Security alarm",
            "ar":"جهاز منع الحركة وإنذار أمني",
          },  
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"ABS with EBD Function ESP(Electronic Stability Program)",
            "ar":"نظام المكابح المانع للانغلاق مع وظيفة التوزيع الإلكتروني لقوة الكبح ونظام الثبات الإلكتروني",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Headlamps LED projector",
            "ar":"المصابيح الأمامية بروجكتور LED",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Follow me home system",
            "ar":"نظام تابعني للمنزل",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"LED-DRL (Daytime Running Lights)",
            "ar":"مصابيح LED للقيادة النهارية",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Power Windows and Center Door Locks",
            "ar":"نوافذ كهربائية وأقفال أبواب مركزية",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Outside mirrors - Power Adjustable",
            "ar":"مرايا خارجية - قابلة للتعديل كهربائيًا",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Outside door mirrors - With built-in turn signal lamps",
            "ar":"مرايا الأبواب الخارجية - مع مصابيح إشارة مدمجة",
          },
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Automatic Day / night rearview mirror - Dimming",
            "ar":"مرآة الرؤية الخلفية النهارية / الليلية - تلقائية التعتيم",
          }, 
          "GL 2WD": "--",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Seat material (Synthetic leather)",
            "ar": "مادة المقاعد (جلد صناعي)",
          }, 
          "GL 2WD": "--",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Seat material (Fabric)",
            "ar":"مادة المقاعد (قماش)",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "--",
          "GLX 4WD": "--",
        },
        {
          name: {
            "en":"Front seats- Seat height adjuster (driver's side)",
            "ar":"مقاعد أمامية - ضبط ارتفاع المقعد (جانب السائق)",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Rear seats- 60:40-split single-fold",
            "ar":"المقاعد الخلفية - تقسيم 60:40 وقابلة للطي",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"Console armrest",
            "ar":"مسند ذراع الكونسول",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en": "Wireless Charger",
            "ar":"شاحن لاسلكي",
          },
          "GL 2WD": "--	",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"USB Charger (Rear x 2)",
            "ar":"شاحن USB (خلفي x 2)",
          }, 
          "GL 2WD": "--",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name: {
            "en":"4 Speakers",
            "ar":"4 مكبرات صوت",
          }, 
          "GL 2WD": "Yes",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
        {
          name:{
            "en":"2 Tweeters",
            "ar":"2 مكبرات صوت عالية التردد",
          }, 
          "GL 2WD": "--",
          "GLX 2WD": "Yes",
          "GLX 4WD": "Yes",
        },
      ],
      dimensions: [
        {
          name: {
            "en":"Overall length (mm)",
            "ar":"الطول الكلي (مم)",
          }, 
          "GL 2WD": "4,345	",
          "GLX 2WD": "4,345	",
          "GLX 4WD": "4,345	",
        },
        {
          name: {
            "en": "Overall width (mm)",
            "ar":"العرض الكلي (مم)",
          },
          "GL 2WD": "1,795	",
          "GLX 2WD": "1,795	",
          "GLX 4WD": "1,795	",
        },
        {
          name: {
            "en":"Overall height (mm)",
            "ar":"الارتفاع الكلي (مم)",
          }, 
          "GL 2WD": "1,645	",
          "GLX 2WD": "1,645	",
          "GLX 4WD": "1,645	",
        },
        {
          name: {
            "en":"Wheelbase (mm)",
            "ar":"قاعدة العجلات (مم)",
          }, 
          "GL 2WD": "2,600	",
          "GLX 2WD": "2,600	",
          "GLX 4WD": "2,600	",
        },
        { name: {
          "en":"Tread(mm)",
          "ar":"عرض المسار (مم)",
        },  "GL 2WD": "", "GLX 2WD": "", "GLX 4WD": "" },
        {
          name: {
            "en":"Front",
            "ar":"الأمام"
          },
          "GL 2WD": "1,530	",
          "GLX 2WD": "1,530	",
          "GLX 4WD": "1,530	",
        },
        {
          name: {
            "en":"Rear",
            "ar":"الخلف",
          }, 
          "GL 2WD": "1,535",
          "GLX 2WD": "1,535",
          "GLX 4WD": "1,535",
        },
        {
          name: {
            "en":"Minimum turning radius (m)",
            "ar":"أدنى نصف قطر دوران (م)",
          }, 
          "GL 2WD": "5.4",
          "GLX 2WD": "5.4",
          "GLX 4WD": "5.4",
        },
        {
          name: {
            "en":"Minimum ground clearance (mm)	",
            "ar":"أدنى خلوص أرضي (مم)",
          }, 
          "GL 2WD": "210",
          "GLX 2WD": "210",
          "GLX 4WD": "210",
        },
      ],
      capacity: [
        {
          name: {
            "en":"Seating capacity (Including Driver)",
            "ar":"سعة الجلوس (بما في ذلك السائق)",
          }, 
          "GL 2WD": "5	",
          "GLX 2WD": "5	",
          "GLX 4WD": "5	",
        },
        {
          name: {
            "en":"Luggage capacity*1 / Max.volume (litres)",
            "ar":"سعة الأمتعة*1 / الحجم الأقصى (لترات)",
          }, 
          "GL 2WD": "1,147",
          "GLX 2WD": "1,147",
          "GLX 4WD": "1,147",
        },
        {
          name: {
            "en":"Fuel tank capacity (litres)",
            "ar":"سعة خزان الوقود (لترات)",
          }, 
          "GL 2WD": "45",
          "GLX 2WD": "45",
          "GLX 4WD": "45",
        },
      ],
      engine: [
        {
          name: {
            "en":"Engine (4 CYL)",
            "ar":"المحرك (4 أسطوانات)",
          }, 
          "GL 2WD": "1.5L	",
          "GLX 2WD": "1.5L	",
          "GLX 4WD": "1.5L	",
        },
        {
          name: {
            "en":"Type",
            "ar":"نوع",
          }, 
          "GL 2WD": "K15C + SHVS Mild Hybrid	",
          "GLX 2WD": "K15C + SHVS Mild Hybrid	",
          "GLX 4WD": "K15C + SHVS Mild Hybrid	",
        },
        {
          name: {
            "en":"Number of cylinders	",
            "ar":"عدد الأسطوانات",
          }, 
          "GL 2WD": "4	",
          "GLX 2WD": "4	",
          "GLX 4WD": "4	",
        },
        {
          name: {
            "en":"Number of valves	",
            "ar": "عدد الصمامات",
          }, 
          "GL 2WD": "16	",
          "GLX 2WD": "16	",
          "GLX 4WD": "16	",
        },
        {
          name: {
            "en":"Piston displacement (cm3)	",
            "ar":"إزاحة المكبس (سم مكعب)",
          }, 
          "GL 2WD": "1,462	",
          "GLX 2WD": "1,462	",
          "GLX 4WD": "1,462	",
        },
        {
          name: {
            "en":"Bore x stroke (mm)	",
            "ar":"قطر الأسطوانة × الشوط (مم)",
          }, 
          "GL 2WD": "74 X 85	",
          "GLX 2WD": "74 X 85	",
          "GLX 4WD": "74 X 85	",
        },
        {
          name: {
            "en":"Compression ratio	",
            "ar":"نسبة الانضغاط",
          }, 
          "GL 2WD": "12	",
          "GLX 2WD": "12	",
          "GLX 4WD": "12	",
        },
        {
          name: {
            "en":"Maximum output (kW/rpm)	",
            "ar":"القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          }, 
          "GL 2WD": "75.8/6,000	",
          "GLX 2WD": "75.8/6,000	",
          "GLX 4WD": "75.8/6,000	",
        },
        {
          name: {
            "en":"Maximum torque (N･m/rpm)	",
            "ar":"أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          }, 
          "GL 2WD": "136.8/4,400	",
          "GLX 2WD": "136.8/4,400	",
          "GLX 4WD": "136.8/4,400	",
        },
        {
          name: {
            "en":"Fuel distribution	",
            "ar":"توزيع الوقود",
          }, 
          "GL 2WD": "Multipoint injection	",
          "GLX 2WD": "Multipoint injection	",
          "GLX 4WD": "Multipoint injection	",
        },
      ],
      transmission: [
        { name: {
          "en":"Gear Ratio	",
          "ar":"نسبة التروس",
        },  "GL 2WD": "", "GLX 2WD": "", "GLX 4WD": "" },
        {
          name: {
            "en":"1st	",
            "ar":"1st	",
          }, 
          "GL 2WD": "4.666	",
          "GLX 2WD": "4.666	",
          "GLX 4WD": "4.666	",
        },
        {
          name: {
            "en":"2nd	",
            "ar":"2nd	",
          }, 
          "GL 2WD": "2.533",
          "GLX 2WD": "2.533",
          "GLX 4WD": "2.533",
        },
        {
          name: {
            "en":"3rd",
            "ar":"3rd",
          }, 
          "GL 2WD": "1.555",
          "GLX 2WD": "1.555",
          "GLX 4WD": "1.555",
        },
        {
          name: {
            "en":"4th	",
            "ar":"4th	",
          }, 
          "GL 2WD": "1.135	",
          "GLX 2WD": "1.135",
          "GLX 4WD": "1.135	",
        },
        {
          name: {
            "en":"5th",
            "ar":"5th",
          }, 
          "GL 2WD": "0.859	",
          "GLX 2WD": "0.859",
          "GLX 4WD": "0.859	",
        },
        {
          name: {
            "en":"6th	",
            "ar":"6th	",
          }, 
          "GL 2WD": "0.685	",
          "GLX 2WD": "0.685",
          "GLX 4WD": "0.685	",
        },
        {
          name: {
            "en":"Reverse",
            "ar":"الرجوع",
          }, 
          "GL 2WD": "3.393	",
          "GLX 2WD": "3.393",
          "GLX 4WD": "3.393	",
        },
        {
          name: {
            "en":"Final Gear Ratio	",
            "ar":"نسبة الترس النهائي",
          }, 
          "GL 2WD": "3.683",
          "GLX 2WD": "3.683",
          "GLX 4WD": "3.683",
        },
      ],
      weight: [
        {
          name: {
            "en":"Kerb weight (min./with full option)	",
            "ar":"الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          }, 
          "GL 2WD": "1,190 - 1,220",
          "GLX 2WD": "1,190 - 1,220",
          "GLX 4WD": "1,270 - 1,295",
        },
        {
          name: {
            "en":"Gross vehicle weight (kg)	",
            "ar":"الوزن الإجمالي للمركبة (كجم)",
          }, 
          "GL 2WD": "1,660 - 1,680",
          "GLX 2WD": "1,660 - 1,680",
          "GLX 4WD": "1,730 - 1,755",
        },
      ],
    },
  },
  // GRAND VITARA DATA SOURCE ENDS

  // ERIGA DATA SOURCE STARTS

  {
    id: "ertiga",
    title: {
      en: "Ertiga",
      ar: "إرتيجا",
    },
    image: ErtigaImage,
    video: ErtigaVideo,
    category: "suv-crossover",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      { name: "Grey", hexCode:"#6A6B70", customName: { en:"Magma Grey Metallic", ar:"رمادي فاخر لؤلؤي لامع"}, image: ErtigaGreyBannerImage },
      { name: "Silver", hexCode:"#BBC4CB", customName: { en:"Premium Splendid Silver", ar:"فضي جوهري أنيق"}, image: ErtigaSilverBannerImage },
      { name: "Blue", hexCode:"#38569C", customName: { en:"Premium Oxford Blue", ar:"أزرق لؤلؤي لامع"}, image: ErtigaBlueBannerImage },
      { name: "Brown", hexCode:"#977264", customName: { en:"Premium Dignity Brown", ar:"بني جوهري أنيق"}, image: ErtigaBrownBannerImage },
      { name: "Red", hexCode:"#BB1926", customName: { en:"Premium Auburn Red", ar:"لؤلؤة حمراء كستنائية"}, image: ErtigaRedBannerImage },
      { name: "White", hexCode:"#E3E3E3", customName: { en:"Pearl Arctic White", ar:"لؤلؤة بيضاء قطبية"}, image: ErtigaWhiteBannerImage },
      { name: "Black", hexCode:"#343436", customName: { en:"Bluish Black Pearl", ar:"أسود لؤلؤي داكن"}, image: ErtigaBlackBannerImage },
    ],
    variants: [
      {
        name: "ERTIGA GL AT WHEEL COVER",
        price: "72,450",
        items: [
          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },

          {
            en:"Rear Fog Lamps",
            ar:" مصابيح خلفية"
          },

          {
            en:"Manual Front AC with Rear Vents",
            ar:"مكيف أمامي يدوي مع فتحات خلفية"
          },

          {
            en:"15-inch Steel Wheels with Full Cover",
            ar:"عجلات فولاذية مقاس 15 بوصة مع غطاء كامل"
          },

          {
            en:"Center Console with AUX-in, USB  & Accessory Socket",
            ar:"الكونسول الوسطي مع مدخل AUX وUSB وفتحة ملحقات"
          },
        ],
      },
      {
        name: "ERTIGA GLX ALLOY",
        price: "78,200",
        items: [ 
          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },

          {
            en:"Front & Rear Fog Lamps",
            ar:"مصابيح أمامية وخلفية"
          },

          {
            en:"Automatic Front AC with Rear Vents",
            ar:"مكيف أمامي تلقائي مع فتحات خلفية"
          },

          {
            en:"15-inch Alloy Wheels",
            ar:"عجلات ألمنيوم مقاس 15 بوصة"
          },

          {
            en:"Keyless Push Engine Start",
            ar:"تشغيل المحرك بضغطة زر"
          },

          {
            en:"12V Charging Socket",
            ar:"منفذ شحن 12 فولت"
          },
        ],
      },
    ],
    overview: [
      { title: {
        en: "Drive Line",
        ar: "خط القيادة",
      }, data: "FWD" },
      { title: {
        en: "Engine",
        ar: "المحرك",
      }, data: "1.5L" },
      { title: {
        en: "Horse Power",
        ar: "القدرة الحصانية",
      }, data: "103" },
      { title: {
        en: "Fuel Efficiency",
        ar: "كفاءة الوقود",
      }, data: "17.7 KMPL" },
      { title: {
        en:"Fuel Tank Capacity",
        ar:"سعة خزان الوقود",
      },  data: "45L" },
    ],
    brochure: ErtigaBrochure,
    shortDescription: {
      "en":"A versatile companion ready to accommodate your adventures, from city streets to countryside escapes.",
      "ar":"رفيق متعدد الاستخدامات جاهز لاستيعاب مغامراتك، من شوارع المدينة إلى الهروب إلى الريف.",
    },
      
    tagline: {
      "en":"Functional. Spacious. Efficient.",
      "ar":"عملي. فسيح. فعال.",
    }, 
    View360Image: Ertiga60ViewImage,
    exteriorImages: [
      ErtigaExterior1,
      ErtigaExterior2,
      ErtigaExterior3,
      ErtigaExterior4,
    ],
    exteriorTitle: {
      "en":"Grow with Elegance.",
      "ar":"انمو بأناقة.",
    },
    exteriorDescription1: {
      "en":"The Suzuki Ertiga’s versatile MPV design is highlighted by its bold front grille, sleek LED headlamps, and striking chrome accents.",
      "ar":"يبرز تصميم MPV متعدد الاستخدامات لسوزوكي إرتيجا بشبكه الأمامي الجريء، المصابيح الأمامية LED الأنيقة، واللمسات الكرومية اللافتة.",
    },
      
    exteriorDescription2: {
      "en":"Sculpted lines along the sides enhance its aerodynamic look, while the rear design underscores its practicality and spaciousness.",
      "ar":"الخطوط المنحوتة على الجوانب تعزز مظهرها الديناميكي الهوائي، بينما التصميم الخلفي يؤكد عمليتها وفسيحها.",
    },
      
    interiorImages: [
      ErtigaInteriorBanner,
      ErtigaInterior1,
      ErtigaInterior2,
      ErtigaInterior3,
      ErtigaInterior4,
    ],
    interiorContents: [
      {
        title: {
          "en":"Easy Touch Panel Audio System",
          "ar":"نظام صوت بشاشة لمس سهلة",
        }, 
        description: {
          "en":"The audio system displays vehicle information and links with your smartphone via Bluetooth® for music playback and hands-free calling. What’s more, you can connect it to your smartphone to access maps, messaging, and other supported smartphone functions by voice command or a tap on the audio system touch panel.",
          "ar":"يعرض نظام الصوت معلومات السيارة ويرتبط بهاتفك الذكي عبر Bluetooth® لتشغيل الموسيقى وإجراء المكالمات بدون استخدام اليدين. علاوة على ذلك، يمكنك توصيله بهاتفك الذكي للوصول إلى الخرائط، الرسائل، والوظائف الأخرى المدعومة للهاتف الذكي عبر الأوامر الصوتية أو لمسة على شاشة النظام الصوتي.",
        },
          
      },
      {
        title: {
          "en":"Maximum Configurability",
          "ar":"الحد الأقصى للتكوين",
        }, 
        description: {
          "en":"The Ertiga provides comfortable seating for up to seven people. The seats are arranged in three rows and can be easily reconfigured to suit the needs of the moment, whether you’re carrying your family and friends, recreational gear, or other bulky items. Whenever your seating needs change, the Ertiga offers a simple, practical solution.",
          "ar":"توفر إرتيجا مقاعد مريحة لما يصل إلى سبعة أشخاص. يتم ترتيب المقاعد في ثلاثة صفوف ويمكن إعادة تكوينها بسهولة لتناسب احتياجات اللحظة، سواء كنت تحمل عائلتك وأصدقائك، معدات الترفيه، أو غيرها من الأشياء الضخمة. عندما تتغير احتياجاتك من المقاعد، تقدم إرتيجا حلاً بسيطًا وعمليًا.",
        },
          
      },
      {
        title: {
          "en":"Comfortable Seating",
          "ar":"مقاعد مريحة",
        }, 
        description: {
          "en":"Suzuki Ertiga's sliding seats offer flexible seating arrangements, allowing you to easily adjust the space to accommodate passengers or cargo, maximizing comfort and versatility for every journey.",
          "ar":"مقاعد سوزوكي إرتيجا المنزلقة توفر ترتيبات جلوس مرنة، مما يتيح لك ضبط المساحة بسهولة لاستيعاب الركاب أو الأمتعة، مما يزيد من الراحة والتنوع في كل رحلة.",
        },
          
      },
      {
        title: {
          "en":"Keyless Push Start System",
          "ar":"نظام بدء التشغيل بدون مفتاح",
        }, 
        description: {
          "en":"Ertiga's Keyless Push Start System revolutionizes the driving experience by eliminating the need for traditional keys. With just a push of a button, conveniently located within the vehicle, you can effortlessly start your car, adding a touch of modern sophistication to your journey while enhancing convenience and ease of use.",
          "ar":"نظام بدء التشغيل بدون مفتاح في إرتيجا يغير تجربة القيادة من خلال التخلص من الحاجة إلى المفاتيح التقليدية. بضغطة زر واحدة، الموجود بشكل مريح داخل السيارة، يمكنك بدء سيارتك بسهولة، مما يضيف لمسة من الأناقة العصرية لرحلتك مع تعزيز الراحة وسهولة الاستخدام.",
        },
          
      },
    ],
    features: [
      {
        image: ErtigaFeature1,
        title: {
          "en":"Reverse parking sensor and rearview camera",
          "ar":"مستشعر الركن الخلفي وكاميرا الرؤية الخلفية",
        }, 
        description: {
          "en":"Ultrasonic sensors in the rear bumper detect obstacles when in reverse gear, and audible alerts sound to indicate your approximate distance from the obstacles as you approach.",
          "ar":"تكتشف أجهزة الاستشعار فوق الصوتية في المصد الخلفي العوائق عند وضع الجير في الرجوع، وتصدر تنبيهات صوتية للإشارة إلى المسافة التقريبية بينك وبين العوائق أثناء الاقتراب.",
        },
          
      },
      {
        image: ErtigaFeature2,
        title: {
          "en":"TECT (Total Effective Control Technology) Body",
          "ar":"هيكل TECT (تقنية التحكم الفعال الكلي)",
        }, 
        description: {
          "en":"Employs ultra-high-tensile steel to increase rigidity, lower weight despite larger overall dimensions, and more efficiently absorb and disperse impact energy in the event of a collision.",
          "ar":"يستخدم الفولاذ عالي الشد لزيادة الصلابة، وخفض الوزن على الرغم من الأبعاد الكبيرة، وامتصاص وتوزيع طاقة الصدمة بشكل أكثر فعالية في حالة التصادم.",
        },
          
      },
      {
        image: ErtigaFeature3,
        title: {
          "en":"SRS Airbag System",
          "ar":"نظام الوسائد الهوائية SRS",
        }, 
        description: {
          "en":"The Ertiga comes standard with front SRS airbags for the safety of the driver and front-seat passenger in case of a frontal collision.",
          "ar":"تأتي إرتيجا بشكل قياسي مع وسائد هوائية أمامية SRS لسلامة السائق والراكب الأمامي في حالة التصادم الأمامي.",
        },
          
      },
    ],
    specSubtitle: {
      "en":"What makes Ertiga ergonomic?",
      "ar":"ما الذي يجعل إرتيجا مريحة؟",
    }, 
    models: [{ type: "GL" }, { type: "GLX" }],
    specifications: {
      features: [
        {
          name: {
            "en":"Cruise Control",
            "ar":"مثبت السرعة",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Keyless push start system",
            "ar":"نظام بدء التشغيل بدون مفتاح",
          }, 
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Rear Parking sensors | 4Sensors",
            "ar":"أجهزة استشعار الركن الخلفية | 4 مستشعرات",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"SRS front, side airbags",
            "ar":"وسائد هوائية أمامية وجانبية  SRS",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"ABS with EBD function, ESP(Electronic Stability Program)",
            "ar":"نظام المكابح المانع للانغلاق مع وظيفة التوزيع الإلكتروني لقوة الكبح، ونظام الثبات الإلكتروني (ESP)",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Immobiliser & Security Alarm",
            "ar":"جهاز منع الحركة وإنذار أمني",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Side impact door beams",
            "ar":"دعامات الأبواب الجانبية لمقاومة الصدمات",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"High-mount stop lamp | LED",
            "ar":"مصباح توقف عالي التركيب | LED",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        
        {
          name: {
            "en":"Air conditioner",
            "ar":"مكيف هواء",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Front",
            "ar":"الأمام",
          }, 
          GL: "Manual",
          GLX: "Auto",
        },
        {
          name: {
            "en":"Rear",
            "ar":"الخلف",
          }, 
          GL: "Manual",
          GLX: "Manual",
        },
        {
          name: {
            "en":"Front seats | Seat height adjuster (driver's side)",
            "ar":"المقاعد الأمامية | ضبط ارتفاع المقعد (جانب السائق)",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"2nd-row seats",
            "ar":"مقاعد الصف الثاني",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Slide and recline function",
            "ar":"وظيفة الانزلاق والإمالة",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"60:40-split single-folding",
            "ar":"تقسيم 60:40 وقابلة للطي الفردي",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"3rd-row seats | 50:50-split tilt down-folding",
            "ar":"مقاعد الصف الثالث | تقسيم 50:50 وقابلة للإمالة والطي",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Power windows | Front & Rears",
            "ar":"نوافذ كهربائية | أمامية وخلفية",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Fog lamps | Front",
            "ar":"مصابيح ضباب | أمامية",
          }, 
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Fog lamps | Rear",
            "ar":"مصابيح ضباب | خلفية",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Outside door handles | Chrome",
            "ar":"مقابض الأبواب الخارجية | كروم",
          }, 
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Outside door mirrors | Power Adjust & Fold	- Built in turn signal lamps",
            "ar":"مرايا الأبواب الخارجية | ضبط الطاقة والطي - مصابيح إشارة الانعطاف المدمجة"
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"3-spoke steering wheel | With audio controls",
            "ar":"عجلة قيادة ثلاثية الأضلاع | مع الضوابط الصوتية",
          },
          GL: "Urethane",
          GLX: "Leather covered",
        },
        {
          name: {
            "en":"Instrument panel ornament",
            "ar":"زخرفة لوحة العدادات",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Black horizontal pattern",
            "ar":"نمط أفقي أسود",
          }, 
          GL: "Yes",
          GLX: "--",
        },
        {
          name: {
            "en":"Wooden-pattern",
            "ar":"نمط خشبي",
          }, 
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Remote-control door locks | With hazard lamp answerback function",
            "ar":"أقفال الأبواب بالتحكم عن بعد | مع وظيفة الرد بمصباح الخطر",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Audio",
            "ar":"نظام صوتي",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Smart Phone Linkage Display Audio + Rear View Camera",
            "ar":"نظام عرض الصوت المرتبط بالهاتف الذكي + كاميرا الرؤية الخلفية",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Head-Rest in second row seat",
            "ar":"مسند الرأس في مقعد الصف الثاني"
          }, 
          GL: "3",
          GLX: "3",
        },
        {
          name: {
            "en":"Front & Rear Speakers",
            "ar":"مكبرات صوت أمامية وخلفية",
          }, 
          GL: "Yes",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Tweeters",
            "ar":"مكبرات صوت عالية التردد",
          }, 
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            "en":"USB port, AUX input and 12V accessory socket",
            "ar":"منفذ USB، مدخل AUX ومقبس ملحقات 12V",
          }, 
          GL: "--",
          GLX: "Yes",
        },
        {
          name: {
            "en":"Wheels 15-inches",
            "ar":"عجلات 15 بوصة",
          }, 
          GL: "Steel",
          GLX: "Alloy",
        },
      ],
      dimensions: [
        {
          name: {
            "en":"Overall length (mm)",
            "ar":"الطول الكلي (مم)",
          }, 
          GL: "4,395	",
          GLX: "4,395	",
        },
        {
          name: {
            "en":"Overall width (mm)",
            "ar":"العرض الكلي (مم)",
          }, 
          GL: "1,735	",
          GLX: "1,735	",
        },
        {
          name: {
            "en":"Overall height (mm)",
            "ar":"الارتفاع الكلي (مم)",
          }, 
          GL: "1,690",
          GLX: "1,690",
        },
        {
          name: {
            "en":"Wheelbase (mm)",
            "ar":"قاعدة العجلات (مم)",
          }, 
          GL: "2,740",
          GLX: "2,740",
        },
        {
          name: {
            "en":"Tread(mm)",
            "ar":"عرض المسار (مم)",
          }, 
          GL: "",
          GLX: "",
        },
        {
          name: {
            "en":"Front",
            "ar":"الأمام",
          }, 
          GL: "1,510",
          GLX: "1,510",
        },
        {
          name: {
            "en":"Rear",
            "ar":"الخلف",
          }, 
          GL: "1,520",
          GLX: "1,520",
        },
        {
          name: {
            "en":"Minimum turning radius (m)",
            "ar":"أدنى نصف قطر دوران (م)",
          }, 
          GL: "5.2",
          GLX: "5.2",
        },
        {
          name: {
            "en":"Minimum ground clearance (mm)",
            "ar":"أدنى خلوص أرضي (مم)",
          }, 
          GL: "180",
          GLX: "180",
        },
      ],
      capacity: [
        {
          name: {
            "en":"Luggage capacity*2* 1 Max volume (litres)",
            "ar":"سعة الأمتعة*2* 1 الحجم الأقصى (لترات)",
          },
          GL:"803",
          GLX:"803",
        },

        {
          name: {
            "en":"Seating capacity (Including Driver)",
            "ar":"سعة الجلوس (بما في ذلك السائق)"
          }, 
          GL: "7",
          GLX: "7",
        },

        {
          name: {
            "en": "Fuel tank capacity (litres)",
            "ar":"سعة خزان الوقود (لترات)",
          },
          GL: "45",
          GLX: "45",
        },
      ],
      engine: [
        {
          name: {
            "en":"Engine (4 CYL)",
            "ar":"المحرك (4 أسطوانات)",
          }, 
          GL: "1.5L",
          GLX: "1.5L",
        },
        {
          name: {
            "en":"Type (4AT)",
            "ar":"نوع (4AT) " ,
          }, 
          GL: "K15B",
          GLX: "K15B",
        },
        {
          name: {
            "en":"Number of cylinders	",
            "ar": "عدد الأسطوانات",
          }, 
          GL: "4",
          GLX: "4",
        },
        {
          name: {
            "en":"Number of valves	",
            "ar": "عدد الصمامات",
          }, 
          GL: "16",
          GLX: "16",
        },
        {
          name: {
            "en":"Piston displacement (cm3)	",
            "ar":"إزاحة المكبس (سم مكعب)",
          }, 
          GL: "1,462",
          GLX: "1,462",
        },
        {
          name: {
            "en":"Bore x stroke (mm)	",
            "ar":"قطر الأسطوانة × الشوط (مم)",
          }, 
          GL: "74.0 X 85.0",
          GLX: "74.0 X 85.0",
        },
        {
          name: {
            "en":"Compression Ratio",
            "ar":"نسبة الانضغاط",
          }, 
          GL: "10.5	",
          GLX: "10.5	",
        },
        {
          name: {
            "en":"Maximum output (kW/rpm)	",
            "ar":"القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          }, 
          GL: "77/6,000	",
          GLX: "77/6,000	",
        },
        {
          name: {
            "en":"Maximum torque (N･m/rpm)	",
            "ar":"أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          }, 
          GL: "138/4,400	",
          GLX: "138/4,400	",
        },

        {
          name: {
            "en":"Fuel distribution",
            "ar":"توزيع الوقود",
          }, 
          GL: "Multipoint injection",
          GLX: "Multipoint injection	",
        },
      ],
      transmission: [
        {
          name: {
            "en":"Gear Ratio",
            "ar":"نسبة التروس",
          }, 
          GL: "",
          GLX: "",
        },

        {
          name: {
            "en":"1st",
            "ar":"1st",
          }, 
          GL: "2.875",
          GLX: "2.875",
        },

        {
          name: {
            "en":"2nd",
            "ar":"2nd",
          }, 
          GL: "1.568",
          GLX: "1.568",
        },

        {
          name: {
            "en":"3rd",
            "ar":"3rd",
          }, 
          GL: "1.000	",
          GLX: "1.000	",
        },

        {
          name: {
            "en":"4th",
            "ar":"4th",
          }, 
          GL: "0.697	",
          GLX: "0.697	",
        },

        {
          name: {
            "en":"Reverse",
            "ar":"الرجوع",
          }, 
          GL: "2.300	",
          GLX: "2.300	",
        },

        {
          name: {
            "en":"Final Gear Ratio",
            "ar":"نسبة الترس النهائي",
          },
          GL: "4.375	",
          GLX: "4.375	",
        },
      ],
      weight: [
        {
          name: {
            "en":"Kerb weight (min./with full option)",
            "ar":"الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          }, 
          GL: "1,130 - 1,165",
          GLX: "1,130 - 1,165",
        },
        {
          name: {
            "en":"Gross vehicle weight (kg)",
            "ar":"الوزن الإجمالي للمركبة (كجم)",
          }, 
          GL: "1,720 - 1,735",
          GLX: "1,720 - 1,735",
        },
      ],
    },
  },

  // ERTIGA DATA SOURCE ENDS

    // JIMNY 5 DOORS DATA SOURCE STARTS

    {
      id: "jimny-5-doors",
      title: {
        en: "Jimny 5 Doors",
        ar: "جمــــني 5 أبواب",
      },
      image: Jimny5DoorsImage,
      video: Jimny5DoorsVideo,
      category: "suv-crossover",
      discontinued: false,
      showOnSparePartsPage: true,
      maintenanceOnly: true,
      colors: [
        { name: "Black/Green", hexCode:"#343436/#c0c85b", customName: { en:"Kinetic Yellow + Bluish Black Pearl", ar:"أصفر فاقع / أسود"}, image: Jimny5DoorsGreenDualBannerImage },
        { name: "Black/Ivory", hexCode:"#343436/#bfb498", customName: { en:"Chiffon Ivory Metallic + Bluish Black Pearl", ar:"بيج فاخر لؤلؤي لامع / أسود"}, image: Jimny5DoorsIvoryDualBannerImage },
        { name: "Black/Red", hexCode:"#343436/#BB1926", customName: { en:"Sizzling Red Metallic + Bluish Black Pearl", ar:"أحمر فاخر لؤلؤي لامع / أسود"}, image: Jimny5DoorsRedDualBannerImage },
        { name: "Black", hexCode:"#343436", customName: { en:"Bluish Black Pearl", ar:"أسود لؤلؤي داكن"}, image: Jimny5DoorsBlackBannerImage },
        { name: "Green", hexCode:"#687267", customName: { en:"Jungle Green", ar:"أخضر غابي"}, image: Jimny5DoorsJungleGreenBannerImage },
        { name: "Grey", hexCode:"#6A6B70", customName: { en:"Medium Gray", ar:"رمادي فاخر لؤلؤي لامع"}, image: Jimny5DoorsDarkGreyBannerImage },
        { name: "White", hexCode:"#E3E3E3", customName: { en:"Arctic White Pearl", ar:"لؤلؤة بيضاء قطبية"}, image: Jimny5DoorsWhiteBannerImage },
      ],
      variants: [
        {
          name: "JIMNY 5DR GL 4WD 5MT",
          price: "99,590",
          items: [
            {
              en:"5 Speed Manual Transmission",
              ar:"ناقل حركة يدوي 5 سرعات"
            },
  
            {
              en:"Halogen Headlamps",
              ar:"مصابيح أمامية هالوجينية"
            },
  
            {
              en:"7-inch Display Audio",
              ar:"شاشة صوت مقاس 9 بوصات "
            },
  
            {
              en:"Front Manual AC",
              ar:"مكيف أمامي يدوي"
            },
  
            {
              en:"Front Airbags for Driver & Passenger",
              ar:"وسائد هوائية أمامية للسائق والراكب"
            },
          ],
        },
        {
          name: "JIMNY 5DR GLX 4WD 4AT",
          price: "111,090",
          items: [
            {
              en:"4 Speed Automatic Transmission",
              ar:"ناقل حركة يدوي 4 سرعات"
            },
  
            {
              en:"LED Headlamps",
              ar:"مصابيح أمامية LED"
            },
  
            {
              en:"9-inch Display Audio",
              ar:"شاشة صوت مقاس 9 بوصات "
            },
  
            {
              en:"Front Automatic AC",
              ar:"مكيف أمامي تلقائي"
            },
  
            {
              en:"Dual Front, Side and Curtain Airbags",
              ar:"وسائد هوائية أمامية وجانبية وستائرية"
            },
  
            {
              en:"Front Lamp Washer",
              ar:"غسالة إضاءة أمامية"
            },
          ],
        },
      ],
      overview: [
        { title: {
          en: "Drive Line",
          ar: "خط القيادة",
        }, data: "4WD" },
        { title: {
          en: "Engine",
          ar: "المحرك",
        }, data: "1.5L" },
        { title: {
          en: "Horse Power",
          ar: "القدرة الحصانية",
        }, data: "101" },
        { title: {
          en: "Fuel Efficiency",
          ar: "كفاءة الوقود",
        }, data: "15.7 KMPL" },
        { title: {
          en: "Fuel Tank Capacity",
          ar: "سعة خزان الوقود",
        }, data: "40L" },
      ],
      brochure: Jimny5DoorsBrochure,
      shortDescription: {
        "en":"Embrace off-road adventures with Suzuki Jimny, built to conquer any terrain, from mountains to deserts.",
        "ar":"استمتع بالمغامرات على الطرق الوعرة مع سوزوكي جمني، المصممة للتغلب على أي تضاريس، من الجبال إلى الصحاري."
      },
        
      tagline: {
        "en":"Rugged. Adventurous. Iconic.",
        "ar":"متين. مغامر. أيقوني.",
      },
      View360Image: Jimny5Doors360ViewImage,
      exteriorImages: [
        Jimny5DoorsExterior1,
        Jimny5DoorsExterior2,
        Jimny5DoorsExterior3,
        Jimny5DoorsExterior4,
      ],
      exteriorTitle: {
        "en":"Nobody But Jimny.",
        "ar":"لا أحد غير جمني.",
      }, 
      exteriorDescription1: {
        "en":"The Suzuki Jimny XL’s expanded design retains the iconic boxy shape, featuring a larger front grille, distinctive round headlamps, and robust off-road styling.",
        "ar":"تصميم سوزوكي جمني XL الموسع يحتفظ بالشكل المربع الأيقوني، ويتميز بشبك أمامي أكبر، ومصابيح أمامية مستديرة مميزة، وتصميم متين للطرق الوعرة.",
      },
        
      exteriorDescription2: {
        "en":"Extended dimensions provide extra space and comfort, while maintaining its adventurous and rugged appeal.",
        "ar":"الأبعاد الممتدة توفر مساحة إضافية وراحة، مع الحفاظ على جاذبيتها المغامرة والمتينة.",
      },
        
      interiorImages: [
        Jimny5DoorsInteriorBanner,
        Jimny5DoorsInterior1,
        Jimny5DoorsInterior2,
        Jimny5DoorsInterior3,
        Jimny5DoorsInterior4,
        Jimny5DoorsInterior5,
      ],
      interiorContents: [
        {
          title: {
            "en":"Display Audio",
            "ar":"عرض الصوت",
          }, 
          description: {
            "en":"The display audio system has a customisable touch panel that lets you view vehicle information and live video from the rearview camera. The system also supports Bluetooth(R) for hands-free calling and music playback, and Wi-Fi(R) and USB connectivity for touch panel and voice-activated access to smartphone navigation and messaging apps.",
            "ar":"نظام عرض الصوت يحتوي على لوحة تعمل باللمس قابلة للتخصيص تتيح لك عرض معلومات المركبة والفيديو المباشر من كاميرا الرؤية الخلفية. يدعم النظام أيضًا Bluetooth(R) لإجراء المكالمات بدون استخدام اليدين وتشغيل الموسيقى، والاتصال بشبكة Wi-Fi(R) وUSB للوصول إلى الملاحة والرسائل عبر التطبيقات بالتحكم الصوتي.",
          },
            
        },
  
        {
          title: {
            "en":"Comfortable Seating for 4 Adults",
            "ar":"مقاعد مريحة لأربعة بالغين",
          }, 
          description: {
            "en":"The rear seats have thick cushioning like the front seats, and an optimized design to ensure everyone on board enjoys exploring the wildness in comfort.",
            "ar":"المقاعد الخلفية تحتوي على وسائد سميكة مثل المقاعد الأمامية، وتصميم محسن لضمان استمتاع الجميع بالاستكشاف براحة.",
          },
            
        },
  
        {
          title: {
            "en":"Side-mounted Lamp & Accessory Socket",
            "ar":"مصباح جانبي ومقبس ملحقات",
          }, 
          description: {
            "en":"The side-mounted luggage area lamp provides illumination where it’s needed most, and a convenient socket delivers 12V power for accessories at your campsite.",
            "ar":"مصباح منطقة الأمتعة الجانبي يوفر إضاءة في المكان الأكثر حاجة، ومقبس مريح يوفر طاقة 12V للملحقات في موقع المخيم.",
          },
            
        },
  
        {
          title: {
            "en":"Intuitively Designed Controls",
            "ar":"أدوات تحكم مصممة بشكل بديهي",
          }, 
          description: {
            "en":"Add a thrilling background score while navigating that hairpin bend. The Jimny’s dials and touch-screen controls are designed for easy operation.",
            "ar":"أضف خلفية مثيرة أثناء التنقل في المنعطفات الضيقة. تم تصميم أدوات التحكم في جمني والشاشة التي تعمل باللمس لسهولة التشغيل.",
          },
            
        },
  
        {
          title: {
            "en":"Practical Luggage Area",
            "ar":"منطقة أمتعة عملية",
          }, 
          description: {
            "en":"The ample luggage space offered by the 5-door Jimny can be further expanded by folding down the rear seat backs, allowing you to carry bulky items and additional supplies for extended expeditions.",
            "ar":"مساحة الأمتعة الواسعة التي تقدمها جمني ذات الأبواب الخمسة يمكن توسيعها أكثر عن طريق طي ظهور المقاعد الخلفية، مما يتيح لك حمل الأشياء الضخمة والإمدادات الإضافية للرحلات الطويلة.",
          },
            
        },
      ],
  
      features: [
        {
          image: Jimny5DoorsFeature1,
          title: {
            "en":"Hill hold control",
            "ar":"نظام التحكم في الثبات على المنحدرات",
          }, 
          description: {
            "en":"A hill hold control function makes hill start easier by preventing the car from rolling backward for up to two seconds when the driver moves his or her foot from the brake pedal to the accelerator pedal.",
            "ar":"وظيفة التحكم في الثبات على المنحدرات تجعل بدء الحركة على المنحدرات أسهل من خلال منع السيارة من الرجوع للخلف لمدة تصل إلى ثانيتين عندما ينقل السائق قدمه من دواسة الفرامل إلى دواسة الوقود.",
          },
            
        },
  
        {
          image: Jimny5DoorsFeature2,
          title: {
            "en":"Hill descent control*",
            "ar":"نظام التحكم في نزول المنحدرات*",
          }, 
          description: {
            "en":"On tricky descents, the system is designed to automatically apply the brakes and maintain a fixed speed so you can concentrate on steering without using the brake pedal.",
            "ar":"في النزول الصعب، تم تصميم النظام لتفعيل الفرامل تلقائيًا والحفاظ على سرعة ثابتة حتى تتمكن من التركيز على التوجيه دون استخدام دواسة الفرامل.",
          },
            
        },
  
        {
          image: Jimny5DoorsFeature3,
          title: {
            "en":"Airbags",
            "ar":"وسائد هوائية",
          }, 
          description: {
            "en":"The six airbags – dual front airbags, side airbags and curtain airbags help protect the driver and passenger in the event of a collision.",
            "ar":"الوسائد الهوائية الست - الوسائد الهوائية الأمامية المزدوجة، والوسائد الهوائية الجانبية، والوسائد الهوائية الستارية تساعد في حماية السائق والركاب في حالة التصادم.",
          },
            
        },
      ],
      specSubtitle: {
        "en":"What makes Jimny jazzy?",
        "ar":"ما الذي يجعل جمني جريئًا؟",
      }, 
      models: [{ type: "GL" }, { type: "GLX" }],
      specifications: {
        features: [
          {
            name: {
              "en":"SRS front dual airbag	",
              "ar":"وسادة هوائية أمامية مزدوجة SRS",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"SRS side airbags	",
              "ar":"وسائد هوائية جانبية SRS",
            }, 
            GL: "--",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Curtain airbags	",
              "ar":"وسائد هوائية ستائرية",
            }, 
            GL: "--",
            GLX: "Yes",
          },

          {
            name: {
              "en":"Headlamps",
              "ar":"المصابيح الأمامية"
            },
            GL:"Halogen multi-reflector",
            GLX:"LED"
          },
  
          {
            name: {
              "en":"Seatbelts Front: 3-point ELR seatbelts x 2 with pretensioners and force limiters",
              "ar":"أحزمة الأمان الأمامية: أحزمة أمان ثلاثية النقاط ELR x 2 مع مشدات ومحددات قوة",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Seatbelts Rear: 3-point ELR seatbelts x 2 with force limiters",
              "ar":"أحزمة الأمان الخلفية: أحزمة أمان ثلاثية النقاط ELR x 2 مع محددات قوة",
            }, 
            GL: "--",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"ISOFIX child seat anchorages	",
              "ar":"مرتكزات مقاعد الأطفال ISOFIX",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Pedal release system	",
              "ar":"نظام تحرير الدواسات",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Side impact door beams	",
              "ar":"دعامات الأبواب الجانبية لمقاومة الصدمات",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Parking sensors (Rear)	",
              "ar":"أجهزة استشعار الركن (خلفي)",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"ABS	",
              "ar":"نظام المكابح المانع للانغلاق",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"ESP",
              "ar":"برنامج الثبات الإلكتروني",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Hill hold control	",
              "ar":"نظام التحكم في الثبات على المنحدرات",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Hill descent control*",
              "ar":"نظام التحكم في نزول المنحدرات*",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"TPMS (Tyre pressure monitoring system)",
              "ar":"نظام مراقبة ضغط الإطارات (TPMS)"
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Brake assist function	",
              "ar":"نظام مساعدة الفرامل",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Shift lock/key interlock	",
              "ar":"قفل ناقل الحركة/قفل المفتاح",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"High-mount stop lamp	",
              "ar":"مصباح توقف عالي التركيب",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Immobilizer	",
              "ar":"جهاز منع الحركة",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Audio Display Screen (Apple CarPlay, Android Auto)	",
              "ar":"شاشة عرض الصوت (Apple CarPlay، Android Auto)",
            }, 
            GL: "Yes",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"7inch Display Audio	",
              "ar":"شاشة عرض الصوت 7 بوصة",
            }, 
            GL: "Yes",
            GLX: "--",
          },
  
          {
            name: {
              "en":"9inch Display Audio	",
              "ar":"شاشة عرض الصوت 9 بوصة",
            }, 
            GL: "--",
            GLX: "Yes",
          },
  
          {
            name: {
              "en":"Cruise Control	",
              "ar":"مثبت السرعة",
            }, 
            GL: "--",
            GLX: "Yes",
          },

          {
            name: {
              "en":"Fog lamps - Front",
              "ar":"مصابيح ضباب - أمامية",
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Fog lamps - Rear",
              "ar":"مصابيح الضباب - خلفية"
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Lampwasher",
              "ar":"غسالة المصباح"
            },
            GL:"--",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Outside Door Mirrors - Power Adjustable",
              "ar":"مرايا الأبواب الخارجية - قابلة للتعديل كهربائياً"
            },
            GL:"Yes",
            GLX:"Yes"
          },

          {
            name:{
              "en":"Outside Door Mirrors - Power Foldable",
              "ar":"مرايا الأبواب الخارجية - قابلة للطي كهربائياً"
            },
            GL:"--",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Day / Night Rearview Mirror - Manual Dimming",
              "ar":"مرآة الرؤية الخلفية ليلا ونهارا - تعتيم يدوي"
            },
            GL:"Yes",
            GLX:"Yes"
          },

          {
            name: {
              "en":"Front Grille - Black with Chrome Accent",
              "ar":"الشبكة الأمامية - باللون الأسود مع لمسات من الكروم"
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Drip rails",
              "ar":"قضبان تقطير",
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Outside Door Handles - Body Colored",
              "ar":"مقابض الأبواب الخارجية - بلون الهيكل",
            },
            GL:"--",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Steering Wheel - Leather Covered",
              "ar":"عجلة القيادة - مغطاة بالجلد",
            },
            GL:"--",
            GLX:"Yes"
          },

          {
            name: {
              "en":"Steering Wheel - Urethane",
              "ar":"عجلة القيادة - يوريتان"
            },
            GL:"Yes",
            GLX:"--"
          },

          {
            name: {
              "en":"Steering Wheels with Audio Controls",
              "ar":"عجلات القيادة مع التحكم الصوتي",
            },
            GL:"Yes",
            GLX:"Yes"
          },

          {
            name: {
              "en":"Steering Wheels with Cruise Control",
              "ar":"عجلات القيادة مع التحكم في السرعة"
            },
            GL:"--",
            GLX:"Yes"
          },

          {
            name: {
              "en":"Rear parking sensors",
              "ar":"حساسات ركن خلفية",
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Remote - control Door Locks",
              "ar": "أقفال الأبواب بالتحكم عن بعد",
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"7inch Display Audio + Rearview Camera",
              "ar":"شاشة عرض 7 بوصة للصوت + كاميرا للرؤية الخلفية",
            },
            GL:"Yes",
            GLX:"--"
          },

          {
            name: {
              "en":"9 inch Display Audio + Rear View Camera",
              "ar":"شاشة 9 بوصة للصوت + كاميرا للرؤية الخلفية",
            },
            GL:"--",
            GLX:"Yes"
          },

          {
            name: {
              "en":"Air-conditioner, Front Manual",
              "ar":"مكيف هواء أمامي يدوي"
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Power Steering, Tilt Adjustable (Manual)",
              "ar":"مقود مرن، قابل لضبط الإمالة (يدوي)"
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Digital Clock",
              "ar":"ساعة رقمية",
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"2 Speakers",
              "ar":"2 مكبرات صوت"
            },
            GL:"Yes",
            GLX:"Yes",
          },

          {
            name: {
              "en":"Antenna(mount at rear of Roof)",
              "ar":"الهوائي (يتم تركيبه في الجزء الخلفي من السقف)"
            },
            GL:"Yes",
            GLX:"Yes"
          },

          {
            name: {
              "en":"USB & 12V Accessary Socket, Bluetooth Connectivity",
              "ar":"مقبس USB و12 فولت، اتصال بلوتوث"
            },
            GL:"Yes",
            GLX:"Yes"
          },

          {
            name: {
              "en":"Tachometer/Display with Outside Temprature",
              "ar":"مقياس سرعة الدوران/عرض مع درجة الحرارة الخارجية"
            },
            GL:"Yes",
            GLX:"Yes"
          }
        ],
  
        dimensions: [
          {
            name: {
              "en":"Overall length (mm)",
              "ar":"الطول الكلي (مم)",
            }, 
            GL: "",
            GLX: "",
          },
  
          {
            name: {
              "en":"Front Bumper Rear Bumper",
              "ar":"المصد الأمامي والخلفي",
            }, 
            GL: "3,820",
            GLX: "3,820",
          },
  
          {
            name: {
              "en":"Front Bumper Spare Wheel Cover",
              "ar":"غطاء عجلة احتياطية للمصد الأمامي",
            }, 
            GL: "3,985	",
            GLX: "3,985	",
          },
  
          {
            name: {
              "en":"Overall width (mm)	",
              "ar":"العرض الكلي (مم)",
            }, 
            GL: "1,645	",
            GLX: "1,645	",
          },
  
          {
            name: {
              "en": "Overall height (mm)	",
              "ar":"الارتفاع الكلي (مم)",
            },
            GL: "1,720	",
            GLX: "1,720	",
          },
  
          {
            name: {
              "en":"Wheelbase (mm)	",
              "ar":"قاعدة العجلات (مم)",
            }, 
            GL: "2,590	",
            GLX: "2,590	",
          },
  
          {
            name: {
              "en":"Tread(mm)	",
              "ar":"عرض المسار (مم)",
            }, 
            GL: "	",
            GLX: "",
          },
  
          {
            name: {
              "en": "Front	",
              "ar":"الأمام",
            },
            GL: "1,395	",
            GLX: "1,395	",
          },
  
          {
            name: {
              "en":"Rear",
              "ar":"الخلف",
            }, 
            GL: "1,405	",
            GLX: "1,405	",
          },
  
          {
            name: {
              "en":"Minimum turning radius (m)	",
              "ar":"أدنى نصف قطر دوران (م)",
            }, 
            GL: "5.7	",
            GLX: "5.7	",
          },
  
          {
            name: {
              "en":"Minimum Ground Clearance (mm)	",
              "ar":"أدنى خلوص أرضي (مم)",
            }, 
            GL: "210",
            GLX: "210",
          },
  
          {
            name: {
              "en":"Approach angle	",
              "ar":"زاوية الاقتراب",
            },
            GL: "37 deg	",
            GLX: "37 deg	",
          },
  
          {
            name: {
              "en":"Ramp breakover angle	",
              "ar":"زاوية العبور فوق المنحدرات",
            }, 
            GL: "28 deg	",
            GLX: "28 deg	",
          },
  
          {
            name: {
              "en": "Departure angle	",
              "ar":"زاوية المغادرة",
            },
            GL: "49 deg	",
            GLX: "49 deg	",
          },
        ],
  
        capacity: [
          {
            name: {
              "en":"Seating capacity (Including Driver)",
              "ar":"سعة الجلوس (بما في ذلك السائق)",
            }, 
            GL: "4",
            GLX: "4",
          },
  
          {
            name: {
              "en":"Luggage capacity*1 / Max.volume (litres)",
              "ar":"سعة الأمتعة*1 / الحجم الأقصى (لترات)",
            }, 
            GL: "1,113	",
            GLX: "1,113	",
          },
  
          {
            name: {
              "en":"Rear seatback folded (VDA method) (litres)	",
              "ar":"طي ظهر المقعد الخلفي (طريقة VDA) (لترات)",
            }, 
            GL: "332",
            GLX: "332",
          },
  
          {
            name: {
              "en":"Rear seatback raised (VDA method) (litres)	",
              "ar":"ظهر المقعد الخلفي مرفوع (طريقة VDA) (لتر)",
            }, 
            GL: "211",
            GLX: "211",
          },
  
          {
            name: {
              "en":"Fuel tank capacity (litres)	",
              "ar":"سعة خزان الوقود (لترات)",
            }, 
            GL: "40",
            GLX: "40",
          },
        ],
  
        engine: [
          {
            name: {
              "en":"Engine (4 CYL)	",
              "ar":"المحرك (4 أسطوانات)",
            }, 
            GL: "1.5L",
            GLX: "1.5L",
          },
  
          {
            name: {
              "en":"Type",
              "ar":"نوع",
            }, 
            GL: "K15B	(5MT)",
            GLX: "K15B (4AT)",
          },
  
          {
            name: {
              "en":"Number of cylinders	",
              "ar":"عدد الأسطوانات",
            }, 
            GL: "4	",
            GLX: "4	",
          },
  
          {
            name: {
              "en":"Number of valves	",
              "ar":"عدد الصمامات",
            }, 
            GL: "16	",
            GLX: "16	",
          },
  
          {
            name: {
              "en":"Piston displacement (cm3)	",
              "ar":"إزاحة المكبس (سم مكعب)",
            }, 
            GL: "1,462	",
            GLX: "1,462	",
          },
  
          {
            name: {
              "en":"Bore x stroke (mm)	",
              "ar":"قطر الأسطوانة × الشوط (مم)",
            }, 
            GL: "74 X 85	",
            GLX: "74 X 85	",
          },
  
          {
            name: {
              "en":"Compression Ratio	",
              "ar":"نسبة الانضغاط",
            }, 
            GL: "10	",
            GLX: "10	",
          },
  
          {
            name: {
              "en":"Maximum output (kW/rpm)	",
              "ar":"القدرة القصوى (كيلوواط/دورة في الدقيقة)",
            }, 
            GL: "75/6,000	",
            GLX: "75/6,000	",
          },
  
          {
            name: {
              "en":"Maximum torque (N･m/rpm)	",
              "ar":"أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
            }, 
            GL: "130/4,000	",
            GLX: "130/4,000	",
          },
  
          {
            name: {
              "en":"Fuel distribution	",
              "ar":"توزيع الوقود",
            }, 
            GL: "Multipoint injection	",
            GLX: "Multipoint injection	",
          },
        ],
  
        transmission: [
          {
            name: {
              "en":"Gear Ratio	",
              "ar":"نسبة التروس",
            }, 
            GL: "",
            GLX: "",
          },
  
          {
            name: {
              "en":"1st	",
              "ar":"1st	",
            }, 
            GL: "4.425",
            GLX: "2.875",
          },
  
          {
            name: {
              "en":"2nd	",
              "ar":"2nd	",
            }, 
            GL: "2.304	",
            GLX: "1.568",
          },
  
          {
            name: {
              "en":"3rd	",
              "ar":"3rd	",
            }, 
            GL: "1.674",
            GLX: "1.000",
          },
  
          {
            name: {
              "en":"4th	",
              "ar":"4th	",
            }, 
            GL: "1.190	",
            GLX: "0.697",
          },
  
          {
            name: {
              "en":"5th	",
              "ar":"5th	",
            }, 
            GL: "1.000	",
            GLX: "--",
          },
  
          {
            name: {
              "en":"Reverse	",
              "ar":"الرجوع",
            }, 
            GL: "5.151	",
            GLX: "2.300",
          },
  
          {
            name: {
              "en":"Final Gear Ratio	",
              "ar":"نسبة الترس النهائي",
            }, 
            GL: "4.090	",
            GLX: "4.300",
          },
        ],
  
        weight: [
          {
            name: {
              "en": "Kerb weight (min./with full option)	",
              "ar": "الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
            },
            GL: "1,185-1,200",
            GLX: "1,200-1,215",
          },
  
          {
            name: {
              "en":"Gross vehicle weight (kg)	",
              "ar":"الوزن الإجمالي للمركبة (كجم)"
            }, 
            GL: "1,545	",
            GLX: "1,545	",
          },
        ],
      },
    },
  
    // JIMNY 5 DOORS DATA SOURCE ENDS

  // JIMNY DATA SOURCE STARTS
  {
    id: "jimny",
    title: {
      en: "Jimny",
      ar: "جمني",
    },
    image: JimnyImage,
    video: JimnyVideo,
    category: "suv-crossover",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [
      { name: "Black/Blue", hexCode:"#343436/#0174ab ", customName: { en:"Brisk Blue Metallic + Bluish Black Pearl", ar:"أزرق لامع / أسود"}, image: JimnyBlueDualBannerImage },
      { name: "Black/Green", hexCode:"#343436/#c0c85b", customName: { en:"Kinetic Yellow + Bluish Black Pearl", ar:"أصفر فاقع / أسود"}, image: JimnyGreenDualBannerImage },
      { name: "Black/Ivory", hexCode:"#343436/#bfb498 ", customName: { en:"Chiffon Ivory Metallic + Bluish Black Pearl", ar:"بيج فاخر لؤلؤي لامع / أسود"}, image: JimnyIvoryDualBannerImage },
      { name: "Black", hexCode:"#343436", customName: { en:"Bluish Black Pearl", ar:"أسود لؤلؤي داكن"}, image: JimnyBlackBannerImage },
      { name: "Green", hexCode:"#687267", customName: { en:"Jungle Green", ar:"أخضر غابي"}, image: JimnyJungleGreenBannerImage },
      { name: "Grey", hexCode:"#6A6B70", customName: { en:"Medium Gray", ar:"رمادي فاخر لؤلؤي لامع"}, image: JimnyDarkGreyBannerImage },
      { name: "Silver", hexCode:"#BBC4CB", customName: { en:"Silky Silver Metallic", ar:"فضي فاخر لؤلؤي لامع"}, image: JimnySilverBannerImage },
      { name: "White", hexCode:"#FFFFFF", customName: { en:"Pure White Pearl", ar:"أبيض"}, image: JimnyWhiteBannerImage },
    ],
    variants: [
      {
        name: "JIMNY 4WD 5MT GL",
        price: "93,840",
        items: [ 
          {
            en:"5 Speed Manual Transmission",
            ar:"ناقل حركة يدوي 5 سرعات"
          },

          {
            en:"Halogen Headlamps",
            ar:"مصابيح أمامية هالوجينية"
          },

          {
            en:"9-inch Screen",
            ar:"شاشة 9 بوصات"
          },

          {
            en:"Front Manual AC",
            ar:"مكيف أمامي يدوي"
          },

          {
            en:"Front Airbags for Driver & Passenger",
            ar:"وسائد هوائية أمامية للسائق والراكب"
          },
        ],
      },
      {
        name: "JIMNY 4WD 4AT GLX",
        price: "105,340",
        items: [
          {
            en:"4 Speed Automatic Transmission",
            ar:"ناقل حركة يدوي 4 سرعات"
          },

          {
            en:"LED Headlamps",
            ar:"مصابيح أمامية LED"
          },

          {
            en:"9-inch Screen",
            ar:"شاشة صوت مقاس 9 بوصات "
          },

          {
            en:"Front Automatic AC",
            ar:"مكيف أمامي تلقائي"
          },

          {
            en:"Dual Front, Side and Curtain Airbags",
            ar:"وسائد هوائية أمامية وجانبية وستائرية"
          },

          {
            en:"Front Lamp Washer",
            ar:"غسالة إضاءة أمامية"
          },
        ],
      },
    ],
    overview: [
      { title: {
        en: "Drive Line",
        ar: "خط القيادة",
      }, data: "4WD" },
      { title: {
        en: "Engine",
        ar: "المحرك",
      }, data: "1.5L" },
      { title: {
        en: "Horse Power",
        ar: "القدرة الحصانية",
      }, data: "101" },
      { title: {
        en: "Fuel Efficiency",
        ar: "كفاءة الوقود",
      }, data: "15.9 - 17 KMPL" },
      { title: {
        en: "Fuel Tank Capacity",
        ar: "سعة خزان الوقود",
      }, data: "40L" },
    ],
    brochure: JimnyBrochure,
    shortDescription: {
      "en":"Embrace off-road adventures with Suzuki Jimny, built to conquer any terrain, from mountains to deserts.",
      "ar":"استمتع بالمغامرات على الطرق الوعرة مع سوزوكي جمني، المصممة للتغلب على أي تضاريس، من الجبال إلى الصحاري."
    },
      
    tagline: {
      "en":"Rugged. Adventurous. Iconic.",
      "ar":"متين. مغامر. أيقوني.",
    }, 
    View360Image: Jimny360ViewImage,
    exteriorImages: [
      JimnyExterior1,
      JimnyExterior2,
      JimnyExterior3,
      JimnyExterior4,
    ],
    exteriorTitle: {
      "en":"Nobody But Jimny.",
      "ar":"لا أحد غير جمني.",
    }, 
    exteriorDescription1: {
      "en":"Jimny’s rugged design is characterized by its iconic boxy shape, five-slot front grille, and round headlamps. Its sturdy, off-road-ready build with bold fender flares and high ground clearance underscores its adventurous spirit.",
      "ar":"يتميز تصميم جمني المتين بشكله المربع الأيقوني، وشبكه الأمامي ذو الخمسة فتحات، والمصابيح الأمامية المستديرة. هيكله المتين الجاهز للطرق الوعرة مع أقواس العجلات الجريئة وارتفاعه الكبير عن الأرض يؤكد روحه المغامرة.",
    },
      
    exteriorDescription2: {
      "en":"Its sturdy, off-road-ready build with bold fender flares and high ground clearance underscores its adventurous spirit.",
      "ar":"هيكله المتين الجاهز للطرق الوعرة مع أقواس العجلات الجريئة وارتفاعه الكبير عن الأرض يؤكد روحه المغامرة.",
    },
      
    interiorImages: [
      JimnyInteriorBanner,
      JimnyInterior1,
      JimnyInterior2,
      JimnyInterior3,
      JimnyInterior4,
    ],
    interiorContents: [
      {
        title: {
          "en":"Display Audio",
          "ar":"عرض الصوت",
        }, 
        description: {
          "en":"The dash includes a 7 inch infrared touchscreen and hands free calling designed to keep you focused on serious off-roading or the urban melee without distraction.",
          "ar":"تتضمن لوحة القيادة شاشة تعمل باللمس بالأشعة تحت الحمراء بحجم 7 بوصات ومكالمات بدون استخدام اليدين مصممة لإبقاءك مركزًا على القيادة الوعرة الجادة أو الزحام الحضري دون تشتيت.",
        },
          
      },
      {
        title: {
          "en":"Automatic AC",
          "ar":"مكيف هواء أوتوماتيكي",
        }, 
        description: {
          "en":"You have everything you need inside the Jimny 5-Door. Relax in climate controlled air conditioned comfort.",
          "ar":"لديك كل ما تحتاجه داخل جمني 5-أبواب. استرخِ في راحة مكيف الهواء المناخي.",
        },
          
      },
      {
        title: {
          "en":"Reclining Seats",
          "ar":"مقاعد قابلة للإمالة",
        }, 
        description: {
          "en":"Suzuki Jimny's 180-degree reclining seats provide ultimate comfort and versatility, allowing passengers to fully relax during the journey.",
          "ar":"مقاعد سوزوكي جمني القابلة للإمالة بزاوية 180 درجة توفر الراحة القصوى والتنوع، مما يتيح للركاب الاسترخاء التام أثناء الرحلة.",
        },
         
      },
      {
        title: {
          "en":"Pack Your Gear for Adventure",
          "ar":"احزم معداتك للمغامرة",
        }, 
        description: {
          "en":"Whether navigating the urban jungle or exploring off the beaten track, the Jimny offers ample space and practical storage to accommodate every need.",
          "ar":"سواء كنت تتنقل في الأدغال الحضرية أو تستكشف الطرق الوعرة، فإن جمني يوفر مساحة واسعة وتخزينًا عمليًا لتلبية كل احتياجاتك.",
        },
      },
    ],

    features: [
      {
        image: JimnyFeature1,
        title: {
          "en":"Hill hold control",
          "ar":"نظام التحكم في الثبات على المنحدرات",
        }, 
        description: {
          "en":"A hill hold control function makes hill start easier by preventing the car from rolling backward for up to two seconds when the driver moves his or her foot from the brake pedal to the accelerator pedal.",
          "ar":"وظيفة التحكم في الثبات على المنحدرات تجعل بدء الحركة على المنحدرات أسهل من خلال منع السيارة من الرجوع للخلف لمدة تصل إلى ثانيتين عندما ينقل السائق قدمه من دواسة الفرامل إلى دواسة الوقود.",
        },
      },

      {
        image: JimnyFeature2,
        title: {
          "en":"Hill descent control*",
          "ar":"نظام التحكم في نزول المنحدرات*",
        }, 
        description: {
          "en":"On tricky descents, the system is designed to automatically apply the brakes and maintain a fixed speed so you can concentrate on steering without using the brake pedal.",
          "ar":"في النزول الصعب، تم تصميم النظام لتفعيل الفرامل تلقائيًا والحفاظ على سرعة ثابتة حتى تتمكن من التركيز على التوجيه دون استخدام دواسة الفرامل.",
        },
      },

      {
        image: JimnyFeature3,
        title: {
          "en":"Airbags",
          "ar":"وسائد هوائية",
        }, 
        description: {
          "en": "The six airbags – dual front airbags, side airbags and curtain airbags help protect the driver and passenger in the event of a collision.",
          "ar": "الوسائد الهوائية الست - الوسائد الهوائية الأمامية المزدوجة، والوسائد الهوائية الجانبية، والوسائد الهوائية الستارية تساعد في حماية السائق والركاب في حالة التصادم.",
        },
      },
    ],
    specSubtitle: {
      "en":"What makes Jimny jazzy?",
      "ar":"ما الذي يجعل جمني جريئًا؟",
    }, 
    models: [{ type: "GL5MT" }, { type: "GLX4AT" }],
    specifications: {
      features: [
        {
          name: {
            "en":"Headlamps - LED projector",
            "ar":"المصابيح الأمامية - بروجكتور LED",
          }, 
          GL5MT: "--",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Headlamps - Halogen Multi Reflector",
            "ar":"المصابيح الأمامية - عاكس متعدد الهالوجين",
          }, 
          GL5MT: "Yes",
          GLX4AT: "--",
        },

        {
          name: {
            "en":"DRL - Daytime Running Lights",
            "ar":"المصابيح الأمامية - عاكس متعدد الهالوجين",
          }, 
          GL5MT: "--",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Automatic Lamps & Front Lamp Washer	",
            "ar":"مصابيح أوتوماتيكية وغسالة المصابيح الأمامية",
          }, 
          GL5MT: "--",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Fog Lamps - Front & Rear	",
            "ar":"مصابيح الضباب - أمامية وخلفية",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Power Windows - Front	",
            "ar":"نوافذ كهربائية - أمامية",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Outside Door Mirrors - Black painted - Power Adjustable",
            "ar":"مرايا الأبواب الخارجية - مطلية باللون الأسود - قابلة للتعديل كهربائياً"
          }, 
          GL5MT: "--",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Air Condition	",
            "ar":"مكيف الهواء",
          }, 
          GL5MT: "Manual",
          GLX4AT: "Auto",
        },

        {
          name: {
            "en":"Digital Clock	",
            "ar":"ساعة رقمية",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Remote - Control door locks with hazard lamp answerback function	",
            "ar":"أقفال الأبواب بالتحكم عن بعد مع وظيفة الرد بمصباح الخطر",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en": "Audio - MP3 / WMA compatible CD player + Bluetooth	",
            "ar":"نظام صوتي - مشغل CD متوافق مع MP3 / WMA + بلوتوث",
          },
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"SRS Front Dual Airbags	",
            "ar":"وسائد هوائية أمامية مزدوجة SRS"
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Side and Curtain Airbags	",
            "ar":"وسائد هوائية جانبية وستائرية",
          }, 
          GL5MT: "--",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"ISO Fix child seat anchorages	",
            "ar":"مرتكزات مقاعد الأطفال ISO Fix",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Side impact door beams	",
            "ar":"دعامات الأبواب الجانبية لمقاومة الصدمات",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Drip rails	",
            "ar":"قضبان تقطير",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Door Handles-Body Color	",
            "ar":"مقابض الأبواب بلون الجسم",
          }, 
          GL5MT: "--",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"ABS - Anti-lock Braking system, ESP - Electronic Stability Program	",
            "ar":"نظام المكابح المانع للانغلاق (ABS)، برنامج الثبات الإلكتروني (ESP)",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"High mount stop lamp	",
            "ar":"مصباح توقف عالي التركيب",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Hill Hold control & Descent control	",
            "ar":"نظام التحكم في الثبات على المنحدرات ونظام التحكم في النزول",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Immobilizer	",
            "ar":"جهاز منع الحركة",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Shift lock / key interlock (AT)	",
            "ar":"قفل ناقل الحركة / قفل المفتاح (AT)",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Cruise Control	",
            "ar":"مثبت السرعة",
          }, 
          GL5MT: "--",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Tire Pressure Monitoring System (TPMS)	",
            "ar":"نظام مراقبة ضغط الإطارات (TPMS)",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },

        {
          name: {
            "en":"Brake Assist Function",
            "ar":"وظيفة مساعدة الفرامل",
          }, 
          GL5MT: "Yes",
          GLX4AT: "Yes",
        },
      ],

      dimensions: [
        {
          name: {
            "en":"Overall length (mm)",
            "ar":"الطول الكلي (مم)",
          }, 
          GL5MT: "",
          GLX4AT: "",
        },

        {
          name: {
            "en":"Front Bumper Rear Bumper	",
            "ar":"المصد الأمامي والخلفي",
          }, 
          GL5MT: "3,480",
          GLX4AT: "3,480",
        },

        {
          name: {
            "en":"Front Bumper Spare Wheel Cover	",
            "ar":"غطاء عجلة احتياطية للمصد الأمامي",
          }, 
          GL5MT: "3,645",
          GLX4AT: "3,645",
        },

        {
          name: {
            "en":"Overall width (mm)	",
            "ar":"العرض الكلي (مم)",
          }, 
          GL5MT: "1,645	",
          GLX4AT: "1,645	",
        },

        {
          name: {
            "en":"Overall height (mm)	",
            "ar":"الارتفاع الكلي (مم)",
          }, 
          GL5MT:  "1,720",
          GLX4AT: "1,720",
        },

        {
          name: {
            "en":"Wheelbase (mm)	",
            "ar":"قاعدة العجلات (مم)",
          }, 
          GL5MT: "2,250	",
          GLX4AT: "2,250	",
        },

        {
          name: {
            "en":"Tread(mm)	",
            "ar":"عرض المسار (مم)",
          }, 
          GL5MT: "",
          GLX4AT: "",
        },

        {
          name: {
            "en":"Front	",
            "ar":"الأمام",
          }, 
          GL5MT: "1,395	",
          GLX4AT: "1,395	",
        },

        {
          name: {
            "en":"Rear",
            "ar":"الخلف",
          }, 
          GL5MT: "1,405",
          GLX4AT: "1,405",
        },

        {
          name: {
            "en":"Minimum turning radius (m)",
            "ar":"أدنى نصف قطر دوران (م)",
          }, 
          GL5MT: "4.9	",
          GLX4AT: "4.9	",
        },

        {
          name: {
            "en":"Minimum ground clearance",
            "ar":"أدنى خلوص أرضي (مم)",
          }, 
          GL5MT: "210",
          GLX4AT: "210",
        },

        {
          name: {
            "en":"Approach angle",
            "ar":"زاوية الاقتراب",
          },
          GL5MT:"37",
          GLX4AT:"37",
        },

        {
          name: {
            "en":"Ramp breakover angle",
            "ar":"زاوية العبور فوق المنحدرات",
          },
          GL5MT:"28",
          GLX4AT:"28",
        },

        {
          name: {
            "en":"Departure angle",
            "ar":"زاوية المغادرة",
          },
          GL5MT:"49",
          GLX4AT:"49"
        },
      ],

      capacity: [
        {
          name: {
            "en":"Luggage capacity*1 / Max.volume (litres)",
            "ar":"سعة الأمتعة*1 / الحجم الأقصى (لترات)",
          },
          GL5MT:"830",
          GLX4AT:"830",
        },

        {
          name: {
            "en":"Seating capacity (Including Driver)	",
            "ar":"سعة الجلوس (بما في ذلك السائق)",
          }, 
          GL5MT: "4",
          GLX4AT: "4",
        },
        {
          name: {
            "en":"Fuel tank capacity (litres)	",
            "ar":"سعة خزان الوقود (لترات)",
          }, 
          GL5MT: "40",
          GLX4AT: "40",
        },
        {
          name: {
            "en":"Rear seatback folded (VDA method)",
            "ar":"طي ظهر المقعد الخلفي (طريقة VDA)",
          },
          GL5MT:"377*1",
          GLX4AT:"377*1"
        },
        {
          name: {
            "en":"Rear seatback raised (VDA method)",
            "ar":"ظهر المقعد الخلفي مرفوع (طريقة VDA)",
          },
          GL5MT:"85*1",
          GLX4AT:"85*1",
        },

      ],

      engine: [
        {
          name: {
            "en":"Engine (4 CYL)	",
            "ar":"المحرك (4 أسطوانات)",
          }, 
          GL5MT: "1.5L",
          GLX4AT: "1.5L",
        },

        {
          name: {
            "en":"Type",
            "ar":"نوع",
          }, 
          GL5MT: "K15B (5MT)",
          GLX4AT: "K15B	(4AT)",
        },

        {
          name: {
            "en":"Number of cylinders	",
            "ar": "عدد الأسطوانات",
          }, 
          GL5MT: "4	",
          GLX4AT: "4	",
        },

        {
          name: {
            "en":"Number of valves	",
            "ar":"عدد الصمامات",
          }, 
          GL5MT: "16	",
          GLX4AT: "16	",
        },

        {
          name: {
            "en":"Piston displacement (cm3)	",
            "ar":"إزاحة المكبس (سم مكعب)",
          }, 
          GL5MT: "1,462	",
          GLX4AT: "1,462	",
        },

        {
          name: {
            "en":"Bore x stroke (mm)	",
            "ar":"قطر الأسطوانة × الشوط (مم)",
          }, 
          GL5MT: "74 X 85	",
          GLX4AT: "74 X 85	",
        },

        {
          name: {
            "en":"Compression Ratio	",
            "ar":"نسبة الانضغاط",
          }, 
          GL5MT: "10",
          GLX4AT: "10",
        },

        {
          name: {
            "en":"Maximum output (kW/rpm)	",
            "ar":"القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          }, 
          GL5MT: "75/6,000	",
          GLX4AT: "75/6,000	",
        },

        {
          name: {
            "en":"Maximum torque (N･m/rpm)	",
            "ar":"أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          }, 
          GL5MT: "130/4,000	",
          GLX4AT: "130/4,000	",
        },

        {
          name: {
            "en":"Fuel distribution	",
            "ar":"توزيع الوقود",
          }, 
          GL5MT: "Multipoint injection	",
          GLX4AT: "Multipoint injection	",
        },
      ],

      transmission: [
        {
          name: {
            "en":"Gear Ratio	",
            "ar":"نسبة التروس",
          }, 
          GL5MT: "",
          GLX4AT: "",
        },

        {
          name: {
            "en":"1st",
            "ar":"1st",
          }, 
          GL5MT: "4.425",
          GLX4AT: "2.875",
        },

        {
          name: {
            "en":"2nd",
            "ar":"2nd",
          }, 
          GL5MT: "2.304",
          GLX4AT: "1.568",
        },

        {
          name: {
            "en":"3rd",
            "ar":"3rd",
          }, 
          GL5MT: "1.674",
          GLX4AT: "1.000",
        },

        {
          name: {
            "en":"4th",
            "ar":"4th",
          }, 
          GL5MT: "1.190",
          GLX4AT: "0.697",
        },

        {
          name: {
            "en":"5th",
            "ar":"5th",
          }, 
          GL5MT: "1.000",
          GLX4AT: "--",
        },

        {
          name: {
            "en":"Reverse",
            "ar":"الرجوع",
          }, 
          GL5MT: "5.151",
          GLX4AT: "2.300",
        },

        {
          name: {
            "en":"Final Gear Ratio",
            "ar":"نسبة الترس النهائي",
          },  
          GL5MT: "4.090",
          GLX4AT: "4.300",
        },
      ],
      weight: [
        {
          name: {
            "en":"Kerb weight (min./with full option)",
            "ar":"الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          }, 
          GL5MT: "1,090 / 1,135",
          GLX4AT: "1,110 / 1,135",
        },

        {
          name: {
            "en":"Gross vehicle weight (kg)",
            "ar":"الوزن الإجمالي للمركبة (كجم)",
          }, 
          GL5MT: "1,435",
          GLX4AT: "1,435",
        },
      ],
    },
  },

  // JIMNY DATA SOURCE ENDS

  // JIMNY AUTOMATICE DATA SOURCE STARTS

  {
    id: "jimny-automatic",
    title: {
      en: "Jimny Automatic",
      ar: "جمني أوتوماتيك",
    },
    image: JimnyGreyImage,
    category: "suv-crossover",
    discontinued: true,
    maintenanceOnly: true,
    showOnSparePartsPage: false,
  },

  // JIMNY AUTOMATICE DATA SOURCE ENDS

  // JIMNY 5 DOORS AUTOMATIC DATA SOURCE STARTS

  {
    id: "jimny-5-doors-automatic",
    title: {
      en: "Jimny 5 Doors",
      ar: "جمني 5 أوتوماتيك",
    },
    image: Jimny5DoorsRedImage,
    category: "suv-crossover",
    discontinued: true,
    maintenanceOnly: true,
    showOnSparePartsPage: false,
  },

  // JIMNY 5 DOORS AUTOMATIC DATA SOURCE ENDS

  // CARRY DATA SOURCE STARTS

  {
    id: "carry",
    title: {
      en: "Carry",
      ar: "كاري",
    },
    image: CarryImage,
    video: CarryVideo,
    category: "Commercial",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [{ name: "White", hexCode:"#E3E3E3", customName: { en:"White", ar:"آبيض"}, image: CarryWhiteBanner }],
    variants: [{ name: "CARRY PICKUP SINGLE CAB", price: "55,200", items: [] }],
    overview: [
      { title: {
        en: "Drive Line",
        ar: "خط القيادة",
      }, data: "RWD" },
      { title: {
        en: "Engine",
        ar: "المحرك",
      }, data: "1.5L" },
      { title: {
        en: "Horse Power",
        ar: "القدرة الحصانية",
      }, data: "95" },
      {title: {
        en: "Fuel Efficiency",
        ar: "كفاءة الوقود",
      }, data: "16.0 KMPL" },
      { title: {
        en: "Fuel Tank Capacity",
        ar: "سعة خزان الوقود",
      }, data: "43L" },
    ],
    brochure: CarryBrochure,
    shortDescription: {
      "en":"Make your everyday life simpler with the SUZUKI Carry",
      "ar":"اجعل حياتك اليومية أبسط مع سوزوكي كاري",
    }, 
    tagline: {
      "en":"Versatile. Economical. Reliable.",
      "ar":"متعدد الاستخدامات. اقتصادي. موثوق.",
    }, 
    View360Image: Carry360ViewImage,
    exteriorImages: [
      CarryExterior1,
      CarryExterior2,
      CarryExterior3,
      CarryExterior4,
    ],
    exteriorTitle: {
      "en":"CARRY MORE.",
      "ar":"احمل المزيد.",
    }, 
    exteriorDescription1: {
      "en":"Carry's spacious bed, measuring 2,565 mm in length and 1,660 mm in width, accommodates both long and wide items effortlessly. Its low 750 mm height simplifies loading and unloading, with easy access at 1,110 mm.",
      "ar":"سرير كاري الواسع، الذي يبلغ طوله 2565 مم وعرضه 1660 مم، يستوعب العناصر الطويلة والعريضة بسهولة. يبلغ ارتفاعه المنخفض 750 مم مما يسهل التحميل والتفريغ، مع الوصول السهل عند 1110 مم.",
    },
      
    exteriorDescription2: {
      "en": "With a hefty capacity of 940 kg, including passengers, and balanced weight distribution, it's ideal for heavy loads.",
      "ar": "بسعة كبيرة تبلغ 940 كجم، بما في ذلك الركاب، وتوزيع متوازن للوزن، فهو مثالي للأحمال الثقيلة.",
    },
      
    interiorImages: [CarryInteriorBanner, CarryInterior1],
    interiorContents: [
      {
        title: {
          "en":"Carry with Comfort",
          "ar":"حمل مع الراحة",
        }, 
        description: {
          "en":"Air Conditioner, Sliding Driver’s Seat, Electric Power Steering, Plenty of Storage.",
          "ar":"مكيف هواء، مقعد سائق منزلق، توجيه كهربائي، والكثير من مساحات التخزين.",
        },
          
      },
    ],

    features: [
      {
        image: CarryFeature1,
        title: {
          "en":"Large-size Bed",
          "ar":"سرير بحجم كبير",
        }, 
        description: {
          "en":"With a length of 2,565 mm and a width of 1,660 mm, Carry’s large-size bed makes it easy to haul long objects as well as wide ones. And with a height of 1,160 mm to the top of the cab, even tall cargo fits on the bed.",
          "ar":"بفضل طوله البالغ 2565 مم وعرضه البالغ 1660 مم، يجعل سرير كاري بحجمه الكبير من السهل حمل الأشياء الطويلة وكذلك العريضة. ومع ارتفاع 1160 مم إلى قمة الكابينة، تتسع حتى الحمولة الطويلة على السرير.",
        },
      },

      {
        image: CarryFeature2,
        title: {
          "en":"Low Bed Height",
          "ar":"ارتفاع سرير منخفض",
        }, 
        description: {
          "en":"The bed sits at a low 750 mm height to make loading and unloading easy work. Even with the gate closed, a convenient height of just 1,110 mm makes it easy to access your equipment.",
          "ar":"يجلس السرير على ارتفاع منخفض يبلغ 750 مم لجعل التحميل والتفريغ عملاً سهلاً. حتى مع إغلاق البوابة، يجعل الارتفاع المريح البالغ 1110 مم من السهل الوصول إلى معداتك.",
        },
          
      },

      {
        image: CarryFeature3,
        title: {
          "en":"Extra-large Loading Capacity",
          "ar":"سعة تحميل كبيرة للغاية",
        }, 
        description: {
          "en":"For a work vehicle, large loading capacity is essential. That’s why the all-new Carry can move up to 940 kg, including passengers. And thanks to the full-cab layout, even when fully loaded, Carry provides good weight distribution between the front and rear tires.",
          "ar":"بالنسبة لمركبة العمل، فإن سعة التحميل الكبيرة ضرورية. لهذا السبب يمكن لسيارة كاري الجديدة كليًا أن تتحرك حتى 940 كجم، بما في ذلك الركاب. وبفضل تصميم الكابينة الكاملة، حتى عند التحميل الكامل، توفر كاري توزيعًا جيدًا للوزن بين الإطارات الأمامية والخلفية.",
        },
          
      },

      {
        image: CarryFeature4,
        title: {
          "en":"Rust Protection",
          "ar":"حماية من الصدأ",
        }, 
        description: {
          "en":"Galvanized steel plates are used for the entire bed to help prevent rust and corrosion. Additionally, sealer and undercoating are used in many parts of the underbody, and the stainless-steel exhaust system is rust-resistant.",
          "ar":"تُستخدم ألواح الصلب المجلفن للسرير بالكامل للمساعدة في منع الصدأ والتآكل. بالإضافة إلى ذلك، يتم استخدام السدادة وطلاء الجسم في العديد من أجزاء الجسم السفلي، ونظام العادم المصنوع من الفولاذ المقاوم للصدأ مقاوم للصدأ.",
        },
          
      },

      {
        image: CarryFeature5,
        title: {
          "en":"High-position Headlamps and Bumper",
          "ar":"المصابيح الأمامية والمصد المرتفع",
        }, 
        description: {
          "en":"The front bumper and headlamps are mounted high off the ground to minimize the risk of damage when driving on rough roads.",
          "ar":"يتم تركيب المصد الأمامي والمصابيح الأمامية على ارتفاع عالٍ عن الأرض لتقليل خطر التلف عند القيادة على الطرق الوعرة.",
        }
          
      },

      {
        image: CarryFeature6,
        title: {
          "en":"Ramp Break-over Angle",
          "ar":"زاوية العبور فوق المنحدرات",
        }, 
        description: {
          "en":"One of the great advantages of Carry’s full cab layout is the short wheelbase. The ramp break-over angle offers plenty of clearance over obstacles and bumpy roads.",
          "ar":"أحد المزايا الكبيرة لتصميم الكابينة الكاملة لكاري هو قاعدة العجلات القصيرة. توفر زاوية العبور فوق المنحدرات الكثير من الخلوص فوق العوائق والطرق الوعرة.",
        },
          
      },
    ],
    specSubtitle: {
      "en":"What makes Carry compact?",
      "ar":"ما الذي يجعل كاري مضغوطًا؟",
    }, 
    models: [{ type: "STD" }, { type: "UPPER" }],
    specifications: {
      features: [
        {
          name: {
            "en":"SRS front dual airbag	",
            "ar":"وسادة هوائية أمامية مزدوجة SRS",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"ABS - Anti-lock Braking System	",
            "ar":"نظام المكابح المانع للانغلاق (ABS)",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"ESP®* - Electronic Stability Program	",
            "ar":"برنامج الثبات الإلكتروني (ESP®*)",
          }, 
          STD:  "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Engine drag control	",
            "ar":"تحكم في جر المحرك",
          }, 
          STD:  "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"TPMS (Tyre pressure monitoring system)",
            "ar":"نظام مراقبة ضغط الإطارات (TPMS)",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Steering lock	",
            "ar":"قفل التوجيه",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Immobilizer	",
            "ar":"جهاز منع الحركة",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"3-spoke steering wheel - Urethane with air bag	",
            "ar":"عجلة قيادة بثلاثة أذرع - يوريثان مع وسادة هوائية",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Headlamps - Halogen multi-reflector	",
            "ar":"المصابيح الأمامية - عاكس متعدد الهالوجين",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Fog lamps - Front & Rear	",
            "ar":"مصابيح ضباب - أمامية وخلفية",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Mud flaps - Front & Rear	",
            "ar":"رفارف الطين - أمامية وخلفية",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Rope hooks	",
            "ar":"خطافات الحبل",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Body color bumper	",
            "ar": "مصد بلون الجسم",
          }, 
          STD: "--",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Outside door mirrors - Manually adjustable",
            "ar":"مرايا الأبواب الخارجية - قابلة للتعديل يدويًا",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Driver's seatbelt reminder - Lamp & alarm	",
            "ar":"تذكير بحزام الأمان للسائق - مصباح وإنذار",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Low fuel warning - Lamp	",
            "ar":"تحذير انخفاض الوقود - مصباح",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Air conditioner - Manual	",
            "ar":"مكيف هواء - يدوي",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"12V interior accessory socket - Centre console x 1	",
            "ar":"مقبس ملحقات داخلي 12 فولت - وحدة التحكم المركزية x 1",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"USB socket - Centre console x 1	",
            "ar":"مقبس USB - وحدة التحكم المركزية x 1",
          }, 
          STD: "--",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Front seats - Slide function	",
            "ar":"المقاعد الأمامية - وظيفة الانزلاق",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Head Rest - Driver & Passenger side	",
            "ar":"مسند الرأس - جانب السائق والراكب",
          }, 
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en": "Power Steering	",
            "ar":"توجيه كهربائي",
          },
          STD: "Yes",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"1DIN MP3 and AM/FM audio	",
            "ar":"نظام صوت 1DIN MP3 و AM/FM",
          }, 
          STD: "--",
          UPPER: "Yes",
        },

        {
          name: {
            "en":"Antenna (mounted at rear of roof)	",
            "ar": "هوائي (مثبت في الجزء الخلفي من السقف)",
          }, 
          STD: "--",
          UPPER: "Yes",
        },

        {
          name: {
            "en": "Speaker x 2	",
            "ar":"مكبر صوت x 2",
          },
          STD: "--",
          UPPER: "Yes",
        },
      ],

      dimensions: [
        {
          name: {
            "en":"Overall length (mm)",
            "ar":"الطول الكلي (مم)",
          }, 
          STD: "4,195	",
          UPPER: "4,195	",
        },

        {
          name: {
            "en": "Overall width (mm)	",
            "ar":"العرض الكلي (مم)",
          },
          STD: "1,765",
          UPPER: "1,765",
        },

        {
          name: {
            "en":"Overall height (mm)	",
            "ar":"الارتفاع الكلي (مم)",
          }, 
          STD: "1,910	",
          UPPER: "1,910	",
        },

        {
          name: {
            "en":"Wheelbase (mm)	",
            "ar":"قاعدة العجلات (مم)",
          }, 
          STD: "2,205",
          UPPER: "2,205",
        },

        {
          name: {
            "en":"Tread(mm)	",
            "ar":"عرض المسار (مم)",
          }, 
          STD: "",
          UPPER: "",
        },

        {
          name: {
            "en":"Front	",
            "ar":"الأمام",
          }, 
          STD: "1,465",
          UPPER: "1,465",
        },

        {
          name: {
            "en":"Rear",
            "ar":"الخلف",
          }, 
          STD: "1,460	",
          UPPER: "1,460",
        },

        {
          name: {
            "en":"Cargo bed length	",
            "ar":"طول سرير الشحن",
          }, 
          STD: "2,375 (MIN)	",
          UPPER: "2,375 (MIN)	",
        },

        {
          name: {
            "en":"Cargo bed width	",
            "ar":"عرض سرير الشحن",
          }, 
          STD: "1,660	",
          UPPER: "1,660	",
        },

        {
          name: {
            "en":"Cargo bed height	",
            "ar":"ارتفاع سرير الشحن",
          }, 
          STD: "355	",
          UPPER: "355	",
        },

        {
          name: {
            "en":"Minimum turning radius (m)	",
            "ar":"أدنى نصف قطر دوران (م)",
          }, 
          STD: "4.4	",
          UPPER: "4.4	",
        },

        {
          name: {
            "en":"Minimum ground clearance (mm)	",
            "ar": "أدنى خلوص أرضي (مم)",
          }, 
          STD: "160	",
          UPPER: "160	",
        },
      ],

      capacity: [
        {
          name: {
            "en":"Seating capacity (Including Driver)	",
            "ar":"سعة الجلوس (بما في ذلك السائق)",
          }, 
          STD: "2",
          UPPER: "2",
        },

        {
          name: {
            "en":"Loading capacity - Max. volume (Incl.Driver ＆ Passengers) kg	",
            "ar":"سعة التحميل - الحجم الأقصى (بما في ذلك السائق والركاب) كجم",
          }, 
          STD: "910～945	",
          UPPER: "910～945	",
        },

        {
          name: {
            "en": "Fuel tank capacity (litres)	",
            "ar":"سعة خزان الوقود (لترات)",
          },
          STD: "43",
          UPPER: "43",
        },

        {
          name: {
            "en":"Number of doors	",
            "ar":"عدد الأبواب",
          }, 
          STD: "2",
          UPPER: "2",
        },
      ],

      engine: [
        {
          name: {
            "en":"Engine (4 CYL)	",
            "ar":"المحرك (4 أسطوانات)",
          }, 
          STD: "1.5L VVT	",
          UPPER: "1.5L VVT	",
        },

        {
          name: {
            "en":"Number of cylinders",
            "ar":"عدد الأسطوانات",
          },
          STD: "4",
          UPPER: "4",
        },

        {
          name: {
            "en":"Number of valves",
            "ar":"عدد الصمامات",
          },
          STD:"16",
          UPPER:"16"
        }, 

        {
          name: {
            "en":"Maximum output (kW/rpm)",
            "ar":"القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          },
          STD:"71 / 5,600",
          UPPER:"71 / 5,600"
        },

        {
          name: {
            "en":"Maximum torque (N･m/rpm)",
            "ar":"أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          },
          STD:"135 / 4,400",
          UPPER:"135 / 4,400"
        },

        {
          name: {
            "en":"Drive System	",
            "ar":"نظام الدفع",
          }, 
          STD: "2WD	",
          UPPER: "2WD	",
        },

        {
          name: {
            "en":"Transmission	",
            "ar":"ناقل الحركة",
          }, 
          STD: "5MT	",
          UPPER: "5MT	",
        },

        {
          name: {
            "en":"Deck Type	",
            "ar":"نوع السطح",
          }, 
          STD: "3 Way	",
          UPPER: "3 Way	",
        },

        {
          name: {
            "en":"Fuel distribution",
            "ar":"توزيع الوقود",
          },
          STD:"Multipoint Injection",
          UPPER:"Multipoint Injection",
        },
      ],

      transmission: [
        {
          name: {
            "en":"Gear Ratio	",
            "ar":"نسبة التروس",
          }, 
          STD: "",
          UPPER: "",
        },

        {
          name: {
            "en":"Type	",
            "ar": "نوع",
          }, 
          STD: "5MT	",
          UPPER: "5MT	",
        },

        {
          name: {
            "en":"1st	",
            "ar":"1st	",
          }, 
          STD: "3.580	",
          UPPER: "3.580	",
        },

        {
          name: {
            "en":"2nd	",
            "ar":"2nd	",
          }, 
          STD: "2.095	",
          UPPER: "2.095	",
        },

        {
          name: {
            "en":"3rd	",
            "ar":"3rd	",
          }, 
          STD: "1.531	",
          UPPER: "1.531	",
        },

        {
          name: {
            "en":"4th	",
            "ar":"4th	",
          }, 
          STD: "1.000	",
          UPPER: "1.000",
        },

        {
          name: {
            "en": "5th	",
            "ar": "5th	",
          },
          STD: "0.855",
          UPPER: "0.855",
        },

        {
          name: {
            "en":"Reverse	",
            "ar":"الرجوع",
          }, 
          STD: "3.727	",
          UPPER: "3.727	",
        },

        {
          name: {
            "en":"Final Gear Ratio	",
            "ar":"نسبة الترس النهائي",
          }, 
          STD: "4.875	",
          UPPER: "4.875	",
        },
      ],

      weight: [
        {
          name: {
            "en":"Kerb weight (min./with full option)	",
            "ar":"الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          }, 
          STD: "1,065-1,100	",
          UPPER: "1,065-1,100",
        },

        {
          name: {
            "en":"Gross vehicle weight (kg)	",
            "ar":"الوزن الإجمالي للمركبة (كجم)",
          }, 
          STD: "2,010",
          UPPER: "2,010",
        },
      ],
    },
  },

  // CARRY DATA SOURCE ENDS

  // EECO DATA SOURCE STARTS

  {
    id: "eeco",
    title: {
      en: "Eeco",
      ar: "ايكو",
    },
    image: EecoImage,
    video: EecoVideo,
    category: "Commercial",
    discontinued: false,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
    colors: [{ name: "White", hexCode:"#E3E3E3", customName: { en:"Arctic White Pearl", ar:"لؤلؤة بيضاء قطبية"}, image: EecoWhiteBanner }],
    variants: [
      // { name: "CARGO VAN 1.2L MT 2023", price: "45,885", items: [] },
      { name: "CARGO VAN 1.2L MT 2024", price: "47,150", items: [] },
    ],
    overview: [
      { title: {
        en: "Drive Line",
        ar: "خط القيادة",
      }, data: "RWD" },
      { title: {
        en: "Engine",
        ar: "المحرك",
      }, data: "1.2L" },
      { title: {
        en: "Horse Power",
        ar: "القدرة الحصانية",
      }, data: "79" },
      { title: {
        en: "Fuel Efficiency",
        ar: "كفاءة الوقود",
      }, data: "19.8 KMPL" },
      { title: {
        en: "Fuel Tank Capacity",
        ar: "سعة خزان الوقود",
      }, data: "32L" },
    ],
    brochure: EecoBrochure,
    shortDescription: {
      "en":"Your reliable partner for every journey, where comfort and affordability meet.",
      "ar":"شريكك الموثوق لكل رحلة، حيث تلتقي الراحة مع القدرة على التحمل.",
    },
      
    tagline: {
      "en":"Efficient. Practical. Reliable.",
      "ar":"فعال. عملي. موثوق.",
    }, 
    View360Image: Eeco360ViewImage,
    exteriorImages: [EecoExterior1, EecoExterior2],

    exteriorTitle: {
      "en":"BOOST EFFICIENCY.",
      "ar":"زيادة الكفاءة.",
    }, 
    exteriorDescription1: {
      "en":"The Suzuki Eeco’s practical design combines functionality with simplicity, featuring a straightforward front grille, large headlamps, and a spacious, boxy shape.",
      "ar":"يجمع تصميم سوزوكي إييكو العملي بين الوظائف والبساطة، ويتميز بشبك أمامي بسيط، مصابيح أمامية كبيرة، وشكل صندوقي فسيح.",
    },
      
    exteriorDescription2: {
      "en":"Its clean lines and utilitarian build highlight its focus on versatility and everyday usability.",
      "ar":"تؤكد خطوطه النظيفة وبنيته العملية على تركيزه على التعددية وقابلية الاستخدام اليومية.",
    },
      
    interiorImages: [EecoInteriorBanner, EecoInterior1],
    interiorContents: [
      {
        title: {
          "en":"Driver Adjustments",
          "ar":"تعديلات السائق",
        }, 
        description: {
          "en":"A sliding driver and passenger seat and reclining front seats allow you to adjust the seats as per your need, making your travels more comfortable and easier. With the addition of air conditioning, the cabin experience in the new Eco Cargo will be even more comfortable for you while you are doing your work",
          "ar":"مقعد سائق وراكب منزلق ومقاعد أمامية قابلة للإمالة تسمح لك بضبط المقاعد حسب حاجتك، مما يجعل رحلاتك أكثر راحة وسهولة. مع إضافة تكييف الهواء، ستجعل تجربة المقصورة في إييكو كارغو الجديدة أكثر راحة لك أثناء قيامك بعملك",
        }
          
      },
    ],
    features: [
      {
        image: EecoFeature1,
        title: {
          "en":"ABS (Anti-lock Braking System)",
          "ar":"نظام المكابح المانع للانغلاق (ABS)",
        }, 
        description: {
          "en": "The ABS (Anti-lock Braking System) in the Suzuki EECO enhances safety by preventing wheel lock-up during sudden braking, maintaining steering control and stability on various road surfaces.",
          "ar":"نظام المكابح المانع للانغلاق (ABS) في سوزوكي إييكو يعزز الأمان عن طريق منع انغلاق العجلات أثناء الكبح المفاجئ، والحفاظ على التحكم في التوجيه والاستقرار على مختلف أسطح الطرق.",
        },
         
      },

      {
        image: EecoFeature2,
        title: {
          "en":"Reverse Parking Sensor",
          "ar":"مستشعر الركن الخلفي",
        }, 
        description: {
          "en":"Ultrasonic sensors in the rear bumper detect obstacles when in reverse gear, and audible alerts sound to indicate your approximate distance from the obstacles as you approach.",
          "ar":"تكتشف أجهزة الاستشعار فوق الصوتية في المصد الخلفي العوائق عند وضع الجير في الرجوع، وتصدر تنبيهات صوتية للإشارة إلى المسافة التقريبية بينك وبين العوائق أثناء الاقتراب.",
        },
          
      },

      {
        image: EecoFeature3,
        title: {
          "en":"Airbags",
          "ar":"وسائد هوائية",
        }, 
        description: {
          "en":"Dual front airbags help protect the driver and passenger in the event of a collision.",
          "ar":"تساعد الوسائد الهوائية الأمامية المزدوجة في حماية السائق والراكب في حالة التصادم.",
        },
          
      },
    ],
    specSubtitle: {
      "en":"What makes Eeco efficient?",
      "ar":"ما الذي يجعل إييكو فعالة؟",
    }, 
    models: [{ type: "VANCargo" }],
    specifications: {
      features: [
        {
          name: {
            "en":"SRS front dual airbag",
            "ar":"وسادة هوائية أمامية مزدوجة SRS",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"ABS - Anti-lock Braking System",
            "ar":"نظام المكابح المانع للانغلاق (ABS)",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"ESP®* - Electronic Stability Program",
            "ar":"برنامج الثبات الإلكتروني (ESP®*)",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"TPMS (Tyre pressure monitoring system)",
            "ar":"نظام مراقبة ضغط الإطارات (TPMS)",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Parking sensors Rear",
            "ar":"أجهزة استشعار الركن الخلفية",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"High-mount stop lamp",
            "ar":"مصباح توقف عالي التركيب",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Immobiliser",
            "ar":"نظام منع السرقة",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Air conditioner - Manual",
            "ar":"مكيف هواء - يدوي",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Front Seats",
            "ar":"المقاعد الأمامية",
          }, 
          VANCargo: "2",
        },

        {
          name: {
            "en":"Slide & Reclining (Drive & Passenger side)",
            "ar":"انزلاق وإمالة (جانب السائق والراكب)",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Slide function (passenger's side)",
            "ar":"وظيفة الانزلاق (جانب الراكب)",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Headlamps - Halogen multi-reflector",
            "ar":"المصابيح الأمامية - عاكس متعدد الهالوجين",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Fog lamps - Front",
            'ar':"مصابيح ضباب - أمامية",
          },
          VANCargo:"Yes"
        },

        {
          name: {
            "en":"Fog lamps - Rear",
            "ar":"مصابيح الضباب - خلفية",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Outside door mirrors Manually adjustable",
            "ar":"مرايا الأبواب الخارجية قابلة للتعديل يدويًا",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Rear window demister",
            "ar":"مزيلة الضباب للنافذة الخلفية",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Manual windows Front",
            "ar":"نوافذ يدوية أمامية",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Side door Sliding door (Both sides)",
            "ar":"باب جانبي - باب منزلق (كلا الجانبين)",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Mud flaps - Front",
            "ar":"اللوحات الطينية - الأمامية",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Tyre Pressure Warning Lamp",
            "ar":"مصباح تحذير ضغط الإطارات",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Cabin Lights",
            "ar":"أضواء المقصورة",
          }, 
          VANCargo: "",
        },

        {
          name: {
            "en":"Front cabin light (3-position)",
            "ar":"ضوء المقصورة الأمامي (3 وضعيات)",
          },
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Centre cabin light (3-position)",
            "ar":"ضوء المقصورة الأوسط (3 وضعيات)",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en": "Audio",
            "ar":"نظام صوتي",
          },
          VANCargo: "",
        },

        {
          name: {
            "en": "Antenna",
            "ar":"هوائي",
          },
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"Speaker x 2",
            "ar":"مكبر صوت x 2",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"1DIN Pocket+ 1DIN Cover",
            "ar":"جيب 1DIN + غطاء 1DIN",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"12V accessory socket Instrument panel",
            "ar":"مقبس ملحقات 12 فولت لوحة العدادات",
          }, 
          VANCargo: "Yes",
        },

        {
          name: {
            "en":"MP3/USB/AUX/SW and DAB Radio, Bluetooth- Locally Installed - Made in China",
            "ar":"مدخل MP3/USB/ AUX/SW وبلوتوث - مجهز محليًا صنع في الصين"
          },
          VANCargo:"Yes"
        }
      ],

      dimensions: [
        {
          name: {
            "en":"Overall length (mm)",
            "ar":"الطول الكلي (مم)",
          }, 
          VANCargo: "3,675",
        },

        {
          name: {
            "en":"Overall width (mm)",
            "ar":"العرض الكلي (مم)",
          }, 
          VANCargo: "1,475",
        },

        {
          name: {
            "en":"Overall height (mm)",
            "ar":"الارتفاع الكلي (مم)",
          }, 
          VANCargo: "1,825",
        },

        {
          name: {
            "en":"Wheelbase (mm)",
            "ar":"قاعدة العجلات (مم)",
          }, 
          VANCargo: "2,350",
        },

        {
          name: {
            "en": "Tread(mm)	",
            "ar":"عرض المسار (مم)",
          },
          VANCargo: "",
        },

        {
          name: {
            "en":"Front	",
            "ar":"الأمام",
          }, 
          VANCargo: "1,280",
        },

        {
          name: {
            "en":"Rear",
            "ar":"الخلف",
          }, 
          VANCargo: "1,290",
        },

        {
          name: {
            "en":"Load Compartment",
            "ar":"حجرة التحميل",
          },
          VANCargo: "",
        },

        {
          name: {
            "en":"Overall length (mm)",
            "ar":"الطول الكلي (مم)",
          }, 
          VANCargo: "1,620",
        },

        {
          name: {
            "en":"Overall width (mm)",
            "ar":"العرض الكلي (مم)",
          }, 
          VANCargo: "1,300",
        },

        {
          name: {
            "en":"Overall height (mm)",
            "ar":"الارتفاع الكلي (مم)",
          }, 
          VANCargo: "1,070",
        },

        {
          name: {
            "en":"Minimum turning radius (m)",
            "ar":"أدنى نصف قطر دوران (م)",
          }, 
          VANCargo: "4.5",
        },

        {
          name: {
            "en":"Minimum ground clearance (mm)	",
            "ar": "أدنى خلوص أرضي (مم)",
          }, 
          VANCargo: "160",
        },

        {
          name: {
            "en":"Overhang Front",
            "ar":"الجبهة المتراكمة",
          },
          VANCargo: "670",
        },

        {
          name: {
            "en":"Overhang Rear",
            "ar":"المتراكمة الخلفية",
          },
          VANCargo: "655",
        },
      ],

      capacity: [
        {
          name: {
            "en":"Seating capacity (Including Driver)",
            "ar":"سعة الجلوس (بما في ذلك السائق)",
          },  
          VANCargo: "2",
        },

        {
          name: {
            "en":"Loading capacity - Max. volume (Incl.Driver ＆ Passengers) kg	",
            "ar":"سعة التحميل - الحجم الأقصى (بما في ذلك السائق والركاب) كجم",
          }, 
          VANCargo: "605-615",
        },

        {
          name: {
            "en":"Fuel tank capacity (litres)	",
            "ar":"سعة خزان الوقود (لترات)",
          }, 
          VANCargo: "32",
        },

        {
          name: {
            "en":"Number of doors",
            "ar":"عدد الأبواب",
          }, 
          VANCargo: "5",
        },
      ],

      engine: [
        {
          name: {
            "en":"Engine (4 CYL)",
            "ar":"المحرك (4 أسطوانات)",
          }, 
          VANCargo: "1.2L",
        },

        {
          name: {
            "en":"Number of cylinders",
            "ar":"عدد الأسطوانات",
          },
          VANCargo:"4"
        },

        {
          name: {
            "en":"Number of valves",
            "ar":"عدد الصمامات",
          },
          VANCargo:"16"
        },

        {
          name: {
            "en":"Maximum output (kW/rpm)",
            "ar":"القدرة القصوى (كيلوواط/دورة في الدقيقة)",
          },
          VANCargo:"59/6,000"
        },

        {
          name: {
            "en":"Maximum torque (N･m/rpm)",
            "ar":"أقصى عزم دوران (نيوتن متر/دورة في الدقيقة)",
          },
          VANCargo:"104/3,000"
        },

        {
          name: {
            "en":"Drive System",
            "ar": "نظام الدفع",
          }, 
          VANCargo: "2WD",
        },

        {
          name: {
            "en":"Transmission",
            "ar":"ناقل الحركة",
          }, 
          VANCargo: "5MT",
        },

        {
          name: {
            "en":"Fuel distribution",
            "ar":"توزيع الوقود",
          },
          VANCargo:"Multipoint Injection"
        }
      ],

      transmission: [
        {
          name: {
            "en":"Gear Ratio",
            "ar":"نسبة التروس",
          }, 
          VANCargo: "",
        },

        {
          name: {
            "en":"Type",
            "ar":"نوع",
          }, 
          VANCargo: "5MT",
        },

        {
          name: {
            "en":"1st	",
            "ar":"1st	",
          }, 
          VANCargo: "4.425",
        },

        {
          name: {
            "en":"2nd",
            "ar":"2nd",
          }, 
          VANCargo: "2.304",
        },

        {
          name: {
            "en":"3rd	",
            "ar":"03rd	",
          },
          VANCargo: "1.675",
        },

        {
          name: {
            "en": "4th",
            "ar": "4th",
          },
          VANCargo: "1.225",
        },

        {
          name: {
            "en": "5th",
            "ar": "5th",
          },
          VANCargo: "1",
        },

        {
          name: {
            "en":"Reverse",
            "ar":"الرجوع",
          },
          VANCargo: "5.152",
        },

        {
          name: {
            "en":"Final Gear Ratio",
            "ar":"نسبة الترس النهائي",
          }, 
          VANCargo: "3.417",
        },
      ],

      weight: [
        {
          name: {
            "en":"Kerb weight (min./with full option)	",
            "ar":"الوزن الفارغ (الحد الأدنى/مع كافة الخيارات)",
          }, 
          VANCargo: "925-935",
        },

        {
          name: {
            "en":"Gross vehicle weight (kg)	",
            "ar":"الوزن الإجمالي للمركبة (كجم)",
          }, 
          VANCargo: "1,540",
        },
      ],
    },
  },

  // EECO DATA SOURCE ENDS

  // VITARA DATA SOURCE STARTS

  {
    id: "vitara", 
    title: {
      en:"Vitara",
      ar:"فيتارا"
    }, 
    image: VitaraImage,
    category: "suv-crossover",
    discontinued: true,
    showOnSparePartsPage: true,
    maintenanceOnly: true,
  },

  // VITARA DATA SOURCE ENDS

  // APV DATA SOURCE STARTS

  {
    id: "apv",
    title: {
      en: "APV",
      ar:"أي بي في"
    },
    image: APVImage,
    category: "commercial",
    discontinued: true,
    maintenanceOnly: true,
    showOnSparePartsPage: false,
  },

  // APV DATA SOURCE ENDS

  // SX4 DATA SOURCE STARTS

  {
    id: "sx4",
    title: {
      en:"SX4",
      ar:"اس اكس 4"
    }, 
    image: SX4Image,
    category: "suv-crossover",
    discontinued: true,
    maintenanceOnly: true,
    showOnSparePartsPage: false,
  },

  // SX4 DATA SOURCE ENDS
];

export default vehicles;
