import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import VehicleCard from "../VehicleCard/VehicleCard";
import vehicles from "../../hooks/vehicles";
import "./CategorizedVehicles.css";

const CategorizedVehicles = ({ showExploreLink = true }) => {
  const [key, setKey] = useState("all");

  const filteredVehicles = vehicles.filter((vehicle) => {
    return !vehicle.discontinued && (key === "all" || vehicle.category.toLowerCase() === key);
  });

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./CategorizedVehicles-ar.css");
    } else {
      import("./CategorizedVehicles.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="section-spacing">
      <Container className="categorized-vehicles">
        <Tab.Container
          id="vehicle-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Nav variant="pills" className="justify-content-center mb-3 text-uppercase">
            <Nav.Item>
              <Nav.Link className="tab-link" eventKey="all">
                {t("All Vehicles")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="tab-link" eventKey="sedan">
                {t("Sedan")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="tab-link" eventKey="hatchback">
                {t("Hatchback")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="tab-link" eventKey="suv-crossover">
                {t("SUV/Crossover")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="tab-link" eventKey="commercial">
                {t("Commercial")}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="mt-5">
            <Tab.Pane eventKey="all">
              <Row className="justify-content-center">
                {filteredVehicles.map((vehicle) => (
                  <Col key={vehicle.id} md={6} lg={3}>
                    <VehicleCard vehicle={vehicle} showPrice={true} showExploreLink={showExploreLink} />
                  </Col>
                ))}
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="sedan">
              <Row className="justify-content-center">
                {filteredVehicles.filter(vehicle => vehicle.category.toLowerCase() === 'sedan').map((vehicle) => (
                  <Col key={vehicle.id} md={6} lg={3}>
                    <VehicleCard vehicle={vehicle} showPrice={true} showExploreLink={showExploreLink} />
                  </Col>
                ))}
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="hatchback">
              <Row className="justify-content-center">
                {filteredVehicles.filter(vehicle => vehicle.category.toLowerCase() === 'hatchback').map((vehicle) => (
                  <Col key={vehicle.id} md={6} lg={3}>
                    <VehicleCard vehicle={vehicle} showPrice={true} showExploreLink={showExploreLink} />
                  </Col>
                ))}
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="suv-crossover">
              <Row className="justify-content-center">
                {filteredVehicles.filter(vehicle => vehicle.category.toLowerCase() === 'suv-crossover').map((vehicle) => (
                  <Col key={vehicle.id} md={6} lg={3}>
                    <VehicleCard vehicle={vehicle} showPrice={true} showExploreLink={showExploreLink} />
                  </Col>
                ))}
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="commercial">
              <Row className="justify-content-center">
                {filteredVehicles.filter(vehicle => vehicle.category.toLowerCase() === 'commercial').map((vehicle) => (
                  <Col key={vehicle.id} md={6} lg={3}>
                    <VehicleCard vehicle={vehicle} showPrice={true} showExploreLink={showExploreLink} />
                  </Col>
                ))}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default CategorizedVehicles;
