import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "../../components/ContactForm/ContactForm";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";
import "../ContactPage/ContactPage.css";

const ContactFormPage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./ContactPage-ar.css");
    } else {
      import("./ContactPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const vehicles = [
    t("Dzire"),
    t("Baleno"),
    t("Ciaz"),
    t("Swift"),
    t("Fronx"),
    t("Grand Vitara"),
    t("Ertiga"),
    t("Jimny"),
    t("Jimny 5 Doors"),
    t("Carry"),
    t("Eeco"),
  ];
  const cities = [t("Riyadh"), t("Jeddah"), t("Dammam"), t("Al Khobar"), t("Al Ahsa"), t("Madinah"), t("Al Kharj"), t("Khamis Mushait"), t("Najran"), t("Onaiza"), t("Mecca")];
  const inquiryTypes = [
    t("General Inquiry"),
    t("Complaint"),
    t("Offers"),
    t("Services"),
    t("Spare Parts"),
    t("Fleet Sales"),
  ];

  return (
    <>
      <div className="sec-bg section-spacing">
        <Container>
          <Row className="align-items-center">
            <Col md={4}>
              <div className="contact-content">
                <h2>{t("Contact Us")}</h2>
                <h6>{t("Saturday - Thursday")}</h6>
                <p>{t("9 AM till 9 PM")}</p>
                <h6>{t("Toll Free Number")}</h6>
                <a href="tel:8002444441">
                  <p>8002444441</p>
                </a>
                <h6>{t("Find us on Whatsapp")}</h6>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=966503666241"
                >
                  <p className="dir-ltr">+966 503 666 241</p>
                </a>
                <h6>{t("Social Media")}</h6>
                <ul className="contact-social">
                  <li>
                    <a href="https://www.instagram.com/najeebauto/?igsh=andtcDVlamdhaGE0&utm_source=qr">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.017 2H7.947C6.37015 2.00185 4.85844 2.62914 3.74353 3.74424C2.62862 4.85933 2.00159 6.37115 2 7.948L2 16.018C2.00185 17.5948 2.62914 19.1066 3.74424 20.2215C4.85933 21.3364 6.37115 21.9634 7.948 21.965H16.018C17.5948 21.9631 19.1066 21.3359 20.2215 20.2208C21.3364 19.1057 21.9634 17.5938 21.965 16.017V7.947C21.9631 6.37015 21.3359 4.85844 20.2208 3.74353C19.1057 2.62862 17.5938 2.00159 16.017 2V2ZM19.957 16.017C19.957 16.5344 19.8551 17.0467 19.6571 17.5248C19.4591 18.0028 19.1689 18.4371 18.803 18.803C18.4371 19.1689 18.0028 19.4591 17.5248 19.6571C17.0467 19.8551 16.5344 19.957 16.017 19.957H7.947C6.90222 19.9567 5.90032 19.5415 5.16165 18.8026C4.42297 18.0638 4.008 17.0618 4.008 16.017V7.947C4.00827 6.90222 4.42349 5.90032 5.16235 5.16165C5.90122 4.42297 6.90322 4.008 7.948 4.008H16.018C17.0628 4.00827 18.0647 4.42349 18.8034 5.16235C19.542 5.90122 19.957 6.90322 19.957 7.948V16.018V16.017Z"
                          fill="#17295A"
                        />
                        <path
                          d="M11.9823 6.81897C10.6137 6.82109 9.30184 7.36576 8.33421 8.33358C7.36658 9.3014 6.82216 10.6134 6.82031 11.982C6.8219 13.3509 7.36634 14.6633 8.33421 15.6314C9.30209 16.5994 10.6144 17.1441 11.9833 17.146C13.3524 17.1444 14.665 16.5998 15.6331 15.6317C16.6012 14.6636 17.1457 13.3511 17.1473 11.982C17.1452 10.6131 16.6003 9.30085 15.632 8.33316C14.6637 7.36547 13.3512 6.82129 11.9823 6.81997V6.81897ZM11.9823 15.138C11.1456 15.138 10.3431 14.8056 9.75139 14.2139C9.15971 13.6222 8.82731 12.8197 8.82731 11.983C8.82731 11.1462 9.15971 10.3437 9.75139 9.75205C10.3431 9.16037 11.1456 8.82797 11.9823 8.82797C12.8191 8.82797 13.6216 9.16037 14.2132 9.75205C14.8049 10.3437 15.1373 11.1462 15.1373 11.983C15.1373 12.8197 14.8049 13.6222 14.2132 14.2139C13.6216 14.8056 12.8191 15.138 11.9823 15.138V15.138Z"
                          fill="#17295A"
                        />
                        <path
                          d="M17.155 8.09497C17.8381 8.09497 18.392 7.54115 18.392 6.85797C18.392 6.1748 17.8381 5.62097 17.155 5.62097C16.4718 5.62097 15.918 6.1748 15.918 6.85797C15.918 7.54115 16.4718 8.09497 17.155 8.09497Z"
                          fill="#17295A"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/najeebauto?s=21&t=3Fvai_OFATn0DysHTpZmpg&mx=2" target="_blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_1268_19860"
                          maskUnits="userSpaceOnUse"
                          x="3"
                          y="2"
                          width="19"
                          height="20"
                        >
                          <path
                            d="M3 2.04639H22V21.0464H3V2.04639Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_1268_19860)">
                          <path
                            d="M17.9625 2.93652H20.8763L14.5113 10.2298L22 20.1559H16.1371L11.5419 14.137L6.28971 20.1559H3.37321L10.1806 12.3524L3 2.93788H9.01214L13.1596 8.43838L17.9625 2.93652ZM16.9379 18.4079H18.5529L8.13 4.59359H6.39829L16.9379 18.4079Z"
                            fill="#17295A"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@najeebauto" target="_blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.6002 5.82C15.9166 5.03962 15.5399 4.03743 15.5402 3H12.4502V15.4C12.4263 16.071 12.143 16.7066 11.6599 17.1729C11.1768 17.6393 10.5316 17.8999 9.86016 17.9C8.44016 17.9 7.26016 16.74 7.26016 15.3C7.26016 13.58 8.92016 12.29 10.6302 12.82V9.66C7.18016 9.2 4.16016 11.88 4.16016 15.3C4.16016 18.63 6.92016 21 9.85016 21C12.9902 21 15.5402 18.45 15.5402 15.3V9.01C16.7932 9.90985 18.2975 10.3926 19.8402 10.39V7.3C19.8402 7.3 17.9602 7.39 16.6002 5.82Z"
                          fill="#17295A"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.snapchat.com/add/najeebauto?sender_web_id=d24d0196-621d-40f2-bcd2-835d94c8abec&device_type=desktop&is_copy_url=true" target="_blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.7143 9.47496C17.7143 0.903529 6.28571 0.903529 6.28571 9.47496V10.9035H4.73524C4.18 10.9035 3.95143 11.6169 4.40381 11.9397L6.28571 13.2845C5.96857 14.3959 4.66667 16.8083 2 17.5702C2.31714 18.0464 3.2381 18.9988 4.38095 18.9988L5.33333 20.4273L7.71429 19.9511C8.50762 20.5864 10.4762 21.8559 12 21.8559C13.5238 21.8559 15.4924 20.5864 16.2857 19.9511L18.6667 20.4273L19.619 18.9988C20.7619 18.9988 21.6829 18.0464 22 17.5702C19.3333 16.8083 18.0314 14.3959 17.7143 13.2845L19.5962 11.9397C20.0486 11.6169 19.82 10.9035 19.2648 10.9035H17.7143V9.47496Z"
                          stroke="#17295A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@najeebauto-suzuki2682" target="_blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.812 5.01699H6.145C3.855 5.01699 2 6.85199 2 9.11599V14.884C2 17.148 3.856 18.984 6.145 18.984H17.812C20.102 18.984 21.957 17.148 21.957 14.884V9.11599C21.957 6.85199 20.101 5.01599 17.812 5.01599V5.01699ZM15.009 12.28L9.552 14.855C9.51872 14.871 9.48192 14.8784 9.44503 14.8763C9.40815 14.8743 9.37237 14.863 9.34103 14.8434C9.3097 14.8239 9.28382 14.7967 9.2658 14.7645C9.24779 14.7322 9.23822 14.6959 9.238 14.659V9.34999C9.23867 9.31286 9.24872 9.27651 9.26722 9.24432C9.28573 9.21212 9.31208 9.18513 9.34382 9.16587C9.37556 9.1466 9.41167 9.13569 9.44877 9.13413C9.48587 9.13258 9.52276 9.14044 9.556 9.15699L15.014 11.892C15.0504 11.9101 15.0809 11.9381 15.102 11.9728C15.1232 12.0075 15.1341 12.0474 15.1336 12.0881C15.1331 12.1287 15.1211 12.1683 15.0991 12.2025C15.077 12.2366 15.0458 12.2638 15.009 12.281V12.28Z"
                          fill="#17295A"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/najeeb-auto/" target="_blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.959 13.719V21.098H17.681V14.213C17.681 12.483 17.062 11.303 15.514 11.303C14.332 11.303 13.628 12.099 13.319 12.868C13.206 13.143 13.177 13.526 13.177 13.911V21.098H8.897C8.897 21.098 8.955 9.438 8.897 8.229H13.177V10.053L13.149 10.095H13.177V10.053C13.745 9.178 14.76 7.927 17.033 7.927C19.848 7.927 21.959 9.767 21.959 13.719V13.719ZM4.421 2.026C2.958 2.026 2 2.986 2 4.249C2 5.484 2.93 6.473 4.365 6.473H4.393C5.886 6.473 6.813 5.484 6.813 4.249C6.787 2.986 5.887 2.026 4.422 2.026H4.421ZM2.254 21.098H6.532V8.229H2.254V21.098Z"
                          fill="#17295A"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={8}>
              <div className="form-wrap">
                <div className="form-title">
                  <h2>{t("Get in Touch")}</h2>
                </div>
                <ContactForm
                  cities={cities}
                  vehicles={vehicles}
                  inquiryTypes={inquiryTypes}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default ContactFormPage;
