import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import FleetSalesInquiryForm from "../../components/FleetSalesInquiryForm/FleetSalesInquiryForm";
import SectionTitleSecondary from "../../components/SectionTitleSecondary/SectionTitleSecondary";
import CallToAction from "../../components/CallToAction/CallToAction";

const FleetSalesInquiryPage = () => {

  const { t, i18n } = useTranslation();

  const vehicles = [
    t("Dzire"),
    t("Baleno"),
    t("Ciaz"),
    t("Swift"),
    t("Fronx"),
    t("Grand Vitara"),
    t("Ertiga"),
    t("Jimny"),
    t("Jimny 5 Doors"),
    t("Carry"),
    t("Eeco"),
  ];

  const cities = [t("Riyadh"), t("Jeddah"), t("Dammam"), t("Al Khobar"), t("Al Ahsa"), t("Madinah"), t("Al Kharj"), t("Khamis Mushait"), t("Najran"), t("Onaiza"), t("Mecca")];
  const regions = [t("Central"), t("Western"), t("Eastern")];

  return (
    <>
      <div className="sec-bg">
        <Container className="section-spacing">
          <SectionTitleSecondary
            className="mb-5"
            title={t("Fleet Sales Inquiry")}
            subtitle="Get Suzuki Vehicles for your business"
          />

          <FleetSalesInquiryForm
            cities={cities}
            vehicles={vehicles}
            regions={regions}
          />
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default FleetSalesInquiryPage;
