import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./VehicleBanner.css";

const VehicleBanner = ({ vehicle }) => {

  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  const [selectedColor, setSelectedColor] = useState(vehicle.colors[0]);

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const getColorStyle = (hexCode) => {
    if (hexCode.includes('/')) {
      const [primary, secondary] = hexCode.split('/').map(part => part.trim().toLowerCase());
      return `linear-gradient(90deg, ${primary} 50%, ${secondary} 50%)`;
    }
    return hexCode.toLowerCase();
  };

  return (
    <div className="vehicle-banner">
      <div className="vehicle-banner-content">
        <Image src={selectedColor.image} />
        <div className="color-options">
          {vehicle.colors.map((color, index) => (
            <div
              key={index}
              className={`color-circle ${
                selectedColor.name === color.name ? "selected" : ""
              }`}
              onClick={() => handleColorChange(color)}
              style={{
                background: getColorStyle(color.hexCode),
              }}
              title= {color.customName[currentLanguage]}
            />
          ))}
        </div>
        <p className="selected-color-name">
          { selectedColor.customName[currentLanguage] }
        </p>
      </div>
      <Container>
        <Row>
          <Col md={6}></Col>
          <Col md={6} className="vehicle-info">
            <h1>{vehicle.name}</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VehicleBanner;
