import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import "./BookTestDrive.css";
import vehicles from "../../hooks/vehicles";

const BookTestDrive = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./BookTestDrive-ar.css");
    } else {
      import("./BookTestDrive.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const cities = [t("Riyadh"), t("Jeddah"), t("Dammam"), t("Al Khobar"), t("Al Ahsa"), t("Madinah"), t("Al Kharj"), t("Khamis Mushait"), t("Najran"), t("Onaiza"), t("Mecca")];

  const formik = useFormik({
    initialValues: {
      fullName: "",
      gender: "",
      city: "",
      phone: "",
      email: "",
      vehicle: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required(t("Full Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z ]+$/, t("Only characters are allowed"))
        .min(3, t("Full Name should be at least 3 characters long")),
      gender: Yup.string().required(t("Gender is required")),
      city: Yup.string().required(t("City is required")),
      phone: Yup.string()
        .required(t("Phone is required"))
        .matches(
          /^05\d{8}$/,
          t("Phone should start with 05 and be 10 digits long")
        ),
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid email format")),
      vehicle: Yup.string().required(t("Vehicle is required")),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("name", values.firstName);
      formData.append("gender", values.gender);
      formData.append("phone", values.contactNumber);
      formData.append("email", values.email);
      formData.append("city", values.city);
      formData.append("vehicle", values.vehicle);
      formData.append("model", values.model);

      axios
        .post("https://najeebapi.nhcops.com/public/api/TestDrive", formData)
        .then((response) => {
          setIsSubmitting(false);
          if (response.data === "done") {
            setIsSubmitted(true);
            formik.resetForm();
          } else {
            alert("Error submitting form.");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setSubmissionError("Error submitting form.");
          console.error(error);
        });
    },
  });

  if (isSubmitted) {
    return (
      <div className="success-message text-center">
        <h3>{t("Submitted Successfully!")}</h3>
        <p>{t("Our team will reach you as soon as possible.")}</p>
      </div>
    );
  }

  if (submissionError) {
    return (
      <div className="error-message text-center">
        <h3>{t("Submission Failed!")}</h3>
        <p>{t("Please try again later.")}</p>
      </div>
    );
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formFullName">
              <Form.Label className="brand-label">
                {t("Full Name")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.fullName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.fullName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formGender">
              <Form.Label className="brand-label">
                {t("Select Gender")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.gender}
              >
                <option value="">{t("Select Gender")}</option>
                <option value="Male">{t("Male")}</option>
                <option value="Female">{t("Female")}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label className="brand-label">
                {t("Select City")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.city}
              >
                <option value="">{t("Select City")}</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formPhone">
              <Form.Label className="brand-label">
                {t("Contact Number")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="brand-label">
                {t("Email Address")}<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formVehicle">
              <Form.Label className="brand-label">
                {t("Select Vehicle")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="vehicle"
                value={formik.values.vehicle}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.vehicle}
              >
                <option value="">{t("Select Vehicle")}</option>
                {vehicles
                  .filter((vehicle) => !vehicle.discontinued)
                  .map((vehicle) => (
                    <option
                      key={vehicle.id}
                      value={vehicle.title[currentLanguage]}
                    >
                      {vehicle.title[currentLanguage]}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.vehicle}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-grid gap-2 col-6 mx-auto">
          <Button
            className="form-brand-btn"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner animation="border" size="sm" /> : t("Submit")}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default BookTestDrive;
