const maintenanceCalculatorData = {
  dzire: {
    "10,000km": "497.95",
    "20,000km": "497.95",
    "30,000km": "654.35",
    "40,000km": "1,334.00",
    "50,000km": "963.70",
    "60,000km": "654.35",
    "70,000km": "497.95",
    "80,000km": "1,685.90",
    "90,000km": "1,120.10",
    "100,000km": "497.95",
    "100,000+km": "",
  },
  baleno: {
    "10,000km": "497.95",
    "20,000km": "497.95",
    "30,000km": "654.35",
    "40,000km": "1,361.00",
    "50,000km": "497.95",
    "60,000km": "654.35",
    "70,000km": "497.95",
    "80,000km": "1,652.55",
    "90,000km": "1,169.55",
    "100,000km": "497.95",
    "100,000+km": "",
  },
  ciaz: {
    "10,000km": "497.95",
    "20,000km": "497.95",
    "30,000km": "654.35",
    "40,000km": "1,457.05",
    "50,000km": "497.95",
    "60,000km": "654.35",
    "70,000km": "497.95",
    "80,000km": "1,668.65",
    "90,000km": "1169.55",
    "100,000km": "497.95",
    "100,000+km": "",
  },
  swift: {
    "10,000km": "509.45",
    "20,000km": "509.45",
    "30,000km": "665.85",
    "40,000km": "1,376.55",
    "50,000km": "509.45",
    "60,000km": "665.85",
    "70,000km": "509.45",
    "80,000km": "1,576.65",
    "90,000km": "1,411.05",
    "100,000km": "509.45",
    "100,000+km": "",
  },
  fronx: {
    "10,000km": "511.75",
    "20,000km": "511.75",
    "30,000km": "851.00",
    "40,000km": "1,645.65",
    "50,000km": "511.75",
    "60,000km": "851.00",
    "70,000km": "511.75",
    "80,000km": "1,645.65",
    "90,000km": "1,725.00",
    "100,000km": "511.75",
    "100,000+km": "",
  },

  "grand-vitara": {
    "10,000km": "566.95",
    "20,000km": "749.80",
    "30,000km": "1,021.20",
    "40,000km": "1,705.40",
    "50,000km": "566.95",
    "60,000km": "905.05",
    "70,000km": "566.95",
    "80,000km": "1,705.45",
    "90,000km": "1,780.20",
    "100,000km": "566.95",
    "100,000+km": "",
  },

  ertiga: {
    "10,000km": "497.95",
    "20,000km": "497.95",
    "30,000km": "654.35",
    "40,000km": "1,127.00",
    "50,000km": "497.95",
    "60,000km": "654.35",
    "70,000km": "497.95",
    "80,000km": "1,396.10",
    "90,000km": "1,169.55",
    "100,000km": "497.95",
    "100,000+km": "",
  },

  jimny: {
    "10,000km": "1,0210",
    "20,000km": "555.45",
    "30,000km": "711.85",
    "40,000km": "1,741.10",
    "50,000km": "1,021.20",
    "60,000km": "711.85",
    "70,000km": "555.45",
    "80,000km": "2,060.80",
    "90,000km": "1,177.60",
    "100,000km": "555.45",
    "100,000+km": "",
  },

  "jimny-automatic": {
    "10,000km": "1,267.30",
    "20,000km": "555.45",
    "30,000km": "711.85",
    "40,000km": "1,741.10",
    "50,000km": "555.45",
    "60,000km": "711.85",
    "70,000km": "555.45",
    "80,000km": "2,060.80",
    "90,000km": "1,585.85",
    "100,000km": "555.45",
    "100,000+km": "",
  },

  "jimny-5-doors": {
    "10,000km": "1,244.30",
    "20,000km": "532.45",
    "30,000km": "688.85",
    "40,000km": "1,616.90",
    "50,000km": "998.20",
    "60,000km": "688.85",
    "70,000km": "532.45",
    "80,000km": "1,946.95",
    "90,000km": "1,154.60",
    "100,000km": "532.45",
    "100,000+km": "",
  },


  "jimny-5-doors-automatic": {
    "10,000km": "1,244.30",
    "20,000km": "532.45",
    "30,000km": "688.85",
    "40,000km": "1,616.90",
    "50,000km": "532.45",
    "60,000km": "688.85",
    "70,000km": "532.45",
    "80,000km": "1,946.95",
    "90,000km": "1,562.85",
    "100,000km": "532.45",
    "100,000+km": "",
  },

  carry: {
    "10,000km": "990.15",
    "20,000km": "532.45",
    "30,000km": "688.85",
    "40,000km": "1,099.40",
    "50,000km": "990.15",
    "60,000km": "688.85",
    "70,000km": "738.30",
    "80,000km": "1,099.40",
    "90,000km": "1,204.05",
    "100,000km": "532.45",
    "100,000+km": "",
  },

  eeco: {
    "10,000km": "566.95",
    "20,000km": "566.95",
    "30,000km": "916.55",
    "40,000km": "1,307.55",
    "50,000km": "566.95",
    "60,000km": "916.55",
    "70,000km": "826.85",
    "80,000km": "1,123.55",
    "90,000km": "737.15",
    "100,000km": "1032.70",
    "100,000+km": "",
  },

  apv: {
    "10,000km": "983.25",
    "20,000km": "525.55",
    "30,000km": "668.15",
    "40,000km": "1,046.50",
    "50,000km": "969.45",
    "60,000km": "668.15",
    "70,000km": "511.75",
    "80,000km": "1,298.35",
    "90,000km": "1,183.35",
    "100,000km": "511.75",
    "100,000+km": "",
  },

  sx4: {
    "10,000km": "564.65",
    "20,000km": "564.65",
    "30,000km": "721.05",
    "40,000km": "1,385.75",
    "50,000km": "564.65",
    "60,000km": "721.05",
    "70,000km": "564.65",
    "80,000km": "1,708.90",
    "90,000km": "1,369.65",
    "100,000km": "564.65",
    "100,000+km": "",
  },

  vitara: {
    "10,000km": "564.65",
    "20,000km": "564.65",
    "30,000km": "721.05",
    "40,000km": "1385.75",
    "50,000km": "564.65",
    "60,000km": "721.05",
    "70,000km": "564.65",
    "80,000km": "1,708.90",
    "90,000km": "1,369.65",
    "100,000km": "564.65",
    "100,000+km": "",
  }
};

export default maintenanceCalculatorData;
