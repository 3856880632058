import React , {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import BookTestDrive from "../../components/BookTestDrive/BookTestDrive";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";

const BookTestDrivePage = () => {

    const { t, i18n } = useTranslation();

  return (
    <>
    <div className="sec-bg">
      <Container className="section-spacing">
        <SectionTitlePrimary title={t("Book a Test Drive")} />
        <BookTestDrive />
      </Container>
    </div>
    <CallToAction />
    </>
  );
};

export default BookTestDrivePage;
