// src/pages/BookServiceAppointmentPage/BookServiceAppointmentPage.js
import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import BookServiceAppointment from "../../components/BookServiceAppointment/BookServiceAppointment";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";
import "./BookServiceAppointmentPage.css";

const BookServiceAppointmentPage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./BookServiceAppointmentPage-ar.css");
    } else {
      import("./BookServiceAppointmentPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const cities = [t("Riyadh"), t("Jeddah"), t("Al Khobar"), t("Dammam"), t("Al Ahsa")];
  const serviceLocations = [t("Al Riyadh (Exit 18) - Najeeb Auto"), t("Al Ahsa, Service Center (Al Rashdiyah) - Najeeb Auto"), t("Jeddah (Palestine Street, Rehab) - Najeeb Auto"), t("Khobar (Sinayiat Al Thuqba) - Najeeb Auto"), t("Riyadh (Anas Ibn Malik) - Najeeb Auto")];
  const mileages = [
    "10,000km",
    "20,000km",
    "30,000km",
    "40,000km",
    "50,000km",
    "60,000km",
    "70,000km",
    "80,000km",
    "90,000km",
    "100,000km",
    "100,000+km",
  ];

  return (
    <>
      <div className="sec-bg section-spacing">
        <Container>
          <SectionTitlePrimary
            subtitle="Owners"
            title={t("Book a Service Appointment")}
          />
          <Row className="align-items-center">
            <Col md={12}>
              <div className="section-spacing">
                <div className="form-instruction">
                  <h3>{t("Fill in the form below")}</h3>
                  <p>
                    {t("Service appointment made easy for your Suzuki by Najeeb Auto")}
                  </p>
                </div>
                <BookServiceAppointment
                  cities={cities}
                  serviceLocations={serviceLocations}
                  mileages={mileages}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default BookServiceAppointmentPage;
