import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
import "./ShowroomDetails.css";

const ShowroomDetails = ({ showroom, category }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  if (!showroom) return null;

  console.log("Showroom data:", showroom);
  console.log("Category:", category);

  const renderWorkingHours = () => {
    if (!showroom.workingHours) {
      return <p>{t("Working hours not available")}</p>;
    }
  
    if (category === "Showrooms" || category === "صالات العرض") {
      return (
        <>
          <h6>{t("Sunday to Thursday")}</h6>
          <p className="dir-ltr">{showroom.workingHours.sundayToThursday?.join(", ") || t("Not available")}</p>
          <h6>{t("Saturday")}</h6>
          <p className="dir-ltr">{showroom.workingHours.saturday?.join(", ") || t("Not available")}</p>
          <h6>{t("Friday")}</h6>
          <p className="dir-ltr">{showroom.workingHours.friday?.join(", ") || t("Not available")}</p>
        </>
      );
    } else {
      return (
        <>
          <h6>{t("Saturday to Thursday")}</h6>
          <p className="dir-ltr">{showroom.workingHours.saturdayToThursday?.join(", ") || t("Not available")}</p>
          <h6>{t("Break")}</h6>
          <p className="dir-ltr">{showroom.workingHours.break?.join(", ") || t("Not available")}</p>
          <h6>{t("Friday")}</h6>
          <p className="dir-ltr">{showroom.workingHours.friday?.join(", ") || t("Not available")}</p>
        </>
      );
    }
  };
  
  

  const renderPhoneNumbers = () => {
    return showroom.telephone?.map((number, index) => (
      <p key={index}>
        <a href={`tel:${number}`}>{number}</a>
      </p>
    )) || <p>{t("Not available")}</p>;
  };

  return (
    <Container>
      <div className="showroom-details">
        <Row>
          <Col md={6}>
            <div>
              <h4 className="address-title">{showroom.locationName}</h4>
              <div className="address-details-wrap">
                <div className="address-icon-holder">
                  <i className="jam jam-phone-f"></i>
                </div>
                <div className="address-details-content">
                  <h5>{t("Telephone")}</h5>
                  {renderPhoneNumbers()}
                </div>
              </div>
              <div className="address-details-wrap">
                <div className="address-icon-holder">
                  <i className="jam jam-clock-f"></i>
                </div>
                <div className="address-details-content">
                  <h5>{t("Working Hours:")}</h5>
                  {renderWorkingHours()}
                </div>
              </div>
              <div className="address-details-wrap">
                <div className="address-icon-holder">
                  <i className="jam jam-gps-f"></i>
                </div>
                <div className="address-details-content">
                  <h5>{t("Address")}</h5>
                  <p>
                    <a
                      href={showroom.mapLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {showroom.address}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <iframe
                src={showroom.embeddedMap}
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ShowroomDetails;
