import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import "./WarrantyForm.css";

const WarrantyForm = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./WarrantyForm-ar.css");
    } else {
      import("./WarrantyForm.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const vehicles = [
    t("Dzire"),
    t("Baleno"),
    t("Ciaz"),
    t("Swift"),
    t("Fronx"),
    t("Grand Vitara"),
    t("Ertiga"),
    t("Jimny"),
    t("Jimny 5 Doors"),
    t("Carry"),
    t("Eeco"),
  ];

  const cities = [t("Riyadh"), t("Jeddah"), t("Dammam"), t("Al Khobar")];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const formik = useFormik({
    initialValues: {
      vehicle: "",
      vinNumber: "",
      plateNumber: "",
      registrationDate: "",
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      nationality: "",
      istimara: null,
      city: "",
      gender: "",
    },
    validationSchema: Yup.object({
      vehicle: Yup.string().required(t("Vehicle is required")),
      vinNumber: Yup.string()
        .required(t("VIN number is required"))
        .matches(
          /^[a-zA-Z0-9]{17}$/,
          t("VIN number must be 17 alphanumeric characters")
        ),
      plateNumber: Yup.string()
        .required(t("Plate number is required"))
        .matches(
          /^\d{4}[a-zA-Z]{3}$/,
          t("Plate number must be 4 digits followed by 3 alphabets")
        ),
      registrationDate: Yup.date().required(t("Registration date is required")),
      firstName: Yup.string()
        .required(t("First Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z]+$/, t("Only characters are allowed"))
        .min(3, t("First Name should be at least 3 characters long")),
      lastName: Yup.string()
        .required(t("Last Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z]+$/, t("Only characters are allowed"))
        .min(3, t("Last Name should be at least 3 characters long")),
      contactNumber: Yup.string()
        .required(t("Contact Number is required"))
        .matches(
          /^05\d{8}$/,
          t("Contact Number should start with 05 and be 10 digits long")
        ),
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid email format")),
      nationality: Yup.string()
        .required(t("Nationality is required"))
        .matches(
          /^[\u0600-\u06FFa-z A-Z]{3,}$/,
          t("Nationality must be at least 3 characters and contain no numbers")
        ),
      istimara: Yup.mixed().required(t("Istimara is required")),
      city: Yup.string().required(t("City is required")),
      gender: Yup.string().required(t("Gender is required")),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("Vehicle", values.vehicle);
      formData.append("VIN", values.vinNumber);
      formData.append("Plate_Number", values.plateNumber);
      formData.append("Date", values.registrationDate);
      formData.append("First_name", values.firstName);
      formData.append("Last_name", values.lastName);
      formData.append("Phone", values.contactNumber);
      formData.append("Email", values.email);
      formData.append("Nationality", values.nationality);
      formData.append("Istimara", values.istimara);
      formData.append("City", values.city);
      formData.append("Gender", values.gender);

      axios
        .post(
          "https://najeebapi.nhcops.com/public/api/Suzuki_Warranty",
          formData
        )
        .then((response) => {
          setIsSubmitting(false);
          if (response.data === "done") {
            setIsSubmitted(true);
            formik.resetForm();
          } else {
            alert("Error submitting form.");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setSubmissionError("Error submitting form.");
          console.error(error);
        });
    },
  });

  if (isSubmitted) {
    return (
      <div className="success-message text-center">
        <h3>{t("Submitted Successfully!")}</h3>
        <p>{t("Our team will reach you as soon as possible.")}</p>
      </div>
    );
  } else if (submissionError) {
    return (
      <div className="error-message text-center">
        <h3>{t("Submission Failed!")}</h3>
        <p>{"Please try again after sometime."}</p>
      </div>
    );
  }

  return (
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formVehicle">
              <Form.Label className="brand-label">
                {t("Select Vehicle")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="vehicle"
                value={formik.values.vehicle}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.vehicle}
              >
                <option value="">{t("Select Vehicle")}</option>
                {vehicles.map((vehicle, index) => (
                  <option key={index} value={vehicle}>
                    {vehicle}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.vehicle}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formVinNumber">
              <Form.Label className="brand-label">
                {t("VIN Number")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="vinNumber"
                value={formik.values.vinNumber}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.vinNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.vinNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formPlateNumber">
              <Form.Label className="brand-label">
                {t("Plate Number")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="plateNumber"
                value={formik.values.plateNumber}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.plateNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.plateNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formRegistrationDate">
              <Form.Label className="brand-label">
                {t("Registration Date")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="date"
                name="registrationDate"
                value={formik.values.registrationDate}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.registrationDate}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.registrationDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formFirstName">
              <Form.Label className="brand-label">
                {t("First Name")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formLastName">
              <Form.Label className="brand-label">
                {t("Last Name")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formContactNumber">
              <Form.Label className="brand-label">
                {t("Contact Number")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.contactNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contactNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="brand-label">
                {t("Email Address")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formNationality">
              <Form.Label className="brand-label">
                {t("Nationality")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="nationality"
                value={formik.values.nationality}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.nationality}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.nationality}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formIstimara">
              <Form.Label className="brand-label">
                {t("Upload Istimara")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="file"
                name="istimara"
                onChange={(event) => {
                  formik.setFieldValue(
                    "istimara",
                    event.currentTarget.files[0]
                  );
                }}
                isInvalid={!!formik.errors.istimara}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.istimara}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label className="brand-label">
                {t("Select City")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.city}
              >
                <option value="">{t("Select City")}</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formGender">
              <Form.Label className="brand-label">
                {t("Select Gender")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.gender}
              >
                <option value="">{t("Select Gender")}</option>
                <option value="Male">{t("Male")}</option>
                <option value="Female">{t("Female")}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="formMessage">
              <Form.Label className="brand-label">
                {t("Message")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                as="textarea"
                rows={3}
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-grid gap-2 col-6 mx-auto">
          <Button
            className="form-brand-btn"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner animation="border" size="sm" />
            ) : (
              t("Submit")
            )}
          </Button>
        </div>
      </Form>
   
  );
};

export default WarrantyForm;
