import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import "./CareersForm.css";

const cities = [
  "Jeddah",
  "Mecca",
  "Taif",
  "Riyadh",
  "Madinah",
  "Dammam",
  "Buraydah",
  "Tabuk",
  "Al Khobar",
  "Jazan",
  "Yanbu",
  "Al Hofuf",
  "Khamis Mushait",
  "Al Bahah",
  "Al Kharj",
  "Hafar Al Batin",
  "Bisha",
  "Al Wajh",
  "Neom",
  "Riyadh Al Khabra",
  "Al Ahsa",
];
const departments = [
  "IT",
  "After Sales",
  "Sales",
  "Marketing",
  "HR",
  "Account & Finance",
  "Operations",
  "Others",
];

const CareersForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      nationality: "",
      gender: "",
      currentCity: "",
      yearsOfExperience: "",
      department: "",
      cv: null,
      coverLetter: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First Name is required")
        .matches(/^[\u0600-\u06FFa-z A-Z]+$/, "Only characters are allowed")
        .min(3, "First Name should be at least 3 characters long"),
      lastName: Yup.string()
        .required("Last Name is required")
        .matches(/^[\u0600-\u06FFa-z A-Z]+$/, "Only characters are allowed")
        .min(3, "Last Name should be at least 3 characters long"),
      contactNumber: Yup.string()
        .required("Contact Number is required")
        .matches(
          /^05\d{8}$/,
          "Contact Number should start with 05 and be 10 digits long"
        ),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email format"),
      nationality: Yup.string()
        .required("Nationality is required")
        .matches(/^[\u0600-\u06FFa-z A-Z]+$/, "Only characters are allowed")
        .min(3, "Nationality should be at least 3 characters long"),
      gender: Yup.string().required("Gender is required"),
      currentCity: Yup.string().required("Current City is required"),
      yearsOfExperience: Yup.number()
        .required("Years of Experience is required")
        .min(0, "Experience cannot be negative"),
      department: Yup.string().required("Department is required"),
      cv: Yup.mixed().required("CV is required"),
      coverLetter: Yup.string().required("Cover Letter is required"),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("First_name", values.firstName);
      formData.append("Last_name", values.lastName);
      formData.append("Phone", values.contactNumber);
      formData.append("Email", values.email);
      formData.append("Nationality", values.nationality);
      formData.append("Gender", values.gender);
      formData.append("City", values.currentCity);
      formData.append("Years_of_Experience", values.yearsOfExperience);
      formData.append("Department", values.department);
      formData.append("CV", values.cv);
      formData.append("Cover_Letter", values.coverLetter);

      axios
        .post("https://najeebapi.nhcops.com/public/api/ApplyJob", formData)
        .then((response) => {
          setIsSubmitting(false);
          if (response.data === "done") {
            setIsSubmitted(true);
            formik.resetForm();
          } else {
            alert("Error submitting form.");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setSubmissionError("Error submitting form.");
          console.error(error);
        });
    },
  });

  if (isSubmitted) {
    return (
      <div className="success-message text-center">
        <h3>Submitted Successfully!</h3>
        <p>Our team will reach you as soon as possible.</p>
      </div>
    );
  } else if (submissionError) {
    return (
      <div className="error-message text-center">
        <h3>Submission Failed!</h3>
        <p>Please try again after sometime.</p>
      </div>
    );
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <div className="form-instruction">
          <h3>Fill in the form below</h3>
          <p>
            We will contact you in case of availability of vacancies suitable to
            your profile.
          </p>
        </div>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formFirstName">
              <Form.Label className="brand-label">
                First Name<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formLastName">
              <Form.Label className="brand-label">
                Last Name<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formContactNumber">
              <Form.Label className="brand-label">
                Contact Number<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.contactNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contactNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="brand-label">
                Email Address<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formNationality">
              <Form.Label className="brand-label">
                Nationality<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="nationality"
                value={formik.values.nationality}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.nationality}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.nationality}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formGender">
              <Form.Label className="brand-label">
                Select Gender<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.gender}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCurrentCity">
              <Form.Label className="brand-label">
                Select Current City<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="currentCity"
                value={formik.values.currentCity}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.currentCity}
              >
                <option value="">Select City</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.currentCity}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formYearsOfExperience">
              <Form.Label className="brand-label">
                Years of Experience<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="number"
                name="yearsOfExperience"
                value={formik.values.yearsOfExperience}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.yearsOfExperience}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.yearsOfExperience}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formDepartment">
              <Form.Label className="brand-label">
                Select Department<span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="department"
                value={formik.values.department}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.department}
              >
                <option value="">Select Department</option>
                {departments.map((department, index) => (
                  <option key={index} value={department}>
                    {department}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.department}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCV">
              <Form.Label className="brand-label">
                Upload CV<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="file"
                name="cv"
                onChange={(event) => {
                  formik.setFieldValue("cv", event.currentTarget.files[0]);
                }}
                isInvalid={!!formik.errors.cv}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.cv}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="formCoverLetter">
              <Form.Label className="brand-label">
                Cover Letter<span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                as="textarea"
                rows={3}
                name="coverLetter"
                value={formik.values.coverLetter}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.coverLetter}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.coverLetter}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-grid gap-2 col-6 mx-auto">
          <Button
            className="form-brand-btn"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner animation="border" size="sm" /> : "Submit"}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CareersForm;
