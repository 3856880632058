import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import NewsCard from "../NewsCard/NewsCard";
import news from "../../hooks/news";
import "./NewsSection.css";
import CustomButton from "../CustomButton/CustomButton";
import SectionTitlePrimary from "../SectionTitlePrimary/SectionTitlePrimary";

const NewsSection = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./NewsSection-ar.css");
    } else {
      import("./NewsSection.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="news-section">
      <Container className="section-spacing">
        <SectionTitlePrimary
          subtitle="News"
          title={t("Read the latest news")}
        />
        <Row>
          {news.slice(0, 4).map((newsItem) => (
            <Col key={newsItem.id} md={6} lg={3}>
              <NewsCard news={newsItem} />
            </Col>
          ))}
        </Row>
        <div className="mt-5">
          <CustomButton text={t("More News")} to="/news" />
        </div>
      </Container>
    </div>
  );
};

export default NewsSection;
