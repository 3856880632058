import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NewsCard from "../../components/NewsCard/NewsCard";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";
import news from "../../hooks/news";
import "./NewsPage.css";

const NewsPage = () => {
  return (
    <>
      <div className="section-spacing sec-bg">
        <Container className="news-page">
          <SectionTitlePrimary subtitle="News" title="Latest Updates" />
          <Row>
            {news.map((news) => (
              <Col key={news.id} md={3}>
                <NewsCard news={news} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default NewsPage;
